import React from 'react';
import style from './style.module.scss';
import { prettyTag } from '../../helpers/GridHelper';
import classnames from 'classnames';

export default (params) => {
  return (
    <div className={style['tag-cell-renderer-wrapper']}>
      <div
        className={classnames(
          style[`${prettyTag(params.value)}`],
          style['tag-cell-renderer'],
        )}
      >
        {params.value}
      </div>
    </div>
  );
};
