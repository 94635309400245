export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5208 11.0833C4.39441 11.0833 4.2899 11.042 4.20726 10.9593C4.12462 10.8767 4.0833 10.7722 4.0833 10.6458C4.0833 10.5194 4.12462 10.4149 4.20726 10.3323C4.2899 10.2496 4.39441 10.2083 4.5208 10.2083H8.29788C8.97844 10.2083 9.5642 9.98226 10.0552 9.53018C10.5461 9.07809 10.7916 8.51663 10.7916 7.8458C10.7916 7.17497 10.5461 6.61351 10.0552 6.16143C9.5642 5.70934 8.97844 5.4833 8.29788 5.4833H3.9958L5.35205 6.83955C5.43955 6.92705 5.4833 7.02913 5.4833 7.1458C5.4833 7.26247 5.43955 7.36455 5.35205 7.45205C5.26455 7.53955 5.16247 7.5833 5.0458 7.5833C4.92913 7.5833 4.82705 7.53955 4.73955 7.45205L2.63955 5.35205C2.59094 5.30344 2.55691 5.25483 2.53747 5.20622C2.51802 5.15761 2.5083 5.10413 2.5083 5.0458C2.5083 4.98747 2.51802 4.934 2.53747 4.88538C2.55691 4.83677 2.59094 4.78816 2.63955 4.73955L4.73955 2.63955C4.82705 2.55205 4.92913 2.5083 5.0458 2.5083C5.16247 2.5083 5.26455 2.55205 5.35205 2.63955C5.43955 2.72705 5.4833 2.82913 5.4833 2.9458C5.4833 3.06247 5.43955 3.16455 5.35205 3.25205L3.9958 4.6083H8.2833C9.20691 4.6083 10.0017 4.91941 10.6677 5.54163C11.3336 6.16386 11.6666 6.93191 11.6666 7.8458C11.6666 8.75969 11.3336 9.52774 10.6677 10.15C10.0017 10.7722 9.20691 11.0833 8.2833 11.0833H4.5208Z"
        fill={props.color ? props.color : '#EEC373'}
      />
    </svg>
  );
};
