import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { FormControl } from '@mui/material';
import RefDatas from '@lib/RefDatas';

import style from './style.module.scss';

const colorStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0',
    padding: '0',
    fontSize: '14px',
  }),
  valueContainer: () => ({
    padding: 0,
  }),
  singleValue: () => ({
    color: 'var(--color-default)',
    marginLeft: '2px',
    marginRight: '2px',
    maxWidth: 'calc(100% - 8px)',
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    top: '50%',
    transform: 'translateY(-50%)',
    boxSizing: 'border-box',
  }),
  menu: () => ({
    backgroundColor: 'var(--color-surface)',
    fontSize: 'var(--fs-small)',
    color: 'var(--color-default)',
    position: 'absolute',
    width: '100%',
    transform: 'translateY(100%)',
    bottom: 0,
    left: 0,
    zIndex: 1,
  }),
};

class SuperSelect extends Component {
  static propTypes = {
    refDatas: PropTypes.object,
    input: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.string,
    meta: PropTypes.object,
    classes: PropTypes.object,
    isClearable: PropTypes.bool,
    refKey: PropTypes.string,
    isMulti: PropTypes.bool,
    closeMenuOnSelectboolean: PropTypes.bool,
    options: PropTypes.array,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      id,
      labels,
      data,
      name,
      changeCampsite,
      changePitch,
      pitchesList,
      label = null,
    } = this.props;

    return (
      <Fragment>
        {label && <p>{label}</p>}
        <FormControl sx={{ width: '100%', marginTop: '15px', padding: '0' }}>
          <label className={style['super-select-label']}>
            <small>{labels[0]}</small>
            <select defaultValue={''} onChange={changeCampsite}>
              <option value={''}>TBD</option>
              {data.map((doc, index) => (
                <option key={index} value={doc.campsite._id}>
                  {doc.campsite.lib}
                </option>
              ))}
            </select>
          </label>
        </FormControl>
        {pitchesList?.length > 0 && (
          <FormControl sx={{ width: '100%', marginTop: '15px', padding: '0' }}>
            <label className={style['super-select-label']}>
              <small>{labels[1]}</small>
              <select
                key={pitchesList}
                defaultValue={''}
                onChange={changePitch}
              >
                <option value={''}>TBD</option>
                {pitchesList.map((pitch, index) => (
                  <option key={index} value={pitch.label}>
                    {pitch.label}
                  </option>
                ))}
              </select>
            </label>
          </FormControl>
        )}
      </Fragment>
    );
  }
}

export default SuperSelect;
