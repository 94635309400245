import React from 'react';
import { connect } from 'react-redux';
import MvForm from './MvForm';
import Layout from '@layout/default';
import { withTranslation } from 'react-i18next';
class MvCreateForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    // ! Document title
    document.title = `EUROPEAN CAMPING GROUP | ${this.props.t(
      'page.transfer_create.document_title',
      'Create transfer',
    )}`;

    return (
      <Layout {...this.props}>
        <MvForm {...this.props} isEdit={false} />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: {},
  refDatas: state.refDatas.data,
});
export default withTranslation()(connect(mapStateToProps)(MvCreateForm));
