import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { FormControl } from '@mui/material';
import RefDatas from '@lib/RefDatas';

import style from './style.module.scss';

const colorStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0',
    padding: '0',
    fontSize: '14px',
  }),
  valueContainer: () => ({
    padding: 0,
  }),
  singleValue: () => ({
    color: 'var(--color-default)',
    marginLeft: '2px',
    marginRight: '2px',
    maxWidth: 'calc(100% - 8px)',
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    top: '50%',
    transform: 'translateY(-50%)',
    boxSizing: 'border-box',
  }),
  menu: () => ({
    backgroundColor: 'var(--color-surface)',
    fontSize: 'var(--fs-small)',
    color: 'var(--color-default)',
    position: 'absolute',
    width: '100%',
    transform: 'translateY(100%)',
    bottom: 0,
    left: 0,
    zIndex: 1,
  }),
};

class SuperSelect extends Component {
  static propTypes = {
    refDatas: PropTypes.object,
    input: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.string,
    meta: PropTypes.object,
    classes: PropTypes.object,
    isClearable: PropTypes.bool,
    refKey: PropTypes.string,
    isMulti: PropTypes.bool,
    closeMenuOnSelectboolean: PropTypes.bool,
    options: PropTypes.array,
  };

  render() {
    const {
      input: { onChange },
      id,
      label,
      meta: { touched, error },
      isClearable,
      refDatas,
      refKey,
      isMulti,
      disabled,
      closeMenuOnSelectboolean,
      nativeValues,
    } = this.props;

    if (!nativeValues) {
      let { value } = this.props.input;

      // console.log('SuperSelect $$$$$$$$$$$$$$$$$$$$$$ : ', name, this.props)

      if (value?._id) {
        value = value._id;
      }

      const options = refDatas[refKey];

      if (!Array.isArray(options)) return <div>Ref data not loaded!</div>;

      // Pas trouvé mieux : il faut injecter la value {val:VVV, label:LLL} et non VVV directement...
      // Du coup, il faut que je retrouve l'objet correspondant à la value VVV.
      // Cette valeur, je la trouve dans les refDatas.
      let realValue;
      if (RefDatas.GROUPED_REF_DATA.indexOf(refKey) !== -1) {
        options.forEach((groupedOption) => {
          if (realValue) return;
          realValue = find(groupedOption.options, { val: value });
        });
      } else {
        if (Array.isArray(value)) {
          realValue = value;
        } else {
          realValue = find(options, { val: value });
        }
      }

      return (
        <FormControl sx={{ width: '100%', marginTop: '15px', padding: '0' }}>
          <label className={style['super-select-label']}>
            <small>{label}</small>
            <Select
              isDisabled={disabled}
              isMulti={isMulti}
              isClearable={isClearable}
              closeMenuOnSelect={closeMenuOnSelectboolean}
              onChange={onChange}
              options={options}
              placeholder={label}
              value={realValue}
              menuShouldScrollIntoView={false}
              // By default, value is get in `value` field, not `val`.
              getOptionValue={(option) => option.val}
              // valueKey="val"
              // Spare to rebuild the value's format {val: xxx, label: xxx}
              simpleValue
              menuPlacement="auto"
              // maxMenuHeight={200}
              id={id}
              styles={colorStyles}
            />
          </label>

          {touched && error && <span>{error}</span>}
        </FormControl>
      );
    } else {
      /*
       * nativeValues: {val: xxx, label: xxx}
       */
      let { value } = this.props.input;

      let realValue = nativeValues.find((option) => option.val === value);
      // if (!realValue) realValue = nativeValues[2];

      return (
        <FormControl sx={{ width: '100%', marginTop: '15px', padding: '0' }}>
          <label className={style['super-select-label']}>
            <small>{label}</small>
            <Select
              isDisabled={disabled}
              isMulti={isMulti}
              isClearable={isClearable}
              closeMenuOnSelect={closeMenuOnSelectboolean}
              onChange={onChange}
              options={nativeValues}
              placeholder={label}
              value={realValue}
              // By default, value is get in `value` field, not `val`.
              getOptionValue={(nativeValue) => nativeValue.val}
              // valueKey="val"
              // Spare to rebuild the value's format {val: xxx, label: xxx}
              simpleValue
              menuPlacement="auto"
              // maxMenuHeight={200}
              id={id}
              styles={colorStyles}
            />
          </label>
          {touched && error && <span>{error}</span>}
        </FormControl>
      );
    }
  }
}

export default SuperSelect;
