const File = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.0895 10.3542H8.91033C9.03672 10.3542 9.14124 10.3129 9.22388 10.2303C9.30652 10.1477 9.34783 10.0431 9.34783 9.91675C9.34783 9.79036 9.30652 9.68585 9.22388 9.60321C9.14124 9.52057 9.03672 9.47925 8.91033 9.47925H5.0895C4.96311 9.47925 4.8586 9.52057 4.77596 9.60321C4.69332 9.68585 4.652 9.79036 4.652 9.91675C4.652 10.0431 4.69332 10.1477 4.77596 10.2303C4.8586 10.3129 4.96311 10.3542 5.0895 10.3542V10.3542ZM5.0895 7.87508H8.91033C9.03672 7.87508 9.14124 7.83376 9.22388 7.75112C9.30652 7.66848 9.34783 7.56397 9.34783 7.43758C9.34783 7.31119 9.30652 7.20668 9.22388 7.12404C9.14124 7.0414 9.03672 7.00008 8.91033 7.00008H5.0895C4.96311 7.00008 4.8586 7.0414 4.77596 7.12404C4.69332 7.20668 4.652 7.31119 4.652 7.43758C4.652 7.56397 4.69332 7.66848 4.77596 7.75112C4.8586 7.83376 4.96311 7.87508 5.0895 7.87508ZM3.20825 12.8334C2.97492 12.8334 2.77075 12.7459 2.59575 12.5709C2.42075 12.3959 2.33325 12.1917 2.33325 11.9584V2.04175C2.33325 1.80841 2.42075 1.60425 2.59575 1.42925C2.77075 1.25425 2.97492 1.16675 3.20825 1.16675H8.10825C8.22492 1.16675 8.33915 1.19105 8.45096 1.23966C8.56277 1.28828 8.65756 1.35147 8.73534 1.42925L11.4041 4.098C11.4819 4.17578 11.5451 4.27057 11.5937 4.38237C11.6423 4.49418 11.6666 4.60842 11.6666 4.72508V11.9584C11.6666 12.1917 11.5791 12.3959 11.4041 12.5709C11.2291 12.7459 11.0249 12.8334 10.7916 12.8334H3.20825ZM8.03533 4.31675V2.04175H3.20825V11.9584H10.7916V4.75425H8.47283C8.34645 4.75425 8.24193 4.71293 8.15929 4.63029C8.07665 4.54765 8.03533 4.44314 8.03533 4.31675ZM3.20825 2.04175V4.75425V2.04175V11.9584V2.04175Z"
        fill={props.color ? props.color : '#FFFFFF'}
      />
    </svg>
  );
};

export default File;
