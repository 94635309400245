import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

import style from './style.module.scss';

function between(x, min, max) {
  return x >= min && x < max;
}

function getClassName(name, value) {
  switch (name) {
    case 'humidity':
      return between(value, 0, 30)
        ? { class: 'moderate', label: 'Too dry' }
        : between(value, 30, 60)
        ? { class: 'low', label: 'Optimum' }
        : { class: 'moderate', label: 'Too moist' };
    case 'wind':
      return between(value, 0, 20)
        ? { class: 'low', label: 'Calm / Gentle breeze' }
        : between(value, 20, 50)
        ? { class: 'moderate', label: 'Fresh breeze / Strong breeze' }
        : between(value, 50, 89)
        ? { class: 'hight', label: 'Near gale / Strong gale' }
        : between(value, 89, 118)
        ? { class: 'very-high', label: 'Storm / Violent storm' }
        : { class: 'extreme', label: 'Hurricane' };
    case 'uvi':
      return between(value, 0, 3)
        ? { class: 'low', label: 'Low' }
        : between(value, 3, 6)
        ? { class: 'moderate', label: 'Moderate' }
        : between(value, 6, 8)
        ? { class: 'high', label: 'High' }
        : between(value, 8, 11)
        ? { class: 'very-high', label: 'Very high' }
        : { class: 'extreme', label: 'Extreme' };
    default:
      return { class: '', label: '' };
  }
}

export default ({ days, current }) => {
  const { t } = useTranslation();
  return (
    <div className={style['site-weather']}>
      <div className={style['weekly-weather']}>
        {[current].concat(days).map((day, index) => {
          const _day = dayjs(dayjs.unix(day?.dt).$d).format('LL');
          const _weather = day?.weather[0].main;
          const _weatherIcon = () => {
            switch (day?.weather[0].main) {
              case 'Clouds':
                return '☁️';
              case 'Drizzle':
              case 'Rain':
                return '🌧️';
              case 'Clear':
                return '☀️';
              case 'Snow':
                return '🌨️';
              case 'Thunderstorm':
                return '⛈️';
              default:
                return '🌫️';
            }
          };
          const _humidity = day?.humidity;
          const _uvi = day?.uvi;
          const _temp = day?.temp?.min
            ? {
                min: Math.round(day?.temp.min),
                day: Math.round(day?.temp.day),
                max: Math.round(day?.temp.max),
              }
            : day?.temp;
          const _wind = day?.wind_speed;
          return (
            <div className={style['weather-day']} key={index}>
              <p className={style['weather-label']}>{day?.weather[0].main}</p>

              <div className={style['weather-description']}>
                <div className={style['weather-temperature']}>
                  {_temp?.min !== undefined ? (
                    <Fragment>
                      <p className={style['weather-temperature-day']}>
                        {_temp.day}°
                      </p>
                      <p className={style['weather-temperature-minmax']}>
                        <span>{_temp.min}°</span>
                        <span>{_temp.max}°</span>
                      </p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <p className={style['weather-temperature-day']}>
                        {Math.round(_temp)}°
                      </p>
                      <p className={style['weather-temperature-minmax']}>
                        <span>{t('page.campsite.label.now', 'Now')}</span>
                      </p>
                    </Fragment>
                  )}
                </div>

                <div className={style['weather-details']}>
                  <div className={style['weather-units']}>
                    <p>{_day}</p>
                    <p>
                      <span>
                        💧 {t('page.campsite.label.humidity', 'Humidity')}
                      </span>
                      <span>{_humidity}</span>
                    </p>
                    <p>
                      <span>
                        🍃 {t('page.campsite.label.wind', 'Wind (km/h)')}
                      </span>
                      <span>{_wind}</span>
                    </p>
                    <p>
                      <span>☀️ {t('page.campsite.label.uvi', 'UVI')}</span>
                      <span>{_uvi}</span>
                    </p>
                  </div>
                  <div className={style['weather-icon']}>{_weatherIcon()}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
