const More = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83333 15.6667C9.65278 15.6667 9.50347 15.6076 9.38542 15.4896C9.26736 15.3715 9.20833 15.2222 9.20833 15.0417V10.4583H4.625C4.44444 10.4583 4.29514 10.3993 4.17708 10.2813C4.05903 10.1632 4 10.0139 4 9.83333C4 9.65278 4.05903 9.50347 4.17708 9.38542C4.29514 9.26736 4.44444 9.20833 4.625 9.20833H9.20833V4.625C9.20833 4.44444 9.26736 4.29514 9.38542 4.17708C9.50347 4.05903 9.65278 4 9.83333 4C10.0139 4 10.1632 4.05903 10.2813 4.17708C10.3993 4.29514 10.4583 4.44444 10.4583 4.625V9.20833H15.0417C15.2222 9.20833 15.3715 9.26736 15.4896 9.38542C15.6076 9.50347 15.6667 9.65278 15.6667 9.83333C15.6667 10.0139 15.6076 10.1632 15.4896 10.2813C15.3715 10.3993 15.2222 10.4583 15.0417 10.4583H10.4583V15.0417C10.4583 15.2222 10.3993 15.3715 10.2813 15.4896C10.1632 15.6076 10.0139 15.6667 9.83333 15.6667Z"
        fill={props.color ? props.color : '#FFFFFF'}
      />
    </svg>
  );
};

export default More;
