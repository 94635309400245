import ArticleHeader from '@components/headers/ArticleHeader';
import { useTranslation } from 'react-i18next';
import residentSales from '@assets/lottie/resident_sales.json';
import character from '@assets/images/svg/pitch_information.svg';

import style from './style.module.scss';

export default ({ pricing = '', information = '' }) => {
  const { t } = useTranslation();
  return (
    <section className={style['in-and-out-section']}>
      <article>
        <ArticleHeader
          label={t(
            'page.campsite.label.pitch_resident_sales',
            'Resident sales',
          )}
          description={t(
            'page.campsite.label.pitch_pricing_description',
            'References for pitch rental',
          )}
          lottieJSON={residentSales}
        />
        <div className={style['wrapper']}>
          <figure>
            <img src={character} alt="Character presents information" />
          </figure>
          <div>
            <div>
              <h3>{t('page.campsite.label.pitch_pricing', 'Pitch pricing')}</h3>
              <pre>
                {pricing || t('common.label.unavailable', 'Not available.')}
              </pre>
            </div>
            <div>
              <h3>
                {t(
                  'page.campsite.label.pitch_information',
                  'Pitch information',
                )}
              </h3>
              <pre>
                {information || t('common.label.unavailable', 'Not available.')}
              </pre>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};
