import style from './style.module.scss';
import Edit from '@components/icons/Edit';
import signPost from '@assets/images/pwa/signpost.png';
import { useRef, useState } from 'react';
import BaseButton from '@components/pwa/buttons/BaseButton';
import Letter from '@components/icons/Letter';
import { submitSavePendingUnit } from '@pages/mh/MhSubmit';
// Cache
import CacheManager from '@helpers/cache-helper';

// Helpers
const cache = new CacheManager();

export default (props) => {
  const { refData, barcode, handleClose } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const form = useRef(null);

  const timeline = [
    {
      name: 'Location',
      fields: [
        {
          label: 'Campsite',
          type: 'select',
          ref: refData['RefSite'],
          name: 'site',
          required: true,
        },
        {
          label: 'Pitch',
          name: 'pitch',
        },
        {
          label: 'Barcode',
          value: barcode,
          readOnly: true,
          name: 'barcode',
          required: true,
        },
        {
          label: 'Chassis ID',
          name: 'id_chassis',
          required: true,
        },
      ],
    },
    {
      name: 'Commercial',
      fields: [
        {
          label: 'MH model',
          type: 'select',
          ref: refData['MhModel'],
          name: 'mh_model',
          required: true,
        },
        {
          label: 'Use type',
          type: 'select',
          ref: refData['RefTypeUtilisation'],
          name: 'use_type',
          required: true,
        },
        {
          label: 'Commercial brand',
          type: 'select',
          ref: refData['RefMarqueCommerciale'],
          name: 'commercial_brand',
        },
        {
          label: 'Commercial type',
          type: 'group-select',
          ref: refData['RefTypeCommercial'],
          name: 'commercial_type',
        },
      ],
    },
    {
      name: 'Technical',
      fields: [
        {
          label: 'Deck type',
          type: 'select',
          ref: refData['RefTypeTerrasse'],
          name: 'deck_type',
        },
        {
          label: 'Deck size',
          type: 'select',
          ref: refData['RefTailleTerrasse'],
          name: 'deck_size',
        },
        {
          label: 'Deck year',
          name: 'deck_year',
        },
        {
          label: 'Deck manufacturer',
          type: 'select',
          ref: refData['RefDeckManufacturer'],
          name: 'deck_manufacturer',
        },
        {
          label: 'Energy supply type',
          type: 'select',
          ref: refData['RefGasSupplyType'],
          name: 'gas_supply_type',
        },
        {
          label: 'Cladding color',
          type: 'select',
          ref: refData['RefCladdingColor'],
          name: 'cladding_color',
        },
        {
          label: 'Cladding material',
          type: 'select',
          ref: refData['RefCladdingMaterial'],
          name: 'cladding_material',
        },
      ],
    },
    {
      name: 'Accounting',
      fields: [
        {
          label: 'Owner',
          type: 'select',
          ref: refData['RefOwner'],
          name: 'owner',
        },
        {
          label: 'Fixed asset code',
          name: 'fixed_asset_code',
        },
        {
          label: 'Purchasing leasing code',
          name: 'purchase_leasing_code',
        },
        {
          label: 'Refurbishment leasing code',
          name: 'reno_leasing_code',
        },
      ],
    },
    {
      name: 'Status',
      fields: [
        {
          label: 'Wintering status',
          type: 'select',
          ref: refData['RefStatutHivernage'],
          name: 'wintering_status',
        },
        {
          label: 'Comment',
          name: 'comment',
        },
      ],
    },
  ];

  function inputComponent(field) {
    switch (field.type) {
      case 'select':
        return (
          <select
            className={style['pending-select']}
            id={field.name}
            name={field.name}
            required={field?.required}
            defaultValue=""
          >
            <option disabled value="">
              Please choose a value
            </option>
            {field?.ref?.map((option, index) => (
              <option key={index} value={option.val}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case 'group-select':
        return (
          <select
            className={style['pending-select']}
            id={field.name}
            name={field.name}
            required={field?.required}
            defaultValue=""
          >
            <option disabled value="">
              Please choose a value
            </option>
            {field?.ref?.map((group, index) => (
              <optgroup label={group.label}>
                {group.options.map((option) => (
                  <option key={option.val} value={option.val}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        );
      default:
        return (
          <input
            className={style['pending-input']}
            id={field.name}
            name={field?.readOnly ? null : field.name}
            disabled={field?.readOnly}
            type="text"
            value={field?.value}
            required={field?.required}
            autoComplete="off"
          />
        );
    }
  }

  function saveData(e) {
    e.preventDefault();
    const formData = new FormData(form.current);
    const data = {};
    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }

    if (
      !data?.site ||
      !data?.id_chassis ||
      !data?.mh_model ||
      !data?.use_type
    ) {
      window.alert('⚠️ Missing campsite, chassis ID, Unit model or use type');
      return;
    }

    // ! Mandatory data
    const completeData = {
      ...data,
      barcode,
      renov_status: refData.RefStatutRenov.find(
        (type) => type.label === 'Origine',
      ),
      status: refData.RefStatut.find(
        (type) => type.label === 'Pending Confirmation',
      ),
    };

    if (window.navigator?.onLine) {
      return submitSavePendingUnit(completeData)
        .then((res) => {
          window.alert(`✅ Request for ${barcode} unit sent!`);
          form.current.reset();
          handleClose();
        })
        .catch((err) => {
          window.alert(`⛔ ${err}`);
          form.current.reset();
          handleClose();
        });
    } else {
      // ? Offline mode
      window.alert(
        '⚠️ Please note, your unit will be uploaded to the server when you will be online.',
      );
      cache.readData('requests', (array) => {
        if (!array) {
          // null
          cache.writeData('requests', [completeData]).then(() => {
            form.current.reset();
            handleClose();
          });
        } else {
          // Exists
          cache.writeData('requests', [...array, completeData]).then(() => {
            form.current.reset();
            handleClose();
          });
        }
        return null;
      });
    }
  }

  function updateIndex(sign) {
    setCurrentIndex(currentIndex + sign);
  }

  return (
    <div className={style['pending-wrapper']}>
      <div className={style['pending-current-section']}>
        <div className={style['pending-pages']}>
          {currentIndex + 1}/{timeline.length}
        </div>
      </div>

      <form
        name="form"
        ref={form}
        onSubmit={saveData}
        className={style['pending-form']}
        encType="multipart/form-data"
      >
        {timeline.map((section, i) => (
          <div key={i} className={i !== currentIndex ? style['hidden'] : null}>
            <div className={style['pending-form-header']}>
              <figure>
                <img src={signPost} alt="image" />
              </figure>
              <p>
                <span>{section.name}</span>* required fields
              </p>
            </div>

            {section.fields.map((field, index) => (
              <label key={index} className={style['pending-field']}>
                <span>
                  {field.label}
                  {field?.required && '*'}
                  {!field?.readOnly && <Edit color="#D1D1D1" />}
                </span>
                {inputComponent(field)}
              </label>
            ))}
          </div>
        ))}
        {currentIndex === timeline.length - 1 && (
          <div className={style['send-data']}>
            <BaseButton
              category="Note"
              type="submit"
              content="Make sure to fill every fields before submitting."
              label="Submit"
              svg={<Letter />}
              action={saveData}
            />
          </div>
        )}
      </form>
      <div className={style['pending-buttons']}>
        {currentIndex !== 0 ? (
          <button
            type="button"
            onClick={() => updateIndex(-1)}
            disabled={currentIndex === 0}
          >
            Prev
          </button>
        ) : (
          <div />
        )}
        {currentIndex !== timeline.length - 1 && (
          <button
            type="button"
            onClick={() => updateIndex(1)}
            disabled={currentIndex === timeline.length - 1}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};
