import BackButton from '@components/pwa/buttons/BackButton';
import style from './style.module.scss';

export default (props) => {
  const { action, title } = props;
  return (
    <header className={style['main-header']}>
      <BackButton action={() => action()} />
      <span>{title}</span>
    </header>
  );
};
