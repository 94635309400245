export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.32713 11.0833C1.12296 11.0833 0.974698 11.0152 0.882337 10.8791C0.789976 10.743 0.782684 10.5777 0.860462 10.3833L3.58755 3.45621C3.64588 3.31038 3.75039 3.18399 3.90109 3.07704C4.05178 2.9701 4.20977 2.91663 4.37505 2.91663C4.54032 2.91663 4.69831 2.9701 4.849 3.07704C4.9997 3.18399 5.10421 3.31038 5.16255 3.45621L7.88963 10.3833C7.96741 10.5777 7.96012 10.743 7.86775 10.8791C7.77539 11.0152 7.62227 11.0833 7.40838 11.0833C7.31116 11.0833 7.2188 11.0541 7.1313 10.9958C7.0438 10.9375 6.98546 10.8645 6.9563 10.777L6.24171 8.89579H2.50838L1.7938 10.777C1.76463 10.8645 1.70387 10.9375 1.6115 10.9958C1.51914 11.0541 1.42435 11.0833 1.32713 11.0833ZM2.85838 7.96246H5.89171L4.40421 4.01038H4.34588L2.85838 7.96246ZM11.0834 9.33329C10.957 9.33329 10.8525 9.29197 10.7698 9.20933C10.6872 9.1267 10.6459 9.02218 10.6459 8.89579V7.43746H9.18755C9.06116 7.43746 8.95664 7.39614 8.874 7.3135C8.79137 7.23086 8.75005 7.12635 8.75005 6.99996C8.75005 6.87357 8.79137 6.76906 8.874 6.68642C8.95664 6.60378 9.06116 6.56246 9.18755 6.56246H10.6459V5.10413C10.6459 4.97774 10.6872 4.87322 10.7698 4.79058C10.8525 4.70795 10.957 4.66663 11.0834 4.66663C11.2098 4.66663 11.3143 4.70795 11.3969 4.79058C11.4796 4.87322 11.5209 4.97774 11.5209 5.10413V6.56246H12.9792C13.1056 6.56246 13.2101 6.60378 13.2928 6.68642C13.3754 6.76906 13.4167 6.87357 13.4167 6.99996C13.4167 7.12635 13.3754 7.23086 13.2928 7.3135C13.2101 7.39614 13.1056 7.43746 12.9792 7.43746H11.5209V8.89579C11.5209 9.02218 11.4796 9.1267 11.3969 9.20933C11.3143 9.29197 11.2098 9.33329 11.0834 9.33329Z"
      fill={props.color ? props.color : '#333333'}
    />
  </svg>
);
