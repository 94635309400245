import { TextField, FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import FlashMessage from '@components/common/FlashMessage';
import PitchesSuperSelect from '@components/form/PitchesSuperSelect';
import SuperSelect from '@components/form/SuperSelect';
import { submitTransferMh } from '../MhSubmit';
import SaveV2 from '@components/icons/SaveV2';
import FormButton from '@components/buttons/FormButton';
import { debugMh } from '../../../lib/debug';
import Checkboxes from '@components/form/Checkboxes';
import { AllowedBlock } from '@components/common/AllowedBlock';
import classnames from 'classnames';
import { fetchMv } from '@store/actions/action-mv';
import { fetchMh } from '@store/actions/action-mh';
import { fetchUserSettings } from '@store/actions/action-common';
import { fetchAllPitchesPerCampsite } from '@store/actions/action-pitches';
import ArticleHeader from '@components/headers/ArticleHeader';
import BaseNavigation from '@components/navigations/BaseNavigation';

import brandJSON from '@assets/lottie/brand.json';
import commentsJSON from '@assets/lottie/comments.json';
import locationJSON from '@assets/lottie/location.json';
import refurbishmentJSON from '@assets/lottie/refurbishment.json';
import seasonJSON from '@assets/lottie/season.json';

import CacheManager from '@helpers/cache-helper';
const cache = new CacheManager();

import style from './style.module.scss';
import getUser from '../../../security/User';
import DatePicker from '@components/form/DatePicker';

class MhTransfer extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    selectedItems: PropTypes.array,
    onClose: PropTypes.func,
    t: PropTypes.func,
    refDatas: PropTypes.object,
    classes: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      user: null,
      isTriggered: false,
      expectedValidationDate: Date.now(),
      pitchesPerCampsite: [],
      toCampsite: null,
      toPitch: null,
      pitchesList: [],
    };
    // this.save = this.save.bind(this)
    this.saveAndBack = this.saveAndBack.bind(this);
    this.getFormDatas = this.getFormDatas.bind(this);
    this.changeCampsite = this.changeCampsite.bind(this);
    this.changePitch = this.changePitch.bind(this);
  }

  async componentDidMount() {
    this.setState({
      user: await this.props.dispatch(fetchUserSettings(getUser())),
      pitchesPerCampsite: await this.props.dispatch(
        fetchAllPitchesPerCampsite(),
      ),
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  saveAndBack() {
    this.setState({ isTriggered: !this.state.isTriggered });

    // Adjust data with custom components
    const data = this.getFormDatas();

    data.expected_validation_date = this.state?.expectedValidationDate;
    if (this.state?.toPitch) data.pitch_to = this.state.toPitch;
    if (this.state?.toCampsite) data.site_to = this.state.toCampsite;
    delete data.pitchesPerCampsite;
    delete data.pitchesList;
    delete data.expectedValidationDate;
    delete data.toPitch;
    delete data.toCampsite;

    return submitTransferMh(data, this.props.t).then((jsonMh) => {
      // ! Steve Chadwick create a lot of small Amendments, with the new system (gell all datas on local) he spend a lot of time to wait reload beceause of this auto "redirect"
      let transferArray = [];
      try {
        jsonMh.mobilhome_ids.forEach((id) => {
          this.props.dispatch(fetchMh(id)).then((unit) => {
            const transfer_id = unit.transfer_id[unit.transfer_id.length - 1];
            this.props.dispatch(fetchMv(transfer_id)).then((json) => {
              transferArray.push(json);
            });
          });
        });
      } catch (error) {
        console.info(error);
      }

      cache.addData('mvList', transferArray);
      cache.addData(
        'mhList',
        this.state.user.view_ghosts
          ? { ghosts: jsonMh.ghosts }
          : { units: jsonMh.mobilhome_ids },
        (newValues) => this.props.onClose(newValues),
      );

      return jsonMh;
    });
  }

  // build data object from sources
  getFormDatas() {
    // standard inputs
    const formElement = document.forms.namedItem('mh_transfer_form');
    const formData = new FormData(formElement);
    // state & props
    const data = Object.assign({}, this.state, {
      selected_items: this.props.selectedItems,
    });
    for (let el of formData.entries()) {
      data[el[0]] = el[1];
    }

    return data;
  }

  // Campsite and pitch edition
  // ---------------------------
  changeCampsite(e) {
    const value = e.target.value;
    const pitchesList = this.state.pitchesPerCampsite.find(
      (doc) => doc.campsite._id === value,
    ).pitches;

    this.setState({
      toCampsite: value,
      toPitch: null,
      pitchesList,
    });
  }
  changePitch(e) {
    const value = e.target.value;
    this.setState({
      toPitch: value,
    });
  }

  render() {
    const { refDatas, t, classes } = this.props;
    debugMh('Rendering MhTransfer... props=', this.props);
    const { currentIndex, isTriggered } = this.state;
    let tabs = [
      {
        label: 'New amendment',
        sections: [
          {
            title: t('form.transfer.label.season', 'Season'),
            file: seasonJSON,
            fields: [
              {
                input: {
                  name: 'season',
                  onChange: (value) =>
                    this.setState({
                      season: value !== null && value.val,
                    }),
                },
                name: 'season',
                component: SuperSelect,
                meta: {},
                label: t('form.transfer.label.season', 'Season'),
                nativeValues: (() => {
                  const currentYear = new Date().getFullYear();
                  let seasons = Array.from(
                    { length: 3 },
                    (_, i) => currentYear + i,
                  );
                  return seasons.map((season) => {
                    return { val: season, label: season.toString() };
                  });
                })(),
              },
            ],
          },
          {
            title: t(
              'form.transfer.label.physical_transfer',
              'Physical movement',
            ),
            file: locationJSON,
            fields: [
              {
                input: {
                  name: 'status_physical_amend',
                  onChange: (value) =>
                    this.setState({
                      status_physical_amend: value !== null && value.val,
                    }),
                },
                name: 'status_physical_amend',
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.physical_transfer_status',
                  'Physical movement status',
                ),
                refKey: 'RefStatusPhyAmend',
              },
              // {
              //   input: {
              //     name: 'site_to',
              //     onChange: (value) =>
              //       this.setState({
              //         site_to: value !== null && value.val,
              //       }),
              //   },
              //   component: SuperSelect,
              //   meta: {},
              //   isClearable: true,
              //   refDatas: refDatas,
              //   label: t('form.transfer.label.campsite_to.lib', 'Campsite to'),
              //   refKey: 'Site',
              // },
              // {
              //   name: 'pitch_to',
              //   component: TextField,
              //   type: 'text',
              //   label: t('form.transfer.label.pitch_to', 'Pitch to'),
              //   onChange: (value) => this.setState({ pitch_to: value }),
              // },
              {
                component: PitchesSuperSelect,
                meta: {},
                isClearable: true,
                data: this.state.pitchesPerCampsite,
                changeCampsite: this.changeCampsite,
                changePitch: this.changePitch,
                pitchesList: this.state.pitchesList.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                }),
                labels: [
                  t('form.transfer.label.campsite_to.lib', 'Campsite to'),
                  t('form.transfer.label.pitch_to', 'Pitch to'),
                ],
              },
            ],
          },
          {
            title: t(
              'form.transfer.label.commercial_transfer',
              'Commercial transfer',
            ),
            file: brandJSON,
            fields: [
              {
                input: {
                  name: 'status_comm_amend',
                  onChange: (value) =>
                    this.setState({
                      status_comm_amend: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.commercial_transfer_status',
                  'Commercial transfer status',
                ),
                refKey: 'RefStatusCommAmend',
              },
              {
                input: {
                  name: 'commercial_brand_to',
                  onChange: (value) =>
                    this.setState({
                      commercial_brand_to: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.commercial_brand_to',
                  'Commercial brand to',
                ),
                refKey: 'RefMarqueCommerciale',
              },
              {
                input: {
                  name: 'commercial_type_to',
                  onChange: (value) =>
                    this.setState({
                      commercial_type_to: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.commercial_type_to',
                  'Commercial type to',
                ),
                refKey: 'RefTypeCommercial',
              },
              {
                input: {
                  name: 'use_type_to',
                  onChange: (value) =>
                    this.setState({
                      use_type_to: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t('form.transfer.label.use_type_to', 'Use type to'),
                refKey: 'RefTypeUtilisation',
              },
            ],
          },
          {
            title: t('form.transfer.section.refurbishment', 'Refurbishment'),
            file: refurbishmentJSON,
            fields: [
              {
                input: {
                  name: 'status_refurb',
                  onChange: (value) =>
                    this.setState({
                      status_refurb: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.refurb_status',
                  'Refurbishment status',
                ),
                refKey: 'RefStatusRefurbAmend',
              },
              {
                input: {
                  name: 'refurb_items',
                  onChange: (value) => this.setState({ refurb_items: value }),
                  value: this.state.refurb_items,
                },
                component: Checkboxes,
                meta: {},
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.refurb_items',
                  'Refurbishment items',
                ),
                refKey: 'RefRefurbItems',
              },
              {
                input: {
                  name: 'cladding_color_to',
                  onChange: (value) =>
                    this.setState({
                      cladding_color_to: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.cladding_color_to',
                  'Cladding color to',
                ),
                refKey: 'RefCladdingColor',
              },
            ],
          },
          {
            title: t(
              'form.transfer.section.deck_replacement',
              'Deck replacement',
            ),
            file: refurbishmentJSON,
            fields: [
              {
                input: {
                  name: 'status_deck_replacement',
                  onChange: (value) =>
                    this.setState({
                      status_deck_replacement: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.deck_replacement_status',
                  'Deck replacement status',
                ),
                refKey: 'RefStatusDeckReplacement',
              },
              {
                input: {
                  name: 'deck_replacement_type',
                  onChange: (value) =>
                    this.setState({
                      deck_replacement_type: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.deck_replacement_type',
                  'Deck replacement type',
                ),
                refKey: 'RefDeckReplacementType',
              },
              {
                input: {
                  name: 'deck_type_to',
                  onChange: (value) =>
                    this.setState({
                      deck_type_to: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t('form.transfer.label.deck_type_to', 'Deck type to'),
                refKey: 'RefTypeTerrasse',
              },
              {
                input: {
                  name: 'deck_size_to',
                  onChange: (value) =>
                    this.setState({
                      deck_size_to: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t('form.transfer.label.deck_size_to', 'Deck size to'),
                refKey: 'RefTailleTerrasse',
              },
              {
                input: {
                  name: 'deck_manufacturer_to',
                  onChange: (value) =>
                    this.setState({
                      deck_manufacturer_to: value !== null && value.val,
                    }),
                },
                component: SuperSelect,
                meta: {},
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.deck_manufacturer_to',
                  'Deck manufacturer to',
                ),
                refKey: 'RefDeckManufacturer',
              },
              {
                name: 'deck_year',
                component: TextField,
                type: 'number',
                label: t('form.unit.label.deck_year', 'Deck year'),
              },
            ],
          },
          {
            title: t('form.transfer.label.comment', 'Comment'),
            file: commentsJSON,
            fields: [
              {
                name: 'comment',
                component: TextField,
                label: t('form.transfer.label.comment', 'Comment'),
                row: 4,
                multiline: true,
              },
              {
                name: 'expected_validation_date',
                updatedDate: (date) =>
                  this.setState({ expectedValidationDate: date }),
                component: DatePicker,
                label: t(
                  'form.transfer.label.expected_validation_date',
                  'Expected validation date',
                ),
              },
            ],
          },
        ],
      },
    ];
    return (
      <AllowedBlock roles={'ROLE_WRI_MH_MVT'}>
        <section className={style['form-section']}>
          <FlashMessage />
          <div className={style['form-section-base-navigation']}>
            <BaseNavigation
              items={tabs.map((tab) => tab.label)}
              currentIndex={currentIndex}
              updateIndex={this.changeTab}
            />
          </div>
          <div className={style['form']}>
            <form onSubmit={this.handleSubmit} id="mh_transfer_form">
              <div className={style['content-container']}>
                {tabs[currentIndex].sections.map((section, index) => (
                  <article
                    id={section.title}
                    key={index}
                    className={style['form-input-block']}
                  >
                    <ArticleHeader
                      label={section.title}
                      lottieJSON={section.file}
                    />
                    <div className={style['article-content']}>
                      <div>
                        {section.fields &&
                          section.fields.map((field, i) => {
                            switch (field.component) {
                              case SuperSelect:
                                return <SuperSelect key={i} {...field} />;
                              case PitchesSuperSelect:
                                return (
                                  <PitchesSuperSelect key={i} {...field} />
                                );
                              case TextField:
                                return (
                                  <label
                                    className={style['input-form']}
                                    key={i}
                                  >
                                    <span>{field.name}</span>
                                    <textarea
                                      name={field.name}
                                      rows={field.row}
                                    />
                                  </label>
                                );
                              case Checkboxes:
                                return <Checkboxes key={i} {...field} />;
                              case DatePicker:
                                return <DatePicker key={i} {...field} />;
                              default:
                                return null;
                            }
                          })}
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            </form>
            <div className={style['form-actions']}>
              <div className={style['form-actions-wrapper']}>
                <FormButton
                  action={this.saveAndBack}
                  label="Publish"
                  icon={SaveV2}
                  color="var(--color-surface-octonary)"
                  disabled={isTriggered}
                />
              </div>
            </div>
          </div>
        </section>
      </AllowedBlock>
    );
  }
}

MhTransfer = withTranslation()(MhTransfer);

export default MhTransfer;
