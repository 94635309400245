import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { CookiesProvider } from 'react-cookie';
import '@lib/i18n';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '@assets/scss/variables.scss';
import '@assets/index.scss';
import '@assets/scss/customAgGrid.scss';
import '@assets/scss/transitions.scss';

import * as agGritEnterprise from 'ag-grid-enterprise';
agGritEnterprise.LicenseManager.setLicenseKey(
  process.env.REACT_APP_LICENCE_AG_GRID,
);
import CacheManager from '@helpers/cache-helper';
const cache = new CacheManager();

// service-worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./service-worker.js', {
      scope: '/', // <--- THIS BIT IS REQUIRED
    })
    .then((reg) => {
      // registration worked
      console.log('Enregistrement réussi');
    })
    .catch((error) => {
      // registration failed
      console.log('Erreur : ' + error);
    });
}

// TODO: Standalone application to target only PWA
window.isProgressiveWebApp = function () {
  const mqStandAlone = '(display-mode: standalone)';
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    return true;
  }
  return false;
};

if (!window.isProgressiveWebApp()) {
  // ? Clear cache only on desktop
  cache.clear();
}

const container = document.querySelector('#root');
const root = createRoot(container);
root.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
);
