import React from 'react';
import { useState } from 'react';
import ContentHeader from '@components/headers/ContentHeader';
import History from '@components/icons/History';
import Reload from '@components/icons/Reload';
import Goal from '@components/icons/Goal';
import ReactGA from 'react-ga4';
import BaseButton from '@components/buttons/BaseButton';
import { getTransfersDeadline } from '@store/actions/action-dashboard';
import lottie from 'lottie-web';
import classnames from 'classnames';
import dayjs from 'dayjs';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

import style from './style.module.scss';
import Tippy from '@tippyjs/react';
import truckAnimationJSON from '@assets/lottie/truck.json';
import { useEffect, createRef } from 'react';
import { Fragment } from 'react';

export default (props) => {
  const { t, transfers, history } = props;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [array, setArray] = useState(transfers);

  async function reload() {
    const req = await props.dispatch(getTransfersDeadline());
    setArray(req);
    setIsRefreshing(true);

    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  }

  const getPosition = (date) => {
    const refDate = new Date(date);
    const current = new Date();
    const weekDays = 7;
    const diff = current > refDate ? 0 : refDate.getDate() - current.getDate();

    const percent = 100 * (diff / weekDays);

    return {
      left: `${percent.toFixed(2)}%`,
    };
  };

  const truckComponent = (date) => {
    const truckAnimationRef = createRef();

    useEffect(() => {
      lottie.loadAnimation({
        container: truckAnimationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: truckAnimationJSON,
        name: 'truck',
      });

      return () => {
        lottie.destroy('truck');
      };
    }, []);

    return (
      <div
        className={style['truck']}
        ref={truckAnimationRef}
        style={getPosition(date)}
      />
    );
  };

  return (
    <div className={style['transfers-reminder-container']}>
      <ContentHeader
        icon={History}
        title={t(
          'page.homepage.incoming_transfers.title',
          'Incoming amendments',
        )}
        subtitle={t(
          'page.homepage.incoming_transfers.subtitle',
          'Do not forget to validate your amendments',
        )}
        // light={true}
      />
      <div className={style['wrapper']}>
        {false && (
          <div className={style['reloading']}>
            <BaseButton
              action={() => {
                // GA
                ReactGA.event('logs_panel', {
                  category: 'Fetching the latest transfers deadline',
                });

                reload();
              }}
              label={
                isRefreshing
                  ? t('page.homepage.buttons.fetching', 'Fetching now')
                  : t('page.homepage.buttons.reload', 'Reload now')
              }
              disabled={isRefreshing ? true : false}
            >
              <Reload color="#194989" />
            </BaseButton>
          </div>
        )}

        <ul>
          {Array.isArray(array) &&
            transfers?.map((transfer, index) => (
              <li
                className={style['transfer']}
                key={index}
                onClick={() => history.push(`/mv/${transfer?._id}`)}
              >
                <div className={style['transfer-detailed']}>
                  <div className={style['transfer-detailed-content']}>
                    <div>
                      <p>
                        Campsite:{' '}
                        <strong>{transfer?.site_from?.lib || '-'}</strong>
                      </p>
                      <p className={style['transfer-tag']}>
                        Types:{' '}
                        <strong>
                          {transfer?.transfer_types
                            .map((type) => type?.lib)
                            .join(', ')}
                        </strong>
                      </p>
                    </div>

                    <div className={style['transfer-detailed-barcode']}>
                      <h3>{transfer?.barcode}</h3>
                    </div>
                  </div>
                </div>
                <div className={style['progress']}>
                  <div className={style['progress-bar']}>
                    <Goal />
                    <div className={style['bar']}>
                      <Tippy
                        placement="top"
                        content={`${
                          new Date(
                            transfer?.expected_validation_date,
                          ).getDate() - new Date().getDate()
                        } day(s) left`}
                      >
                        {truckComponent(transfer?.expected_validation_date)}
                      </Tippy>
                    </div>
                  </div>
                  <p
                    className={classnames(
                      style['transfer-date'],
                      new Date(transfer?.expected_validation_date).getDate() -
                        new Date().getDate() <
                        0 && style['expired'],
                    )}
                  >
                    Due date:{' '}
                    {dayjs(new Date(transfer?.expected_validation_date)).format(
                      'll',
                    )}
                  </p>
                </div>
              </li>
              /* <Log log={log} key={index} {...props} /> */
            ))}
        </ul>
      </div>
    </div>
  );
};
