import React from 'react';
import MainHeader from '@components/pwa/MainHeader';
import dayjs from 'dayjs';

import UnitForm from './UnitForm';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

import style from './style.module.scss';

export default (props) => {
  const { refData, barcode, selectedIndex } = props;
  return (
    <div className={style['new-pending-units']}>
      <MainHeader title={'New Pending Unit'} action={() => selectedIndex()} />
      <UnitForm {...props} barcode={barcode} handleClose={selectedIndex} />
    </div>
  );
};
