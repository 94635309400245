export default (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 14 14"
    >
      <g clipPath="url(#a)">
        <path
          d="M7 2.2a.4.4 0 0 1-.4-.4V1A.4.4 0 0 1 7 .6a.4.4 0 0 1 .4.4v.8a.4.4 0 0 1-.4.4Zm0 11.2a.4.4 0 0 1-.4-.4v-.8a.4.4 0 0 1 .4-.4.4.4 0 0 1 .4.4v.8a.4.4 0 0 1-.4.4Zm5.2-6a.4.4 0 0 1-.4-.4.4.4 0 0 1 .4-.4h.8a.4.4 0 0 1 .4.4.4.4 0 0 1-.4.4h-.8ZM1 7.4A.4.4 0 0 1 .6 7a.4.4 0 0 1 .4-.4h.7a.4.4 0 0 1 .5.4.4.4 0 0 1-.5.4H1Zm9.5-4-.1-.2.1-.4.3-.3.4-.1.3.1.1.3-.1.3-.4.4-.3.1-.3-.1Zm-8 8V11l.4-.4.3-.1.3.1.1.3-.1.3-.4.4-.2.1-.4-.1Zm8.3 0-.3-.3-.1-.3.1-.3.3-.1.3.1.4.4.1.3-.1.3-.3.1-.4-.1Zm-8-8-.3-.3-.1-.3.1-.3.3-.1.3.1.4.4.1.3-.1.3-.3.1-.3-.1ZM7 10.4c-1 0-1.7-.3-2.3-1-.7-.6-1-1.4-1-2.3 0-1 .3-1.7 1-2.3.6-.7 1.4-1 2.3-1 1 0 1.7.3 2.3 1 .7.6 1 1.4 1 2.3 0 1-.3 1.7-1 2.3-.6.7-1.4 1-2.3 1Zm0-.9c.7 0 1.2-.2 1.7-.7.5-.5.7-1 .7-1.7s-.2-1.2-.7-1.7c-.5-.5-1-.7-1.7-.7s-1.2.2-1.7.7c-.5.5-.7 1-.7 1.7s.2 1.2.7 1.7c.5.5 1 .7 1.7.7Z"
          fill={props.color ? props.color : '#333333'}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
