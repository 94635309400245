import style from './style.module.scss';

export default (props) => {
  return (
    <div className={style['lds-roller']}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
