import { applyMiddleware, createStore, compose } from 'redux';
import { debugStore as debug } from '../lib/debug';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducers';
import { saveState, loadState } from '../lib/reduxLocalStorage';

// ================================
// Format du store.
// ================================
// {
//    refDatas: {
//      isFetching: BOOL,
//      lastUpdated: TIMESTAMP,
//      data: {
//        RefXXXX: [],
//        ...
//      }
//    },
//    mh: {
//      ...,
//    },
//    mhMovements: []
//    mhList: {
//      isFetching: BOOL,
//      lastUpdated: TIMESTAMP,
//      data: [...],
//      metas: {
//        page: 1,
//        per_page: 15,
//        total_pages: 1514,
//        total_entries: 22701,
//        total_entries_without_fictif: 22609,
//      },
//    },
//    movement: {},
//    movementsList: {},
//    user: {
//     isAuthenticated: BOOL,
//    },
//    userSettings: {
//      list: {
//        mobilhomes: {
//          nbLines: 100,
//          page: 0,
//          columnsState: [{name: 'colname', hidden: true}, ...],
//          filters: {},
//          sort: {},
//          userView: {
//            columns: {}, -> columnState
//            sort: [],  -> agGridSortModel
//            filter: {} -> agGridFilterModel
//          }
//        },
//        transfers: { ... }
//      }
//    },
//    flashMsg: {
//    }
// }
// ================================

let store;
export default () => store;

// export default store
export function configureStore(initialState, options) {
  let { settings } = options;

  debug('##### configureStore: options=', options);

  initialState = Object.assign(loadState(), initialState);
  debug('##### configureStore: initialState=', initialState);

  const enhancer = compose(
    //  persistentStore(db),
    applyMiddleware(thunkMiddleware.withExtraArgument({ settings })),
    typeof window !== 'undefined' && window.devToolsExtension
      ? window.devToolsExtension()
      : (x) => x,
  );

  store = createStore(reducer, initialState, enhancer);

  store.subscribe(() => {
    saveState(store.getState());
  });

  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept('./reducers', () => {
  //     const nextRootReducer = require('./reducers')

  //     store.replaceReducer(nextRootReducer)
  //   })
  // }

  return store;
}
