export default (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 19C4.53333 19 4.35417 18.9292 4.2125 18.7875C4.07083 18.6458 4 18.4667 4 18.25C4 18.0333 4.07083 17.8542 4.2125 17.7125C4.35417 17.5708 4.53333 17.5 4.75 17.5H6.1V9.85C6.1 8.48333 6.5125 7.24583 7.3375 6.1375C8.1625 5.02917 9.25 4.33333 10.6 4.05V3.325C10.6 2.94167 10.7375 2.625 11.0125 2.375C11.2875 2.125 11.6167 2 12 2C12.3833 2 12.7125 2.125 12.9875 2.375C13.2625 2.625 13.4 2.94167 13.4 3.325V4.05C14.75 4.33333 15.8417 5.02917 16.675 6.1375C17.5083 7.24583 17.925 8.48333 17.925 9.85V17.5H19.25C19.4667 17.5 19.6458 17.5708 19.7875 17.7125C19.9292 17.8542 20 18.0333 20 18.25C20 18.4667 19.9292 18.6458 19.7875 18.7875C19.6458 18.9292 19.4667 19 19.25 19H4.75ZM12 22C11.4667 22 11 21.8042 10.6 21.4125C10.2 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22ZM7.6 17.5H16.425V9.85C16.425 8.6 16.0042 7.54583 15.1625 6.6875C14.3208 5.82917 13.2833 5.4 12.05 5.4C10.8 5.4 9.74583 5.82917 8.8875 6.6875C8.02917 7.54583 7.6 8.6 7.6 9.85V17.5Z"
        fill={props.color ? props.color : '#194989'}
      />
    </svg>
  );
};
