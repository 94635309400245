import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTransferTypes } from '../../mv/MvForm';
var localizedFormat = require('dayjs/plugin/localizedFormat');
import dayjs from 'dayjs';
dayjs.extend(localizedFormat);

// Components
import Trash from '@components/icons/Trash';
import { AllowedBlock } from '@components/common/AllowedBlock';
import BaseButton from '@components/buttons/BaseButton';
import DangerousButton from '@components/buttons/DangerousButton';
import Edit from '@components/icons/Edit';
import Timeline from '@vendor/mdpr/components/Timeline/Timeline';
import Tag from '@components/common/Tag';

// Store
import { sendFlashMessage } from '@store/actions/action-common';
import { removeMv } from '@store/actions/action-mv';
import { fetchMhMovements } from '@store/actions/action-mh';

import style from './style.module.scss';

const OneMovement = ({ classes, t, movement }) => (
  <div className={style['table-wrapper']}>
    <table className={style['timeline-table']}>
      <thead>
        <tr>
          <th>{t('common.label.field_name', 'Field name')}</th>
          <th>{t('common.label.before', 'Before')}</th>
          <th>{t('common.label.after', 'After')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{t('form.transfer.label.campsite', 'Campsite')}</th>
          <td
            className={
              movement?.site_from?.lib !== movement?.site_to?.lib
                ? style['transfer-before-state']
                : undefined
            }
          >
            {movement?.site_from ? <p>{movement?.site_from?.lib}</p> : '-'}
          </td>
          <td
            className={
              movement?.site_from?.lib !== movement?.site_to?.lib
                ? style['transfer-after-state']
                : undefined
            }
          >
            {movement?.site_to ? <p>{movement?.site_to?.lib}</p> : ''}
          </td>
        </tr>
        <tr>
          <th>
            {t('form.transfer.label.commercial_brand', 'Commercial brand')}
          </th>
          <td
            className={
              movement.commercial_brand_from?.lib !==
              movement.commercial_brand_to?.lib
                ? style['transfer-before-state']
                : undefined
            }
          >
            {movement.commercial_brand_from ? (
              <p>{movement.commercial_brand_from.lib}</p>
            ) : (
              '-'
            )}
          </td>
          <td
            className={
              movement.commercial_brand_from?.lib !==
              movement.commercial_brand_to?.lib
                ? style['transfer-after-state']
                : undefined
            }
          >
            {movement.commercial_brand_to ? (
              <p>{movement.commercial_brand_to.lib}</p>
            ) : (
              '-'
            )}
          </td>
        </tr>
        <tr>
          <th>{t('form.transfer.label.commercial_type', 'Commercial type')}</th>
          <td
            className={
              movement.commercial_type_from?.lib !==
              movement.commercial_type_to?.lib
                ? style['transfer-before-state']
                : undefined
            }
          >
            {movement.commercial_type_from ? (
              <p>{movement.commercial_type_from.lib}</p>
            ) : (
              '-'
            )}
          </td>
          <td
            className={
              movement.commercial_type_from?.lib !==
              movement.commercial_type_to?.lib
                ? style['transfer-after-state']
                : undefined
            }
          >
            {movement.commercial_type_to ? (
              <p>{movement.commercial_type_to.lib}</p>
            ) : (
              '-'
            )}
          </td>
        </tr>
        <tr>
          <th>{t('form.transfer.label.use_type', 'Use type')}</th>
          <td
            className={
              movement.use_type_from?.lib !== movement.use_type_to?.lib
                ? style['transfer-before-state']
                : undefined
            }
          >
            {movement.use_type_from ? <p>{movement.use_type_from.lib}</p> : '-'}
          </td>
          <td
            className={
              movement.use_type_from?.lib !== movement.use_type_to?.lib
                ? style['transfer-after-state']
                : undefined
            }
          >
            {movement.use_type_to ? <p>{movement.use_type_to.lib}</p> : '-'}
          </td>
        </tr>
        <tr>
          <th>{t('form.transfer.label.pitch', 'Pitch')}</th>
          <td
            className={
              movement.pitch_from !== movement.pitch_to
                ? style['transfer-before-state']
                : undefined
            }
          >
            {movement.pitch_from ? <p>{movement.pitch_from}</p> : '-'}
          </td>
          <td
            className={
              movement.pitch_from !== movement.pitch_to
                ? style['transfer-after-state']
                : undefined
            }
          >
            {movement.pitch_to ? <p>{movement.pitch_to}</p> : '-'}
          </td>
        </tr>
        <tr>
          <th>{t('form.transfer.label.cladding_color', 'Cladding color')}</th>
          <td
            className={
              movement.cladding_color_from?.lib !==
              movement.cladding_color_to?.lib
                ? style['transfer-before-state']
                : undefined
            }
          >
            {movement.cladding_color_from?.lib ? (
              <p>{movement.cladding_color_from.lib}</p>
            ) : (
              '-'
            )}
          </td>
          <td
            className={
              movement.cladding_color_from?.lib !==
              movement.cladding_color_to?.lib
                ? style['transfer-after-state']
                : undefined
            }
          >
            {movement.cladding_color_to?.lib ? (
              <p>{movement.cladding_color_to.lib}</p>
            ) : (
              '-'
            )}
          </td>
        </tr>
        <tr>
          <th>
            {t('form.transfer.label.deck_manufacturer', 'Deck manufacturer')}
          </th>
          <td
            className={
              movement.deck_manufacturer_from?.lib !==
              movement.deck_manufacturer_to?.lib
                ? style['transfer-before-state']
                : undefined
            }
          >
            {movement.deck_manufacturer_from?.lib ? (
              <p>{movement.deck_manufacturer_from.lib}</p>
            ) : (
              '-'
            )}
          </td>
          <td
            className={
              movement.deck_manufacturer_from?.lib !==
              movement.deck_manufacturer_to?.lib
                ? style['transfer-after-state']
                : undefined
            }
          >
            {movement.deck_manufacturer_to?.lib ? (
              <p>{movement.deck_manufacturer_to.lib}</p>
            ) : (
              '-'
            )}
          </td>
        </tr>
        <tr>
          <th>{t('form.transfer.label.deck_size', 'Deck size')}</th>
          <td
            className={
              movement.deck_size_from?.lib !== movement.deck_size_to?.lib
                ? style['transfer-before-state']
                : undefined
            }
          >
            {movement.deck_size_from ? (
              <p>{movement.deck_size_from.lib}</p>
            ) : (
              '-'
            )}
          </td>
          <td
            className={
              movement.deck_size_from?.lib !== movement.deck_size_to?.lib
                ? style['transfer-after-state']
                : undefined
            }
          >
            {movement.deck_size_to ? <p>{movement.deck_size_to.lib}</p> : '-'}
          </td>
        </tr>
        <tr>
          <th>{t('form.transfer.label.deck_type', 'Deck type')}</th>
          <td
            className={
              movement.deck_type_from?.lib !== movement.deck_type_to?.lib
                ? style['transfer-before-state']
                : undefined
            }
          >
            {movement.deck_type_from ? (
              <p>{movement.deck_type_from.lib}</p>
            ) : (
              '-'
            )}
          </td>
          <td
            className={
              movement.deck_type_from?.lib !== movement.deck_type_to?.lib
                ? style['transfer-after-state']
                : undefined
            }
          >
            {movement.deck_type_to ? <p>{movement.deck_type_to.lib}</p> : '-'}
          </td>
        </tr>
        <tr>
          <th>
            {t('form.transfer.label.refurb_items', 'Refurbishment items')}
          </th>
          <td colSpan="2">
            {Array.isArray(movement.refurb_items) &&
            movement?.refurb_items.length
              ? movement.refurb_items.map((i) => i.lib).join(', ')
              : '-'}
          </td>
        </tr>
        <tr>
          <th>{t('form.transfer.label.comment', 'Comment')}</th>
          <td colSpan="2">
            <code>{movement.comment}</code>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

class MhMovementsList extends React.Component {
  static propTypes = {
    mh: PropTypes.object,
    mhMovements: PropTypes.object,
    // refDatas: PropTypes.object,
    t: PropTypes.func,
    dispatch: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.onEditMovement = this.onEditMovement.bind(this);
    this.onRemoveMovement = this.onRemoveMovement.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchMhMovements(this.props.mh._id));
  }

  onEditMovement(movementId) {
    return () => {
      this.props.history.push(`/mv/${movementId}`);
    };
  }
  onRemoveMovement(movementId) {
    return () => {
      if (
        window.confirm(
          `Are you sure to remove this amendment (${movementId}) ?`,
        )
      ) {
        this.props
          .dispatch(removeMv(movementId))
          .then(() =>
            this.props.dispatch(
              sendFlashMessage(
                this.props.t('form.transfer.messages.removed_ok', {
                  id: movementId,
                }),
                'success',
              ),
            ),
          )
          .then(() => this.props.dispatch(fetchMhMovements(this.props.mh._id)))
          .catch((e) => {
            this.props.dispatch(
              sendFlashMessage(
                this.props.t('form.transfer.messages.removed_with_error', {
                  error: `${e}`,
                  id: movementId,
                }),
                'error',
              ),
            );
            console.error('removeMv/fetchMhMovements:', e);
          });
      }
    };
  }

  render() {
    // console.log('Rendering MhMovementsList...');
    const { classes, mhMovements, t } = this.props;

    if (
      !mhMovements ||
      !mhMovements.mobilhome ||
      mhMovements.transfers.length === 0
    )
      return (
        <div style={{ fontSize: 'var(--fs-small)' }}>
          No movements for this mobilhome
        </div>
      );

    const widgetStories = [];
    // I=0 => current mh
    // I>0 => transfers

    mhMovements.transfers.forEach((movement) => {
      widgetStories.push({
        inverted: true,
        title: (
          <header className={style['widget-header']}>
            <div className={style['date']}>
              <div>
                <h2>{`Season ${movement.season}`}</h2>
                <Tag
                  level={movement.locked ? 'success' : 'info'}
                  label={
                    movement.transfer_status
                      ? `STATE: ${movement.transfer_status.lib}`
                      : '-'
                  }
                />
              </div>
              <small>
                {t('common.label.updated_on', 'Updated on')}{' '}
                {movement.updated_at
                  ? `${dayjs(movement.updated_at).format('lll')}`
                  : ''}
              </small>
            </div>
            <div className={style['tags-wrapper']}>
              {getTransferTypes(movement, classes)}
            </div>
          </header>
        ),
        body: (
          <OneMovement
            t={t}
            movement={movement}
            key={movement._id}
            onEditMovement={this.onEditMovement(movement._id)}
            onRemoveMovement={this.onRemoveMovement(movement._id)}
          />
        ),
        // footerTitle: movement.comment,
        footer: (
          <div className={style['timeline-footer']}>
            <AllowedBlock
              roles={['ROLE_ADMIN_MVT', 'ROLE_WRI_MH_MVT']}
              oneOfThem={true}
            >
              <BaseButton
                action={this.onEditMovement(movement._id)}
                label={'More details'}
              >
                <Edit color="#194989" />
              </BaseButton>
            </AllowedBlock>
            {/*movement.locked && (
              <AllowedBlock
                roles={['ROLE_ADMIN_MVT', 'ROLE_WRI_MH_MVT']}
                oneOfThem={true}
              >
                <DangerousButton
                  action={this.onRemoveMovement(movement._id)}
                  label={'Remove'}
                  description="Irreversible action"
                >
                  <Trash />
                </DangerousButton>
              </AllowedBlock>
            )*/}
          </div>
        ),
      });
    });

    //return movements

    return (
      <div className="timeline">
        <Timeline simple stories={widgetStories} />
      </div>
    );
  }
}
MhMovementsList = withTranslation()(MhMovementsList);

const mapStateToProps = ({ mhMovements }) => ({ mhMovements });

MhMovementsList = connect(mapStateToProps)(MhMovementsList);
export default MhMovementsList;
