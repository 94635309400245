import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import FormButton from '@components/buttons/FormButton';
import Trash from '@components/icons/Trash';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import UnLocked from '@components/icons/UnLocked';
import VerifiedIcon from '@components/icons/Verified';
import EmptyVerifiedIcon from '@components/icons/EmptyVerified';
import Tippy from '@tippyjs/react';

import style from './style.module.scss';

export default (props) => {
  const { t } = useTranslation();
  const {
    label,
    disabled = false,
    updateTerms,
    defaultValue,
    subtitle,
  } = props;

  const [dates, setDates] = useState([...defaultValue]);
  const inputRef = useRef(null);
  updateTerms(dates);

  const sum = useMemo(() =>
    dates.length > 0
      ? dates
          ?.map((item) => item.value)
          ?.reduce((a, b) => Number(a) + Number(b))
      : 0,
  );

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const onCalendarChange = (date, index) => {
    const copyDates = dates.map((item) => ({ ...item }));
    copyDates[index].date = date;
    setDates(copyDates);
    updateTerms(copyDates);
  };

  const onInputChange = (e, index) => {
    const value = Number(e.target.value);
    const copyDates = dates.map((item) => ({ ...item }));
    if ((sum === 100 && value > copyDates[index].value) || value > 100) return;

    if (value === 0 && copyDates.length > 1) {
      const index = copyDates.findIndex((item) => item.value === 0);
      copyDates.splice(index, 1);
    } else {
      copyDates[index].value = value;
    }

    setDates(copyDates);
    updateTerms(copyDates);
  };

  const addRow = () => {
    const copyDates = [
      ...dates,
      {
        date:
          dates.length > 0
            ? dates[dates.length - 1].date.addDays(1)
            : new Date(),
        value: 100 - sum,
        locked: false,
      },
    ];
    const index = copyDates.findIndex((item) => item.value === 0);
    if (index >= 0 && copyDates.length > 1) {
      copyDates.splice(index, 1);
      setDates(copyDates);
    } else {
      setDates(copyDates);
    }
  };

  const removeRow = (index) => {
    const copyDates = dates.map((item) => ({ ...item }));
    copyDates.splice(index, 1);
    setDates(copyDates);
  };

  const lockRow = (index) => {
    const copyDates = dates.map((item) => ({ ...item }));
    copyDates[index].locked = true;
    setDates(copyDates);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }, []);

  return (
    <div className={style['term']}>
      <h3>{label}</h3>
      {subtitle && <p className={style['subtitle']}>{subtitle}</p>}
      {dates.map((obj, index) => (
        <div className={style['term-row-wrapper']}>
          <div
            key={index}
            className={classnames(
              style['term-row'],
              (dates[index].locked &&
                dates[index].validation_process?.length === 0) ||
                (!dates[index].locked &&
                  dates[index].validation_process?.length === 0)
                ? style['term-row-validated']
                : '',
            )}
          >
            <label
              className={classnames(
                style['render-field'],
                dates[index].locked ||
                  disabled ||
                  dates[index]?.validation_process?.length === 0
                  ? style['render-field-disabled']
                  : '',
              )}
            >
              <small>Date</small>
              <ReactDatePicker
                dateFormat="dd/MM/yyyy"
                selected={dates[index].date}
                onChange={(date) => !disabled && onCalendarChange(date, index)}
                minDate={index > 0 ? dates[index - 1].date.addDays(1) : null}
                disabled={
                  dates[index].locked ||
                  disabled ||
                  dates[index]?.validation_process?.length === 0
                }
                calendarClassName={style['calendar-style']}
              />
            </label>

            <label
              className={classnames(
                style['render-field'],
                dates[index].locked ||
                  disabled ||
                  dates[index]?.validation_process?.length === 0
                  ? style['render-field-disabled']
                  : '',
              )}
            >
              <small>Value (%)</small>
              <input
                type="number"
                onChange={(e) => !disabled && onInputChange(e, index)}
                value={dates[index].value}
                min={0}
                max={100}
                className={style['input']}
                disabled={
                  dates[index].locked ||
                  disabled ||
                  dates[index]?.validation_process?.length === 0
                }
              />
            </label>

            {!disabled &&
              !dates[index].locked &&
              dates[index]?.validation_process?.length > 0 && (
                <Fragment>
                  <FormButton
                    action={() => removeRow(index)}
                    icon={Trash}
                    disabled={dates.length === 1}
                    color={'var(--color-surface-senary)'}
                  />
                  <FormButton
                    action={() => lockRow(index)}
                    icon={UnLocked}
                    color="var(--color-surface-octonary)"
                  />
                </Fragment>
              )}
            {
              // Meaning it has been validated by everyone
              dates[index].locked &&
              dates[index].validation_process?.length === 0 ? (
                <div className={style['validated']}>SETTLED</div>
              ) : (
                !dates[index].locked &&
                dates[index].validation_process?.length === 0 && (
                  <div className={style['pending']}>PENDING</div>
                )
              )
            }
          </div>

          <div className={style['users']}>
            <small>Status:</small>
            {dates[index].validated_by?.map((user) => {
              return (
                <Tippy
                  key={user.lastname}
                  content={`${user.firstname} ${user.lastname.toUpperCase()}`}
                  placement="bottom"
                >
                  <div className={style['animation']}>
                    <VerifiedIcon />
                  </div>
                </Tippy>
              );
            })}
            {Array.from(
              { length: 3 - dates[index]?.validated_by?.length || 0 },
              (_, i) => (
                <Tippy
                  key={i}
                  content="Waiting for validation"
                  placement="bottom"
                >
                  <div
                    className={classnames(
                      style['empty-icon'],
                      style['animation'],
                    )}
                  >
                    <EmptyVerifiedIcon />
                  </div>
                </Tippy>
              ),
            )}
          </div>
        </div>
      ))}
      {sum < 100 && !disabled && (
        <button className={style['add-more']} type="button" onClick={addRow}>
          Add a row
        </button>
      )}
    </div>
  );
};
