import socketIOClient from 'socket.io-client';
const SOCKET_SERVER_URL = `${process.env.REACT_APP_API_URL}`;
import CacheManager from '../helpers/cache-helper';
const cache = new CacheManager();
const socket = socketIOClient(SOCKET_SERVER_URL);
import { logInfo, logError, logSuccess } from '../helpers/logs';
import { getUserSetting, getAPIToken } from '../security/User';

let errorOccurred = false;

socket.on('connect', () => {
  if (getAPIToken()) {
    logSuccess('Socket io is connected');

    fetch(`${SOCKET_SERVER_URL}/api/get_avatar?access_token=${getAPIToken()}`)
      .then((res) => res.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      })
      .then((dataUrl) => {
        socket.emit('user_connected', {
          name: `${getUserSetting('firstname')} ${getUserSetting('lastname')}`,
          _id: getUserSetting('_id'),
          picture: dataUrl,
          socket_id: socket.id,
        });
      });
  }

  if (errorOccurred) {
    !window.isProgressiveWebApp() && location.reload();
    errorOccurred = !errorOccurred;
  }
});

socket.on('connect_error', (err) => {
  logError(`Connect_error due to ${err.message}`);
  errorOccurred = true;
});

export function refreshData(updatedData, callback) {
  const { key, data } = updatedData;
  logInfo('=============> Socket refreshData');

  return new Promise((resolve, reject) => {
    resolve(cache.updateData(key, data, callback && callback));
  }).then(() => {
    logSuccess(`${key} updated`);
  });
}

export function deleteData(deletedData, callback) {
  const { key, season, transfer_id, mobilhome_id } = deletedData;
  logInfo('=============> Socket deleteData');

  return new Promise((resolve, reject) => {
    resolve(cache.deleteData(key, season, transfer_id, mobilhome_id, callback));
  }).then(() => {
    logSuccess(`Item from ${key} was deleted`);
  });
}

export function addData(addedData, callback) {
  const { key, data, disableCallback } = addedData;
  logInfo('=============> Socket addData');
  return new Promise((resolve, reject) => {
    resolve(
      cache.addData(key, data, () => {
        callback && callback();
      }),
    );
  }).then(() => {
    logSuccess(`Item from ${key} added`);
  });
}

export default socket;
