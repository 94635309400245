import React from 'react';
import { connect } from 'react-redux';
import MhForm from '@pages/mh/MhForm';
import Layout from '@layout/default';
import FlashMessage from '@components/common/FlashMessage';
import ContentHeader from '@components/headers/ContentHeader';
import RedirectionButton from '@components/buttons/RedirectionButton';
import Unit from '@components/icons/Unit';
import Arrow from '@components/icons/Arrow';
import { withTranslation } from 'react-i18next';
import style from './style.module.scss';

class MhCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.backToList = this.backToList.bind(this);
  }

  backToList() {
    this.props.history.push('/mh');
  }

  render() {
    // ! Document title
    document.title = `EUROPEAN CAMPING GROUP | ${this.props.t(
      'page.unit_create.document_title',
      'Create unit',
    )}`;

    return (
      <Layout {...this.props}>
        <FlashMessage />
        <div className={style['unit-form-wrapper']}>
          <div className={style['upper-header']}>
            <RedirectionButton
              icon={Arrow}
              action={this.backToList}
              label={this.props.t(
                'common.action.go_back_units',
                'Go back to units section',
              )}
            />
          </div>
          <MhForm {...this.props} isEdit={false} />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: {},
  refDatas: state.refDatas.data,
});
export default withTranslation()(connect(mapStateToProps)(MhCreateForm));
