import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React from 'react';
import classnames from 'classnames';

import style from './style.module.scss';

const RenderField = (props) => {
  const {
    id,
    input,
    label,
    type,
    meta: { touched, error },
    disabled,
    inputProps,
  } = props;

  const moreProps = {};
  if (props.multiline) {
    moreProps.multiline = props.multiline;
    moreProps.rows = props.rows;
    moreProps.maxRows = props.maxRows;
  }

  let field;
  if (type === 'checkbox') {
    const checked = input.value ? !!input.value : false;
    const inputProps = Object.assign({}, input);
    inputProps.value = '1';
    field = (
      <Checkbox
        id={id}
        disabled={disabled}
        checked={checked}
        {...inputProps}
        {...moreProps}
        margin="normal"
        sx={{
          padding: 0,
          fontSize: '14px',
          marginRight: '10px',
        }}
      />
    );
    if (label) {
      field = (
        <FormControlLabel
          control={field}
          label={label}
          style={{
            cursor: disabled ? 'not-allowed' : 'default',
            opacity: disabled ? 0.5 : 1,
          }}
        />
      );
    }
  } else {
    field = (
      <label
        className={classnames(
          style['render-field'],
          disabled && style['render-field-disabled'],
        )}
      >
        <small>{label}</small>
        <TextField
          id={id}
          variant="standard"
          disabled={disabled}
          {...input}
          {...moreProps}
          type={type}
          margin="normal"
          // To put the label always above the input.
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...inputProps,
            disableUnderline: true,
          }}
          sx={{
            width: '100%',
            margin: 0,
          }}
        />
      </label>
    );
  }

  let render;
  if (type === 'hidden') {
    render = field;
  } else {
    render = (
      <div className={style['render-field-wrapper']}>
        {field}
        {touched && error && <span>{error}</span>}
      </div>
    );
  }
  return render;
};

export default RenderField;
