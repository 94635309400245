import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FlashMessage from '@components/common/FlashMessage';
import { newPassword, sendFlashMessage } from '@store/actions/action-common';

import bg1 from '@assets/images/login/bg-1.jpg';
import bg2 from '@assets/images/login/bg-2.jpg';
import bg3 from '@assets/images/login/bg-3.jpg';
import bg4 from '@assets/images/login/bg-4.jpg';
import bg5 from '@assets/images/login/bg-5.jpg';

import style from './style.module.scss';

class NewPassword extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        tempToken: PropTypes.string,
      }),
    }),
  };

  constructor(props) {
    super(props);

    this.state = { disabledButton: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.password = React.createRef();
    this.passwordConfirm = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(
    //   'NewPassword -> componentDidUpdate -> (prevProps, prevState)',
    //   prevProps,
    //   prevState,
    // );
  }

  handleSubmit(e) {
    e.preventDefault();

    const credentials = {
      tempToken: this.props.match.params.tempToken,
      password: this.password.current.value,
      confirm: this.passwordConfirm.current.value,
    };

    if (credentials.password !== credentials.confirm) {
      console.error('Password are differents');
      this.props.dispatch(sendFlashMessage('Password are different', 'error'));
    } else {
      this.props
        .dispatch(
          newPassword(credentials, () => {
            this.props.history.push('/login');
          }),
        )
        .catch((err) => {
          console.info(err);
        });
    }
  }

  getARandomBackground() {
    const images = [bg1, bg2, bg3, bg4, bg5];

    const index = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const image = images[index(0, images.length - 1)];

    return {
      backgroundImage: `url(${image})`,
    };
  }

  render() {
    // console.log('render, props=', this.props);
    let redirectToReferrer = localStorage.getItem('referrer');
    if (redirectToReferrer && redirectToReferrer != 'undefined')
      redirectToReferrer = JSON.parse(redirectToReferrer);
    else {
      redirectToReferrer = { pathname: '/' };
    }

    if (this.props.user.isAuthenticated && redirectToReferrer) {
      localStorage.removeItem('referrer');
      return <Redirect to={redirectToReferrer} />;
    }

    return (
      <section
        className={style['login-section']}
        style={this.getARandomBackground()}
      >
        <FlashMessage />

        <div className={style['login-container']}>
          <div className={style['grid-item']}>
            <div className={style['wrapper']}>
              <div className={style['form-wrapper']}>
                <form className={style['form']} onSubmit={this.handleSubmit}>
                  <p className={style['form-title']}>Set your new password</p>
                  <label>
                    <span>Type your new Password</span>
                    <input
                      ref={this.password}
                      name="password"
                      type="password"
                      autoFocus
                    />
                  </label>
                  <label>
                    <span>Check your new Password</span>
                    <input
                      ref={this.passwordConfirm}
                      name="passwordConfirm"
                      type="password"
                      autoFocus
                    />
                  </label>
                  <button type="submit" className={style['submit']}>
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>

          <small>
            © {new Date().getFullYear()} European Camping Group. All rights
            reserved.
          </small>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(NewPassword);
