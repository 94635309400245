import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';
import { debugMv } from '@lib/debug';
import dayjs from 'dayjs';
import { CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga4';
import { getUserSetting } from '@security/User';

// Layout
import Layout from '@layout/default';

// Components
import FlashMessage from '@components/common/FlashMessage';
import { Linear } from '@components/Loader';
import { isAllowed } from '@security/User';
import MvGrid from '../MvGrid';
import { AllowedBlock } from '@components/common/AllowedBlock';
import ExportButton from '@components/buttons/ExportButton';
import Excel from '@components/icons/Excel';
import Reload from '@components/icons/Reload';
import Clipboard from '@components/icons/Clipboard';
import GridConfigs from '@components/headers/GridConfigs';
import GridViewConfig from '@components/common/dialogs/GridViewConfig';
import ScrollToTopButton from '@components/buttons/ScrollToTopButton';
import Notification from '@components/icons/Notification';

// Store
import getStore from '@store/store';
import { fetchMvList, cancelAndDuplicateMV } from '@store/actions/action-mv';
import {
  updateFilters,
  updateColumnState,
  toggleCopyFromButton,
  sendFlashMessage,
  validateDataByAdmin,
  receiveNotification,
} from '@store/actions/action-common';
import { clearReduxState } from '@store/actions/action-site';

// Cache
import CacheManager from '@helpers/cache-helper';

// Helpers
import { logInfo } from '@helpers/logs';
import ColumnsDefinition from '@components/helpers/ColumnsDefinition';
import { expandColumns, shrinkColumns } from '@components/helpers/GridHelper';

const cache = new CacheManager();
import style from './style.module.scss';

class MvList extends Component {
  static GRID_NAME = 'transfers';
  static propTypes = {
    settings: PropTypes.shape({}),
    labels: PropTypes.object,
    refDatas: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: true,
      mvList: {},
      showGridView: false,
      nbItemsSelected: 0,
      isFetching: true,
      hasNotification: false,
    };

    this._isMounted = true;

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onColumnStateChanged = this.onColumnStateChanged.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onCopyToExcel = this.onCopyToExcel.bind(this);
    this.onCancelAndDuplicate = this.onCancelAndDuplicate.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onClearCache = this.onClearCache.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadIndexedDB = this.loadIndexedDB.bind(this);
    this.editRow = this.editRow.bind(this);
    this.editMh = this.editMh.bind(this);
    this.validateData = this.validateData.bind(this);
    this.state.excelStyles = this.getExcelStyles();
    this.toggleControlsView = this.toggleControlsView.bind(this);
  }

  componentDidMount() {
    document.title = `EUROPEAN CAMPING GROUP | ${this.props.t(
      'page.transfers.document_title',
      'Transfer list',
    )}`;

    debugMv('componentDidMount after URL params');
    this.fetchData();
    this._isMounted = true;
    getStore().getState().receiveNotification !== null &&
      this.setState({
        hasNotification: getStore().getState().receiveNotification,
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.receiveNotification !== this.props.receiveNotification &&
      this.props.receiveNotification !== null
    ) {
      this.setState({
        hasNotification: this.props.receiveNotification,
      });
    }
  }

  componentWillUnmount() {
    this.setState({ mvList: {} });
    this.api?.destroy && this.api.destroy();
    this._isMounted = false;
  }

  async fetchData() {
    this.setState({ isFetching: true });
    cache.readData('mvList', (res) => {
      if (!res || !res?.data || res?.data.length === 0) {
        logInfo('Fetching data from API');
        sessionStorage.removeItem('transferAwaitingRegistration');
        sessionStorage.removeItem('transferAwaitingRemoving');
        return new Promise((resolve, reject) => {
          resolve(this.props.dispatch(fetchMvList()));
        }).then((res) => {
          if (this._isMounted) {
            cache.writeData('mvList', { data: res });
            this.setState({ mvList: { data: res } });
            this.setState({ isFetching: false });
          }
          this.props.dispatch(
            sendFlashMessage(
              this.props.t(
                'common.flash_message.fetch_transfers',
                'Fetch amendments completed!',
              ),
              'success',
            ),
          );
        });
      } else {
        this.loadIndexedDB(res);
        sessionStorage.removeItem('transferAwaitingRegistration');
        sessionStorage.removeItem('transferAwaitingRemoving');
      }
    });
  }

  loadIndexedDB(res) {
    if (this._isMounted) {
      this.setState({ mvList: res });
      this.setState({ isFetching: false });
      logInfo('Fetching data from IndexedDB');
    }
  }

  getExcelStyles() {
    const cd = new ColumnsDefinition();
    return cd.getExcelStyles();
  }

  getColDefs() {
    const hasWritePerm =
      isAllowed('ROLE_ADMIN_MVT') || isAllowed('ROLE_WRI_MH_MVT');

    const listActions = [
      {
        func: this.editRow,
        labelFunc: 'editRow',
        labelButton: (node) =>
          hasWritePerm && node.data && !node.data.locked
            ? this.props.t('common.action.edit', 'Edit')
            : this.props.t('common.action.view', 'View'),
      },
      // { func: this.editMh, labelFunc: 'editMh', labelButton: 'MH' },
    ];

    // TODO: Enable validateData for later
    // if (isAllowed('ROLE_ADMIN')) {
    //   listActions.push({
    //     func: this.validateData,
    //     labelFunc: 'validateData',
    //     labelButton: 'Validate',
    //   });
    // }

    const cd = new ColumnsDefinition(this.props.refDatas, MvList.GRID_NAME);
    return cd.getMVListConfiguration(listActions, this.props.t);
  }

  editRow(node) {
    debugMv('editRow triggered!', node);
    this.props.history.push(`/mv/${node.id}`);
  }

  validateData(node) {
    // console.log('ValidatData fired ... : ', MvList.GRID_NAME, node.id);
    this.props.dispatch(
      validateDataByAdmin({ gridName: MvList.GRID_NAME, _id: node.id }),
    );
  }

  editMh(node) {
    debugMv('editMh triggered!', node);
    if (get(node, 'data.mobilhome_id._id')) {
      this.props.history.push(`/mh/${node.data.mobilhome_id._id}`);
    } else {
      alert('Empty data, mobilhome ID not found');
      // console.log(node);
    }
  }

  onFilterChanged(e) {
    // Updating filters will refresh the results.
    this.props.dispatch(
      updateFilters(MvList.GRID_NAME, e.api.getFilterModel()),
    );
  }

  onColumnStateChanged(e) {
    debugMv('onColumnStateChanged triggered!', e, e.columnApi.getColumnState());

    // Updating sort will refresh the results.
    this.props.dispatch(
      updateColumnState(MvList.GRID_NAME, e.columnApi.getColumnState()),
    );
  }

  onExport() {
    const nodeSelected = this.api.getSelectedNodes();
    const selectedColumns = this.api.columnController.columnApi
      .getAllGridColumns()
      .filter(
        (column) => column.colId !== 'selection' && column.colId !== 'actions',
      )
      .filter((column) => column.visible);

    if (selectedColumns?.length > 0) {
      const params = {
        fileName: `AssetRegister-ExportAmendments-${dayjs().format(
          'YYYY.MM.DD-hh',
        )}h.xlsx`,
        sheetName: 'Amendments',
        columnKeys: selectedColumns.map((column) => column.colId),
        onlySelected: nodeSelected?.length > 0 ? true : false,
      };

      this.api.exportDataAsExcel(params);
    } else {
      this.props.dispatch(
        sendFlashMessage(
          this.props.t(
            'common.flash_message.an_error_occurred',
            'An error has happened',
          ),
          'error',
        ),
      );
    }
  }

  onAdd() {
    this.props.history.push(`/mv/create`);
  }

  onCopyToExcel() {
    this.props.dispatch(toggleCopyFromButton({ copyFromButton: true }));
    this.api.copySelectedRowsToClipboard(true);
    this.props.dispatch(toggleCopyFromButton({ copyFromButton: false }));

    this.props.dispatch(
      sendFlashMessage(
        this.props.t(
          'common.flash_message.paste_to_excel',
          'Success! Now you can paste into Excel',
        ),
        'success',
      ),
    );

    // TODO: Try this line of code to get the number of row selected
    // console.log('JYO: this.api.getCellRanges: ', this.api.getCellRanges);
  }

  onCancelAndDuplicate() {
    // * La V1 utilise uniquement le premier id du tableau, si besoin d'en avoir plusieurs d'un coup,
    // * retirer la logique qui récupère que le premier ci-dessous et permettre au bouton de s'activer
    // * lorsqu'il y a plus d'une ligne selectionné

    const rowsIds = this.api.getSelectedNodes().map((rowNode) => rowNode.id);
    const firstId = rowsIds.shift();

    this.props.dispatch(cancelAndDuplicateMV(firstId));
  }

  onReset() {
    debugMv('Reset filters and sort...');

    // ? use applyColumnState instead ?
    // this.api.setSortModel(null)
    setTimeout(() => this.api.setFilterModel(null), 150);
  }

  /**
   * Clear IndexedDB and Redux
   */
  onClearCache() {
    cache.clear();
    this.props.dispatch(clearReduxState());
    // Clear object first
    this.setState({ mvList: {} });
    // Then fill the object
    this.fetchData();
  }

  toggleControlsView() {
    this.setState({ expanded: !this.state.expanded });
  }

  onGridReady(api) {
    this.api = api;
    api.addEventListener('selectionChanged', () => {
      const nbItems = api.getSelectedNodes().length;
      this.setState({
        nbItemsSelected: nbItems,
      });
    });
  }

  render() {
    const { t, classes, ...otherProps } = this.props;
    const listSettings = this.props.userSettings.list
      ? this.props.userSettings.list[MvList.GRID_NAME]
      : {};
    const {
      expanded,
      showGridView,
      nbItemsSelected,
      isFetching,
      hasNotification,
    } = this.state;

    if (!listSettings) return <div>LOADING MOVEMENTS...</div>;

    let content;
    if (this.state.mvList && this._isMounted) {
      content = (
        <React.Fragment>
          <div className={style['content-body']}>
            <Linear isFetching={isFetching} {...otherProps} />
            <MvGrid
              mvList={this.state.mvList}
              gridName={MvList.GRID_NAME}
              onFilterChanged={this.onFilterChanged}
              onColumnStateChanged={this.onColumnStateChanged}
              onGridReady={this.onGridReady}
              colDef={this.getColDefs()}
              excelStyles={this.state.excelStyles}
              isFetching={isFetching}
              {...otherProps}
            />
          </div>
          <div className="content-footer"></div>
        </React.Fragment>
      );
    } else {
      content = <div>:( :( :( NO RESULT OR NOT YET LOADED!</div>;
    }

    debugMv('MVLIST RENDER = > this.props=', this.props);
    const customProps = {
      ...otherProps,
      api: this.api,
      gridName: MvList.GRID_NAME,
      expanded: expanded,
    };
    return (
      <Layout {...customProps}>
        <ScrollToTopButton />
        <FlashMessage />
        <header className={style['transfers-header']}>
          <ExportButton
            action={() => {
              // GA
              ReactGA.event('fetch_data', {
                user_interaction: 'Re-fetch data from transfers database',
                browser: navigator.userAgent,
                user_id: getUserSetting('_id').toString(),
                dateTime: dayjs().format(),
              });
              this.onClearCache();
              this.props.dispatch(receiveNotification(false));
            }}
            label={t('page.transfers.button.update', 'Update now')}
            hasNotification={
              hasNotification ||
              !!sessionStorage.getItem('transferAwaitingRegistration') ||
              !!sessionStorage.getItem('transferAwaitingRemoving')
            }
          >
            {hasNotification ||
            !!sessionStorage.getItem('transferAwaitingRegistration') ||
            !!sessionStorage.getItem('transferAwaitingRemoving') ? (
              <Notification />
            ) : (
              <Reload color="#194989" />
            )}
          </ExportButton>
          <ExportButton
            action={() => {
              this.onCopyToExcel();
            }}
            disabled={nbItemsSelected < 1}
            label={t('common.action.copy_to_clipboard', 'Copy to clipboard')}
            description={
              nbItemsSelected === 0
                ? t('common.label.no_selected', '0 selected')
                : `${nbItemsSelected} ${t('common.label.selected', 'selected')}`
            }
          >
            <Clipboard />
          </ExportButton>
          <AllowedBlock roles={'ROLE_EXP_LIST'}>
            <ExportButton
              action={() => {
                this.onExport();
              }}
              label={t('common.action.export_excel', 'Export to excel')}
              description={`${
                this.state.nbItemsSelected && this.state.nbItemsSelected > 0
                  ? `${t('common.label.export', 'Export')} ${
                      this.state.nbItemsSelected
                    } ${t('common.label.row', 'row')}${
                      this.state.nbItemsSelected > 1 ? 's' : ''
                    }`
                  : ''
              } `}
            >
              <Excel />
            </ExportButton>
          </AllowedBlock>
        </header>
        <div className={style['content-container']}>
          <div className={style['content-container-header']}>
            <div className={style['content-container-header-item']}></div>
            <GridConfigs
              api={this.api}
              expandColumns={expandColumns}
              shrinkColumns={shrinkColumns}
              showGridView={() =>
                this.setState({ showGridView: !this.state.showGridView })
              }
              onReset={this.onReset}
            />
          </div>
          {content}
        </div>

        <CSSTransition
          in={showGridView}
          classNames="slideBottom"
          timeout={300}
          unmountOnExit
        >
          <GridViewConfig
            handleClose={() => {
              this.setState({ showGridView: !this.state.showGridView });
            }}
            {...this.props}
            {...customProps}
          />
        </CSSTransition>
      </Layout>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.user,
  userSettings: store.userSettings,
  refDatas: store.refDatas.data,
  receiveNotification: store.receiveNotification,
});
export default withTranslation()(connect(mapStateToProps)(MvList));
