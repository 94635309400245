import style from './style.module.scss';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { isAllowed } from '@security/User';
import classnames from 'classnames';

const ConnectedUsers = ({ name, picture, _id, logged_in }) => {
  const copyToClipboard = () => {
    if (!isAllowed('ROLE_ADMIN')) return;
    navigator.clipboard.writeText(_id);
  };

  return _id || isAllowed('ROLE_ADMIN') ? (
    <figure
      className={classnames(
        style['connected-user'],
        isAllowed('ROLE_ADMIN') ? style['copy'] : '',
      )}
      onClick={copyToClipboard}
    >
      <Tippy
        content={
          isAllowed('ROLE_ADMIN') ? (
            <span>{`${name} | ${_id} | ${logged_in}`}</span>
          ) : (
            name
          )
        }
        placement="top"
      >
        <img alt={name} src={`${picture}`} />
      </Tippy>
    </figure>
  ) : null;
};

export default ConnectedUsers;
