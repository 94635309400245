const Restore = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.49992 7.58337C3.49992 6.62087 3.89075 5.74587 4.52659 5.11004L3.69825 4.28171C2.85825 5.12754 2.33325 6.29421 2.33325 7.58337C2.33325 9.96337 4.11242 11.9234 6.41659 12.2092V11.0309C4.76575 10.7509 3.49992 9.31587 3.49992 7.58337ZM11.6666 7.58337C11.6666 5.00504 9.57825 2.91671 6.99992 2.91671C6.96492 2.91671 6.92992 2.92254 6.89492 2.92254L7.53075 2.28671L6.70825 1.45837L4.66659 3.50004L6.70825 5.54171L7.53075 4.71921L6.90075 4.08921C6.93575 4.08921 6.97075 4.08337 6.99992 4.08337C8.93075 4.08337 10.4999 5.65254 10.4999 7.58337C10.4999 9.31587 9.23409 10.7509 7.58325 11.0309V12.2092C9.88742 11.9234 11.6666 9.96337 11.6666 7.58337Z"
      fill={props.color ? props.color : '#FFFFFF'}
    />
  </svg>
);
export default Restore;
