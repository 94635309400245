import React, { Fragment } from 'react';
import dayjs from 'dayjs';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import style from './style.module.scss';

export default ({ actualValues, plannedValues, siblings, name } = props) => {
  const scheme1 = ['#DDE392', '#AFBE8F', '#7D8570', '#646F58', '#504B3A'];
  const scheme2 = ['#FFBC42', '#D81159', '#8F2D56', '#218380', '#73D2DE'];

  const generateRandomHexColor = (index, scheme) => {
    // const min = Math.ceil(0);
    // const max = Math.floor(scheme.length);
    return scheme[index];

    // const characters = '0123456789ABCDEF';
    // let color = '#';

    // for (let i = 0; i < 6; i++) {
    //   color += characters[Math.floor(Math.random() * 16)];
    // }

    return color;
  };

  const mergeObjectsByDate = (...arrays) => {
    const mergedArray = arrays.reduce((acc, curr) => {
      curr.forEach((obj) => {
        const existingObj = acc.find((item) => item.date === obj.date);
        if (existingObj) {
          Object.assign(existingObj, obj);
        } else {
          acc.push(obj);
        }
      });
      return acc;
    }, []);

    return mergedArray;
  };

  const addPreviousItems = (data, key) => {
    let previousItems = {};

    return data.map((item, index) => {
      item.date = dayjs(item?.date).format('DD/MM/YY');
      item[`${key} ${index}`] = item.value;
      delete item.value;
      const newItem = Object.assign({}, previousItems, item);
      previousItems = Object.assign({}, previousItems, item);
      return newItem;
    });
  };

  let copyPlannedValues = plannedValues
    .map((item) => ({ ...item }))
    .sort((a, b) => a.date - b.date);
  copyPlannedValues = addPreviousItems(copyPlannedValues, 'Planned Term Rank');

  let copyActualValues = actualValues
    .map((item) => ({ ...item }))
    .sort((a, b) => a.date - b.date);
  copyActualValues = addPreviousItems(copyActualValues, 'Actual Term Rank');

  const data = mergeObjectsByDate([...copyActualValues, ...copyPlannedValues]);

  const totalPriceFunction = (objects) => {
    let sum = 0;

    objects.forEach((object) => {
      const _total =
        object.quantity * object.value +
        object.quantity * object.value * object.tax;
      sum += _total;
    });

    return sum.toFixed(2);
  };

  const totalPrice = totalPriceFunction(
    siblings?.map((sibling) => ({
      quantity: sibling.quantity?.actual || 0,
      value: sibling.unit_price_excluding_tax?.actual || 0,
      tax: sibling.tax_rate || 0,
    })),
  );

  const currencyFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  });

  let actualValidatedPercent = (actualValues || []).map((term) =>
    term.locked ? term.value : 0,
  );
  actualValidatedPercent =
    actualValidatedPercent.length > 0 &&
    actualValidatedPercent.reduce((a, b) => a + b);

  return (
    <div className={style['container']} noname={name}>
      <div className={style['prices']}>
        <h3>{`Pitches in ${siblings[0].site.lib} [${siblings[0].site.code_group}]`}</h3>
        <p>
          <span>
            <strong className={style['tag']}>Total price (w/ tax)</strong>
          </span>
          <strong>{currencyFormat.format(totalPrice)}</strong>
        </p>
        <p>
          <span>
            <strong className={style['tag']}>Currently paid (w/ tax)</strong>
          </span>
          <strong>
            {currencyFormat.format((totalPrice * actualValidatedPercent) / 100)}
          </strong>
          {/* <strong>{currencyFormat.format(totalPrice * 0.5)}</strong> tax */}
        </p>
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis unit="%" />
          <Tooltip />
          <Legend />

          {Array.from(
            { length: copyPlannedValues.length },
            (_, plannedTermIndex) => (
              <Bar
                key={'planned' + plannedTermIndex}
                dataKey={`Planned Term Rank ${plannedTermIndex}`}
                stackId="plan"
                fill={generateRandomHexColor(plannedTermIndex, scheme1)}
              />
            ),
          )}
          {Array.from(
            { length: copyActualValues.length },
            (_, actualTermIndex) => (
              <Bar
                key={'actual' + actualTermIndex}
                dataKey={`Actual Term Rank ${actualTermIndex}`}
                stackId="actual"
                fill={generateRandomHexColor(actualTermIndex, scheme2)}
              />
            ),
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
