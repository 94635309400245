import style from './style.module.scss';

export default ({ label, icon: Icon, action }) => {
  return (
    <button
      type="button"
      className={style['redirection-button']}
      onClick={action}
    >
      {Icon && (
        <div className={style['icon-wrapper']}>
          <Icon />
        </div>
      )}
      {label && <span>{label}</span>}
    </button>
  );
};
