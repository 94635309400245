//
// MH List Grid
// Use ag-grid.com
//
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseGrid from '@components/BaseGrid';
import { submitSaveSite } from './SiteSubmit';
import RefDatas from '@lib/RefDatas';
import { withTranslation } from 'react-i18next';

class SiteGrid extends PureComponent {
  static propTypes = {
    userSettings: PropTypes.object,
    history: PropTypes.object,
    siteList: PropTypes.shape({
      data: PropTypes.array,
      metas: PropTypes.object,
    }),
    gridName: PropTypes.string.isRequired,
    refDatas: PropTypes.object,
    onSortChanged: PropTypes.func,
    onFilterChanged: PropTypes.func,
    // Mutualized event listener on column state changed.
    onColumnStateChanged: PropTypes.func,
    colDef: PropTypes.array,
    onGridReady: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.onCellValueChanged = this.onCellValueChanged.bind(this);

    // this.undoLastEdit = this.undoLastEdit.bind(this)
    this.onGridReady = this.onGridReady.bind(this);
  }

  onGridReady(params) {
    this.api = params.api;
    // this.columnApi = params.columnApi
    this.props.onGridReady(params.api);
  }

  onCellValueChanged(e) {
    // debugSite('SiteGrid => onCellValueChanged triggered!', e)
    let idColValue = e.value;

    // handle copy/paste : in that case, we are receiving the full raw object
    if (
      typeof e.value === 'object' &&
      (e.value.value || e.value.val || e.value.id)
    ) {
      idColValue = e.value.value || e.value.val || e.value.id;
    }
    // build params for submit
    const values = {
      _id: e.data.id,
      [e.colDef.field]: idColValue,
    };
    // debugSite('SiteGrid => onCellValueChanged triggered, values =', JSON.stringify(values))

    // call server and on callback, flash cell to indicate that it as been updated

    return submitSaveSite(values, this.props.t).then(() => {
      if (RefDatas.GROUPED_REF_DATA.includes(e.colDef.refData)) {
        // FIXME bug connu : il faudrait fetcher toute la ligne pour récupérer l'info des colonnes calculées :
        // commercial_type.parent_lib, commercial_type.group, commercial_type.is_terrasse, commercial_type.is_clim, commercial_type.is_empl_premium
        // debugSite('this.api.refreshCells({ rowNodes: [e.node] }): ', e.node)
        return (
          !e.api.destroyCalled && e.api.refreshCells({ rowNodes: [e.node] })
        );
      } else {
        return e.api.flashCells({ rowNodes: [e.node], columns: [e.column] });
      }
    });
  }

  // // Undo the last edit with provided datas = submit Save with old values...
  // undoLastEdit(data) {
  //   // debugSite('undoLastEdit triggered, data =', data)
  //   const value = {
  //     _id: data._id,
  //     [data.field]: data.oldValue,
  //   }
  //   debugSite('undoLastEdit => value to undo: ', value)

  //   // on callback, refresh values in grid
  //   // return submitSaveSite(value).then(() => {
  //   //   const rowNode = this.api.getRowNode(data._id)
  //   //   rowNode.setDataValue(data.field, data.oldValue)
  //   //   // FIXME bug connu : il faudrait fetcher toute la ligne pour récupérer l'info des colonnes calculées :
  //   //   // commercial_type.parent_lib, commercial_type.group, commercial_type.is_terrasse, commercial_type.is_clim, commercial_type.is_empl_premium
  //   //   return this.api.refreshCells({ rowNodes: [rowNode], columns: [data.field] })
  //   // })
  // }

  render() {
    // debugSite('render => this.props.refDatas: ', this.props.refDatas)
    const gridName = this.props.gridName;
    const { data } = this.props.siteList;

    return (
      <BaseGrid
        gridName={gridName}
        userListSettings={this.props.userSettings.list[gridName]}
        columnDefs={this.props.colDef}
        rowData={data}
        onSortChanged={this.props.onSortChanged}
        onFilterChanged={this.props.onFilterChanged}
        onColumnStateChanged={this.props.onColumnStateChanged}
        onCellValueChanged={this.onCellValueChanged}
        onGridReady={this.onGridReady}
        isFetching={this.props.isFetching}

        // undo={this.undoLastEdit}
      />
    );
  }
}
export default withTranslation()(SiteGrid);
