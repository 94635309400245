import style from './style.module.scss';

export default (props) => {
  const { list, selectedIndex } = props;
  return (
    <div className={style['base-list']}>
      {list.map((item, index) => (
        <div
          className={style['base-list-item']}
          key={index}
          onClick={() => selectedIndex('guide', index)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};
