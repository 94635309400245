import MainHeader from '@components/pwa/MainHeader';
import style from './style.module.scss';

export default (props) => {
  const { news, action } = props;
  return (
    <div className={style['news-view']}>
      <MainHeader title={news.tag} action={action} />

      <div className={style['news-content']}>
        <span className={style['news-content-title']}>{news.title}</span>
        <p>{news.content}</p>
      </div>
    </div>
  );
};
