export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.47921 16.6666V15.4166H6.18754L5.87504 15.1666C4.98615 14.4583 4.34032 13.6874 3.93754 12.8541C3.53476 12.0208 3.33337 11.0902 3.33337 10.0624C3.33337 8.5902 3.7674 7.26728 4.63546 6.09367C5.50351 4.92006 6.64587 4.11103 8.06254 3.66659V4.95825C7.02087 5.36103 6.1806 6.03117 5.54171 6.96867C4.90282 7.90617 4.58337 8.93742 4.58337 10.0624C4.58337 10.9374 4.74657 11.6978 5.07296 12.3437C5.39935 12.9895 5.84032 13.5485 6.39587 14.0208L7.02087 14.4583V11.8749H8.27087V16.6666H3.47921ZM11.9584 16.3541V15.0416C13.0139 14.6388 13.8542 13.9687 14.4792 13.0312C15.1042 12.0937 15.4167 11.0624 15.4167 9.93742C15.4167 9.27075 15.2535 8.59367 14.9271 7.90617C14.6007 7.21867 14.1737 6.61103 13.6459 6.08325L13.0417 5.54159V8.12492H11.7917V3.33325H16.5834V4.58325H13.8542L14.1667 4.87492C15 5.6527 15.625 6.48603 16.0417 7.37492C16.4584 8.26381 16.6667 9.11797 16.6667 9.93742C16.6667 11.4096 16.2362 12.736 15.375 13.9166C14.5139 15.0971 13.375 15.9096 11.9584 16.3541V16.3541Z"
      fill={props.color ? props.color : '#194989'}
    />
  </svg>
);
