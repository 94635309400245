import style from './style.module.scss';

export default (props) => {
  return (
    <div className={style['popup']}>
      <span>Advices</span>
      <p>
        Ensure to have enough light before scanning the barcode. Still not
        working? Please use the search input in the Homepage.
      </p>
    </div>
  );
};
