import style from './style.module.scss';
import Unit from '@components/icons/Unit';

export default ({ items }) => {
  return (
    <ul className={style['campsite-key-figures']}>
      {items.map((item, index) =>
        item?.value ? (
          <li key={index}>
            <figure>
              <img src={item?.icon} alt={item?.brand} title={item?.brand} />
            </figure>
            <div>
              <p>{item?.brand}</p>
              <small>{item.description}</small>
            </div>
            <div>
              <span>{item?.value}</span>
              <Unit />
            </div>
          </li>
        ) : null,
      )}
    </ul>
  );
};
