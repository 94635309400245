import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Select from 'react-select'
import { groupStyles, groupBadgeStyles } from './SelectStyles'

// Stateless component, based on props
export default class SelectList extends Component {
  static propTypes = {
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    group: PropTypes.bool,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    menuIsOpen: PropTypes.bool,
    onChange: PropTypes.func,
    closeMenuOnSelect: PropTypes.bool,
    hideSelectedOptions: PropTypes.bool,
    menuPosition: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    autoFocus: PropTypes.bool,
    styles: PropTypes.object,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.formatGroupLabel = this.formatGroupLabel.bind(this)

    this.state = {
      timeouts: [],
    }
  }

  // build group labels for specific lists
  formatGroupLabel(data) {
    if (this.props.group) {
      return (
        <div style={groupStyles}>
          <span>{data.label}</span>
          <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
      )
    } else {
      return false
    }
  }

  render() {
    const { selected, options } = this.props
    // console.log('SelectList => render, 'options: ', options, 'selected: ', selected)
    return (
      <Select
        value={selected}
        onChange={this.props.onChange}
        options={options}
        isClearable={this.props.isClearable}
        isMulti={this.props.isMulti}
        isSearchable={this.props.isSearchable}
        closeMenuOnSelect={this.props.closeMenuOnSelect}
        hideSelectedOptions={this.props.hideSelectedOptions}
        menuIsOpen={this.props.menuIsOpen}
        formatGroupLabel={this.formatGroupLabel}
        menuPortalTarget={document.body}
        menuPlacement={'auto'}
        autoFocus={this.props.autoFocus}
        openMenuOnFocus
        openMenuOnClick
        className={this.props.className}
        styles={this.props.styles}
      />
    )
  }
}
