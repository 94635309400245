const ResetFilter = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.21877 5.62505L6.09377 4.50005H15.1875C15.35 4.50005 15.4844 4.55317 15.5906 4.65942C15.6969 4.76567 15.75 4.90005 15.75 5.06255C15.75 5.22505 15.6969 5.35942 15.5906 5.46567C15.4844 5.57192 15.35 5.62505 15.1875 5.62505H7.21877ZM11.1563 9.56255L10.0313 8.43755H12.9375C13.1 8.43755 13.2344 8.49067 13.3406 8.59692C13.4469 8.70317 13.5 8.83755 13.5 9.00005C13.5 9.16255 13.4469 9.29692 13.3406 9.40317C13.2344 9.50942 13.1 9.56255 12.9375 9.56255H11.1563ZM9.93752 13.5H8.06252C7.90002 13.5 7.76565 13.4469 7.6594 13.3407C7.55315 13.2344 7.50002 13.1 7.50002 12.9375C7.50002 12.775 7.55315 12.6407 7.6594 12.5344C7.76565 12.4282 7.90002 12.375 8.06252 12.375H9.93752C10.1 12.375 10.2344 12.4282 10.3406 12.5344C10.4469 12.6407 10.5 12.775 10.5 12.9375C10.5 13.1 10.4469 13.2344 10.3406 13.3407C10.2344 13.4469 10.1 13.5 9.93752 13.5ZM14.85 16.4438L7.95002 9.56255H5.06252C4.90002 9.56255 4.76565 9.50942 4.6594 9.40317C4.55315 9.29692 4.50002 9.16255 4.50002 9.00005C4.50002 8.83755 4.55315 8.70317 4.6594 8.59692C4.76565 8.49067 4.90002 8.43755 5.06252 8.43755H6.82502L1.53752 3.15005C1.42502 3.03755 1.36877 2.90317 1.36877 2.74692C1.36877 2.59067 1.42502 2.4563 1.53752 2.3438C1.65002 2.2313 1.7844 2.17505 1.94065 2.17505C2.0969 2.17505 2.23127 2.2313 2.34377 2.3438L15.6563 15.6563C15.7688 15.7688 15.825 15.9 15.825 16.05C15.825 16.2 15.7688 16.3313 15.6563 16.4438C15.5438 16.5563 15.4094 16.6125 15.2531 16.6125C15.0969 16.6125 14.9625 16.5563 14.85 16.4438V16.4438ZM4.01252 4.50005V5.62505H2.81252C2.65002 5.62505 2.51565 5.5688 2.4094 5.4563C2.30315 5.3438 2.25002 5.21255 2.25002 5.06255C2.25002 4.88755 2.30002 4.75005 2.40002 4.65005C2.50002 4.55005 2.63752 4.50005 2.81252 4.50005H4.01252Z"
        fill={props.color ? props.color : '#333333'}
      />
    </svg>
  );
};

export default ResetFilter;
