import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { isAllowed } from '../../security/User';

//
// HOC to manage permissions on fields.
// name: must contain a namespace as a prefix : `mh.site`, `mv.site_from`, ...
// This namespace is used to guess the field permission.
//
const AllowedField = ({ children, mvLocked, disabled, ...props }) => {
  // if (isAllowed(props.name))
  const splitArray = props.name.split(/\.(.*)/s);
  splitArray.pop();
  const [ns, name] = splitArray;

  props.name = name;

  const allowed = isFieldAllowed(ns, name, { mvLocked });

  // console.log(ns, props.name, '=>', allowed)
  return (
    <Field {...props} disabled={!allowed || disabled === true}>
      {children}
    </Field>
  );
};
AllowedField.propTypes = {
  // Only for amendments.
  mvLocked: PropTypes.bool,
  name: PropTypes.string,
  children: PropTypes.object,
};

//
// The backbone of the field permissions:
// For each field, return if the authenticated used is allowed to write it.
// Otherwise, the field will be disabled (=readonly).
//
export function isFieldAllowed(ns, name, options) {
  // console.log('TCL: isFieldAllowed -> (ns, name, options)', ns, name, options)
  var ret = false;

  switch (ns) {
    case 'mh':
      switch (name) {
        case 'commercial_type':
        case 'commercial_type_actual':
          ret = isAllowed('ROLE_WRI_MH_COMMERCIAL_TYPE');
          break;

        case 'use_type':
          ret = isAllowed('ROLE_WRI_MH_USE_TYPE');
          break;

        case 'perimeter':
          ret = isAllowed('ROLE_WRI_MH_ECG_PERIMETER');
          break;

        case 'mh_model':
        case 'barcode':
        case 'old_id_homair':
        case 'id_chassis':
        case 'key_tag':
        case 'vintage':
        case 'site':
        case 'commercial_brand':
        case 'constructeur':
        case 'modele_constructeur':
        case 'type_alfresco':
        case 'type_unite':
        case 'status':
        case 'deck_type':
        case 'deck_size':
        case 'cladding_color':
        case 'cladding_material':
        case 'aircon_fitting_type':
        case 'gas_supply_type':
        case 'deck_year':
        case 'deck_manufacturer':
        case 'deck_refurb_year':
        case 'five_year_review':
        case 'heater_type':
        case 'skirting_material':
        case 'air_conditioning_year':
        case 'skirting_sides':
          ret = isAllowed('ROLE_WRI_MH_INFO');
          break;

        case 'sales.tax':
        case 'sales.advisor_data':
        case 'sales.status':
        case 'sales.category':
          ret = isAllowed('ROLE_WRI_MH_SALES');
          break;

        // case 'pitch':
        case 'pitch_corres':
          ret = isAllowed('ROLE_WRI_MH_EMPL');
          break;

        case 'pitch_fees':
          ret = isAllowed('ROLE_WRI_CONTRACTOR');
          break;

        case 'wintering_status':
          ret = isAllowed('ROLE_WRI_MH_HIVERN');
          break;

        case 'comment':
          ret = isAllowed('ROLE_WRI_MH_COMMENT');
          break;

        case 'owner':
        case 'dt_achat':
        case 'renov_year':
        case 'renov_status':
        case 'dt_vente':
        case 'purchase_leasing_code':
        case 'reno_leasing_code':
        case 'fixed_asset_code':
          ret = isAllowed('ROLE_ACCOUNTING_MGNT');
          break;

        default:
          ret = false;
      }
      break;
    case 'pending-unit':
    case 'unit-error':
      ret = true;
      break;

    // Amendment is allowed or not, that's all!
    // connections.aircon.disconn.cost
    // connections.aircon.disconn.purchase_order
    case 'mv':
      switch (name) {
        case 'connections.aircon.disconn.cost':
        case 'connections.aircon.disconn.purchase_order':
        case 'connections.deck.disconn.cost':
        case 'connections.deck.disconn.purchase_order':
        case 'connections.deck.transport.cost':
        case 'connections.deck.transport.purchase_order':
        case 'connections.mh.disconn.cost':
        case 'connections.mh.disconn.purchase_order':
        case 'connections.mh.transport.cost':
        case 'connections.mh.transport.purchase_order':
        case 'connections.mh.storage.cost':
        case 'connections.mh.storage.purchase_order':
        case 'connections.mh.transport_bis.cost':
        case 'connections.mh.transport_bis.purchase_order':
        case 'connections.mh.reconn.cost':
        case 'connections.mh.reconn.purchase_order':
        case 'connections.deck.reconn.cost':
        case 'connections.deck.reconn.purchase_order':
        case 'connections.aircon.reconn.cost':
        case 'connections.aircon.reconn.purchase_order':
          ret =
            isAllowed('ROLE_ADMIN_MVT') ||
            (isAllowed('ROLE_WRI_MVT_LOGISTIC') && !options.mvLocked);
          break;
        case 'pitch_to':
        case 'site_to':
          ret = false;
          break;
        default:
          ret =
            isAllowed('ROLE_ADMIN_MVT') ||
            (isAllowed('ROLE_WRI_MH_MVT') && !options.mvLocked);
          break;
      }
      break;
    case 'site':
      ret = isAllowed('ROLE_WRI_SITE');
      break;

    case 'pitch-fee':
      ret = isAllowed('ROLE_WRI_PITCH_FEES');
      break;

    case 'pitches':
      ret = isAllowed('ROLE_ADMIN');
      break;
    default:
      ret = false;
  }

  return ret;
}

export default AllowedField;
