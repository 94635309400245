import { getAPIToken } from '../../security/User';
import fetch from 'node-fetch';
// ------------------------------------------------------------------------
// FETCH LOGS
// ------------------------------------------------------------------------
export function fetchLogs() {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    const url = `${process.env.REACT_APP_API_URL}/api/logs`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(url, options);
      const contentType = response.headers.get('content-type');
      // console.info(response.headers.get('content-type'));

      if (response.status >= 400) {
        console.error(
          `fetchLogs: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
        );
      }

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then(function (json) {
          return json;
        });
      } else {
        console.log("Oops, nous n'avons pas du JSON!");
      }
    } catch (e) {
      console.error('fetchLogs:', e);
    }
  };
}

// ------------------------------------------------------------------------
// GET TRANSFERS DEADLINE
// ------------------------------------------------------------------------
export function getTransfersDeadline() {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    const url = `${process.env.REACT_APP_API_URL}/api/transfers_deadline`;

    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(url, options);
      const contentType = response.headers.get('content-type');

      if (response.status >= 400) {
        console.error(
          `fetchLogs: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
        );
      }

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then(function (json) {
          return json;
        });
      } else {
        console.log("Oops, nous n'avons pas du JSON!");
      }
    } catch (e) {
      console.error('fetchLogs:', e);
    }
  };
}
