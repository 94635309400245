import Rental from '@components/icons/Rental';
import Unit from '@components/icons/Unit';
import { isArray } from 'lodash';

import Placeholder from '@components/common/Placeholder';
import KeyFigure from '@components/dashboard/KeyFigures/KeyFigure';
import ContentHeader from '@components/headers/ContentHeader';
import KeyFigures from '@components/icons/KeyFigures';

import HomairLogo from '@assets/images/common/brands/homair.png';
import VacanceselectLogo from '@assets/images/common/brands/vacanceselect.png';
import EurocampLogo from '@assets/images/common/brands/eurocamp.png';
import RoanLogo from '@assets/images/common/brands/roan.png';

import style from './style.module.scss';

export default ({ brands, rentalBrands, t }) => {
  function getGroupPerBrand(brands) {
    if (brands && brands.length > 0) {
      const groupPerBrand = {};
      if (!brands || brands?.length === 0 || !isArray(brands)) return;

      brands.forEach((brand) => {
        if (brand.name === 'homair' || brand.name === 'palmiers') {
          groupPerBrand['homair']
            ? groupPerBrand['homair'].push(brand)
            : (groupPerBrand['homair'] = [brand]);
        } else if (brand.name === 'vacanceselect') {
          groupPerBrand['vacanceselect']
            ? groupPerBrand['vacanceselect'].push(brand)
            : (groupPerBrand['vacanceselect'] = [brand]);
        } else if (brand.name === 'eurocamp' || brand.name === 'alfresco') {
          groupPerBrand['eurocamp']
            ? groupPerBrand['eurocamp'].push(brand)
            : (groupPerBrand['eurocamp'] = [brand]);
        } else if (brand.name === 'roan' || brand.name === 'go4camp') {
          groupPerBrand['roan']
            ? groupPerBrand['roan'].push(brand)
            : (groupPerBrand['roan'] = [brand]);
        }
      });

      return (
        <div className={style['key-figures']}>
          {groupPerBrand &&
            Object.keys(groupPerBrand).map((brand, index) => {
              const name = brand;
              let description, logo;
              switch (name) {
                case 'homair':
                  description = t('common.brand.french_brand', 'French brand');
                  logo = HomairLogo;
                  break;
                case 'vacanceselect':
                  description = t('common.brand.french_brand', 'French brand');
                  logo = VacanceselectLogo;
                  break;
                case 'eurocamp':
                  description = t(
                    'common.brand.british_brand',
                    'British brand',
                  );
                  logo = EurocampLogo;
                  break;
                default:
                  description = t('common.brand.dutch_brand', 'Dutch brand');
                  logo = RoanLogo;
                  break;
              }

              let value = 0;
              let rentalValue = 0;
              groupPerBrand[brand].forEach((brand) => (value += brand.value));
              groupPerBrand[brand].forEach(
                (brand) => (rentalValue += brand.rentalValue),
              );

              return (
                <KeyFigure
                  key={index}
                  totalValue={value}
                  rentalValue={rentalValue}
                  logo={logo}
                  label={name}
                  description={description}
                />
              );
            })}
        </div>
      );
    }

    return <Placeholder height={100} />;
  }

  const mergeSameBrand = [];
  brands.forEach((brand) => {
    rentalBrands.find(
      (rentalBrand) =>
        brand.name === rentalBrand.name &&
        mergeSameBrand.push(
          Object.assign({ ...brand, rentalValue: rentalBrand.value }),
        ),
    );
  });

  return (
    <div className={style['key-figures-wrapper']}>
      <ContentHeader
        icon={KeyFigures}
        title={t('page.homepage.key_figures.title', 'Key figures')}
        subtitle={t(
          'page.homepage.key_figures.subtitle',
          'Follow the units per brand',
        )}
      />
      <div className={style['key-figures-content']}>
        {getGroupPerBrand(mergeSameBrand)}
      </div>
    </div>
  );
};
