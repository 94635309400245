import style from './style.module.scss';
import classnames from 'classnames';
export default ({ label, level }) => {
  return (
    <div
      className={classnames(style['tag-wrapper'], style[level.toLowerCase()])}
    >
      <span>{label}</span>
    </div>
  );
};
