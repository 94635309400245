import React, { Component } from 'react';
import ReactGA from 'react-ga4';

// Layout
import Layout from '@layout/default';

// Components
import BaseButton from '@components/buttons/BaseButton';
import Dashboard from '@components/icons/Dashboard';

import style from './style.module.scss';
import { getUserSetting } from '@security/User';
import dayjs from 'dayjs';

class PageNotFound extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = 'EUROPEAN CAMPING GROUP | 404';
  }

  render() {
    return (
      <Layout {...this.props}>
        <div className={style['pnf-wrapper']}>
          <div className={style['pnf-content']}>
            <p className={style['pnf-oversize']}>
              <span>404</span>
            </p>
            <p className={style['pnf-warning']}>
              Oops, you weren't supposed to see this
            </p>
            <p className={style['pnf-message']}>
              The page you are looking for no longer exists.
              <br />
              Return to the homepage and remember: you haven’t see anything.
            </p>
            <BaseButton
              action={() => {
                // GA
                ReactGA.event('not_found', {
                  user_interaction: 'Going back to homepage',
                  user_id: getUserSetting('_id').toString(),
                  dateTime: dayjs().format(),
                });
                this.props.history.push('/');
              }}
              label={'Back to homepage'}
            >
              <Dashboard color="var(--color-primary)" />
            </BaseButton>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PageNotFound;
