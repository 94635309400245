import {
  REQUEST_SITE_LIST,
  REQUEST_SITE_EXPORT,
  RECEIVE_SITE,
  RECEIVE_SITE_EXPORT,
  RECEIVE_SITE_LIST,
  CLEAR_REDUX_STATE,
} from '../actions/action-site';
import getStore from '../store';
import { debugStore as debug } from '../../lib/debug';

// -------------------------------------------------------------------------------------------
// Manage `store.site`.
// -------------------------------------------------------------------------------------------
export function handleSite(previousState = {}, action) {
  switch (action.type) {
    case RECEIVE_SITE:
      debug(
        'reducer RECEIVE_SITE: previousState=',
        previousState,
        'action=',
        action,
      );

      // Copy _id into id to be compatible with the Grid.
      // if (action.data && action.data.length) {
      //   action.data.map(d => {
      //     d.id = d._id
      //   })
      // }

      return Object.assign({}, previousState, action.data);

    default:
      return previousState;
  }
}

// -------------------------------------------------------------------------------------------
// Manage `store.siteList`.
// -------------------------------------------------------------------------------------------
//
// Update data (siteList) in state, provided by the Mobilhomes API call (action `fetchSiteList`).
//
export function handleSiteList(
  previousState = {
    isFetching: false,
    lastUpdated: 0,
    noResultsFound: false,
    data: [],
    metas: {},
  },
  action,
) {
  switch (action.type) {
    case REQUEST_SITE_LIST:
    case REQUEST_SITE_EXPORT:
      debug(
        'reducer REQUEST_SITE_LIST/REQUEST_SITE_EXPORT: previousState=',
        previousState,
        'action=',
        action,
      );
      return Object.assign({}, previousState, { isFetching: true });

    case RECEIVE_SITE_LIST:
      debug(
        'reducer RECEIVE_SITE_LIST: previousState=',
        previousState,
        'action=',
        action,
      );

      // Copy _id into id to be compatible with the Grid.
      if (action.data && action.data.length) {
        action.data.map((d) => (d.id = d._id));
      }

      return Object.assign({}, previousState, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        noResultsFound: action.data.noResultsFound,
        data: action.data,
        metas: action.metas,
      });

    case RECEIVE_SITE_EXPORT:
      debug(
        'reducer RECEIVE_SITE_EXPORT: previousState=',
        previousState,
        'action=',
        action,
      );
      return Object.assign({}, previousState, { isFetching: false });

    /**
     * Clear data array from previous state
     *
     * @return Object with data key empty array
     */
    case CLEAR_REDUX_STATE:
      console.info('CLEAR_REDUX_STATE');
      const removeDataFromPreviousState = { ...previousState };
      delete removeDataFromPreviousState.data;
      return Object.assign({ data: [] }, removeDataFromPreviousState, {
        isFetching: false,
      });

    default:
      return previousState;
  }
}
