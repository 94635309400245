import MainHeader from '@components/pwa/MainHeader';
import style from './style.module.scss';
import CacheManager from '@helpers/cache-helper';
import { useEffect, useState } from 'react';
const cache = new CacheManager();

export default (props) => {
  const { pending, action } = props;
  const [list, setList] = useState(null);

  useEffect(() => {
    if (!list) {
      cache.readData(pending.storageName, (list) => {
        list ? setList(list) : setList([]);
      });
    }

    return () => {};
  }, [list]);

  return (
    <div className={style['pending-view']}>
      <div className={style['header-wrapper']}>
        <MainHeader title={pending.label} action={action} />
      </div>

      <div className={style['pending-wrapper']}>
        {list?.map((item, index) => (
          <div key={index} className={style['pending-item']}>
            <figure>
              <img src={pending.picture} alt={pending.label} />
            </figure>
            <div className={style['pending-object']}>
              {Object.keys(item).map((key) => (
                <div key={key} className={style['pending-content']}>
                  <span>{key}</span>
                  <p>{item[key]?.val ? item[key].val : item[key]}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
