import { sendFlashMessage } from '@store/actions/action-common';
import getStore from '@store/store';
import { updateMv, updateMvLine } from '@store/actions/action-mv';

//
// Submit function.
// Return a Promise: the submitting property will be set to true until the promise has been resolved or rejected.
// https://redux-form.com/6.5.0/docs/api/props.md/#-handlesubmit-eventorsubmit-function-
//
export function submitSaveMv(values, callback = null, t = null) {
  return getStore()
    .dispatch(updateMv(values, callback))
    .then((response) => {
      // getStore().dispatch(updateMvLine(response));
      t &&
        getStore().dispatch(
          sendFlashMessage(
            t('common.flash_message.saving_in_progress', 'Saving in progress'),
            'info',
          ),
        );
      return response;
    })
    .catch((err) => {
      getStore().dispatch(sendFlashMessage(err, 'error'));
    });
}
