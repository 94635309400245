import React from 'react';
import { withTranslation } from 'react-i18next';
import ContentHeader from '@components/headers/ContentHeader';
import MhTransfer from '../MhTransfer';
import RedirectionButton from '@components/buttons/RedirectionButton';
import Arrow from '@components/icons/Arrow';
import Amendments from '@components/icons/Amendments';

import style from './style.module.scss';

const MhDialogTransfer = (props) => {
  const { t } = props;

  function backToList(values) {
    props.handleClose(values);
  }

  return (
    <div className={style['popin']}>
      <div className={style['popin-wrapper']}>
        <div className={style['upper-header']}>
          <RedirectionButton
            icon={Arrow}
            action={backToList}
            label={t('common.action.go_back_units', 'Go back to units section')}
          />
        </div>
        <ContentHeader
          icon={Amendments}
          title={t('common.action.create_transfer', 'New amendment')}
          subtitle={t(
            'common.label.create_transfer_notice',
            'Please note to not forget to validate to affect the new state to the unit(s)',
          )}
        />
        <MhTransfer {...props} onClose={(values) => backToList(values)} />
      </div>
    </div>
  );
};

export default withTranslation()(MhDialogTransfer);
