import React from 'react';
import style from './style.module.scss';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import Key from '@components/icons/Key.js';
import Unit from '@components/icons/Unit.js';

const KeyFigure = ({
  rentalValue = 'XXXX',
  totalValue = 'XXXX',
  label = 'Undefined label',
  description = '',
  logo,
}) => {
  const { t } = useTranslation();

  return (
    <div className={style['key-figure']}>
      <figure>
        <img src={logo} alt={'Brand'} />
        <figcaption>
          <h4>{label}</h4>
          {description}
        </figcaption>
      </figure>
      <Tippy
        placement="top"
        content={t('common.label.rental_units', 'Rental units')}
      >
        <div className={style['key-figure-rental-value']}>
          {rentalValue}
          <Key />
        </div>
      </Tippy>
      <Tippy
        placement="top"
        content={t('common.label.total_units', 'Total units')}
      >
        <div className={style['key-figure-total-value']}>
          {totalValue}
          <Unit />
        </div>
      </Tippy>
    </div>
  );
};

export default KeyFigure;
