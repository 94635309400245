import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { debugSite } from '@lib/debug';
import styles from '@assets/json/map.json';
import darkStyles from '@assets/json/darkmap.json';
import marker from '@assets/images/map/marker.png';
const REACT_APP_GOOGLE_MAP_KEY = `${process.env.REACT_APP_GOOGLE_MAP_KEY}`;

import style from './style.module.scss';

class SimpleMap extends Component {
  static propTypes = {
    points: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.renderMarkers = this.renderMarkers.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.state = {
      darkmode:
        JSON.parse(localStorage.getItem('prefers-color-scheme')) || false,
    };
  }

  onMarkerClick(props, marker, e) {
    console.log('onMarkerClick : marker, e ', marker, e);
  }

  renderMarkers(map, maps) {
    const { points } = this.props;
    if (points && points.length > 0) {
      for (var i = 0; i < points.length; i++) {
        new maps.Marker({
          key: `marker_${i}`,
          title: points[i]?.title,
          name: points[i]?.name,
          icon: marker,
          position: { lat: points[i]?.lat, lng: points[i]?.lng },
          onClick: this.onMarkerClick,
          map,
        });
      }
    }
  }

  getCenter(points) {
    let center = null;
    if (points && points.length === 1) {
      const lat = points[0]?.lat;
      const long = points[0]?.lng;
      if (lat >= -90 && lat <= 90 && long >= -180 && long <= 180) {
        center = {
          lat: lat,
          lng: long,
        };
      }
    }
    return center;
  }

  render() {
    const { points } = this.props;
    debugSite('GoogleMap render: ', points);
    const center = this.getCenter(points);

    return (
      // Important! Always set the container height explicitly
      <div className={style['google-map']}>
        {center ? (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: REACT_APP_GOOGLE_MAP_KEY,
            }}
            defaultZoom={12}
            center={center}
            onGoogleApiLoaded={({ map, maps }) =>
              setTimeout(() => this.renderMarkers(map, maps), 200)
            }
            yesIWantToUseGoogleMapApiInternals
            options={{ styles: this.state.darkmode ? darkStyles : styles }}
          />
        ) : (
          <p>
            Sorry, but we could not find your location, it seems the gps data is
            not right.
          </p>
        )}
      </div>
    );
  }
}

export default SimpleMap;
