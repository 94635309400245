import style from './style.module.scss';
import lottie from 'lottie-web';
import { useEffect, useRef } from 'react';

export default ({ label, description, lottieJSON }) => {
  const lottieRef = useRef(null);

  useEffect(() => {
    lottieJSON &&
      lottie.loadAnimation({
        container: lottieRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: lottieJSON,
        name: label,
      });

    return () => lottie.destroy(label);
  }, []);

  return (
    <header className={style['article-header']}>
      {lottieJSON && <div ref={lottieRef} className={style['lottie']} />}
      <div className={style['article-header-content']}>
        {label && label}
        {description && <small>{description}</small>}
      </div>
    </header>
  );
};
