export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.625 12.25C2.39167 12.25 2.1875 12.1625 2.0125 11.9875C1.8375 11.8125 1.75 11.6083 1.75 11.375V2.625C1.75 2.39167 1.8375 2.1875 2.0125 2.0125C2.1875 1.8375 2.39167 1.75 2.625 1.75H9.59583C9.7125 1.75 9.82674 1.77431 9.93854 1.82292C10.0503 1.87153 10.1451 1.93472 10.2229 2.0125L11.9875 3.77708C12.0653 3.85486 12.1285 3.94965 12.1771 4.06146C12.2257 4.17326 12.25 4.2875 12.25 4.40417V11.375C12.25 11.6083 12.1625 11.8125 11.9875 11.9875C11.8125 12.1625 11.6083 12.25 11.375 12.25H2.625ZM11.375 4.43333L9.56667 2.625H2.625V11.375H11.375V4.43333ZM7 10.4271C7.41806 10.4271 7.77535 10.2788 8.07187 9.98229C8.3684 9.68576 8.51667 9.32847 8.51667 8.91042C8.51667 8.49236 8.3684 8.13507 8.07187 7.83854C7.77535 7.54201 7.41806 7.39375 7 7.39375C6.58194 7.39375 6.22465 7.54201 5.92812 7.83854C5.6316 8.13507 5.48333 8.49236 5.48333 8.91042C5.48333 9.32847 5.6316 9.68576 5.92812 9.98229C6.22465 10.2788 6.58194 10.4271 7 10.4271ZM3.83542 5.48333H8.18125C8.30764 5.48333 8.41215 5.44201 8.49479 5.35937C8.57743 5.27674 8.61875 5.17222 8.61875 5.04583V3.83542C8.61875 3.70903 8.57743 3.60451 8.49479 3.52187C8.41215 3.43924 8.30764 3.39792 8.18125 3.39792H3.83542C3.70903 3.39792 3.60451 3.43924 3.52187 3.52187C3.43924 3.60451 3.39792 3.70903 3.39792 3.83542V5.04583C3.39792 5.17222 3.43924 5.27674 3.52187 5.35937C3.60451 5.44201 3.70903 5.48333 3.83542 5.48333ZM2.625 4.43333V11.375V2.625V4.43333Z"
      fill={props.color ? props.color : '#6D8B74'}
    />
  </svg>
);
