export default (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 21V19.1L4.5 17.6V21H3ZM7.125 21V15.1L8.625 13.6V21H7.125ZM11.25 21V13.6L12.75 15.125V21H11.25ZM15.375 21V15.125L16.875 13.625V21H15.375ZM19.5 21V11.1L21 9.6V21H19.5ZM3 15.1V12.975L10 6.025L14 10.025L21 3V5.125L14 12.15L10 8.15L3 15.1Z"
        fill={props.color ? props.color : '#194989'}
      />
    </svg>
  );
};
