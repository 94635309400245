import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getStore from '@store/store';
import FlashMessage from '@components/common/FlashMessage';
import { userLogin } from '@store/actions/action-common';
import { sendFlashMessage } from '@store/actions/action-common';
import bg1 from '@assets/images/login/bg-1.jpg';
import bg2 from '@assets/images/login/bg-2.jpg';
import bg3 from '@assets/images/login/bg-3.jpg';
import bg4 from '@assets/images/login/bg-4.jpg';
import bg5 from '@assets/images/login/bg-5.jpg';

import logo from '@assets/images/login/logo_with_palm.png';

import style from './style.module.scss';

class Login extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.goTo = this.goTo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getARandomBackground = this.getARandomBackground.bind(this);

    this.username = React.createRef();
    this.password = React.createRef();
  }

  componentDidMount() {
    const errorValue = new URLSearchParams(window.location.search)?.get(
      'failed',
    );
    if (errorValue === 'oauth') {
      getStore().dispatch(
        sendFlashMessage(
          'No account was found with these credentials or it has been disabled.',
          'error',
        ),
      );
    }
  }

  signInWithGoogle = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/get-in/google`;
  };

  signInWithMicrosoft = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/azureadoauth2`;
  };

  handleSubmit(e) {
    e.preventDefault();

    const username = this.username.current.value.trim();
    const password = this.password.current.value.trim();

    if (username.length === 0 || password.length === 0) {
      return;
    }

    const credentials = {
      username: username,
      password: password,
    };

    this.props.dispatch(userLogin(credentials));
  }

  goTo(path) {
    return () => {
      this.props.history.push(path);
    };
  }

  getARandomBackground() {
    const images = [bg1, bg2, bg3, bg4, bg5];
    let index = Math.floor(Math.random() * images.length);

    return images[index];
  }

  render() {
    let redirectToReferrer = localStorage.getItem('referrer');
    if (redirectToReferrer && redirectToReferrer != 'undefined')
      redirectToReferrer = JSON.parse(redirectToReferrer);
    else {
      redirectToReferrer = { pathname: '/' };
    }

    if (this.props.user.isAuthenticated && redirectToReferrer) {
      localStorage.removeItem('referrer');
      return <Redirect to={redirectToReferrer} />;
    }

    return (
      <section className={style['login-section']}>
        <FlashMessage />

        <div
          className={style['login-background']}
          style={{ backgroundImage: `url(${this.getARandomBackground()})` }}
        />

        <div className={style['login-wrapper']}>
          <div className={style['login-container']}>
            <header>
              <figure>
                <img
                  src={logo}
                  alt="European Camping Group"
                  title="European Camping Group"
                />
              </figure>
              <p>
                <span className="login-header-title">Welcome back</span>
                Please enter your login access.
              </p>
            </header>

            <div className={style['wrapper']}>
              <div className={style['form-wrapper']}>
                <form
                  id="loginform"
                  className={style['form']}
                  onSubmit={this.handleSubmit}
                >
                  <label>
                    <span>EMAIL/USERNAME</span>
                    <input
                      type="text"
                      ref={this.username}
                      name="username"
                      required
                      autoComplete="email"
                      autoFocus
                    />
                  </label>

                  <label>
                    <span>PASSWORD</span>
                    <input
                      type="password"
                      required
                      name="password"
                      ref={this.password}
                      autoComplete="current-password"
                    />
                  </label>

                  <div className={style['reset-wrapper']}>
                    <button
                      className={style['reset-button']}
                      type="button"
                      onClick={this.goTo('/reset-password')}
                    >
                      Forgot password ?
                    </button>
                  </div>
                  <button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={style['submit']}
                  >
                    Continue
                  </button>
                </form>
              </div>
              <div className={style['separator']}>
                <div className={style['middle-line']} />
                <div className={style['text']}>OR</div>
                <div className={style['middle-line']} />
              </div>
              <div className={style['service-apps']}>
                <button
                  type="button"
                  onClick={this.signInWithGoogle}
                  className={style['login-with-google-btn']}
                >
                  Sign in with Google
                </button>
                <button
                  type="button"
                  onClick={this.signInWithMicrosoft}
                  className={style['login-with-microsoft-btn']}
                >
                  Sign in with Microsoft
                </button>
              </div>
            </div>

            <small>
              © {new Date().getFullYear()} European Camping Group. All rights
              reserved.
            </small>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(Login);
