const Check = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.51239 10.2376C5.45405 10.2376 5.40058 10.2278 5.35197 10.2084C5.30336 10.189 5.25475 10.1549 5.20614 10.1063L2.56655 7.46673C2.47905 7.37923 2.4353 7.27229 2.4353 7.1459C2.4353 7.01951 2.47905 6.91257 2.56655 6.82507C2.65405 6.73757 2.75614 6.69382 2.8728 6.69382C2.98947 6.69382 3.09155 6.73757 3.17905 6.82507L5.51239 9.1584L10.8061 3.86465C10.8936 3.77715 10.9981 3.7334 11.1197 3.7334C11.2412 3.7334 11.3457 3.77715 11.4332 3.86465C11.5207 3.95215 11.5645 4.05666 11.5645 4.17819C11.5645 4.29972 11.5207 4.40423 11.4332 4.49173L5.81864 10.1063C5.77002 10.1549 5.72141 10.189 5.6728 10.2084C5.62419 10.2278 5.57072 10.2376 5.51239 10.2376Z"
        fill={props.color ? props.color : '#FFFFFF'}
      />
    </svg>
  );
};

export default Check;
