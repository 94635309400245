export default (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99989 9.18742C7.69016 9.18742 8.27593 8.94679 8.75718 8.46554C9.23843 7.98429 9.47905 7.39853 9.47905 6.70825C9.47905 6.01797 9.23843 5.43221 8.75718 4.95096C8.27593 4.46971 7.69016 4.22909 6.99989 4.22909C6.30961 4.22909 5.72384 4.46971 5.24259 4.95096C4.76134 5.43221 4.52072 6.01797 4.52072 6.70825C4.52072 7.39853 4.76134 7.98429 5.24259 8.46554C5.72384 8.94679 6.30961 9.18742 6.99989 9.18742ZM6.99989 8.34159C6.54294 8.34159 6.15648 8.1836 5.84051 7.86763C5.52454 7.55165 5.36655 7.1652 5.36655 6.70825C5.36655 6.25131 5.52454 5.86485 5.84051 5.54888C6.15648 5.2329 6.54294 5.07492 6.99989 5.07492C7.45683 5.07492 7.84329 5.2329 8.15926 5.54888C8.47523 5.86485 8.63322 6.25131 8.63322 6.70825C8.63322 7.1652 8.47523 7.55165 8.15926 7.86763C7.84329 8.1836 7.45683 8.34159 6.99989 8.34159ZM6.99989 11.0833C5.65822 11.0833 4.43565 10.7187 3.33218 9.9895C2.22871 9.26034 1.37558 8.29784 0.772803 7.102C0.743636 7.05339 0.721761 6.99506 0.707178 6.927C0.692594 6.85895 0.685303 6.78603 0.685303 6.70825C0.685303 6.63047 0.692594 6.55756 0.707178 6.4895C0.721761 6.42145 0.743636 6.36311 0.772803 6.3145C1.37558 5.11867 2.22871 4.15617 3.33218 3.427C4.43565 2.69784 5.65822 2.33325 6.99989 2.33325C8.34155 2.33325 9.56412 2.69784 10.6676 3.427C11.7711 4.15617 12.6242 5.11867 13.227 6.3145C13.2561 6.36311 13.278 6.42145 13.2926 6.4895C13.3072 6.55756 13.3145 6.63047 13.3145 6.70825C13.3145 6.78603 13.3072 6.85895 13.2926 6.927C13.278 6.99506 13.2561 7.05339 13.227 7.102C12.6242 8.29784 11.7711 9.26034 10.6676 9.9895C9.56412 10.7187 8.34155 11.0833 6.99989 11.0833ZM6.99989 10.2083C8.17627 10.2083 9.25787 9.88985 10.2447 9.25304C11.2315 8.61624 11.9825 7.76797 12.4978 6.70825C11.9825 5.64853 11.2315 4.80027 10.2447 4.16346C9.25787 3.52665 8.17627 3.20825 6.99989 3.20825C5.8235 3.20825 4.7419 3.52665 3.75509 4.16346C2.76829 4.80027 2.01239 5.64853 1.48739 6.70825C2.01239 7.76797 2.76829 8.61624 3.75509 9.25304C4.7419 9.88985 5.8235 10.2083 6.99989 10.2083Z"
        fill={props.color ? props.color : '#FFFFFF'}
      />
    </svg>
  );
};
