import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Custom style
import style from './style.module.scss';

function Timeline({ ...props }) {
  const { classes, stories, simple } = props;
  return (
    <ul className={style['timeline-wrapper']}>
      {stories.map((prop, key) => {
        return (
          <li
            key={key}
            className={classnames(
              style['timeline-item'],
              key % 2 === 0 ? style['odd-item'] : style['even-item'],
            )}
          >
            {/* {prop.badgeIcon ? (
              <div className={timelineBadgeClasses}>
                <prop.badgeIcon className={classes.badgeIcon} />
              </div>
            ) : null} */}
            <div>
              {prop.title ? (
                <div>
                  {/* <Badge color={prop.titleColor} className="timeline-title"> */}
                  {prop.title}
                  {/* </Badge> */}
                  {prop.author || ''}
                </div>
              ) : null}
              {prop.body}
              {prop.footerTitle && <h6>{prop.footerTitle}</h6>}
              {/* {prop.footer ? <hr className={classes.footerLine} /> : null} */}
              {prop.footer && prop.footer}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

Timeline.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool,
};

export default Timeline;
