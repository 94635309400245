import { get } from 'lodash'

//
// Get a user setting.
// If gridName is specified, it's a setting specific to a grid, stored under 'list.' + the_grid_name.
//
export function getStoreUserSettings(storedUserSettings, name, gridName) {
  if (!storedUserSettings || (gridName && (!storedUserSettings.list || !storedUserSettings.list[gridName]))) {
    console.warn('getStoreUserSettings: this settings doesn’t exist. Name=', name, 'gridName=', gridName, 'userSettings=', storedUserSettings)
    return undefined
  }
  if (gridName) return storedUserSettings.list[gridName][name]
  return storedUserSettings[name]
}

//
// Filter hidden columns (set in the store) from the grid (default columns definition).
//
export function filterColumns(columnsState, columnsDef) {
  var i = columnsDef.length
  while (i--) {
    const col = columnsDef[i]

    if (col.children) {
      filterColumns(columnsState, col.children)
    } else {
      const colIsHidden = get(columnsState, `${col.field}.hidden`, true)
      console.log('​filterColumns -> ', col.field, colIsHidden)
      if (colIsHidden) {
        columnsDef.splice(i, 1)
      }
    }
  }
}

export function fixRefValues(values) {
  if (values) {
    for (const k in values) {
      if (values[k] && values[k].val) values[k] = values[k].val
    }
  }
}

export function saveToLocalStorage(key, object) {
  try {
    localStorage.setItem(key, JSON.stringify(object))
  } catch (err) {
    console.error(`saveToLocalStorage ${key} : can't write to localStorage !`)
  }
}

export function retreiveFromLocalStorage(key) {
  try {
    const serializedObject = localStorage.getItem(key)
    if (serializedObject === null) {
      return {}
    }
    return JSON.parse(serializedObject)
  } catch (err) {
    return {}
  }
}

export function buildFromUrlParams(searchUrl) {
  const gridParams = {
    filters: {},
    sorts: {},
  }
  if (searchUrl) {
    const params = new URLSearchParams(searchUrl)
    for (let p of params) {
      const value = p[1].split(':')
      // debugMh('gridParams[p[0]]: ', gridParams[p[0]])
      // debugMh('gridParams[p[0]][value[0]]: ', gridParams[p[0]][value[0]])
      if (!gridParams[p[0]][value[0]]) {
        gridParams[p[0]][value[0]] = []
      }
      gridParams[p[0]][value[0]].push(value[1])
    }
    // debugMh('buildFromUrlParams params: ', gridParams)
  }
  return gridParams
}
