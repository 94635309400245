export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 12.25C5.54167 12.25 4.30208 11.7396 3.28125 10.7188C2.26042 9.69794 1.75 8.45836 1.75 7.00002C1.75 5.68752 2.13646 4.57433 2.90938 3.66044C3.68229 2.74655 4.69583 2.15836 5.95 1.89586C6.34861 1.81808 6.62083 1.88613 6.76667 2.10002C6.9125 2.31391 6.90764 2.60558 6.75208 2.97502C6.66458 3.19863 6.59653 3.42711 6.54792 3.66044C6.49931 3.89377 6.475 4.13197 6.475 4.37502C6.475 5.25002 6.78125 5.99377 7.39375 6.60627C8.00625 7.21877 8.75 7.52502 9.625 7.52502C9.86806 7.52502 10.1038 7.50315 10.3323 7.4594C10.5608 7.41565 10.7819 7.35489 10.9958 7.27711C11.4139 7.12155 11.725 7.12884 11.9292 7.29898C12.1333 7.46912 12.1868 7.75836 12.0896 8.16669C11.8271 9.34308 11.2389 10.3177 10.325 11.0906C9.41111 11.8636 8.30278 12.25 7 12.25ZM7 11.375C8.05972 11.375 8.98333 11.0469 9.77083 10.3906C10.5583 9.7344 11.0493 8.96391 11.2437 8.07919C11.0007 8.18613 10.7406 8.26634 10.4635 8.31982C10.1865 8.37329 9.90694 8.40002 9.625 8.40002C8.50694 8.40002 7.5566 8.0087 6.77396 7.22607C5.99132 6.44343 5.6 5.49308 5.6 4.37502C5.6 4.14169 5.62431 3.89134 5.67292 3.62398C5.72153 3.35662 5.80903 3.0528 5.93542 2.71252C4.98264 2.97502 4.19271 3.50732 3.56563 4.3094C2.93854 5.11148 2.625 6.00836 2.625 7.00002C2.625 8.2153 3.05035 9.24829 3.90104 10.099C4.75174 10.9497 5.78472 11.375 7 11.375Z"
        fill={props.color ? props.color : '#333333'}
      />
    </svg>
  );
};
