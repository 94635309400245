import ReactGA from 'react-ga4';
const REACT_APP_GA_ID = `${process.env.REACT_APP_GA_ID}`;
import { useCookies } from 'react-cookie';

// Components
import BaseButton from '@components/buttons/BaseButton';
import Close from '@components/icons/Close';
import Check from '@components/icons/Check';

// Store
import getStore from '@store/store';
import { updateGoogleAnalyticsState } from '@store/actions/action-common';

// Helpers
import { logError, logSuccess } from '@helpers/logs';

import style from './style.module.scss';
import { getUserSetting } from '@security/User';
import dayjs from 'dayjs';

export default (props) => {
  const { declinedLabel, acceptedLabel, declinedAction, acceptedAction } =
    props;

  const [cookies, setCookie, removeCookie] = useCookies(['enable_ga']);

  function accepted() {
    logSuccess('Cookie accepted');
    ReactGA.initialize(REACT_APP_GA_ID);
    getStore().dispatch(updateGoogleAnalyticsState(true));
    ReactGA.event('page', location.pathname + location.search);
    ReactGA.send('pageview');

    setCookie('enable_ga', true, {
      maxAge: 1000 * 60 * 60 * 24 * 365,
      secure: true,
    }); // One year

    if (acceptedAction) acceptedAction();
  }

  function declined() {
    logError('Cookies denied');
    window[`ga-disable-${REACT_APP_GA_ID}`] = true;
    getStore().dispatch(updateGoogleAnalyticsState(false));
    setCookie('enable_ga', false, {
      maxAge: 1000 * 60 * 60 * 24 * 365,
      secure: true,
    });
    if (declinedAction) declinedAction();
    else props.history.push('/logout');
  }

  return (
    <div className={style['cookie-banner']}>
      <h3>European Camping Group</h3>
      <br />
      <h2>takes care of your data</h2>
      <div className={style['image']}>🍪</div>
      <p>
        We waited to make sure that you were interested in the content of this
        website before bothering you, but we would love to be your companions
        during your visit...
        <br />
        <br />
        We use cookies to collect activity data on this site. Is that OK with
        you?
      </p>
      <div className={style['buttons-wrapper']}>
        <BaseButton
          action={() => {
            // GA
            ReactGA.event('cookie_form', {
              user_interaction: 'Declined',
              user_id: getUserSetting('_id').toString(),
              dateTime: dayjs().format(),
            });

            declined();
            props.close();
          }}
          label={`${declinedLabel || 'Decline'}`}
        >
          <Close color="#194989" />
        </BaseButton>
        <BaseButton
          action={() => {
            // GA
            ReactGA.event('cookie_form', {
              user_interaction: 'Accepted',
              user_id: getUserSetting('_id').toString(),
              dateTime: dayjs().format(),
            });

            accepted();
            props.close();
          }}
          label={`${acceptedLabel || 'Accept'}`}
        >
          <Check color="#194989" />
        </BaseButton>
      </div>
    </div>
  );
};
