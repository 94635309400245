import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import SelectList from '../helpers/SelectList'
import { gridSelectStyles } from '../helpers/SelectStyles'
import RefDatas from '../../lib/RefDatas'
import get from 'lodash/get'

export default class ListCellEditor extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    values: PropTypes.array, // et pas 'options' pour coller à l'api de cellEditor...
    api: PropTypes.object,
    group: PropTypes.bool,
    formatedValues: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value,
    }

    this.handleChange = this.handleChange.bind(this)
    this.getValue = this.getValue.bind(this)
  }

  handleChange(value) {
    console.log('ListCellEditor: handleChange => value: ', value, ', Old value =', this.state.value)

    if (value._id !== get(this.state.value, '_id')) {
      this.setState(
        {
          value: value, //this.getValueObjFromId(value),
        },
        () => {
          // console.log('stop editing, value=', this.state.value)
          this.props.api.stopEditing()
        },
      )
    } else {
      this.props.api.stopEditing(true)
    }
  }

  componentDidMount() {
    this.focus()
  }

  componentDidUpdate() {
    this.focus()
  }

  focus() {
    setTimeout(() => {
      if (this.node) {
        const input = this.node.getElementsByTagName('input')
        input && input.length > 0 && this.node.getElementsByTagName('input')[0].focus()
      }
    })
  }

  // grab value in refData values list, from formatedValues list
  getValue(args) {
    console.log('getValue => this.state: ', this.state, 'args: ', args)
    // console.log('getValue: this.state=', this.state.value)
    const valueObject = RefDatas.getValueObjFromId(get(this.state.value, '_id'), this.props.values, this.props.group)
    // console.log('getValue ===============> ', valueObject);
    return valueObject
  }

  render() {
    return (
      <div
        style={{ height: 200, minWidth: 300 }}
        ref={node => {
          this.node = node
        }}
      >
        <SelectList
          selected={this.state.value}
          onChange={this.handleChange}
          options={this.props.formatedValues}
          isClearable={false}
          isMulti={false}
          isSearchable={true}
          closeMenuOnSelect
          hideSelectedOptions={false}
          menuIsOpen
          group={this.props.group}
          styles={gridSelectStyles}
          className={'gridSelect'}
        />
      </div>
    )
  }
}
