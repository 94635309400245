import Scan from '@components/icons/Scan';
import style from './style.module.scss';

export default (props) => {
  const { label, category, content, svg, type } = props;
  return (
    <div className={style['base-button-wrapper']}>
      <p>
        <span className={style['category']}>
          {category ? category : 'Unit information'}
        </span>
        {content ? content : 'Details, F2M history and report an issue.'}
      </p>

      <button
        type={type ? type : 'button'}
        className={style['base-button']}
        onClick={props.action}
      >
        {svg ? svg : <Scan />}
        <span>{label ? label : 'Scan Unit Barcode'}</span>
      </button>
    </div>
  );
};
