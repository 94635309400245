import { sendFlashMessage } from '@store/actions/action-common';
import getStore from '@store/store';
import { updateSite } from '@store/actions/action-site';
//
// Submit function.
// Return a Promise: the submitting property will be set to true until the promise has been resolved or rejected.
// https://redux-form.com/6.5.0/docs/api/props.md/#-handlesubmit-eventorsubmit-function-
//

export function submitSaveSite(values, t = null) {
  return getStore()
    .dispatch(updateSite(values))
    .then((json) => {
      getStore().dispatch(
        t &&
          sendFlashMessage(
            t('common.flash_message.saved', 'Saved!'),
            'success',
          ),
      );
      return json;
    })
    .catch((err) => {
      getStore().dispatch(
        t &&
          sendFlashMessage(
            t('common.flash_message.oops', 'Oops, errors!'),
            'error',
          ),
      );
    });
}
