export default (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.583328 2.91667H1.82291V11.0833H0.583328V2.91667ZM4.08333 2.91667H4.66666V11.0833H4.08333V2.91667ZM2.33333 2.91667H3.49999V11.0833H2.33333V2.91667ZM11.6667 2.91667H13.4167V11.0833H11.6667V2.91667ZM5.83333 2.91667H6.99999V11.0833H5.83333V2.91667ZM9.91666 2.91667H10.5V11.0833H9.91666V2.91667ZM7.58333 2.91667H9.33333V11.0833H7.58333V2.91667Z"
      fill={props.color ? props.color : '#FFF'}
    />
  </svg>
);
