import React, { Component, Fragment } from 'react';
import { instanceOf } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { logSuccess } from '@helpers/logs';
import { connect } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import MainFooter from '@components/pwa/MainFooter';
// Helpers
import CacheManager from '@helpers/cache-helper';
import { logInfo } from '@helpers/logs';

// Layout
import PublicLayout from '@layout/public';

// Store
import getStore from '@store/store';
import { fetchUnitsBarcode } from '@store/actions/action-mh';
import { submitSavePendingUnit } from '@pages/mh/MhSubmit';
import { submitUnitReport } from '@pages/pwa/ScannerSubmit';

// Components
import { withCookies, Cookies } from 'react-cookie';
import Loading from '@components/common/Loading';
// import UnitReporting from './UnitReporting';

import style from './style.module.scss';

// Images
import Homepage from './Homepage';
import Scanner from './Scanner';

const cache = new CacheManager();

class UnitScanner extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    this.handleConnectionChange = this.handleConnectionChange.bind(this);
    this.events = this.events.bind(this);
    this.sendOfflineRequests = this.sendOfflineRequests.bind(this);
    this.toggleScanner = this.toggleScanner.bind(this);
    this.unitFound = this.unitFound.bind(this);
    this.fetchData = this.fetchData.bind(this);

    this.state = {
      isOffline: false,
      barcode: '',
      unitsList: null,
      refData: null,
      isFetching: true,
      stepIndex: -1,
      histories: null,
      unitID: null,
      unitData: null, // Unit data found
      openScanner: false,
    };
  }

  componentDidMount() {
    document.title = 'EUROPEAN CAMPING GROUP | PWA';

    this.events();
    const isOffline = !window.navigator?.onLine;
    this.setState({ isOffline: isOffline });
    cache.readData('requests', (pendingUnits) => {
      cache.readData('reports', (reports) => {
        this.setState({
          requests: pendingUnits?.map((item) => item.barcode) || [],
          reports: reports?.map((item) => item.barcode) || [],
        }); // ? Load when app is launched
        if (!isOffline) {
          // ! Online
          // lightMode();
          this.sendOfflineRequests({ pendingUnits, reports });
        } else {
          // ! Offline
          // darkMode();
          this.fetchData();
        }
      });
    });
  }

  componentWillUnmount() {
    window.addEventListener('offline', this.handleConnectionChange);
    window.addEventListener('online', this.handleConnectionChange);
  }

  events() {
    window.addEventListener('offline', this.handleConnectionChange);
    window.addEventListener('online', this.handleConnectionChange);
  }

  // Connection
  handleConnectionChange(event) {
    cache.readData('requests', (pendingUnits) => {
      cache.readData('reports', (reports) => {
        this.setState({
          requests: pendingUnits?.map((item) => item.barcode) || [],
          reports: reports?.map((item) => item.barcode) || [],
        });

        if (event.type == 'offline') {
          this.setState({ isOffline: true });
          // darkMode();
        }
        if (event.type == 'online') {
          this.setState({ isOffline: false });
          this.sendOfflineRequests({ pendingUnits, reports });
          // lightMode();
        }
      });
    });
  }

  // Submit offline data
  sendOfflineRequests({ pendingUnits, reports }) {
    if (!this.props.user?.isAuthenticated) {
      this.props.history.push('/login');
      return;
    }

    if (
      (!pendingUnits || pendingUnits.length === 0) &&
      (!reports || reports.length === 0)
    ) {
      this.fetchData();
      return;
    } else {
      // ! Send offline requests
      let counter_pending_units = 0;
      // Pending units
      pendingUnits &&
        pendingUnits.forEach((item) => {
          submitSavePendingUnit(item)
            .then((res) => {
              window.alert(`✅ Request for ${item.barcode} unit sent!`);
              if (counter_pending_units === pendingUnits.length - 1) {
                cache.removeData('requests').then(() => {
                  this.setState({ requests: [] });
                  return;
                });
              }
              counter_pending_units += 1;
            })
            .catch((err) => {
              cache.removeData('requests').then(() => {
                window.alert(`⛔ ${err}`);
                this.setState({ requests: [] });
              });
            });
        });

      let counter_reports = 0;
      reports &&
        reports.forEach((item) => {
          submitUnitReport(item)
            .then((res) => {
              window.alert(`✅ Issue report for ${item.unit_id} unit sent!`);
              if (counter_reports === reports.length - 1) {
                cache.removeData('reports').then(() => {
                  this.setState({ reports: [] });
                  return;
                });
              }
              counter_reports += 1;
            })
            .catch((err) => {
              cache.removeData('reports').then(() => {
                window.alert(`⛔ ${err}`);
                this.setState({ reports: [] });
              });
            });
        });

      cache.clear();
      this.fetchData();
    }
  }

  fetchData() {
    this.setState({ unitsList: [] });
    // ? Fetching data in indexedDB
    cache.readData('unitsList', (res) => {
      // ? No data found
      if (!res || !res?.data || res?.data?.length === 0) {
        // cache.clear();
        // ? User is connected
        if (!this.props.user?.isAuthenticated && !this.state.isOffline) {
          this.props.history.push('/login');
          return;
        }

        logInfo('Fetching data from API');
        return new Promise((resolve) => {
          resolve(getStore().dispatch(fetchUnitsBarcode()));
        })
          .then((barcodes) => {
            cache
              .writeData('unitsList', {
                data: barcodes,
                refData: this.props.refDatas,
              })
              .then(() => {
                this.setState({
                  unitsList: barcodes,
                  refData: this.props.refDatas,
                  isFetching: false,
                });
                // this.init();
              });

            logSuccess('The fetch is over!');
          })
          .catch((e) => {
            cache.clear();
            // window.location.reload();
          });
      } else {
        // ? If data already exist, we do not need to connect
        // ? Using saved data
        this.setState({
          unitsList: res.data,
          refData: res.refData,
          isFetching: false,
        });
        logSuccess('Load data from indexedDB');
        // this.init();
      }
    });
  }

  unitFound(data) {
    this.setState({ unitData: data });
    this.toggleScanner();
  }

  toggleScanner(bool) {
    if (bool === undefined)
      this.setState({ openScanner: !this.state.openScanner });
    else this.setState({ openScanner: bool });
  }

  render() {
    const {
      isFetching,
      stepIndex,
      requests,
      reports,
      isOffline,
      openScanner,
      unitData,
      unitsList,
    } = this.state;

    return (
      <PublicLayout {...this.props}>
        {!isFetching ? (
          <div className={style['scanner-wrapper']}>
            <SwitchTransition mode="out-in">
              <CSSTransition
                timeout={300}
                key={openScanner}
                addEndListener={(node, done) =>
                  node.addEventListener('transitionend', done, false)
                }
                onEntered={() => {
                  if (stepIndex === -1) {
                    cache.readData('requests', (pendingUnits) => {
                      cache.readData('reports', (reports) => {
                        this.setState({
                          requests:
                            pendingUnits?.map((item) => item.barcode) || [],
                          reports: reports?.map((item) => item.barcode) || [],
                        });
                      });
                    });
                  }
                }}
                classNames="fade"
              >
                <>
                  {!openScanner && unitsList ? (
                    <Homepage
                      requests={requests}
                      reports={reports}
                      isOffline={isOffline}
                      unitsList={unitsList}
                      isAuthenticated={this.props.user.isAuthenticated}
                      history={this.props.history}
                      toggleScanner={this.toggleScanner}
                      unitFoundByScanner={unitData}
                      refData={this.props.refDatas}
                    />
                  ) : (
                    unitsList && (
                      <Scanner
                        unitsList={unitsList}
                        unitFound={this.unitFound}
                        handleClose={this.toggleScanner}
                      />
                    )
                  )}
                </>
              </CSSTransition>
            </SwitchTransition>

            <MainFooter
              toggleScanner={this.toggleScanner}
              currentPage={openScanner ? 1 : 0}
            />
          </div>
        ) : (
          <div className={style['in-progress']}>
            <Loading />
          </div>
        )}
      </PublicLayout>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user,
    userSettings: store.userSettings,
    refDatas: store.refDatas.data,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(withCookies(UnitScanner)),
);
