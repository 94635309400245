import React from 'react';
import style from './style.module.scss';
import classnames from 'classnames';

export default ({
  children,
  label,
  action,
  description,
  disabled = false,
  hasNotification = false,
  invert = false,
}) => {
  return (
    <button
      className={classnames(style['export-button'], invert && style['invert'])}
      type="button"
      onClick={action}
      disabled={disabled}
    >
      {children && (
        <div
          className={classnames(
            style['icon-wrapper'],
            hasNotification && style['notification-icon'],
          )}
        >
          {children}
        </div>
      )}
      <div>
        <span>{label}</span>
        {description && description}
      </div>
    </button>
  );
};
