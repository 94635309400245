export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.80629 12.25C3.57296 12.25 3.36879 12.1625 3.19379 11.9875C3.01879 11.8125 2.93129 11.6083 2.93129 11.375V3.0625H2.77087C2.64449 3.0625 2.53997 3.02118 2.45733 2.93854C2.37469 2.8559 2.33337 2.75139 2.33337 2.625C2.33337 2.49861 2.37469 2.3941 2.45733 2.31146C2.53997 2.22882 2.64449 2.1875 2.77087 2.1875H5.07504C5.07504 2.06111 5.11636 1.9566 5.199 1.87396C5.28164 1.79132 5.38615 1.75 5.51254 1.75H8.48754C8.61393 1.75 8.71844 1.79132 8.80108 1.87396C8.88372 1.9566 8.92504 2.06111 8.92504 2.1875H11.2292C11.3556 2.1875 11.4601 2.22882 11.5427 2.31146C11.6254 2.3941 11.6667 2.49861 11.6667 2.625C11.6667 2.75139 11.6254 2.8559 11.5427 2.93854C11.4601 3.02118 11.3556 3.0625 11.2292 3.0625H11.0688V11.375C11.0688 11.6083 10.9813 11.8125 10.8063 11.9875C10.6313 12.1625 10.4271 12.25 10.1938 12.25H3.80629ZM3.80629 3.0625V11.375H10.1938V3.0625H3.80629ZM3.80629 3.0625V11.375V3.0625ZM7.00004 7.875L8.40004 9.28958C8.50698 9.38681 8.62608 9.43542 8.75733 9.43542C8.88858 9.43542 9.00282 9.38681 9.10004 9.28958C9.19726 9.19236 9.2483 9.07569 9.25317 8.93958C9.25803 8.80347 9.20698 8.69167 9.10004 8.60417L7.70004 7.175L9.10004 5.74583C9.19726 5.64861 9.2483 5.53194 9.25317 5.39583C9.25803 5.25972 9.20698 5.14792 9.10004 5.06042C9.00282 4.96319 8.88615 4.91458 8.75004 4.91458C8.61393 4.91458 8.49726 4.96319 8.40004 5.06042L7.00004 6.475L5.61462 5.06042C5.52712 4.95347 5.41532 4.90243 5.27921 4.90729C5.1431 4.91215 5.02157 4.96319 4.91462 5.06042C4.80768 5.15764 4.75664 5.27187 4.7615 5.40312C4.76636 5.53437 4.8174 5.65347 4.91462 5.76042L6.31462 7.175L4.91462 8.58958C4.8174 8.68681 4.76879 8.80104 4.76879 8.93229C4.76879 9.06354 4.8174 9.18264 4.91462 9.28958C5.02157 9.38681 5.14067 9.43542 5.27192 9.43542C5.40317 9.43542 5.5174 9.38681 5.61462 9.28958L7.00004 7.875Z"
        fill={props.color ? props.color : '#632626'}
      />
    </svg>
  );
};
