import {
  RECEIVE_MH,
  RECEIVE_MH_MOVEMENTS,
  REQUEST_MH_EXPORT,
  RECEIVE_MH_EXPORT,
  REQUEST_MH_LIST,
  RECEIVE_MH_LIST,
} from '../actions/action-mh';
import { debugStore as debug } from '../../lib/debug';

// -------------------------------------------------------------------------------------------
// Manage `store.mh`.
// -------------------------------------------------------------------------------------------
export function handleMh(previousState = {}, action) {
  switch (action.type) {
    case RECEIVE_MH:
      debug(
        'reducer RECEIVE_MH: previousState=',
        previousState,
        'action=',
        action,
      );

      let mergeMhState;

      if (
        typeof action.data === 'object' &&
        Object.keys(action.data).length > 0
      ) {
        mergeMhState = { ...action.data };
      } else {
        mergeMhState = previousState;
      }

      return mergeMhState;

    default:
      return previousState;
  }
}

// -------------------------------------------------------------------------------------------
// Manage `store.mhMovements`.
// -------------------------------------------------------------------------------------------
export function handleMhMovements(previousState = {}, action) {
  switch (action.type) {
    case RECEIVE_MH_MOVEMENTS:
      debug(
        'reducer RECEIVE_MH_MOVEMENTS: previousState=',
        previousState,
        'action=',
        action,
      );

      return Object.assign({}, previousState, action.data);

    default:
      return previousState;
  }
}

// -------------------------------------------------------------------------------------------
// Manage `store.mhList`.
// -------------------------------------------------------------------------------------------
//
// Update data (mhList) in state, provided by the Mobilhomes API call (action `fetchMhList`).
//
export function handleMhList(
  previousState = {
    isFetching: false,
    lastUpdated: 0,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_MH_LIST:
    case REQUEST_MH_EXPORT:
      debug(
        'reducer REQUEST_MH_LIST/REQUEST_MH_EXPORT: previousState=',
        previousState,
        'action=',
        action,
      );
      return Object.assign({}, previousState, { isFetching: true });

    case RECEIVE_MH_LIST:
      debug(
        'reducer RECEIVE_MH_LIST: previousState=',
        previousState,
        'action=',
        action,
      );

      return Object.assign({}, previousState, {
        isFetching: false,
        lastUpdated: action.receivedAt,
      });

    case RECEIVE_MH_EXPORT:
      debug(
        'reducer RECEIVE_MH_EXPORT: previousState=',
        previousState,
        'action=',
        action,
      );
      return Object.assign({}, previousState, { isFetching: false });

    default:
      return previousState;
  }
}
