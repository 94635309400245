import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
const localizedFormat = require('dayjs/plugin/localizedFormat');
import dayjs from 'dayjs';
dayjs.extend(localizedFormat);

// Layout
import Layout from '@layout/default';

// Components
import ContentHeader from '@components/headers/ContentHeader';
import { Linear } from '@components/Loader';
import MhForm from '@pages/mh/MhForm';
import FlashMessage from '@components/common/FlashMessage';
import Unit from '@components/icons/Unit';
import RedirectionButton from '@components/buttons/RedirectionButton';
import Arrow from '@components/icons/Arrow';

// Helpers
import { debugMh } from '@lib/debug';

// Store
import { fetchMh } from '@store/actions/action-mh';
import { sendFlashMessage } from '@store/actions/action-common';

import style from './style.module.scss';

class MhEditForm extends React.Component {
  static propTypes = {
    initialValues: PropTypes.object,
    handleSubmit: PropTypes.func,
    dispatch: PropTypes.func,
    submitting: PropTypes.bool,
    reset: PropTypes.func,
    t: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  };

  constructor(props) {
    super(props);

    this.backToList = this.backToList.bind(this);
  }

  componentDidMount() {
    // Fetching data by its ID.
    this.props.dispatch(fetchMh(this.props.match.params.id)).catch((err) => {
      if (err && err.code === 404) {
        err.message = `Sorry, we could not find the unit ID ${this.props.match.params.id}`;
      }
      this.props.dispatch(sendFlashMessage(`${err}`, 'error'));
      this.props.history.push('/mh');
    });
  }

  backToList() {
    this.props.history.push('/mh');
  }

  render() {
    const { initialValues, match, t } = this.props;
    debugMh('MhEditForm: rendering MhEditForm...');

    // ! Document title
    document.title = `EUROPEAN CAMPING GROUP | ${this.props.t(
      'page.unit.document_title',
      'Unit',
    )} ${initialValues?.barcode || ''}`;

    // pas très performant, mais pas trouvé mieux
    // finalement pas besoin d'appeler  "initialize(initialValues)"...
    if (initialValues._id !== match.params.id) {
      // return
      return (
        <Layout {...this.props}>
          <Linear {...this.props} />
        </Layout>
      );
    } else {
      const {
        barcode,
        id_chassis: chassisID,
        updated_at,
        updated_by,
        created_at,
        created_by,
      } = this.props.initialValues;
      return (
        <Layout {...this.props}>
          <FlashMessage />

          <div className={style['unit-form-wrapper']}>
            <div className={style['upper-header']}>
              <RedirectionButton
                icon={Arrow}
                action={this.backToList}
                label={t(
                  'common.action.go_back_units',
                  'Go back to units section',
                )}
              />
            </div>
            <ContentHeader
              icon={Unit}
              title={`${barcode} - ${chassisID}`}
              subtitle={`${t('common.label.updated_on', 'Updated on')} ${dayjs(
                updated_at || created_at,
              ).format('lll')} ${t('common.label.by', 'by')} ${
                updated_by?.username || created_by?.username
              }`}
            />
            <MhForm
              key={this.props.i18n.language}
              ref={this.form}
              {...this.state}
              {...this.props}
              isEdit={true}
            />
          </div>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  // Init form data from the Model in our store.
  initialValues: state.mh,
  refDatas: state.refDatas.data,
});
export default withTranslation()(connect(mapStateToProps)(MhEditForm));
