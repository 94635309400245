import Logout from '@components/icons/Logout';
import getStore from '@store/store';
import { userLogout } from '@store/actions/action-common';

import style from './style.module.scss';

export default (props) => {
  const { history } = props;
  function logOut() {
    history.push('/logout');

    getStore().dispatch(userLogout());
  }

  return (
    <button
      type="button"
      className={style['logout-button']}
      onClick={() => logOut()}
    >
      <Logout color="var(--color-quaternary)" />
      <span>Log out</span>
    </button>
  );
};
