export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.29163 15.8334C2.11107 15.8334 1.96176 15.7744 1.84371 15.6563C1.72565 15.5383 1.66663 15.389 1.66663 15.2084V10.5209C1.66663 10.1737 1.73607 9.8473 1.87496 9.54175C2.01385 9.23619 2.22218 8.98619 2.49996 8.79175V6.37508C2.49996 5.75008 2.71176 5.22578 3.13538 4.80216C3.55899 4.37855 4.08329 4.16675 4.70829 4.16675H8.45829C8.76385 4.16675 9.04857 4.23619 9.31246 4.37508C9.57635 4.51397 9.80551 4.70147 9.99996 4.93758C10.1944 4.70147 10.4201 4.51397 10.677 4.37508C10.934 4.23619 11.2152 4.16675 11.5208 4.16675H15.2708C15.8958 4.16675 16.4236 4.37855 16.8541 4.80216C17.2847 5.22578 17.5 5.75008 17.5 6.37508V8.79175C17.7777 8.98619 17.9861 9.23619 18.125 9.54175C18.2638 9.8473 18.3333 10.1737 18.3333 10.5209V15.2084C18.3333 15.389 18.2743 15.5383 18.1562 15.6563C18.0382 15.7744 17.8888 15.8334 17.7083 15.8334C17.5277 15.8334 17.3784 15.7744 17.2604 15.6563C17.1423 15.5383 17.0833 15.389 17.0833 15.2084V14.1667H2.91663V15.2084C2.91663 15.389 2.8576 15.5383 2.73954 15.6563C2.62149 15.7744 2.47218 15.8334 2.29163 15.8334ZM10.625 8.43758H16.25V6.37508C16.25 6.0973 16.1562 5.86814 15.9687 5.68758C15.7812 5.50703 15.5486 5.41675 15.2708 5.41675H11.4583C11.2222 5.41675 11.0243 5.51397 10.8645 5.70841C10.7048 5.90286 10.625 6.12508 10.625 6.37508V8.43758ZM3.74996 8.43758H9.37496V6.37508C9.37496 6.12508 9.2951 5.90286 9.13538 5.70841C8.97565 5.51397 8.77774 5.41675 8.54163 5.41675H4.70829C4.4444 5.41675 4.21871 5.5105 4.03121 5.698C3.84371 5.8855 3.74996 6.11119 3.74996 6.37508V8.43758ZM2.91663 12.9167H17.0833V10.5209C17.0833 10.2848 17.0034 10.0869 16.8437 9.92717C16.684 9.76744 16.4861 9.68758 16.25 9.68758H3.74996C3.51385 9.68758 3.31593 9.76744 3.15621 9.92717C2.99649 10.0869 2.91663 10.2848 2.91663 10.5209V12.9167ZM17.0833 12.9167H2.91663H17.0833Z"
        fill={props.color ? props.color : '#333333'}
      />
    </svg>
  );
};
