import React, { useState, useEffect } from 'react';
import { fetchUnitFile } from '@store/actions/action-mh';
import getStore from '@store/store';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

export default (props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  // const {
  //   id,
  //   input,
  //   label,
  //   type,
  //   meta: { touched, error },
  //   classes,
  //   disabled,
  //   inputProps,
  // } = props;

  useEffect(() => {
    if (!file) {
      displayFile();
    }
  }, []);

  const displayFile = async () => {
    const file = await getStore().dispatch(fetchUnitFile(props.input.value));
    setFile(file);
  };

  return (
    <div className={style['open-file-field']}>
      {file ? (
        <a className={style['model-plan-button']} href={file} target="_blank">
          📏 Unit model plan
        </a>
      ) : file !== null ? (
        <p>
          <small>Loading…</small>
        </p>
      ) : (
        <p>
          <small>
            {t(
              'form.unit.label.no_file',
              '⚠️ There is no plan attached to this model.',
            )}
          </small>
        </p>
      )}
    </div>
  );
};
