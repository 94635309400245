//
// MH List Grid
// Use ag-grid.com
// Could use https://adazzle.github.io/react-data-grid/#/examples/basic if too expensive !
//
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseGrid from '@components/BaseGrid';
import { submitSave } from './submit';
import RefDatas from '@lib/RefDatas';
import { debugMh } from '@lib/debug';

import getStore from '@store/store';
import { withTranslation } from 'react-i18next';
import { map } from 'lodash';

class Grid extends PureComponent {
  static propTypes = {
    userSettings: PropTypes.object,
    history: PropTypes.object,
    list: PropTypes.shape({
      data: PropTypes.array,
      metas: PropTypes.object,
    }),
    gridName: PropTypes.string.isRequired,
    refDatas: PropTypes.object,
    onSortChanged: PropTypes.func,
    onFilterChanged: PropTypes.func,
    // Mutualized event listener on column state changed.
    onColumnStateChanged: PropTypes.func,
    colDef: PropTypes.array,
    excelStyle: PropTypes.array,
    onGridReady: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      fieldMapping: {
        'commercial_brand.lib': 'RefMarqueCommerciale',
        'site.lib': 'RefSite',
        'product_type.lib': 'RefContractingProductTypes',
      },
    };

    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.undoLastEdit = this.undoLastEdit.bind(this);
  }

  onGridReady(params) {
    this.api = params.api;
    // this.columnApi = params.columnApi
    this.props.onGridReady(params.api);
  }

  onCellValueChanged(e) {
    // build params for submit
    const values = {
      _id: e.data._id,
      [e.colDef.field]: e.value,
    };

    if (
      e?.colDef?.hasOwnProperty('cellEditorSelector') &&
      this.state.fieldMapping.hasOwnProperty(e.colDef.field)
    ) {
      const refData = this.state.fieldMapping[e?.colDef?.field];
      console.log('JYO: refData: ', refData);
      const flatRefData = getStore()
        ?.getState()
        ?.refDatas?.data?.[refData]?.flatMap?.((elem) => {
          if (elem.options) {
            return elem.options.map((subElem) => subElem);
          } else {
            return elem;
          }
        });

      const result = flatRefData.find((elem) => {
        if (elem.options) {
          const tempResult = elem.options.find(
            (subElem) => subElem.label === e.value,
          );
          if (tempResult) {
            return tempResult.val;
          }
        } else {
          return elem.label === e.value;
        }
      });

      if (result) {
        let fieldName = e.colDef.field;
        if (e.colDef.field.includes('.lib')) {
          fieldName = e.colDef.field.replace('.lib', '');
        } else if (e.colDef.field.includes('.nom')) {
          fieldName = e.colDef.field.replace('.nom', '');
        }
        values[fieldName] = result.val;
      }
    }

    debugMh(
      'Grid => onCellValueChanged triggered, values =',
      JSON.stringify(values),
    );

    // call server and on callback, flash cell to indicate that it as been updated
    return submitSave(values, null, this.props.t).then(() => {
      if (RefDatas.GROUPED_REF_DATA.includes(e.colDef.refData)) {
        // FIXME bug connu : il faudrait fetcher toute la ligne pour récupérer l'info des colonnes calculées :
        // commercial_type.parent_lib, commercial_type.group, commercial_type.is_terrasse, commercial_type.is_clim, commercial_type.is_empl_premium
        // debugMh('this.api.refreshCells({ rowNodes: [e.node] }): ', e.node)
        return (
          !e.api.destroyCalled && e.api.refreshCells({ rowNodes: [e.node] })
        );
      } else {
        return e.api.flashCells({ rowNodes: [e.node], columns: [e.column] });
      }
    });
  }

  // Undo the last edit with provided datas = submit Save with old values...
  undoLastEdit(data) {
    // debugMh('undoLastEdit triggered, data =', data)
    const value = {
      _id: data._id,
      [data.field]: data.oldValue,
    };
    debugMh('undoLastEdit => value to undo: ', value);

    // on callback, refresh values in grid
    return submitSave(value, null, this.props.t).then(() => {
      const rowNode = this.api.getRowNode(data._id);
      rowNode.setDataValue(data.field, data.oldValue);
      // FIXME bug connu : il faudrait fetcher toute la ligne pour récupérer l'info des colonnes calculées :
      // commercial_type.parent_lib, commercial_type.group, commercial_type.is_terrasse, commercial_type.is_clim, commercial_type.is_empl_premium

      return (
        !this.api.destroyCalled &&
        this.api.refreshCells({ rowNodes: [rowNode], columns: [data.field] })
      );
    });
  }

  render() {
    // debugMh('render => this.props.refDatas: ', this.props.refDatas)
    const gridName = this.props.gridName;
    const { data } = this.props.list;
    debugMh('this.props.list: ', this.props.list);
    /*<div className="ag-theme-material">*/
    // https://github.com/ag-grid/ag-grid-docs/blob/master/src/example.js
    // debugMh('this.props.onCellValueChanged: ', this.props.onCellValueChanged)
    return (
      <BaseGrid
        gridName={gridName}
        userListSettings={this.props.userSettings}
        columnDefs={this.props.colDef}
        excelStyles={this.props.excelStyles}
        rowData={data}
        isFetching={this.props.isFetching}
        onSortChanged={this.props.onSortChanged}
        onFilterChanged={this.props.onFilterChanged}
        onColumnStateChanged={this.props.onColumnStateChanged}
        onCellValueChanged={this.onCellValueChanged}
        onGridReady={this.onGridReady}
        undo={this.undoLastEdit}
      />
    );
  }
}
export default withTranslation()(Grid);
