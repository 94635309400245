import { getAPIToken } from '../../security/User';
import fetch from 'node-fetch';
import { debugStore } from '../../lib/debug';
import { fixRefValues } from '../../lib/utils';
// ------------------------------------------------------------------------
// MOBILHOME EDITING
// ------------------------------------------------------------------------
export const RECEIVE_UR = 'RECEIVE_UR';

//
// After receiving data from API.
//
export function receiveUR(data) {
  return {
    type: RECEIVE_UR,
    data,
  };
}

export function createUnitReport(myValues) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore('createUnitReports: myValues=', myValues);

    delete myValues.__v;

    fixRefValues(myValues);

    const url = `${process.env.REACT_APP_API_URL}/api/unit_reports/`;

    const options = {
      method: 'PUT',
      body: JSON.stringify(myValues),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('createUnitReport: url=', url, options);

    return fetch(url, options).then((response) => {
      if (response.status >= 400) {
        console.error(
          `createUnitReport: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
        );

        const err = new Error(response.statusText);
        err.code = response.status;
        throw err;
      }
      dispatch(receiveUR(response));

      return response;
    });
  };
}

export function fixUnitReport(myValues) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore('fixUnitReport: myValues=', myValues);

    delete myValues.__v;
    fixRefValues(myValues);

    const url = `${process.env.REACT_APP_API_URL}/api/unit_reports/`;

    const options = {
      method: 'POST',
      body: JSON.stringify(myValues),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('createUnitReport: url=', url, options);

    return fetch(url, options).then((response) => {
      if (response.status >= 400) {
        console.error(
          `fixUnitReport: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
        );

        const err = new Error(response.statusText);
        err.code = response.status;
        throw err;
      }
      dispatch(receiveUR(response));

      return response;
    });
  };
}
