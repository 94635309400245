//
// Format timeline.
//
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';

// Components
import More from '@components/icons/More';
import Tag from '@components/common/Tag';
import Timeline from '@vendor/mdpr/components/Timeline/Timeline';

// Characters
import technical_character from '@assets/images/svg/technical_character.svg';
import default_character from '@assets/images/svg/default_character.svg';
import maintenance_request_character from '@assets/images/svg/maintenance_request_character.svg';
import safety_commissioning_character from '@assets/images/svg/safety_commissioning_character.svg';
import equipment_character from '@assets/images/svg/equipment_character.svg';

import style from './style.module.scss';

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const F2MCard = ({ t, data }) => {
  const [show, setShow] = useState(false);
  const title = () => {
    switch (data.app_name) {
      case 'equipment_check':
        return 'Equipment check';
      case 'technical_check':
        return 'Technical check';
      case 'safety_commissionning':
      case 'safety_commissioning':
        return 'Safety commissioning';
      case 'maintenance_request':
        return 'Maintenance request';
      default:
        return data.app_name;
    }
  };
  const imageSrc = () => {
    switch (data.app_name) {
      case 'equipment_check':
        return equipment_character;
      case 'technical_check':
        return technical_character;
      case 'safety_commissionning':
      case 'safety_commissioning':
        return safety_commissioning_character;
      case 'maintenance_request':
        return maintenance_request_character;
      default:
        return default_character;
    }
  };
  return (
    <div className={style['f2m-data-wrapper']}>
      <header className={style['widget-header']}>
        <figure>
          <img src={imageSrc()} alt="Technical check" title="Technical check" />
        </figure>
      </header>

      <div>
        <div className={style['f2m-data-body']}>
          <div>
            <div className={style['date']}>
              <div>
                <p>{title()}</p>
                <Tag level={'status'} label={data.status} />
              </div>
              <small className={style['f2m-data-season']}>
                {t('common.label.season', 'Season')} {data.season}
              </small>
              <small>
                {t('common.label.created_at', 'Created at')}{' '}
                {dayjs(data.date).format('lll')}
              </small>
            </div>
            <div className={style['widget-author']}>
              <small>{t('common.label.staff_member', 'Staff member')}</small>
              <p>{`${data.user && data.user}`}</p>
            </div>
          </div>
          <div className={style['widget-details']}>
            <small>{t('common.label.details', 'Details')}</small>
            <ul>
              {data?.label?.split('\n').map((element, index) => (
                <li key={index}>{element}</li>
              ))}
            </ul>
            <div className={style['details-list']}>
              <Tippy placement="top" content={show ? 'Close' : 'See more'}>
                <button
                  type="button"
                  onClick={() => setShow(!show)}
                  className={show ? style['close'] : undefined}
                >
                  <More />
                </button>
              </Tippy>

              <ul className={show ? style['active'] : undefined}>
                {Object.keys(data.details).map((key) => {
                  return (
                    data?.details[key] && (
                      <li key={key}>
                        <span>{key.replace(/_/g, ' ')}</span>
                        {data.details[key]}
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class MhMovementsList extends React.Component {
  static propTypes = {
    mh: PropTypes.object,
    mhMovements: PropTypes.object,
    t: PropTypes.func,
    dispatch: PropTypes.func,
  };
  constructor(props) {
    super(props);
  }

  render() {
    // console.log('Rendering MhListF2M...');
    const { mh, t } = this.props;

    if (!mh || !mh.f2m_data || mh.f2m_data.length === 0) {
      return (
        <div style={{ fontSize: 'var(--fs-small)' }}>
          {t('common.label.no_data_f2m', 'No data from F2M for this mobilhome')}
        </div>
      );
    }

    const widgetStories = [];

    // Order by date => Desc
    mh.f2m_data.sort((a, b) =>
      dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1,
    );
    mh.f2m_data.forEach((data) => {
      if (!data.details) {
        data.details = {};
      }

      const dataToPush = {
        inverted: true,
        // title: (
        // <header className={style['widget-header']}>
        //   <div className={style['date']}>
        //     <div>
        //       <h2>{`Season ${data.season}`}</h2>
        //       <Tag level={'status'} label={data.status} />
        //     </div>
        //     <small>Created at {dayjs(data.date).format('lll')}</small>
        //   </div>
        // </header>
        // ),
        // author: (

        // ),
        body: <F2MCard t={t} data={data} />,
      };

      return widgetStories.push(dataToPush);
    });

    return (
      <div className="timeline">
        <Timeline simple stories={widgetStories} />
      </div>
    );
  }
}
MhMovementsList = withTranslation()(MhMovementsList);

const mapStateToProps = ({ mhMovements }) => ({ mhMovements });

MhMovementsList = connect(mapStateToProps)(MhMovementsList);
export default MhMovementsList;
