import React from 'react';
import style from './style.module.scss';

export default (params) => {
  // const transferTypesArray = params?.value?.trim().split(',').filter(Boolean);
  const transferTypesArray = params?.value;

  return (
    <div className={style['transfer-types-cell-renderer-wrapper']}>
      {transferTypesArray &&
        transferTypesArray.map((item, index) => {
          const _type = item.trim().replace(' ', '-').toLowerCase();
          return (
            <p key={index} className={style[_type]}>
              <span>{item}</span>
            </p>
          );
        })}
    </div>
  );
};
