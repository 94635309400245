import MainHeader from '@components/pwa/MainHeader';
import bannerImage from '@assets/images/pwa/guide-banner.png';
import style from './style.module.scss';

export default (props) => {
  const { guide, action } = props;
  return (
    <div className={style['guide-view']}>
      <div className={style['header-wrapper']}>
        <MainHeader title={guide.title} action={action} />
      </div>

      <div className={style['guide-wrapper']}>
        <figure>
          <img src={bannerImage} alt="banner" />
        </figure>
        <div className={style['guide-content']}>
          <p>{guide.content}</p>
        </div>
      </div>
    </div>
  );
};
