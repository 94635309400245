const Reload = (props) => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 4.375V7L10.5 3.5L7 0V2.625C3.1325 2.625 0 5.7575 0 9.625C0 10.9988 0.4025 12.2763 1.085 13.3525L2.3625 12.075C1.96875 11.3488 1.75 10.5088 1.75 9.625C1.75 6.72875 4.10375 4.375 7 4.375ZM12.915 5.8975L11.6375 7.175C12.0225 7.91 12.25 8.74125 12.25 9.625C12.25 12.5213 9.89625 14.875 7 14.875V12.25L3.5 15.75L7 19.25V16.625C10.8675 16.625 14 13.4925 14 9.625C14 8.25125 13.5975 6.97375 12.915 5.8975Z"
        fill={props.color ? props.color : '#FFFFFF'}
      />
    </svg>
  );
};

export default Reload;
