import React from 'react';
import style from './style.module.scss';

const SettingButton = ({ children, action, label, disabled = false }) => {
  return (
    <button
      className={style['setting-button']}
      type="button"
      onClick={action}
      disabled={disabled}
    >
      <small>{label}</small>
      {children && <div className={style['icon-wrapper']}>{children}</div>}
    </button>
  );
};

export default SettingButton;
