import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      lng: localStorage.getItem('i18nextLng'),
      detection: {
        order: ['querystring', 'localStorage', 'sessionStorage', 'navigator'],
      },
      fallbackLng: 'en',
      supportedLngs: ['en', 'es', 'fr', 'it', 'nl', 'hr'],
      saveMissing: process.env.NODE_ENV !== 'production',
      saveMissingTo: 'current',
      ns: ['translation'],
      defaultNS: 'translation',
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      backend: {
        expirationTime: 7 * 24 * 60 * 60 * 1000,
        loadPath: `${process.env.REACT_APP_API_URL}/locales/resources.json?lng={{lng}}&ns={{ns}}`,
        addPath: `${process.env.REACT_APP_API_URL}/locales/add/{{lng}}/{{ns}}`,
        parse: (data, language, namespace) => {
          const json = JSON.parse(data);
          console.info(language, json[language][namespace]);
          return json[language][namespace];
        },
      },
    },
    (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      t('key'); // -> same as i18next.t
    },
  );

export default i18n;
