export default (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 96 960 960"
    >
      <path
        fill={props.color ? props.color : '#333333'}
        d="m480 482-42-42 74-74H330v-60h182l-74-74 42-42 146 146-146 146ZM289.8 976q-29.8 0-50.8-21.2-21-21.2-21-51t21.2-50.8q21.2-21 51-21t50.8 21.2q21 21.2 21 51T340.8 955q-21.2 21-51 21Zm404 0q-29.8 0-50.8-21.2-21-21.2-21-51t21.2-50.8q21.2-21 51-21t50.8 21.2q21 21.2 21 51T744.8 955q-21.2 21-51 21ZM62 236v-60h116l170 364h287.7L796 260h67L701 563q-11 19-28.6 30.5Q655 605 634 605H331l-56 104h491v60H284q-37.7 0-57.3-30-19.7-30-2.7-61l64-118-148-324H62Z"
      />
    </svg>
  );
};
