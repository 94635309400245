import { sendFlashMessage } from '@store/actions/action-common';
import getStore from '@store/store';
import { logInfo, logError } from '@helpers/logs';

import { update } from '@store/actions/action-pitchFees';

//
// Submit function.
// Return a Promise: the submitting property will be set to true until the promise has been resolved or rejected.
// https://redux-form.com/6.5.0/docs/api/props.md/#-handlesubmit-eventorsubmit-function-
//
export function submitSave(values, callback = null, t = null) {
  return getStore()
    .dispatch(update(values, callback))
    .then((response) => {
      getStore().dispatch(
        t &&
          sendFlashMessage(
            t('common.flash_message.saving_in_progress', 'Saving in progress'),
            'info',
          ),
      );
      return response;
    })
    .catch((err) => {
      getStore().dispatch(sendFlashMessage(err, 'error'));
    });
}
