import React from 'react';
import style from './style.module.scss';
import classnames from 'classnames';

const BaseButton = ({
  children,
  label,
  action,
  disabled = false,
  disabledUnderlineStyle = false,
}) => {
  return (
    <button
      className={classnames(
        style['base-button'],
        disabledUnderlineStyle ? style['disabled-underline'] : '',
      )}
      type="button"
      onClick={action}
      disabled={disabled}
    >
      {children && <div className={style['icon-wrapper']}>{children}</div>}
      {label}
    </button>
  );
};

export default BaseButton;
