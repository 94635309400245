const date = new Date();
let month = date.getMonth();

export const currentSeasonYear = (() => {
  // After October
  if (month > 9) {
    return date.getFullYear() + 1;
  } else {
    return date.getFullYear();
  }
})();
