import React from 'react';
import ArticleHeader from '@components/headers/ArticleHeader';
import RedirectionButton from '@components/buttons/RedirectionButton';
import Arrow from '@components/icons/Arrow';
import Check from '@components/icons/Check';
import FormButton from '@components/buttons/FormButton';
import { useTranslation } from 'react-i18next';

import amendment_validationJSON from '@assets/lottie/amendment_validation.json';

import style from './style.module.scss';
import { useState } from 'react';

export default (props) => {
  const { t } = useTranslation();
  const { seasons, action, handleClose } = props;

  const [selectedCampsite, updateSelectedCampsite] = useState(null);
  const [selectedSeasonCampsite, updateSelectedSeasonCampsite] = useState(null);

  const setCampsite = (e) => {
    if (e.target.value) {
      updateSelectedCampsite(seasons[e.target.value]);
      updateSelectedSeasonCampsite(null);
    } else {
      updateSelectedCampsite(null);
      updateSelectedSeasonCampsite(null);
    }
  };

  const setSeason = (e) => {
    if (e.target.value) {
      updateSelectedSeasonCampsite(e.target.value);
    } else {
      updateSelectedSeasonCampsite(null);
    }
  };
  // Seasons -> [{site: {_id, lib}, season: Array}]

  return (
    <dialog open>
      <div className={style['valid-transfers-wrapper']}>
        <div className={style['upper-header']}>
          <RedirectionButton
            icon={Arrow}
            action={() => handleClose()}
            label={t('common.validate_transfers.close', 'Close')}
          />
        </div>
        <ArticleHeader
          label="Amendments validation"
          lottieJSON={amendment_validationJSON}
        />
        <div className={style['wrapper']}>
          <p>
            {t(
              'common.validate_transfers.notice',
              'Please note this action is irreversible.',
            )}
          </p>

          <div className={style['buttons']}>
            {seasons && seasons.length > 0 ? (
              <select onChange={setCampsite} defaultValue="">
                <option value="">Select a campsite</option>
                {seasons?.map((season, index) => (
                  <option key={index} value={index}>
                    {season?.site?.lib}
                  </option>
                ))}
              </select>
            ) : (
              <p>
                {t(
                  'common.validate_transfers.unavailable',
                  'No complete amendments data available.',
                )}
              </p>
            )}

            {selectedCampsite && (
              <select
                onChange={setSeason}
                key={selectedCampsite?.site?._id}
                defaultValue=""
              >
                <option value="">Select the season</option>
                {selectedCampsite?.seasons
                  ?.sort(function (a, b) {
                    return a - b;
                  })
                  .map((season, index) => (
                    <option key={index} value={season}>
                      {season}
                    </option>
                  ))}
              </select>
            )}

            {selectedCampsite && selectedSeasonCampsite && (
              <div className={style['confirm-btn']}>
                <FormButton
                  action={action(
                    selectedCampsite?.site?._id,
                    selectedSeasonCampsite,
                  )}
                  label="Confirm"
                  // label={`${t(
                  //   'common.validate_transfers.year',
                  //   'Year',
                  // )} ${season}`}
                  icon={Check}
                  color="var(--color-surface-octonary)"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </dialog>
  );
};
