import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import dayjs from 'dayjs';
import { CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga4';
import { getUserSetting } from '@security/User';

// Layout
import Layout from '@layout/default';

// Components
import FlashMessage from '@components/common/FlashMessage';
import { Linear } from '@components/Loader';
import MhDialogTransfer from '../MhDialogTransfer';
import ColumnsDefinition from '@components/helpers/ColumnsDefinition';
import BaseButton from '@components/buttons/BaseButton';
import ExportButton from '@components/buttons/ExportButton';
import More from '@components/icons/More';
import Notification from '@components/icons/Notification';
import ReadyToBeSold from '@components/icons/ReadyToBeSold';
import Excel from '@components/icons/Excel';
import Reload from '@components/icons/Reload';
import { AllowedBlock } from '@components/common/AllowedBlock';
import GridConfigs from '@components/headers/GridConfigs';
import GridViewConfig from '@components/common/dialogs/GridViewConfig';
import ScrollToTopButton from '@components/buttons/ScrollToTopButton';

// Helpers
import { logInfo } from '@helpers/logs';
import CacheManager from '@helpers/cache-helper';
import { isAllowed } from '@security/User';
import { debugMh } from '@lib/debug';
import { expandColumns, shrinkColumns } from '@components/helpers/GridHelper';
import pdf from '@components/helpers/UnitAsPdf';

// Store
import { connect } from 'react-redux';
import getStore from '@store/store';
import { fetchMhList, fetchMh, setMhForSale } from '@store/actions/action-mh';
import { fetchMv } from '@store/actions/action-mv';
import { clearReduxState } from '@store/actions/action-site';
import MhGrid from '../MhGrid';
import {
  updateFilters,
  updateColumnState,
  sendFlashMessage,
  receiveNotification,
} from '@store/actions/action-common';

import style from './style.module.scss';

const cache = new CacheManager();

class MhList extends Component {
  static GRID_NAME = 'mobilhomes';
  static propTypes = {
    settings: PropTypes.shape({}),
    labels: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      transferFormOpen: false,
      mhTransferIds: [],
      expanded: true,
      mhList: {},
      showGridView: false,
      isFetching: true,
      hasNotification: false,
    };
    this.api = {};
    this._isMounted = true;

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onColumnStateChanged = this.onColumnStateChanged.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onTransfer = this.onTransfer.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.getListSettings = this.getListSettings.bind(this);
    this.editRow = this.editRow.bind(this);
    this.convertAsPdf = this.convertAsPdf.bind(this);
    this.state.excelStyles = this.getExcelStyles();
    this.toggleControlsView = this.toggleControlsView.bind(this);
    this.validateData = this.validateData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onClearCache = this.onClearCache.bind(this);
    this.loadIndexedDB = this.loadIndexedDB.bind(this);
    // this.addData = this.addData.bind(this);
    // this.socketRefreshData = this.socketRefreshData.bind(this);
    // this.socketDeleteData = this.socketDeleteData.bind(this);
    // this.socketAddData = this.socketAddData(this);
    this.searchToObject = this.searchToObject.bind(this);
  }

  getColDefs() {
    debugMh('getColDefs: this.props=', this.props);

    const hasWritePerm = isAllowed(
      [
        'ROLE_WRI_MH_EQUI',
        'ROLE_WRI_MH_HIVERN',
        'ROLE_WRI_MH_EMPL',
        'ROLE_WRI_MH_INFO',
        'ROLE_WRI_MH_MVT',
        'ROLE_WRI_MH_USE_TYPE',
        'ROLE_WRI_MH_COMMERCIAL_TYPE',
        'ROLE_ACCOUNTING_MGNT',
      ],
      true,
    );

    const listActions = [
      {
        func: this.editRow,
        labelFunc: 'editRow',
        labelButton: hasWritePerm
          ? this.props.t('common.action.edit', 'Edit')
          : this.props.t('common.action.view', 'View'),
      },
      {
        func: this.convertAsPdf,
        labelFunc: 'convertAsPdf',
        labelButton: this.props.t('common.action.history', 'History'),
      },
    ];

    // TODO: Enable validateData for later
    // if (isAllowed('ROLE_ADMIN')) {
    //   listActions.push({
    //     func: this.validateData,
    //     labelFunc: 'validateData',
    //     labelButton: 'Validate',
    //   });
    // }

    const cd = new ColumnsDefinition(
      this.props.refDatas,
      MhList.GRID_NAME,
      get(this.props, 'userSettings.list.mobilhomes.filters'),
    );
    return cd.getMHListConfiguration(listActions, this.props.t);
  }

  getExcelStyles() {
    const cd = new ColumnsDefinition();
    return cd.getExcelStyles();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // No refresh create amendment is opened
      if (!this.props.location.hash) this.fetchData();
    }

    if (
      prevProps.receiveNotification !== this.props.receiveNotification &&
      this.props.receiveNotification !== null
    ) {
      this.setState({
        hasNotification: this.props.receiveNotification,
      });
    }
  }

  componentDidMount() {
    this.fetchData();
    this._isMounted = true;
    getStore().getState().receiveNotification !== null &&
      this.setState({
        hasNotification: getStore().getState().receiveNotification,
      });
  }

  componentWillUnmount() {
    this.setState({ mhList: {} });
    this.api?.destroy && this.api.destroy();
    this._isMounted = false;
  }

  async fetchData() {
    this.setState({ isFetching: true });
    cache.readData('mhList', (res) => {
      if (!res || !res?.data || res?.data?.length === 0 || location.search) {
        logInfo('Fetching data from API');
        sessionStorage.removeItem('unitAwaitingRegistration');
        sessionStorage.removeItem('unitAwaitingRemoving');
        return new Promise((resolve, reject) => {
          resolve(this.props.dispatch(fetchMhList(location.search)));
        }).then((res) => {
          if (this._isMounted) {
            if (!location.search) {
              cache.writeData('mhList', { data: res });
            }
            this.setState({ mhList: { data: res } });
            this.setState({ isFetching: false });
          }
          this.props.dispatch(
            sendFlashMessage(
              this.props.t(
                'common.flash_message.fetch_units',
                'Fetch units completed!',
              ),
              'success',
            ),
          );
        });
      } else {
        this.loadIndexedDB(res);
        sessionStorage.removeItem('unitAwaitingRegistration');
        sessionStorage.removeItem('unitAwaitingRemoving');
      }
    });
  }

  searchToObject() {
    var pairs = window.location.search.substring(1).split('&'),
      obj = {},
      pair,
      i;

    for (i in pairs) {
      if (pairs[i] === '') continue;

      pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  }

  loadIndexedDB(res) {
    if (this._isMounted) {
      this.setState({ mhList: res });
      this.setState({ isFetching: false });
      logInfo('Fetching data from IndexedDB');
    }
  }

  onFilterChanged(e) {
    // if (this.props.userSettings.list[MhList.GRID_NAME]) {
    //   console.info(e.api.getFilterModel());
    // }
    debugMh('onFilterChanged triggered!', e, e.api.getFilterModel());

    // Updating filters will refresh the results.

    this.props.dispatch(
      updateFilters(MhList.GRID_NAME, e.api.getFilterModel()),
    );
  }

  onColumnStateChanged(e) {
    debugMh('onColumnStateChanged triggered!', e, e.columnApi.getColumnState());

    // Updating sort will refresh the results.
    this.props.dispatch(
      updateColumnState(MhList.GRID_NAME, e.columnApi.getColumnState()),
    );
  }

  onExport() {
    const nodeSelected = this.api.getSelectedNodes();
    const selectedColumns = this.api.columnController.columnApi
      .getAllGridColumns()
      .filter(
        (column) => column.colId !== 'selection' && column.colId !== 'actions',
      )
      .filter((column) => column.visible);

    if (selectedColumns?.length > 0) {
      const params = {
        fileName: `AssetRegister-ExportUnits-${dayjs().format(
          'YYYY.MM.DD-hh',
        )}h.xlsx`,
        sheetName: 'Units',
        columnKeys: selectedColumns.map((column) => column.colId),
        onlySelected: nodeSelected?.length > 0 ? true : false,
      };

      ReactGA.event('units_export', {
        user_interaction: selectedColumns
          ? 'Exporting specific unit(s)'
          : 'Exporting all units',
        count: selectedColumns > 0 ? selectedColumns : 'All',
        user_id: getUserSetting('_id').toString(),
        dateTime: dayjs().format(),
      });

      this.api.exportDataAsExcel(params);
    } else {
      this.props.dispatch(
        sendFlashMessage(
          this.props.t('common.flash_message.error', 'An error has happened'),
          'error',
        ),
      );
    }
  }

  onAdd() {
    this.props.history.push('/mh/create');
  }

  onTransfer() {
    const nodeSelected = this.api
      .getSelectedNodes()
      .map((rowNode) => rowNode.id);
    debugMh('onTransfer: ', nodeSelected);
    this.setState({ transferFormOpen: true, mhTransferIds: nodeSelected });
  }

  onCloseTransfer(newValues) {
    // Reset fixed Ag Grid Header
    this.setState({ transferFormOpen: false });
    for (const rowNode of this.api.getSelectedNodes()) {
      rowNode.setSelected(false);
    }

    this.loadIndexedDB({ data: newValues });
  }

  setForSale = () => {
    if (
      window.confirm(
        'Are you sure to change the sale status of these units to For Sale?',
      )
    ) {
      const nodeSelected = this.api
        .getSelectedNodes()
        .map((rowNode) => rowNode.id);

      this.props.dispatch(setMhForSale(nodeSelected));
    }
  };

  onReset() {
    debugMh('Reset filters and sort...');

    // ? use applyColumnState instead ?
    // this.api.setSortModel(null)
    setTimeout(() => this.api.setFilterModel(null), 150);
  }

  /**
   * Clear IndexedDB and Redux
   */
  onClearCache() {
    cache.clear();
    this.props.dispatch(clearReduxState());
    // Clear object first
    this.setState({ mhList: {} });
    // Then fill the object
    this.fetchData();
  }

  onGridReady(api) {
    this.api = api;
    // events : enable transfer if MH are selected
    api.addEventListener('selectionChanged', () => {
      const nbItems = api.getSelectedNodes().length;
      this.setState({
        nbItemsSelected: nbItems,
      });
    });
  }

  editRow(node) {
    debugMh('editRow triggered!', node);
    this.props.history.push(`/mh/${node.id}`);
  }

  async convertAsPdf(node) {
    this.props.dispatch(fetchMh(node.id)).then((unit) => {
      let transfersArray = [];
      let counter = 0;
      if (unit?.transfer_id?.length > 0) {
        unit.transfer_id.forEach((transfer, index) => {
          this.props
            .dispatch(fetchMv(transfer))
            .then((value) => {
              transfersArray.push(value);
              counter += 1;
              if (counter === unit.transfer_id.length) {
                pdf({ ...unit, transfers: transfersArray });
              }
            })
            .catch((err) => {
              counter += 1;
              if (counter === unit.transfer_id.length) {
                pdf({ ...unit, transfers: transfersArray });
              }
            });
        });
      } else {
        pdf(unit);
      }
    });
  }

  validateData(node) {
    console.log('ValidatData fired ... : ', MhList.GRID_NAME, node.id);
    // this.props.dispatch(validateDataByAdmin({ gridName: MhList.GRID_NAME, _id: node.id }));
  }

  getListSettings() {
    return this.props.userSettings.list
      ? this.props.userSettings.list[MhList.GRID_NAME]
      : {};
  }

  toggleControlsView() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    // ! Document title
    document.title = `EUROPEAN CAMPING GROUP | ${this.props.t(
      'page.units.document_title',
      'Unit list',
    )}`;
    // debugMh('MHLIST RENDER: this.props: ', this.props);
    const { t, classes, ...otherProps } = this.props;
    const listSettings = this.getListSettings();
    // debugMh('MhList => listSettings: ', listSettings)
    const { expanded, showGridView, isFetching, hasNotification } = this.state;

    if (!listSettings) return <div>LOADING MOBILHOMES...</div>;

    let content;
    if (this.state.mhList && this._isMounted) {
      content = (
        <div className={style['content-body']}>
          <Linear isFetching={isFetching} {...otherProps} />
          <MhGrid
            mhList={this.state.mhList}
            gridName={MhList.GRID_NAME}
            onFilterChanged={this.onFilterChanged}
            onColumnStateChanged={this.onColumnStateChanged}
            onGridReady={this.onGridReady}
            colDef={this.getColDefs()}
            excelStyles={this.state.excelStyles}
            isFetching={isFetching}
            {...otherProps}
          />
        </div>
      );
    } else {
      content = <div>:( :( :( NO RESULT OR NOT YET LOADED!</div>;
    }

    const customProps = {
      ...otherProps,
      api: this.api,
      gridName: MhList.GRID_NAME,
      expanded: expanded,
    };

    return (
      <Layout {...customProps}>
        <ScrollToTopButton />
        <FlashMessage />
        <header className={style['units-header']}>
          <div>
            <ExportButton
              action={() => {
                // GA
                ReactGA.event('fetch_data', {
                  user_interaction: 'Re-fetch data from units database',
                  browser: navigator.userAgent,
                  user_id: getUserSetting('_id').toString(),
                  dateTime: dayjs().format(),
                });
                this.onClearCache();
                this.props.dispatch(receiveNotification(false));
              }}
              label={t('common.action.update', 'Update now')}
              hasNotification={
                hasNotification ||
                !!sessionStorage.getItem('unitAwaitingRegistration') ||
                !!sessionStorage.getItem('unitAwaitingRemoving')
              }
            >
              {hasNotification ||
              !!sessionStorage.getItem('unitAwaitingRegistration') ||
              !!sessionStorage.getItem('unitAwaitingRemoving') ? (
                <Notification />
              ) : (
                <Reload color="#194989" />
              )}
            </ExportButton>
            <AllowedBlock roles={'ROLE_EXP_LIST'}>
              <ExportButton
                action={() => {
                  this.onExport();
                }}
                label={t('common.action.export_excel', 'Export to excel')}
                description={`${
                  this.state.nbItemsSelected && this.state.nbItemsSelected > 0
                    ? `${t('common.label.export', 'Export')} ${
                        this.state.nbItemsSelected
                      } ${t('common.label.row', 'row')}${
                        this.state.nbItemsSelected > 1 ? 's' : ''
                      }`
                    : ''
                } `}
              >
                <Excel />
              </ExportButton>
            </AllowedBlock>
          </div>
          <div className={style['units-header-add']}>
            <AllowedBlock roles={'ROLE_WRI_MH_SALES'}>
              <ExportButton
                action={() => {
                  // GA
                  ReactGA.event('units', {
                    user_interaction: 'Ready to be sold',
                    user_id: getUserSetting('_id').toString(),
                    dateTime: dayjs().format(),
                  });
                  this.setForSale();
                }}
                label={t('common.action.create_for_sale', 'Ready to be sold')}
                description={`${
                  this.state.nbItemsSelected
                    ? `${this.state.nbItemsSelected} ${t(
                        'common.action.selected',
                        t('common.label.selected', 'selected'),
                      )}`
                    : t('common.label.no_selected', '0 selected')
                } `}
                disabled={
                  !this.state.nbItemsSelected ||
                  this.state.nbItemsSelected === 0
                }
              >
                <ReadyToBeSold color="#194989" />
              </ExportButton>
            </AllowedBlock>
            <AllowedBlock roles={'ROLE_WRI_MH_MVT'}>
              <ExportButton
                action={() => {
                  // GA
                  ReactGA.event('units', {
                    user_interaction: 'Adding an amendment',
                    user_id: getUserSetting('_id').toString(),
                    dateTime: dayjs().format(),
                  });
                  this.onTransfer();
                }}
                label={t('common.action.create_transfer', 'New amendment')}
                description={`${
                  this.state.nbItemsSelected
                    ? `${this.state.nbItemsSelected} ${t(
                        'common.action.selected',
                        t('common.label.selected', 'selected'),
                      )}`
                    : t('common.label.no_selected', '0 selected')
                } `}
                disabled={
                  !this.state.nbItemsSelected ||
                  this.state.nbItemsSelected === 0
                }
              >
                <More color="#194989" />
              </ExportButton>
            </AllowedBlock>
            <AllowedBlock roles={'ROLE_CREATE_MH'}>
              <BaseButton
                action={() => {
                  // GA
                  ReactGA.event('units', {
                    user_interaction: 'Adding a unit',
                    user_id: getUserSetting('_id').toString(),
                    dateTime: dayjs().format(),
                  });

                  this.onAdd();
                }}
                label={t('common.action.create_unit', 'Add a new entry')}
              >
                <More color="#194989" />
              </BaseButton>
            </AllowedBlock>
          </div>
        </header>
        <div className={style['content-container']}>
          <div className={style['content-container-header']}>
            <GridConfigs
              api={this.api}
              expandColumns={expandColumns}
              shrinkColumns={shrinkColumns}
              showGridView={() =>
                this.setState({ showGridView: !this.state.showGridView })
              }
              onReset={this.onReset}
            />
          </div>
          <div id="wrapper" hidden={this.state.transferFormOpen}>
            {content}
          </div>
          <CSSTransition
            in={this.state.transferFormOpen}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <MhDialogTransfer
              handleClose={(values) => this.onCloseTransfer(values)}
              selectedItems={this.state.mhTransferIds}
              {...this.props}
            />
          </CSSTransition>
        </div>

        <CSSTransition
          in={showGridView}
          classNames="slideBottom"
          timeout={300}
          unmountOnExit
        >
          <GridViewConfig
            handleClose={() => {
              this.setState({ showGridView: !this.state.showGridView });
            }}
            {...this.props}
            {...customProps}
          />
        </CSSTransition>
      </Layout>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user,
    userSettings: store.userSettings,
    refDatas: store.refDatas.data,
    receiveNotification: store.receiveNotification,
  };
};

export default withTranslation()(connect(mapStateToProps)(MhList));
