import { Link } from 'react-router-dom';
import classnames from 'classnames';
import style from './style.module.scss';

export default (props) => {
  const { campsite, grade, active } = props;

  const safety_commissioning_percent = campsite.safety_commissioning_report;

  return (
    <Link className={style['marker']} to={`/site/${campsite?._id}`}>
      <span
        className={classnames(
          active ? style['pin'] : '',
          campsite.safety_commissioning_report === 0 ? style['old'] : '',
        )}
      >
        {active ? '' : '📍'}
      </span>
      <div className={style['panel']}>
        <h4>
          {campsite?.lib} | {campsite?.code_group} | {grade}
        </h4>
        {campsite.safety_commissioning_report > 0 ? (
          <div className={style['statistics']}>
            <small>Safety commissioning</small>
            <div className={style['progress-bar']}>
              <span
                className={style['filled']}
                style={{ width: `${safety_commissioning_percent}%` }}
              />
              <span className={style['percent']}>
                {safety_commissioning_percent}%
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </Link>
  );
};
