//
// BaseGrid component.
// All our gris enrich/"extends" this component.
//
import React, { PureComponent } from 'react';
import {
  AgGridReact,
  DateComponent,
  SortableHeaderComponent,
} from 'ag-grid-react';
import PropTypes from 'prop-types';
import { debugCommon } from '../lib/debug';
import ListCellEditor from './cells/ListCellEditor';
import ListCellRenderer from './cells/ListCellRenderer';
import MultiSelectFilter from './filters/MultiSelectFilter';
import ButtonCellRenderer from './cells/ButtonCellRenderer';
import getStore from '../store/store';
import TagCellRenderer from './cells/TagCellRenderer';
import FlagCellRenderer from './cells/FlagCellRenderer';
import UnitTypeCellRenderer from './cells/UnitTypeCellRenderer';
import TransferTypesCellRenderer from './cells/TransferTypesCellRenderer';
import PercentCellRenderer from './cells/PercentCellRenderer';
import LinkCellRenderer from './cells/LinkCellRenderer';

import { stickyAgHeader } from '@helpers/aggrid-helper';

import { currentSeasonYear } from '@mixins/currentSeasonYear';

// TODO: find better way to implement this peace of code /!\
const sideBar = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
};

export default class BaseGrid extends PureComponent {
  static propTypes = {
    gridName: PropTypes.string,
    userListSettings: PropTypes.object,
    onGridReady: PropTypes.func,
    onCellValueChanged: PropTypes.func,
    // Mutualized event listener on column state changed.
    onColumnStateChanged: PropTypes.func,
    undo: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.handleCellValueChanged = this.handleCellValueChanged.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.undo = this.undo.bind(this);
    this.bindUndoEvent = this.bindUndoEvent.bind(this);

    this.unitAwaitingRegistration = [];
    this.unitAwaitingRemoving = [];
    this.transferAwaitingRemoving = [];
    this.transferAwaitingRegistration = [];
    this.pitchAwaitingRegistration = [];
    this.pitchAwaitingRemoving = [];

    this.state = {
      undo: [],
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', stickyAgHeader);
  }

  componentDidUpdate() {
    this.unitAwaitingRegistration = JSON.parse(
      sessionStorage.getItem('unitAwaitingRegistration'),
    );
    this.unitAwaitingRemoving = JSON.parse(
      sessionStorage.getItem('unitAwaitingRemoving'),
    );
    this.transferAwaitingRegistration = JSON.parse(
      sessionStorage.getItem('transferAwaitingRegistration'),
    );
    this.transferAwaitingRemoving = JSON.parse(
      sessionStorage.getItem('transferAwaitingRemoving'),
    );
    this.pitchAwaitingRegistration = JSON.parse(
      sessionStorage.getItem('pitchAwaitingRegistration'),
    );
    this.pitchAwaitingRemoving = JSON.parse(
      sessionStorage.getItem('pitchAwaitingRemoving'),
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', stickyAgHeader);
  }
  onGridReady(e) {
    this.props.onGridReady(e);

    // post processing : init filters and sort
    const settings = this.props.userListSettings;

    if (settings) {
      // -------------------------
      // Restore column state
      // -------------------------
      e.columnApi.applyColumnState({
        state: settings.columnState,
        applyOrder: true,
      });

      // -------------------------
      // Restore filters
      // -------------------------
      const idSetInterval = setInterval(() => {
        if (!this.props.isFetching) {
          clearInterval(idSetInterval);
          setTimeout(() => {
            e.api.setFilterModel(settings.filters);
          }, 500);
        }
      }, 1000);

      // ? Useless ????
      // e.api.onFilterChanged();
    }

    // resize columns
    //e.api.sizeColumnsToFit()
    //expandColumns(e.columnApi)

    // allow undo on grid
    this.bindUndoEvent();
  }

  handleCellValueChanged(e) {
    debugCommon('BaseGrid: handleCellValueChanged: ', e);
    // console.log(`e.oldValue=${e.oldValue}, e.value=${e.value}, e.newValue=${e.newValue}, e.value=${e.value}`)
    if (
      e.newValue === e.oldValue ||
      (e.newValue === '' && e.oldValue === undefined)
    ) {
      return;
    }

    // undo stack
    // blindage pour Ctrl+z successifs, et nettoyage de la pile d'undo lorsque la valeur revient avec le onCellValueChanged :
    // puisqu'on remet à jour le contenu de la cellule, l'evènement est déclenché
    // => on peux detecter la correspondance avec une modification précédente, et la supprimer de la pile (@see isSameEditExist)
    if (this.state.undo.length === 0 || !this.isSameEditExist(e)) {
      const editChange = {
        _id: e.data.id,
        field: e.column.colId,
        oldValue: e.oldValue,
        newValue: e.value,
      };
      this.setState({ undo: this.state.undo.concat(editChange) }, () => {
        debugCommon(
          'handleCellValueChanged: this.state.undo=',
          this.state.undo,
        );
      });
    }

    this.props.onCellValueChanged(e);
  }

  // scan previous edit events and remove it if found
  isSameEditExist(currentEdit) {
    // build new stack, removing the one that matches...
    const newStack = this.state.undo.filter(
      (o) =>
        !(
          o._id === currentEdit.data.id &&
          o.field === currentEdit.column.colId &&
          o.newValue === currentEdit.oldValue
        ),
    );
    debugCommon('newStack: ', newStack);
    const isSameEditExist = newStack.length !== this.state.undo.length;
    if (isSameEditExist) {
      if (this.state.undo.length - newStack.length > 1) {
        debugCommon(
          'undo: WARNING ! more than 1 operation can be undone... ABORT !',
        );
      } else {
        // set new undo stack
        debugCommon('undo : SET NEW STACK !');
        this.setState({ undo: newStack });
      }
    }
    return isSameEditExist;
  }

  // just undo last move
  undo(event) {
    const undoLastElement = this.state.undo.length - 1;
    if (this.props.undo && this.state.undo.length > 0) {
      debugCommon('undo: stack=', this.state.undo);

      const undoElement = this.state.undo[undoLastElement];
      // undo the movement
      this.props.undo(undoElement);
    } else {
      debugCommon('undo: nothing to undo...');
      event.preventDefault();
      return;
    }
  }

  // Undo Event : "Ctrl + z"
  bindUndoEvent() {
    document.addEventListener(
      'keydown',
      (event) => {
        if (event.key === 'Control') {
          // Pas d'alerte si seule la touche Control est pressée.
          return;
        }
        // metaKey (for OSX), ctrlKey (for Win/Linux).
        if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
          this.undo(event);
        }
      },
      false,
    );
  }

  copyToClipboard(params) {
    let value;
    debugCommon('copyToClipboard => params: ', params);
    // if we are processing a Reference data,  copy the _id in a "value" field for the "renderer", and return a string copy
    // warning : in case of copy from original cell, "params.value" contains the raw refData
    //           but if the copy comes from a list change, "params.value" contains a representation object {val,label}...
    // FIXME : trouver une manière d'uniformiser ce comportement à la source (renvoyer toujours la même valeur)

    if (
      params.column &&
      params.column.colDef &&
      params.column.colDef.refData &&
      ((params.value && params.value._id) || (params.value && params.value.val))
    ) {
      if (getStore().getState().userSettings.copyFromButton || false) {
        value = JSON.stringify(
          params.value.nom ||
            params.value.lib ||
            params.value.val ||
            params.value._id,
        );
      } else {
        value = encodeURI(JSON.stringify(params.value));
        // debugCommon('copyToClipboard => value: ', value)
      }
    } else if (
      params &&
      params.value &&
      params.value.constructor === Array &&
      params.value.length > 0
    ) {
      value = params.value
        .map((element) => element.lib || element.code || element.nom || null)
        .join(', ');
    } else {
      value = params.value;
    }

    return value;
  }

  pasteFromClipboard(params) {
    debugCommon('pasteFromClipboard => params: ', params);
    // if we are processing a Reference data, decode it
    if (params.column.colDef.refData) {
      const value = JSON.parse(decodeURI(params.value));
      return value;
    } else {
      return params.value;
    }
  }

  render() {
    const rowClassRules = {
      // apply green to 2008
      'ghost-row': function (params) {
        return params?.data?.status?.lib?.toLowerCase().trim() === 'ghost';
      },
      'pending-movement-row': function (params) {
        return (
          params?.data?.status?.lib?.toLowerCase().trim() ===
            'pending movement' &&
          params?.data?.nearest_transfer_season === currentSeasonYear
        );
      },
      'pending-unit-row': function (params) {
        return (
          params?.data?.status?.lib?.toLowerCase().trim() ===
          'pending confirmation'
        );
      },
      'uncached-row': (params) => {
        return params?.data?.uncached;
      },
      'updated-row': (params) => {
        return (
          this.unitAwaitingRegistration?.some(
            (id) => id === params?.data?._id,
          ) ||
          this.transferAwaitingRegistration?.some(
            (id) => id === params?.data?._id,
          ) ||
          this.pitchAwaitingRegistration?.some((id) => id === params?.data?._id)
        );
      },
      'removed-row': (params) => {
        return (
          this.unitAwaitingRemoving?.some((id) => id === params?.data?._id) ||
          this.transferAwaitingRemoving?.some(
            (id) => id === params?.data?._id,
          ) ||
          this.pitchAwaitingRemoving?.some((id) => id === params?.data?._id)
        );
      },
    };

    return (
      <div id="myGrid" className="ag-theme-alpine">
        <AgGridReact
          animateRows={false}
          debounceVerticalScrollbar={true}
          displayName="agGrid"
          {...this.props}
          // no binding, just providing hard coded strings for the properties
          // boolean properties will default to true if provided (ie enableColResize => enableColResize="true")
          rowSelection="multiple"
          suppressRowClickSelection={true}
          rowClassRules={rowClassRules}
          // suppressExcelExport={true}
          // suppressCsvExport={true}
          // -----
          // TODO: Find how to save this properties on right time (here commented beceause is slowdawn the client when move columns)
          // Save column state on some events
          onColumnVisible={this.props.onColumnStateChanged}
          onColumnPinned={this.props.onColumnStateChanged}
          onColumnMoved={this.props.onColumnStateChanged}
          onColumnRowGroupChanged={this.props.onColumnStateChanged}
          onColumnResized={(e) =>
            e.finished && this.props.onColumnStateChanged(e)
          }
          onSortChanged={this.props.onColumnStateChanged}
          onFilterChanged={this.props.onFilterChanged}
          // ? Move this logic outside ?
          // onFilterChanged={e => {
          //   getStore().dispatch(updateFilters(this.props.gridName, e.api.getFilterModel()))
          //   getStore().dispatch(updateFilters(this.props.gridName, e.api.gridCore.gridApi.getFilterModel()))
          // }}

          // -----
          // selection
          enableRangeSelection
          multiSortKey="ctrl"
          // ---------------------

          // setting grid wide date component
          dateComponentFramework={DateComponent}
          // setting default column properties

          // TODO: Find how to customise the rowHeght without crop displayed data
          // rowHeight={20}

          defaultColDef={{
            headerComponentFramework: SortableHeaderComponent,
            resizable: true,
            sortable: true,
            floatingFilter: true,
            suppressMenu: true,
          }}
          pagination={true}
          paginationPageSize={this.props.userListSettings.nbLines}
          autoGroupColumnDef={{
            suppressSorting: true,
          }}
          // enable delta updates
          immutableData={true}
          // provide context menu callback
          // getContextMenuItems={this.getContextMenuItems}
          // return id required for delta updates
          getRowNodeId={(data) => (data ? data._id : null)}
          onCellValueChanged={this.handleCellValueChanged}
          onGridReady={this.onGridReady}
          processCellForClipboard={this.copyToClipboard}
          processCellFromClipboard={this.pasteFromClipboard}
          suppressCopyRowsToClipboard
          // TODO: See on top of this file to better implement this options
          sideBar={sideBar}
          // headerHeight
          headerHeight={35}
          floatingFiltersHeight={35}
          domLayout={'autoHeight'}
          frameworkComponents={{
            listCellEditor: ListCellEditor,
            listCellRenderer: ListCellRenderer,
            multiSelectFilter: MultiSelectFilter,
            tagCellRenderer: TagCellRenderer,
            flagCellRenderer: FlagCellRenderer,
            unitTypeCellRenderer: UnitTypeCellRenderer,
            percentCellRenderer: PercentCellRenderer,
            // multiSelectFloatingFilter: MultiSelectFloatingFilter,
            buttonCellRenderer: ButtonCellRenderer,
            transferTypesCellRenderer: TransferTypesCellRenderer,
            linkCellRenderer: LinkCellRenderer,
          }}
          enableBrowserTooltips={true}
          overlayLoadingTemplate={`<span class="ag-overlay-center">Fetching data ⌛</span>`}
          overlayNoRowsTemplate={`<span class="ag-overlay-center">Maybe, there is no data to display 🤷‍♂️</span>`}
        />
      </div>
    );
  }
}
