import { getAPIToken } from '../../security/User';
import fetch from 'node-fetch';
import qs from 'qs';
import { debugStore, debugSite } from '../../lib/debug';
import { fixRefValues } from '../../lib/utils';

// ------------------------------------------------------------------------
// SITE LIST
// ------------------------------------------------------------------------
export const REQUEST_SITE_LIST = 'REQUEST_SITE_LIST';
export const RECEIVE_SITE_LIST = 'RECEIVE_SITE_LIST';

//
// Before calling API.
//
export function requestSiteList() {
  debugStore('ACTION-SITE: requestSiteList...');
  return {
    type: REQUEST_SITE_LIST,
  };
}

//
// After receiving data from API.
//
export function receiveSiteList({ data, metas }) {
  debugStore('ACTION-SITE: receiveSiteList...');
  return {
    type: RECEIVE_SITE_LIST,
    data,
    metas,
    receivedAt: Date.now(),
  };
}

//
// Fetch the sites datas from API.
//
export function fetchSiteList({ listSettings }) {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    // First dispatch: the app state is updated to inform that the API call is starting.
    debugSite(
      'fetchSiteList: BEFORE REQUEST RESULT DISPATCH !!!! listSettings=',
      listSettings,
    );
    dispatch(requestSiteList());

    // let params = {
    //   current_page: listSettings && listSettings.page ? listSettings.page + 1 : undefined,
    //   page_size: listSettings && listSettings.nbLines ? listSettings.nbLines : 400,
    //   sort: listSettings && listSettings.sort ? JSON.stringify(listSettings.sort) : undefined,
    // }

    let params = {
      current_page: 1,
      page_size: 9999,
      sort:
        listSettings && listSettings.sort
          ? JSON.stringify(listSettings.sort)
          : undefined,
    };

    // TODO: Find how to get Updated and REAL value of nbLines before request the server /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\ /!\

    // console.log('JYO: action-site: listSettings: ', listSettings);
    // console.log('JYO: action-site: listSettings.nbLines: ', listSettings.nbLines);
    // console.log('JYO: action-site: listSettings && listSettings.nbLines ? listSettings.nbLines : 400: ', listSettings && listSettings.nbLines ? listSettings.nbLines : 400);
    // console.log('JYO: action-site: params: ', params);

    // clean params (remove unecessary ones)
    // params = _.pickBy(params)
    // prepare URL
    let paramsUrl = qs.stringify(params, { arrayFormat: 'brackets' });

    // filters, can't be done before
    if (listSettings && listSettings.filters) {
      const filters = qs.stringify(listSettings.filters, {
        arrayFormat: 'brackets',
      });
      paramsUrl += `&${filters}`;
    }

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.
    const url = `${process.env.REACT_APP_API_URL}/api/sites?${paramsUrl}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugSite('fetchSiteList: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `fetchSiteList: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (e) {
          console.error(
            `fetchSiteList: JSON parse error from ${url} with response=${text}`,
            { error: e },
          );
          // return {}
          throw Error(`Error while searching, error=${e}`);
        }
      })
      .then((json) => {
        // Here, we update the app state with the results of the API call.
        // dispatch(resetError())
        // dispatch(updateFormValues(json.storeData.formValues))
        debugSite('dispatch(receiveSiteList: ', json);
        dispatch(receiveSiteList({ data: json[1], metas: json[0] }));
        return json;
      })
      .catch((e) => {
        console.error('fetchSiteList:', e);
      });
  };
}

// ------------------------------------------------------------------------
// SITE EDITING
// ------------------------------------------------------------------------
export const RECEIVE_SITE = 'RECEIVE_SITE';

//
// After receiving data from API.
//
export function receiveSite(data) {
  return {
    type: RECEIVE_SITE,
    data,
  };
}

//
// Fetch the mobilhome from API.
//
export function fetchSite(id) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugSite(`fetchSite: EDIT MH id=${id}`);

    const url = `${process.env.REACT_APP_API_URL}/api/sites/${id}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugSite('fetchSite: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `fetchSite: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );

          const err = new Error(response.statusText);
          err.code = response.status;
          throw err;
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `fetchSite: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(
            `Error while getting the MH data for id=${id}, err=${err}`,
          );
        }
      })
      .then((json) => {
        // Here, we update the app state with the results of the API call.
        // dispatch(resetError())
        // dispatch(updateFormValues(json.storeData.formValues))
        dispatch(receiveSite(json));

        return json;
      });
  };
}

//
// After receiving data from API.
//
export function updateSite(myValues) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugSite('updateSite: myValues=', myValues);

    delete myValues.__v;

    fixRefValues(myValues);

    const isEdit = myValues._id && myValues._id.length > 0;
    let url = isEdit ? `sites/${myValues._id}` : 'sites';
    url = `${process.env.REACT_APP_API_URL}/api/${url}`;

    const options = {
      method: isEdit ? 'put' : 'post',
      body: JSON.stringify(myValues),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugSite('updateSite: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `updateSite: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `updateSite: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(`Error while saving MH data, err=${err}`);
        }
      })
      .then((json) => {
        dispatch(receiveSite(json));
        return json;
      });
  };
}

// ------------------------------------------------------------------------
// SITE EXPORT
// ------------------------------------------------------------------------
export const REQUEST_SITE_EXPORT = 'REQUEST_SITE_EXPORT';
export const RECEIVE_SITE_EXPORT = 'RECEIVE_SITE_EXPORT';

//
// Before calling API.
//
export function requestMhExport() {
  return {
    type: REQUEST_SITE_EXPORT,
  };
}

// ------------------------------------------------------------------------
// Clear Redux state
// ------------------------------------------------------------------------
export const CLEAR_REDUX_STATE = 'CLEAR_REDUX_STATE';
export function clearReduxState() {
  return {
    type: CLEAR_REDUX_STATE,
  };
}
