import style from './style.module.scss';

export default ({ label, icon: Icon, action, disabled, color }) => {
  return (
    <button
      type="button"
      className={style['form-button']}
      onClick={action}
      disabled={disabled}
    >
      <div
        className={style['background-button']}
        style={color && { backgroundColor: color }}
      />
      {Icon && (
        <div className={style['icon-wrapper']}>
          <Icon />
        </div>
      )}
      {label && <span>{label}</span>}
    </button>
  );
};
