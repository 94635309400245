import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex, filter } from 'lodash';
import { FormGroup, Checkbox, FormControlLabel } from '@mui/material';

import style from './style.module.scss';

// const styles = (theme) => ({
//   formControl: {
//     width: '100%',
//   },
//   formGroup: {
//     flexDirection: 'row',
//   },
//   checkbox: ,
// });

class Checkboxes extends Component {
  static propTypes = {
    refDatas: PropTypes.object,
    input: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.string,
    meta: PropTypes.object,
    classes: PropTypes.object,
    emptyValue: PropTypes.bool,
    refKey: PropTypes.string,
  };

  render() {
    const {
      input: { name, value, onChange },
      id,
      label,
      meta: { touched, error },
      classes,
      refDatas,
      refKey,
      disabled,
    } = this.props;

    // console.log('Checkboxes $$$$$$$$$$$$$$$$$$$$$$ : ', name, this.props)

    const options = refDatas[refKey];

    if (!Array.isArray(options)) return <div>Ref data not loaded!</div>;

    // Blindage  pour virer les valeurs "{}"
    const realValue = filter(value, (v) => !!v._id);

    const checkboxes = [];
    options.forEach((o, index) => {
      // Index of the checked chkbox in values.
      const selIdx = findIndex(realValue, { _id: o.val });
      const handleChange = (event) => {
        const arr = [...realValue];
        if (event.target.checked) {
          arr.push({ _id: o.val, lib: o.label });
        } else {
          arr.splice(selIdx, 1);
        }
        console.log(arr);
        return onChange(arr);
      };

      // console.log(realValue
      const checked = selIdx !== -1;

      checkboxes.push(
        <FormControlLabel
          key={`${id}-${o.val}`}
          style={{
            cursor: disabled ? 'not-allowed' : 'default',
            opacity: disabled ? 0.5 : 1,
          }}
          control={
            <Checkbox
              name={`${name}[${index}]`}
              checked={checked}
              onChange={handleChange}
              value={o.val}
              disabled={disabled}
              sx={{
                padding: 0,
                fontSize: '14px',
                marginRight: '10px',
              }}
            />
          }
          label={o.label}
        />,
      );
    });

    // console.log(refKey, 'realValue=', realValue, 'value=', value, '###########################################', options)

    return (
      <div className={style['form-checkbox']}>
        <small>{label}</small>
        <FormGroup
          sx={{
            flexDirection: 'row',
            gap: '10px',
            marginTop: '5px',
          }}
        >
          {checkboxes}
        </FormGroup>
        {touched && error && <span>{error}</span>}
      </div>
    );
  }
}

export default Checkboxes;
