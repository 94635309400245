import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { submitSaveMh } from '../MhSubmit';
import MhMvListTimeline from '@pages/mh/MhMvListTimeline';
import MhMvListF2M from '@pages/mh/MhMvListF2M';
import classnames from 'classnames';
import ReactGA from 'react-ga4';
import { isAllowed } from '@security/User';

// Components
import { AllowedBlock } from '@components/common/AllowedBlock';
import Save from '@components/icons/Save';
import Undo from '@components/icons/Undo';
import RenderField from '@components/form/RenderField';
import SuperSelect from '@components/form/SuperSelect';
import OpenFileField from '@components/form/OpenFileField';
import AllowedField from '@components/form/AllowedField';
import dayjs from 'dayjs';
import SaveV2 from '@components/icons/SaveV2';
import Trash from '@components/icons/Trash';
import MhIssuesReporting from '@pages/mh/MhIssuesReporting';
import BaseNavigation from '@components/navigations/BaseNavigation';
import FormButton from '@components/buttons/FormButton';
import ArticleHeader from '@components/headers/ArticleHeader';
import { getUserSetting } from '@security/User';

// Store
import { removeMh, fetchMh } from '@store/actions/action-mh';
import { debugMh } from '@lib/debug';

import style from './style.module.scss';

import locationJSON from '@assets/lottie/location';
import brandJSON from '@assets/lottie/brand';
import accountingJSON from '@assets/lottie/accounting';
import technicalJSON from '@assets/lottie/technical';
import commentsJSON from '@assets/lottie/comments';
import f2mJSON from '@assets/lottie/f2m';
import generalInfoJSON from '@assets/lottie/general_info';
import issueReportingJSON from '@assets/lottie/issue_reporting';
import unitAmendmentsJSON from '@assets/lottie/unit_amendments';
import residentSalesJSON from '@assets/lottie/resident_sales.json';

export var commonFormPropTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func,
  classes: PropTypes.object,
  pristine: PropTypes.bool,
  dirty: PropTypes.bool,
  submitting: PropTypes.bool,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  error: PropTypes.any,
  initialValues: PropTypes.object,
  dispatch: PropTypes.func,
  refDatas: PropTypes.object,
  isEdit: PropTypes.bool,
  dispTextButton: PropTypes.string,
};

class MhForm extends React.Component {
  static propTypes = {
    ...commonFormPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      addedData: {},
      deletedData: {},
      refreshedData: {},
      currentIndex: 0,
      triggeredAction: false,
      btl: false,
      issues_reporting: this.props.initialValues?.issues_reporting,
    };

    this.backToList = this.backToList.bind(this);
    this.goToUnit = this.goToUnit.bind(this);
    this.saveAndBack = this.saveAndBack.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.save = this.save.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.updateIssueReporting = this.updateIssueReporting.bind(this);

    // ! TABS
    const { refDatas, history, t, isEdit } = this.props;

    this.tabs = [
      {
        label: t('form.unit.tab.characteristics', 'Characteristics'),
        href: '#main',
        sections: [
          {
            title: t('form.unit.panel.location', 'Location info'),
            file: locationJSON,
            fields: [
              {
                name: 'mh.site',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.campsite.title', 'Campsite'),
                refKey: 'Site',
              },
              {
                name: 'mh.site.loca.region',
                component: RenderField,
                label: t('form.unit.label.region', 'Region'),
                type: 'text',
              },
              {
                name: 'mh.site.loca.country',
                component: RenderField,
                label: t('form.unit.label.country', 'Country'),
                type: 'text',
              },
              {
                name: 'mh.site.is_propre',
                component: RenderField,
                label: t('form.unit.label.is_own', 'Own campsite'),
                type: 'checkbox',
              },
              {
                name: 'mh.pitch',
                component: RenderField,
                label: t('form.unit.label.pitch', 'Pitch'),
                type: 'text',
              },
              {
                name: 'mh.pitch_corres',
                component: RenderField,
                label: t('form.unit.label.pitch_corres', 'Pitch corresponding'),
                type: 'text',
              },
              {
                name: 'mh.barcode',
                component: RenderField,
                label: t('form.unit.label.barcode', 'Barcode'),
                type: 'text',
              },
              {
                name: 'mh.former_barcode',
                component: RenderField,
                label: t('form.unit.label.former_barcode', 'Former barcode'),
                type: 'text',
              },
              {
                name: 'mh.old_id_homair',
                component: RenderField,
                label: t('form.unit.label.old_id_homair', 'Old ID Homair'),
                type: 'text',
              },
              {
                name: 'mh.id_chassis',
                component: RenderField,
                label: t('form.unit.label.chassis_id', 'Chassis ID'),
                type: 'text',
              },
            ],
          },
          {
            title: t('form.unit.panel.commercial', 'Commercial info'),
            file: brandJSON,
            fields: [
              {
                name: 'mh.mh_model',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.unit_model.title', 'Unit model'),
                refKey: 'MhModel',
              },
              {
                name: 'mh.mh_model.file',
                component: OpenFileField,
                type: 'text',
                label: t('from.unit.label.file', 'File'),
              },
              {
                name: 'mh.use_type',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.use_type', 'Use type'),
                refKey: 'RefTypeUtilisation',
              },
              {
                name: 'mh.commercial_brand',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.unit.label.commercial_brand',
                  'Commercial brand',
                ),
                refKey: 'RefMarqueCommerciale',
              },
              {
                name: 'mh.commercial_type',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.commercial_type', 'Commercial type'),
                refKey: 'RefTypeCommercial',
              },
              {
                name: 'mh.commercial_type_actual',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.unit.label.commercial_type_actual',
                  'Commercial type actual',
                ),
                refKey: 'RefTypeCommercial',
              },
              {
                name: 'mh.commercial_type.is_clim',
                component: RenderField,
                type: 'checkbox',
                label: t('form.unit.label.ac', 'Air conditioning'),
              },
              {
                name: 'mh.commercial_type.is_terrasse',
                component: RenderField,
                type: 'checkbox',
                label: t('form.unit.label.deck', 'Deck'),
              },
              {
                name: 'mh.commercial_type.dishwasher',
                component: RenderField,
                type: 'checkbox',
                label: t('form.unit.label.dishwasher', 'Dishwasher'),
              },
            ],
          },
          {
            title: t('form.unit.panel.technical', 'Technical info'),
            file: technicalJSON,
            fields: [
              {
                name: 'mh._id',
                component: RenderField,
                type: 'text',
                label: t('form.unit.label.unit_id', 'Unit ID'),
              },
              {
                name: 'mh.mh_model.width',
                component: RenderField,
                type: 'text',
                label: t('form.unit.label.width', 'Unit width (m)'),
              },
              {
                name: 'mh.mh_model.length',
                component: RenderField,
                type: 'text',
                label: t('form.unit.label.length', 'Unit length (m)'),
              },
              {
                name: 'mh.deck_type',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.deck_type', 'Deck type'),
                refKey: 'RefTypeTerrasse',
              },
              {
                name: 'mh.deck_size',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.deck_size', 'Deck size'),
                refKey: 'RefTailleTerrasse',
              },
              {
                name: 'mh.deck_year',
                component: RenderField,
                type: 'number',
                label: t('form.unit.label.deck_year', 'Deck year'),
              },
              {
                name: 'mh.deck_manufacturer',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.unit.label.deck_manufacturer',
                  'Deck manufacturer',
                ),
                refKey: 'RefDeckManufacturer',
              },
              {
                name: 'mh.skirting_material',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.unit.label.skirting_material',
                  'Skirting material',
                ),
                refKey: 'RefSkirtingMaterial',
              },
              {
                name: 'mh.skirting_sides',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.skirting_sides', 'Skirting sides'),
                refKey: 'RefSkirtingSides',
              },
              {
                name: 'mh.gas_supply_type',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.unit.label.energy_supply_type',
                  'Energy supply type',
                ),
                refKey: 'RefGasSupplyType',
              },
              {
                name: 'mh.heater_type',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.heater_type', 'Heater type'),
                refKey: 'RefHeaterType',
              },
              {
                name: 'mh.cladding_color',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.cladding_color', 'Cladding color'),
                refKey: 'RefCladdingColor',
              },
              {
                name: 'mh.cladding_material',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.unit.label.cladding_material',
                  'Cladding material',
                ),
                refKey: 'RefCladdingMaterial',
              },
              {
                name: 'mh.mh_model.roof_type.lib',
                component: RenderField,
                refDatas: refDatas,
                label: t('form.unit.label.roof_type', 'Roof type'),
                refKey: 'RefTypeToiture',
              },
              {
                name: 'mh.perimeter',
                component: SuperSelect,
                label: t('form.unit.label.perimeter', 'Maintenance perimeter'),
                nativeValues: [
                  { val: 'Brand Managed', label: 'Brand Managed' },
                  { val: 'Marvilla', label: 'Marvilla' },
                  { val: 'ECG Support', label: 'ECG Support' },
                  { val: 'Site Managed', label: 'Site Managed' },
                  { val: 'ERD', label: 'ERD' },
                  { val: 'Cisca', label: 'Cisca' },
                  { val: 'Out', label: 'Out' },
                ],
              },
              {
                name: 'mh.key_tag',
                component: RenderField,
                type: 'text',
                label: t('form.unit.label.ec_key', 'Key tag'),
              },
              {
                name: 'mh.air_conditioning_year',
                component: RenderField,
                type: 'number',
                label: t(
                  'form.unit.label.air_conditioning_year',
                  'Air conditioning year',
                ),
              },
            ],
          },
          {
            title: t('form.unit.panel.financial', 'Accounting'),
            file: accountingJSON,
            fields: [
              {
                name: 'mh.owner',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.ybut.label.owner', 'Owner'),
                refKey: 'RefOwner',
              },
              {
                name: 'mh.renov_year',
                component: SuperSelect,
                type: 'number',
                label: t('form.unit.label.renov_year', 'Renovation year'),
                nativeValues: (() => {
                  let vintage = this.props?.initialValues?.mh_model?.vintage;
                  if (!vintage) vintage = 1980;
                  const currentYear = new Date().getFullYear();
                  let refurb_years = Array.from(
                    { length: currentYear - vintage + 1 },
                    (_, i) => vintage + i,
                  );
                  return refurb_years.map((year) => {
                    return { val: year, label: year.toString() };
                  });
                })(),
              },
              {
                name: 'mh.renov_status',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.unit.label.renovation_status',
                  'Renovation status',
                ),
                refKey: 'RefStatutRenov',
              },
              {
                name: 'mh.fixed_asset_code',
                component: RenderField,
                type: 'text',
                label: t(
                  'form.unit.label.fixed_asset_code',
                  'Fixed asset code',
                ),
              },
              {
                name: 'mh.purchase_leasing_code',
                component: RenderField,
                type: 'text',
                label: t(
                  'form.unit.label.purchase_leasing_code',
                  'Purchase leasing code',
                ),
              },
              {
                name: 'mh.reno_leasing_code',
                component: RenderField,
                type: 'text',
                label: t(
                  'form.unit.label.renovation_leasing_code',
                  'Renovation leasing code',
                ),
              },
            ],
          },
        ],
      },
      {
        label: t('form.mh.tab.comments', 'Comments'),
        href: '#comments',
        sections: [
          {
            title: 'Tell us more about the update',
            file: commentsJSON,
            fields: [
              {
                name: 'mh.comment',
                component: RenderField,
                multiline: true,
                rows: 4,
                label: t('form.unit.label.comment', 'Comment'),
              },
              {
                name: 'mh.five_year_review',
                component: RenderField,
                multiline: true,
                rows: 4,
                label: t(
                  'form.unit.label.five_year_review',
                  'Five-year review',
                ),
              },
              {
                name: 'mh.pitch_history',
                component: RenderField,
                multiline: true,
                rows: 4,
                label: t('form.unit.label.pitch_history', 'Pitch history'),
              },
            ],
          },
        ],
      },

      isEdit && {
        label: t('form.unit.tab.transfers', 'Amendments'),
        href: '#mvlist-timeline',
        sections: [
          {
            title: 'History timeline',
            file: unitAmendmentsJSON,
            component: (
              <MhMvListTimeline
                mh={this.props.initialValues}
                history={history}
              />
            ),
          },
        ],
      },
      isEdit && {
        label: t('form.unit.tab.sales', 'Sales'),
        href: '#mvlist-timeline',
        sections: [
          {
            title: t('form.unit.panel.resident_sales', 'Resident sales'),
            file: residentSalesJSON,
            fields: [
              {
                name: 'mh.sales.status',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.sales.status', 'Sale status'),
                refKey: 'RefSalesStatus',
              },
              // {
              //   name: 'mh.sales.price',
              //   component: RenderField,
              //   type: 'text',
              //   label: t('form.unit.label.sales.price', 'Price'),
              // },
              {
                name: 'mh.sales.tax',
                component: RenderField,
                type: 'checkbox',
                label: t('form.unit.label.sales.tax', 'Tax applied'),
                nativeValues: [
                  { val: true, label: 'Yes' },
                  { val: false, label: 'No' },
                ],
              },
              {
                name: 'mh.sales.advisor_data',
                component: RenderField,
                multiline: true,
                rows: 4,
                label: t('form.unit.label.sales.advisor_data', 'Advisor data'),
              },
              {
                name: 'mh.sales.category',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.sales.category', 'Sale category'),
                refKey: 'RefSalesCategory',
              },
              {
                name: 'mh.sales.history',
                component: RenderField,
                multiline: true,
                rows: 4,
                label: t('form.unit.label.sales.history', 'History'),
              },
            ],
          },
        ],
      },
      isEdit && {
        label: t('form.unit.tab.f2m', 'F2M data'),
        href: '#f2m',
        sections: [
          {
            title: 'Enhancements and technical checks',
            file: f2mJSON,
            component: (
              <MhMvListF2M mh={this.props.initialValues} history={history} />
            ),
          },
        ],
      },
      isEdit && {
        label: t('form.unit.tab.issues_reporting', 'Issues Reporting'),
        href: '#issues_reporting',
        sections: [
          {
            title: 'Tickets',
            file: issueReportingJSON,
            component:
              this.state.issues_reporting.length > 0 ? (
                <MhIssuesReporting
                  unitID={this.props.initialValues?._id}
                  updateIssueReporting={this.updateIssueReporting}
                  reports={this.state.issues_reporting || []}
                  history={history}
                />
              ) : (
                <p className={style['no-report']}>
                  {t('common.label.nothing_to_report', 'Nothing to report.')}
                </p>
              ),
          },
        ],
      },
      {
        label: t('form.unit.tab.infos', 'General info'),
        href: '#infos',
        sections: [
          {
            title: 'Status',
            file: generalInfoJSON,
            fields: [
              {
                name: 'mh.status',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.unit.label.statut', 'Status'),
                refKey: 'RefStatut',
              },
              {
                name: 'mh.wintering_status',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.unit.label.wintering_status',
                  'Wintering status',
                ),
                refKey: 'RefStatutHivernage',
              },
            ],
          },
        ],
      },
    ];

    // Some tabs are available only on Edit version
    this.tabs = this.tabs.filter((tab) => tab);
  }

  changeTab(index) {
    const { t, isEdit } = this.props;
    const tab_names = isEdit
      ? [
          t('form.unit.tab.characteristics', 'Characteristics'),
          t('form.unit.tab.comment', 'Comment'),
          t('form.unit.tab.transfers', 'Amendments'),
          t('form.unit.tab.sales', 'Sales'),
          t('form.unit.tab.issues_reporting', 'Issues Reporting'),
          t('form.unit.tab.info', 'General info'),
        ]
      : [
          t('form.unit.tab.characteristics', 'Characteristics'),
          t('form.unit.tab.comment', 'Comment'),
          t('form.unit.tab.info', 'General info'),
        ];

    ReactGA.event('unit_details_navigation', {
      tab_name: tab_names[index],
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });

    this.setState({ currentIndex: index });
  }

  save(data) {
    const { isEdit } = this.props;
    ReactGA.event('unit_details_form_buttons', {
      user_interaction: 'Saving data',
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });
    return submitSaveMh(data, !isEdit && this.goToUnit, this.props.t);
  }

  saveAndBack(data) {
    // ! GA
    ReactGA.event('unit_details_form_buttons', {
      user_interaction:
        'Saving data and automatically going back to units page',
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });

    // ! Saving
    return submitSaveMh(data, this.backToList, this.props.t);
  }

  goToUnit(_id) {
    this.props.history.push(`/mh/${_id}`);
  }

  backToList() {
    ReactGA.event('unit_details_form_buttons', {
      user_interaction: 'Going back to units page without saving data',
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });
    this.props.history.push('/mh');
  }

  updateIssueReporting(index) {
    const update = this.state.issues_reporting.map((issue) => {
      if (issue.index === index) {
        issue.fixed = true;
      }
      return issue;
    });

    this.setState({
      issues_reporting: update,
    });
  }

  handleRemove(e) {
    e.preventDefault();
    this.setState({ triggeredAction: true });

    const ID = this.props.initialValues._id;
    if (
      window.confirm(
        this.props.t(
          'form.mh.confirm.removing',
          'Are you sure to remove this pitch fee?',
        ),
      )
    ) {
      ReactGA.event('unit_details_form_buttons', {
        user_interaction: 'Delete a unit',
        user_id: getUserSetting('_id').toString(),
        dateTime: dayjs().format(),
      });

      this.props.dispatch(removeMh(ID, this.backToList));
    } else {
      this.setState({ triggeredAction: false });
    }
  }

  render() {
    const { submitting, reset, handleSubmit, t, isEdit } = this.props;
    const { currentIndex, triggeredAction } = this.state;

    debugMh('Rendering MhForm... props=', this.props);
    const rolesAllowed = [
      'ROLE_WRI_MH_EQUI',
      'ROLE_WRI_MH_HIVERN',
      'ROLE_WRI_MH_EMPL',
      'ROLE_WRI_MH_INFO',
      'ROLE_WRI_MH_MVT',
      'ROLE_WRI_MH_USE_TYPE',
      'ROLE_WRI_MH_COMMERCIAL_TYPE',
      'ROLE_ACCOUNTING_MGNT',
    ];

    return (
      <section className={style['form-section']}>
        <div className={style['form-section-base-navigation']}>
          <BaseNavigation
            items={this.tabs.map((tab) => tab.label)}
            currentIndex={currentIndex}
            updateIndex={this.changeTab}
          />
        </div>
        <div
          className={classnames(
            style['form'],
            isAllowed([...rolesAllowed, 'ROLE_DEL_MH'], true)
              ? style['edition']
              : '',
          )}
        >
          <form onSubmit={handleSubmit(this.save)}>
            {/* {isEdit && (
              <Prompt
                when={this.props.dirty}
                message={t('common.label.warning_leave_form_no_save')}
              />
            )} */}
            <div className={style['content-container']}>
              {this.tabs.map((tab, _i) => (
                <div
                  key={_i}
                  className={classnames(
                    currentIndex !== _i ? style['hide'] : style['display'],
                    tab.sections.length === 1
                      ? style['grid-one-item']
                      : undefined,
                  )}
                >
                  {tab.sections.map((section, index) => (
                    <article
                      id={section.title}
                      key={index}
                      className={style['form-input-block']}
                    >
                      <ArticleHeader
                        label={section.title}
                        lottieJSON={section.file}
                      />

                      <div className={style['article-content']}>
                        {section.fields
                          ? section.fields.map((field, i) => {
                              return <AllowedField key={i} {...field} />;
                            })
                          : section?.component && section.component}
                      </div>
                    </article>
                  ))}
                </div>
              ))}
            </div>
            <div className="content-footer actions" />
          </form>

          {isAllowed([...rolesAllowed, 'ROLE_DEL_MH'], true) && (
            <div className={style['form-actions']}>
              <div className={style['form-actions-wrapper']}>
                <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                  <FormButton
                    action={handleSubmit(this.save)}
                    label={t('common.action.save', 'Save')}
                    icon={Save}
                    disabled={triggeredAction}
                    color={'var(--color-surface-octonary)'}
                  />
                </AllowedBlock>
                <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                  <FormButton
                    action={handleSubmit(this.saveAndBack)}
                    label={t('common.action.save_and_back', 'Save and back')}
                    icon={SaveV2}
                    disabled={triggeredAction}
                    color={'var(--color-surface-octonary)'}
                  />
                </AllowedBlock>
                <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                  <FormButton
                    action={reset}
                    label={t('common.action.undo_changes', 'Undo changes')}
                    icon={Undo}
                    disabled={submitting}
                    color={'var(--color-surface-septenary)'}
                  />
                </AllowedBlock>
                {isEdit && (
                  <AllowedBlock roles={'ROLE_DEL_MH'} oneOfThem={true}>
                    <FormButton
                      action={this.handleRemove}
                      label={t('common.action.remove', 'Remove')}
                      icon={Trash}
                      disabled={triggeredAction}
                      color={'var(--color-surface-senary)'}
                    />
                  </AllowedBlock>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

// function onChange(values, dispatch, props, previousValues) {
//   console.log(values, props, previousValues)
// }

MhForm = reduxForm({
  // a unique name for the form
  form: 'mh_form',
  // onChange: onChange,
  // https://redux-form.com/7.1.2/examples/initializefromstate/
  enableReinitialize: true,
})(MhForm);

MhForm = withTranslation()(MhForm);

export default MhForm;
