import { sendFlashMessage } from '../../store/actions/action-common';
import getStore from '../../store/store';
import { logInfo, logError } from '@helpers/logs';

import {
  updateMh,
  transferMh,
  createPendingUnit,
} from '../../store/actions/action-mh';

//
// Submit function.
// Return a Promise: the submitting property will be set to true until the promise has been resolved or rejected.
// https://redux-form.com/6.5.0/docs/api/props.md/#-handlesubmit-eventorsubmit-function-
//
export function submitSaveMh(values, callback = null, t = null) {
  return getStore()
    .dispatch(updateMh(values, callback))
    .then((response) => {
      getStore().dispatch(
        t &&
          sendFlashMessage(
            t('common.flash_message.saving_in_progress', 'Saving in progress'),
            'info',
          ),
      );
      return response;
    })
    .catch((err) => {
      getStore().dispatch(sendFlashMessage(err, 'error'));
    });
}

export function submitSavePendingUnit(values) {
  return getStore()
    .dispatch(createPendingUnit(values))
    .then((json) => {
      logInfo(JSON.stringify(json));
    })
    .catch((err) => {
      logError(JSON.stringify(err));
      throw err;
    });
}

export function submitTransferMh(values, t = null) {
  return getStore()
    .dispatch(transferMh(values))
    .then((json) => {
      getStore().dispatch(
        sendFlashMessage(
          json?.counts
            ? t(
                'common.flash_message.transfer_created',
                'Unit(s) transfer(s) created !',
              )
            : json.message,
          json?.counts ? 'success' : 'error',
        ),
      );
      return json;
    })
    .catch((err) => {
      getStore().dispatch(
        sendFlashMessage(
          t('common.flash_message.oops', 'Oups, errors!'),
          'error',
        ),
      );
    });
}
