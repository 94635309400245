import React from 'react';

// Icons
import Room from '@components/icons/Room';
import Chalet from '@components/icons/Chalet';
import Unit from '@components/icons/Unit';
import Tent from '@components/icons/Tent';

import style from './style.module.scss';

function icon(value) {
  switch (value) {
    case 'Chalet':
      return <Chalet color={'var(--color-quaternary)'} />;
    case 'Room':
      return <Room color={'var(--color-surface-tertiary)'} />;
    case 'MH':
      return <Unit color={'var(--color-primary)'} />;
    case 'Tent':
      return <Tent color={'var(--color-quinary)'} />;
    default:
      break;
  }
}

export default (params) => {
  return (
    <div className={style['unit-type-renderer-wrapper']}>
      <div className={style['unit-type-cell-renderer']}>
        {params.value && (
          <div className={style['unit-type-icon-wrapper']}>
            {icon(params.value)}
          </div>
        )}
        <span>{params.value === 'MH' ? 'Mobile Home' : params.value}</span>
      </div>
    </div>
  );
};
