import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import styled from '@emotion/styled';

const LinearProgressContainer = styled.div`
  flex-grow: 1;
  margin-top: 2px;
  height: 3px;
`;

const Placeholder = styled.div`
  height: 3px;
`;

function LinearLoader(props) {
  const { isFetching } = props;
  return (
    <LinearProgressContainer>
      {isFetching ? <LinearProgress /> : <Placeholder />}
    </LinearProgressContainer>
  );
}
LinearLoader.propTypes = {
  isFetching: PropTypes.bool,
};
export const Linear = LinearLoader;
