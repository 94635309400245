const ExitFullScreen = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M6.2 14.3v-2.5H3.7v-1.2h3.7v3.7H6.2Zm4.4 0v-3.7h3.7v1.2h-2.5v2.4h-1.2Zm-6.8-7v-1h2.4V3.7h1.2v3.6H3.7Zm6.8 0V3.9h1.2v2.4h2.4v1.2h-3.6Z"
          fill={props.color ? props.color : '#333333'}
        />
      </g>
    </svg>
  );
};

export default ExitFullScreen;
