//
// To enable console debugging:
// Add a localStorage key named `debug` with this value (or customed): `arc/*,-arc/router`
// `localStorage.setItem('debug', 'arc/*,-arc/router')`
//
import debug from 'debug'

const debugCommon = debug('arc/common')
const debugLayout = debug('arc/layout')
const debugMh = debug('arc/mh')
const debugMv = debug('arc/mv')
const debugSite = debug('arc/site')
const debugStore = debug('arc/store')
const debugRouter = debug('arc/router')

export { debugCommon, debugMh, debugMv, debugSite, debugStore, debugRouter, debugLayout }
