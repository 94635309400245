export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 15.8124C5.90278 15.8124 5.60764 15.6909 5.36458 15.4478C5.12153 15.2048 5 14.9096 5 14.5624V2.89575C5 2.54853 5.12153 2.25339 5.36458 2.01034C5.60764 1.76728 5.90278 1.64575 6.25 1.64575H15.4167C15.7639 1.64575 16.059 1.76728 16.3021 2.01034C16.5451 2.25339 16.6667 2.54853 16.6667 2.89575V14.5624C16.6667 14.9096 16.5451 15.2048 16.3021 15.4478C16.059 15.6909 15.7639 15.8124 15.4167 15.8124H6.25ZM6.25 14.5624H15.4167V2.89575H6.25V14.5624ZM3.75 18.3124C3.40278 18.3124 3.10764 18.1909 2.86458 17.9478C2.62153 17.7048 2.5 17.4096 2.5 17.0624V5.12492C2.5 4.94436 2.55903 4.79506 2.67708 4.677C2.79514 4.55895 2.94444 4.49992 3.125 4.49992C3.30556 4.49992 3.45486 4.55895 3.57292 4.677C3.69097 4.79506 3.75 4.94436 3.75 5.12492V17.0624H13C13.1806 17.0624 13.3299 17.1214 13.4479 17.2395C13.566 17.3576 13.625 17.5069 13.625 17.6874C13.625 17.868 13.566 18.0173 13.4479 18.1353C13.3299 18.2534 13.1806 18.3124 13 18.3124H3.75ZM6.25 2.89575V14.5624V2.89575Z"
        fill={props.color ? props.color : '#194989'}
      />
    </svg>
  );
};
