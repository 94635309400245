export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66663 17.7084V14.9375C1.66663 14.7986 1.68746 14.6667 1.72913 14.5417C1.77079 14.4167 1.83329 14.2986 1.91663 14.1875L9.22913 4.2917L8.20829 2.9167C8.15274 2.84725 8.11802 2.77434 8.10413 2.69795C8.09024 2.62156 8.08676 2.54517 8.09371 2.46878C8.10065 2.39239 8.12496 2.31948 8.16663 2.25003C8.20829 2.18059 8.26385 2.11809 8.33329 2.06253C8.47218 1.95142 8.62496 1.90975 8.79163 1.93753C8.95829 1.96531 9.09718 2.04864 9.20829 2.18753L9.99996 3.25003L10.7916 2.18753C10.9027 2.04864 11.0416 1.96531 11.2083 1.93753C11.375 1.90975 11.5277 1.95142 11.6666 2.06253C11.8055 2.17364 11.8888 2.31253 11.9166 2.4792C11.9444 2.64587 11.9027 2.79864 11.7916 2.93753L10.7916 4.2917L18.0833 14.1875C18.1666 14.2986 18.2291 14.4167 18.2708 14.5417C18.3125 14.6667 18.3333 14.7986 18.3333 14.9375V17.7084C18.3333 17.8889 18.2743 18.0382 18.1562 18.1563C18.0382 18.2743 17.8888 18.3334 17.7083 18.3334H2.29163C2.11107 18.3334 1.96176 18.2743 1.84371 18.1563C1.72565 18.0382 1.66663 17.8889 1.66663 17.7084ZM9.99996 5.3542L2.91663 14.9375V17.0834H5.93746L9.49996 12.1042C9.62496 11.9236 9.79163 11.8334 9.99996 11.8334C10.2083 11.8334 10.375 11.9236 10.5 12.1042L14.0625 17.0834H17.0833V14.9375L9.99996 5.3542ZM7.47913 17.0834H12.5208L9.99996 13.5417L7.47913 17.0834ZM10.5208 12.1042L14.0625 17.0834L10.5 12.1042C10.375 11.9236 10.2083 11.8334 9.99996 11.8334C9.79163 11.8334 9.62496 11.9236 9.49996 12.1042L5.93746 17.0834L9.49996 12.1042C9.62496 11.9236 9.7951 11.8334 10.0104 11.8334C10.2257 11.8334 10.3958 11.9236 10.5208 12.1042V12.1042Z"
        fill={props.color ? props.color : '#333333'}
      />
    </svg>
  );
};
