// component/flash_message.js
// Inspired by https://alexzywiak.github.io/react-redux-a-flash-messenger-example/index.html

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies
import { sendFlashMessage } from '@store/actions/action-common';
import Alert from './Alert';
import isEqual from 'lodash/isEqual';

// Level :
// error
// warning
// success

class FlashMessage extends Component {
  static propTypes = {
    children: PropTypes.node,
    duration: PropTypes.number,
    persistOnHover: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  static defaultProps = {
    duration: 5000,
    children: null,
    persistOnHover: true,
  };

  constructor(props) {
    super(props);

    this.state = { isVisible: false };

    this.hide = this.hide.bind(this);
    this.resumeTimer = this.resumeTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  componentDidMount() {
    const { message } = this.props.flashMsg;
    if (message) {
      // Ici ne pas starter si error

      this.startTimer();
      this.setState({ isVisible: true });
    }
  }

  componentDidUpdate(prevProps) {
    // Only if props have changed...
    if (!isEqual(prevProps, this.props)) {
      const { message, level } = this.props.flashMsg;
      if (message) {
        if (level !== 'error') {
          this.startTimer();
        }
        this.setState({ isVisible: true });
      }
    }
  }

  hide() {
    this.setState({ isVisible: false });
    // Purge store.
    this.props.dispatch(sendFlashMessage());
  }

  startTimer() {
    this.remaining = this.props.duration;
    this.resumeTimer();
  }

  resumeTimer() {
    const { level } = this.props.flashMsg;

    if (level !== 'error') {
      window.clearTimeout(this.timer);

      this.start = new Date();
      this.timer = setTimeout(this.hide, this.remaining);
    }
  }

  pauseTimer() {
    if (this.props.persistOnHover) {
      clearTimeout(this.timer);

      this.remaining -= new Date() - this.start;
    }
  }

  render() {
    const { isVisible } = this.state;
    const { message, level } = this.props.flashMsg;

    if (!message) {
      return null;
    }

    return isVisible ? (
      <div onMouseEnter={this.pauseTimer} onMouseLeave={this.resumeTimer}>
        <Alert level={level} message={message} onClose={this.hide} />
      </div>
    ) : null;
  }
}

const mapStateToProps = ({ flashMsg }) => ({ flashMsg });

export default connect(mapStateToProps)(FlashMessage);
