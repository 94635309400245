import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import get from 'lodash/get'

// Cell renderer : Used for all purposes :
// - rendering referenceDatas on simple display (take a "raw" complex object - ie a RefData)
// - for cellEditor after selection (take a complex object - the one returned by the cellEditor)
// - for display group (take a simple text value, as it comes from keyCreator col def function...)
export default class ListCellRenderer extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]), // can be a string if no value setted...
  }

  constructor(props) {
    super(props)
    this.state = {}
    this.processLabel = this.processLabel.bind(this)
    this.processLabel(true)
    // if (typeof this.props.value !== 'object' && typeof this.props.value !== 'string') {
    //   console.log('ListCellRenderer => this.props.value =', this.props.value)
    // }
  }

  processLabel(fromConstructor) {
    let data
    const cellRefData = get(this.props, 'column.colDef.refData')

    // console.log(`processLabel: fromConstructor=${fromConstructor}, cellRefData=${cellRefData} => this.props: `, this.props)

    // handle row group : it's a simple text value
    if (this.props.node && this.props.node.group === true) {
      data = {
        label: this.props.value,
      }
    } else if (cellRefData === 'Site' && this.props.value && this.props.value.lib) {
      // format values - special case for Site
      data = {
        label: this.props.value.lib,
        _id: this.props.value._id,
      }
    } else if (Array.isArray(this.props.value)) {
      // Value is an array of {_id, lib, code}
      // Used currently for transfer_types and refurb_items.
      data = {
        lib: this.props.value
          .filter(v => v !== undefined && v !== null)
          // Ideally the code (shorter), lib by default.
          .map(v => v.code || v.lib)
          .join(', ')
          .toUpperCase(),
      }
    } else {
      data = this.props.value
    }

    const newState = {
      label: data ? data.lib || data.label : '',
      value: data ? data._id : undefined,
    }

    if (fromConstructor === true) {
      // eslint-disable-next-line
      this.state = newState
    } else {
      this.setState(newState)
    }
  }

  // refreshing manually does not seems to work...
  // returning false lead to component destruction and recreation (easier but slower)
  refresh(/*params*/) {
    // console.log('ListCellRenderer: refresh: ', params);
    // this.processLabel(params.value)
    return false
  }

  render() {
    // if (typeof this.props.value !== 'object' && typeof this.props.value !== 'string')
    //   console.log('ListCellRenderer => this.props: ', this.props, ', this.state = ', this.state)

    return <Fragment>{this.state.label}</Fragment>
  }
}
