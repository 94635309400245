import style from './style.module.scss';
import Quagga from 'quagga';
import { logSuccess } from '@helpers/logs';
import { useEffect, useRef, useState } from 'react';
import MainHeader from '@components/pwa/MainHeader';
import Popup from '@components/pwa/Popup';

export default (props) => {
  const { unitFound, unitsList, handleClose } = props;
  const scannerContainer = useRef(null);
  const [barcode, setBarcode] = useState(null);

  function init() {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          name: 'Live',
          target: scannerContainer.current,
          constraints: {
            facingMode: 'environment', // or user
          },
        },
        multiple: false,
        debug: {
          drawBoundingBox: true,
          showFrequency: true,
          drawScanline: true,
          showPattern: true,
        },
        locate: true,
        locator: {
          halfSample: false,
          patchSize: 'large',
        },
        numOfWorkers: navigator.hardwareConcurrency,
        frequency: 1,
        decoder: {
          readers: ['code_128_reader', 'code_39_reader', 'code_93_reader'],
        },
      },
      (err) => {
        if (err) {
          return console.log(err);
        }
        logSuccess('Initialization finished. Ready to start.');
        Quagga.start();
      },
    );

    Quagga.onProcessed((result) => {
      let drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute('width')),
            parseInt(drawingCanvas.getAttribute('height')),
          );
          result.boxes
            .filter((box) => box !== result.box)
            .forEach((box) => {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: '#f8aa00',
                lineWidth: 2,
              });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: '#0069b4',
            lineWidth: 2,
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: 'x', y: 'y' },
            drawingCtx,
            { color: '#d91e18', lineWidth: 3 },
          );
        }
      }
    });

    Quagga.onDetected(async (res) => {
      if (barcode) return;
      setBarcode(res.codeResult.code);
      const data = unitsList.find(
        (unit) =>
          JSON.stringify(unit?.barcode?.trim().toUpperCase()) ===
          JSON.stringify(res.codeResult.code.trim().toUpperCase()),
      );
      if (data) {
        if (navigator.vibrate) navigator.vibrate(200);
        Quagga.stop();
        if (window.navigator?.onLine) {
          unitFound(data);
        } else
          window.alert(
            'Unfortunately, you seem to be in offline mode. Check your network please.',
          );
      } else {
        Quagga.stop();
        if (
          window.confirm(
            `Sorry but the unit ${res.codeResult.code} does not exist. Please make sure you have created.`,
          )
        ) {
          handleClose();
        }
      }
    });
  }

  useEffect(() => {
    init();
    return () => {
      Quagga.stop();
    };
  }, []);

  return (
    <div className={style['scanner']}>
      {/* <MainHeader title={'Scanner'} action={handleClose} /> */}
      <div className={style['camera-wrapper']}>
        <div ref={scannerContainer} className={style['scanner-container']} />
      </div>

      <Popup />
    </div>
  );
};
