import { getAPIToken } from '../../security/User';
import fetch from 'node-fetch';
import { debugStore } from '../../lib/debug';
import { fixRefValues } from '../../lib/utils';
import { sendFlashMessage } from './action-common';

const BSON = require('bson');

// ! Worker
const transferWorker = new Worker('@workers/transfer.worker.js', {
  type: 'module',
});

// ------------------------------------------------------------------------
// MOVEMENTS LIST
// ------------------------------------------------------------------------
export const REQUEST_MV_LIST = 'REQUEST_MV_LIST';
export const RECEIVE_MV_LIST = 'RECEIVE_MV_LIST';
export const UPDATE_MV_LINE = 'UPDATE_MV_LINE';

//
// Before calling API.
//
export function requestMvList() {
  return {
    type: REQUEST_MV_LIST,
  };
}

//
// After receiving data from API.
//
export function receiveMvList() {
  return {
    type: RECEIVE_MV_LIST,
    receivedAt: Date.now(),
  };
}

//
// After receiving data from API.
//
export function updateMvLine(data) {
  return {
    type: UPDATE_MV_LINE,
    data,
    receivedAt: Date.now(),
  };
}

//
// Fetch the transfers datas from API.
//
export function fetchMvList() {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    // First dispatch: the app state is updated to inform that the API call is starting.
    debugStore('fetchMvList: BEFORE REQUEST RESULT DISPATCH !!!!');
    dispatch(requestMvList());

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.

    const url = `${process.env.REACT_APP_API_URL}/api/transfers`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
        'Content-Encoding': 'gzip',
      },
    };

    debugStore('fetchMvList: url=', url, options);

    let arrayResult = [];
    const types = ['aircon', 'deck', 'mh'];
    const actions = [
      'disconn',
      'transport',
      'storage',
      'transport_bis',
      'reconn',
      'disposal',
    ];

    try {
      const response = await fetch(url, options);

      if (response.status >= 400) {
        console.error(
          `fetchMvList: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
        );
      }

      const reader = await response.arrayBuffer();
      BSON.deserializeStream(
        reader,
        0,
        response.headers.get('nbResults'),
        arrayResult,
        0,
      );

      // ? Get uncached mobilhomes
      let uncached =
        response.headers.get('uncached') &&
        response.headers.get('uncached').split(',');

      for (let doc of arrayResult) {
        // doc.created_at = `${moment(doc.created_at).format('YYYY-MM-DDThh:mm:ss')}.000Z`;
        // doc.updated_at = `${moment(doc.updated_at).format('YYYY-MM-DDThh:mm:ss')}.000Z`;
        // doc.dt_achat ? doc.dt_achat = `${moment(doc.dt_achat).format('YYYY-MM-DDThh:mm:ss')}.000Z` : '';
        // doc.dt_vente  ? doc.dt_vente = `${moment(doc.dt_vente).format('YYYY-MM-DDThh:mm:ss')}.000Z` : '';

        if (doc?._id) {
          doc._id = doc._id?.toString();
          doc.mobilhome_id._id = doc.mobilhome_id._id?.toString();
        }

        if (!!uncached && uncached.includes(doc?._id)) {
          doc.uncached = true;
          uncached = uncached.filter((id) => id !== doc?._id);
        }

        for (const type of types) {
          for (const action of actions) {
            if (doc?.connections?.[type]?.[action]?.status?._id) {
              doc.connections[type][action].status._id =
                doc.connections[type][action].status._id?.toString();
            }
          }
        }
      }

      const created =
        !!uncached &&
        uncached.map(async (id) => {
          const url = `${process.env.REACT_APP_API_URL}/api/transfers/${id}`;

          // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
          const options = {
            method: 'GET',
            headers: {
              'x-access-token': API_TOKEN,
            },
          };

          const doc = await fetch(url, options);
          if (doc.status >= 400) {
            return null;
          }
          return doc.json();
        });

      // ? Created uncached ones
      return !!uncached
        ? Promise.all(created).then((docs) => {
            docs = docs.map((doc) => {
              if (!doc) return null;
              return { ...doc, uncached: true };
            });

            docs = docs.filter((doc) => doc !== null);

            arrayResult.push(...docs);
            dispatch(receiveMvList());
            return arrayResult;
          })
        : arrayResult;
    } catch (e) {
      console.error('fetchMvList:', e);
    }
  };
}

// ------------------------------------------------------------------------
// MOBILHOME EDITING
// ------------------------------------------------------------------------
export const RECEIVE_MV = 'RECEIVE_MV';

//
// After receiving data from API.
//
export function receiveMv(data) {
  return {
    type: RECEIVE_MV,
    data,
  };
}

//
// Fetch the mobilhome from API.
//
export function fetchMv(id) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(`fetchMv: EDIT MH id=${id}`);
    const url = `${process.env.REACT_APP_API_URL}/api/transfers/${id}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('fetchMv: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `fetchMv: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );

          const err = new Error(response.statusText);
          err.code = response.status;
          throw err;
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `fetchMv: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(
            `Error while getting the MH data for id=${id}, err=${err}`,
          );
        }
      })
      .then((json) => {
        // Here, we update the app state with the results of the API call.
        // dispatch(resetError())
        // dispatch(updateFormValues(json.storeData.formValues))
        dispatch(receiveMv(json));

        return json;
      });
  };
}

//
// After receiving data from API.
//
export function updateMv(myValues, callback = null) {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore('updateMv: myValues=', myValues);

    delete myValues.__v;

    fixRefValues(myValues);

    // ! Encoding
    const enc = new TextEncoder('utf-8');

    const isEdit = myValues?._id?.length > 0;

    const isEditBuffer = enc.encode(isEdit).buffer;

    const apiTokenBuffer = enc.encode(API_TOKEN).buffer;

    // ! Delegate to worker
    const valuesStringify = enc.encode(JSON.stringify(myValues));
    const valuesBuffer = valuesStringify.buffer;

    transferWorker.onmessage = (event) => {
      const { _id, barcode } = event.data;

      let transferAwaitingRegistration = sessionStorage.getItem(
        'transferAwaitingRegistration',
      );
      transferAwaitingRegistration = !transferAwaitingRegistration
        ? []
        : JSON.parse(transferAwaitingRegistration);

      if (!transferAwaitingRegistration.some((id) => id === _id)) {
        transferAwaitingRegistration.push(_id);
      }

      sessionStorage.setItem(
        'transferAwaitingRegistration',
        JSON.stringify(transferAwaitingRegistration),
      );

      dispatch(receiveMv(event.data));
      dispatch(sendFlashMessage(`Saved transfer ${_id}`, 'success'));
      callback && isEdit && callback();
      callback && !isEdit && callback(_id);

      return _id;
    };

    transferWorker.postMessage({ apiTokenBuffer, isEditBuffer, valuesBuffer }, [
      apiTokenBuffer,
      isEditBuffer,
      valuesBuffer,
    ]);
  };
}

//
// After receiving data from API.
//
export function cancelAndDuplicateMV(ids) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();
    const url = `${process.env.REACT_APP_API_URL}/api/transfers_cancel_and_duplicate`;

    const options = {
      method: 'PATCH',
      body: JSON.stringify({ ids }),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `updateMv: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `updateMv: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(`Error while saving MH data, err=${err}`);
        }
      })
      .then((json) => {
        // TODO: Find solution to update view with the new datas
        // console.log('JYO: Response: json: ', json);
        dispatch(
          sendFlashMessage('Canceled & Duplicated successfully!', 'success'),
        );

        dispatch(receiveMv(json));
        return json;
      });
  };
}

// ------------------------------------------------------------------------
// MOBILHOME EXPORT
// ------------------------------------------------------------------------
export const REQUEST_MV_EXPORT = 'REQUEST_MV_EXPORT';
export const RECEIVE_MV_EXPORT = 'RECEIVE_MV_EXPORT';

//
// Before calling API.
//
export function requestMvExport() {
  return {
    type: REQUEST_MV_EXPORT,
  };
}

//
// Remove a movement by its ID.
//
export function removeMv(mvId, callback = null) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(`removeMv: REMOVE MOVEMENT id=${mvId}`);

    // ! Encoding
    const enc = new TextEncoder('utf-8');
    const apiTokenBuffer = enc.encode(API_TOKEN).buffer;
    const idBuffer = enc.encode(mvId).buffer;
    // ! Delegate to saving worker

    transferWorker.onmessage = (event) => {
      const _id = event.data;
      let transferAwaitingRemoving = sessionStorage.getItem(
        'unitAwaitingRemoving',
      );
      transferAwaitingRemoving = !transferAwaitingRemoving
        ? []
        : JSON.parse(transferAwaitingRemoving);

      if (!transferAwaitingRemoving.some((id) => id === _id)) {
        transferAwaitingRemoving.push(_id);
      }

      sessionStorage.setItem(
        'transferAwaitingRemoving',
        JSON.stringify(transferAwaitingRemoving),
      );

      dispatch(sendFlashMessage(`Removed ${_id}`, 'success'));
      callback && callback();
    };

    // ? Transferable object
    transferWorker.postMessage({ apiTokenBuffer, idBuffer }, [
      apiTokenBuffer,
      idBuffer,
    ]);
  };
}

export function cancelMv(mvId, callback = null) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(`cancelMv: CANCEL MOVEMENT id=${mvId}`);

    // ! Encoding
    const enc = new TextEncoder('utf-8');

    const isCancelledBuffer = enc.encode(true).buffer;
    const apiTokenBuffer = enc.encode(API_TOKEN).buffer;
    const idBuffer = enc.encode(mvId).buffer;
    // ! Delegate to saving worker

    transferWorker.onmessage = (event) => {
      const _id = event.data;
      let transferAwaitingRemoving = sessionStorage.getItem(
        'transferAwaitingRemoving',
      );
      transferAwaitingRemoving = !transferAwaitingRemoving
        ? []
        : JSON.parse(transferAwaitingRemoving);

      if (!transferAwaitingRemoving.some((id) => id === _id)) {
        transferAwaitingRemoving.push(_id);
      }

      sessionStorage.setItem(
        'transferAwaitingRemoving',
        JSON.stringify(transferAwaitingRemoving),
      );

      dispatch(sendFlashMessage(`Cancelled ${_id}`, 'success'));
      callback && callback();
    };

    // ? Transferable object
    transferWorker.postMessage(
      { apiTokenBuffer, isCancelledBuffer, idBuffer },
      [apiTokenBuffer, isCancelledBuffer, idBuffer],
    );
  };
}

export function getTransferSeasons() {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    const url = `${process.env.REACT_APP_API_URL}/api/transfer_seasons`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `transfer_seasons: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );

          const err = new Error(response.statusText);
          err.code = response.status;
          throw err;
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `fetchMv: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(
            `Error while getting transfer years for id=${id}, err=${err}`,
          );
        }
      })
      .then((json) => {
        return json;
      });
  };
}
