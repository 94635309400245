export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="M390-740h60v-60h-60v60Zm120 0v-60h60v60h-60ZM390-500v-60h60v60h-60Zm240-120v-60h60v60h-60Zm0 120v-60h60v60h-60Zm-120 0v-60h60v60h-60Zm120-240v-60h60v60h-60Zm-180 60v-60h60v60h-60ZM299.825-160Q287-160 278.5-168.625T270-190v-580q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.422 8.625 8.597 8.625 8.597 21.375v30H390v60h-59.806v60H390v60h-60v370q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM570-560v-60h60v60h-60Zm-120 0v-60h60v60h-60Zm-60-60v-60h60v60h-60Zm120 0v-60h60v60h-60Zm60-60v-60h60v60h-60Z" />
  </svg>
);
