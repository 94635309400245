export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    width="20"
    height="20"
  >
    <path
      fill="#8f8f8f"
      d="M460-49 346.4-160H190q-12.8 0-21.4-8.6T160-190v-155.8L47-459q-9-9-9-21t9-21l113-113.2V-770q0-12.8 8.6-21.4T190-800h156.4L460-913q9-9 21-9t21 9l112 113h156q12.8 0 21.4 8.6T800-770v155.8L913-501q9 9 9 21t-9 21L800-345.8V-190q0 12.8-8.6 21.4T770-160H614L502-50q-9 8-21 8.5T460-49Zm21-432Zm0 368 108-107h151v-151l109-109-109-109v-151H589L481-849 371-740H220v151L111-480l109 109v151h150l111 107Z"
    />
  </svg>
);
