import React from 'react';
import style from './style.module.scss';
import '@assets/json/countries.json';
import { prettyCountry, prettyBrand } from '@components/helpers/GridHelper';
import classnames from 'classnames';

export default (params) => {
  const value = prettyBrand(params.value);

  const flag = prettyCountry(value);

  return (
    <div className={style['flag-cell-renderer-wrapper']}>
      <div
        className={classnames(
          // style[`${prettyTag(props.value)}`],
          style['flag-cell-renderer'],
        )}
      >
        {params.value && flag && (
          <img
            src={require(`@assets/images/flags/${flag}.svg`).default}
            alt={flag}
          />
        )}
        <span>{params.value}</span>
      </div>
    </div>
  );
};
