export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.60633 9.91663H7.48133V6.41663H6.60633V9.91663ZM7.00008 5.33746C7.13619 5.33746 7.25043 5.29371 7.34279 5.20621C7.43515 5.11871 7.48133 5.0069 7.48133 4.87079C7.48133 4.73468 7.43515 4.61802 7.34279 4.52079C7.25043 4.42357 7.13619 4.37496 7.00008 4.37496C6.86397 4.37496 6.74973 4.42357 6.65737 4.52079C6.56501 4.61802 6.51883 4.73468 6.51883 4.87079C6.51883 5.0069 6.56501 5.11871 6.65737 5.20621C6.74973 5.29371 6.86397 5.33746 7.00008 5.33746V5.33746ZM7.00008 12.8333C6.20286 12.8333 5.44939 12.6802 4.73966 12.3739C4.02994 12.0677 3.41015 11.6496 2.88029 11.1198C2.35043 10.5899 1.93237 9.9701 1.62612 9.26038C1.31987 8.55065 1.16675 7.79232 1.16675 6.98538C1.16675 6.18815 1.31987 5.43468 1.62612 4.72496C1.93237 4.01524 2.35043 3.39788 2.88029 2.87288C3.41015 2.34788 4.02994 1.93225 4.73966 1.626C5.44939 1.31975 6.20772 1.16663 7.01466 1.16663C7.81189 1.16663 8.56536 1.31975 9.27508 1.626C9.9848 1.93225 10.6022 2.34788 11.1272 2.87288C11.6522 3.39788 12.0678 4.01524 12.374 4.72496C12.6803 5.43468 12.8334 6.19302 12.8334 6.99996C12.8334 7.79718 12.6803 8.55065 12.374 9.26038C12.0678 9.9701 11.6522 10.5899 11.1272 11.1198C10.6022 11.6496 9.9848 12.0677 9.27508 12.3739C8.56536 12.6802 7.80703 12.8333 7.00008 12.8333ZM7.01466 11.9583C8.3855 11.9583 9.55217 11.4746 10.5147 10.5073C11.4772 9.53989 11.9584 8.36593 11.9584 6.98538C11.9584 5.61454 11.4772 4.44788 10.5147 3.48538C9.55217 2.52288 8.38064 2.04163 7.00008 2.04163C5.62925 2.04163 4.46015 2.52288 3.49279 3.48538C2.52543 4.44788 2.04175 5.6194 2.04175 6.99996C2.04175 8.37079 2.52543 9.53989 3.49279 10.5073C4.46015 11.4746 5.63411 11.9583 7.01466 11.9583Z"
        fill={props.color ? props.color : '#333333'}
      />
    </svg>
  );
};
