export default (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.625 12.25C2.39167 12.25 2.1875 12.1625 2.0125 11.9875C1.8375 11.8125 1.75 11.6083 1.75 11.375V3.74792C1.75 3.60208 1.76458 3.47812 1.79375 3.37604C1.82292 3.27396 1.87639 3.17917 1.95417 3.09167L2.77083 1.98333C2.84861 1.89583 2.93854 1.83507 3.04063 1.80104C3.14271 1.76701 3.26181 1.75 3.39792 1.75H10.6021C10.7382 1.75 10.8549 1.76701 10.9521 1.80104C11.0493 1.83507 11.1368 1.89583 11.2146 1.98333L12.0458 3.09167C12.1236 3.17917 12.1771 3.27396 12.2062 3.37604C12.2354 3.47812 12.25 3.60208 12.25 3.74792V11.375C12.25 11.6083 12.1625 11.8125 11.9875 11.9875C11.8125 12.1625 11.6083 12.25 11.375 12.25H2.625ZM2.87292 3.29583H11.1125L10.5875 2.625H3.39792L2.87292 3.29583ZM2.625 4.17083V11.375H11.375V4.17083H2.625ZM7 10.0625L9.275 7.7875L8.69167 7.20417L7.4375 8.45833V5.52708H6.5625V8.45833L5.30833 7.20417L4.725 7.7875L7 10.0625ZM2.625 11.375H11.375H2.625Z"
        fill={props.color ? props.color : '#FFFFFF'}
      />
    </svg>
  );
};
