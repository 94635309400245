import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';

import style from './style.module.scss';

export default (props) => {
  const { initialDate, updatedDate } = props;
  const [date, setDate] = useState(
    initialDate ? new Date(initialDate) : Date.now(),
  );
  return (
    <div className={style['date-picker']}>
      <label className={style['render-field']}>
        <small>{props.label}</small>
        <ReactDatePicker
          disabled={props?.disabled}
          dateFormat="dd/MM/yyyy"
          selected={date}
          onChange={(newDate) => {
            setDate(newDate);
            updatedDate(newDate);
          }}
          minDate={Date.now()}
          calendarClassName={style['calendar-style']}
        />
      </label>
    </div>
  );
};
