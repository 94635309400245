export default (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 48 48"
    >
      <path
        fill={props.color ? props.color : '#FFF'}
        d="M6 40V8l38 16Zm3-4.65L36.2 24 9 12.5v8.4L21.1 24 9 27Zm0 0V12.5 27Z"
      />
    </svg>
  );
};
