import { sendFlashMessage } from '../../store/actions/action-common';
import getStore from '../../store/store';

import { createUnitReport } from '../../store/actions/action-scanner';

export function submitUnitReport(values) {
  return getStore()
    .dispatch(createUnitReport(values))
    .then((response) => {
      // getStore().dispatch(sendFlashMessage('Saved!', 'success'));
      return response;
    })
    .catch((err) => {
      getStore().dispatch(
        sendFlashMessage('Sorry cannot submit your request', 'error'),
      );
    });
}
