//
// Override the Route Component to check the authentication state before routing to the asked route.
//
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { debugRouter as debug } from '../lib/debug';
import PropTypes from 'prop-types';

class koRedirect extends React.Component {
  render() {
    debug('PrivateRoute: ✘ ROUTE NOT ALLOWED :( => Redirecting to /login');

    // Saving location to do the redirection after auth.
    // console.info(this.props.location);
    // localStorage.setItem('referrer', JSON.stringify(this.props.location));

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: this.props.location },
        }}
      />
    );
  }
}

// Failed prop type: Invalid prop `component` of type `object` supplied to `PrivateRoute`, expected `function`.

class PrivateRoute extends React.Component {
  static propTypes = {
    component: PropTypes.any,
    user: PropTypes.object,
  };

  render() {
    const { component: Component, user, ...rest } = this.props;

    // debug('PrivateRoute: rendering... props=', rest, 'isAuthenticated=', user.isAuthenticated)

    // const okRenderComponent = props => {
    //   debug('PrivateRoute: ✔︎ ROUTE ALLOWED :)', props)
    //   return <Component {...props} />
    // }
    localStorage.setItem('referrer', JSON.stringify(this.props.location));
    const toRender = user?.isAuthenticated ? Component : koRedirect;
    return <Route key={rest.location.key} {...rest} component={toRender} />;
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(
  mapStateToProps,
  null,
  null,
  // To force the re-rendering and redirect correctly.
  { pure: false },
)(PrivateRoute);
