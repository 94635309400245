export default (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.08325 10.5C3.11103 10.5 2.28464 10.1597 1.60409 9.47917C0.92353 8.79861 0.583252 7.97222 0.583252 7C0.583252 6.02778 0.92353 5.20139 1.60409 4.52083C2.28464 3.84028 3.11103 3.5 4.08325 3.5C4.84159 3.5 5.48082 3.68229 6.00096 4.04688C6.5211 4.41146 6.90756 4.89514 7.16034 5.49792H12.5416C12.7846 5.49792 12.9912 5.58299 13.1614 5.75312C13.3315 5.92326 13.4166 6.12986 13.4166 6.37292V7.62708C13.4166 7.87014 13.3315 8.07674 13.1614 8.24687C12.9912 8.41701 12.7846 8.50208 12.5416 8.50208H11.8708V9.625C11.8708 9.86806 11.7857 10.0747 11.6155 10.2448C11.4454 10.4149 11.2388 10.5 10.9958 10.5H10.0333C9.7902 10.5 9.5836 10.4149 9.41346 10.2448C9.24332 10.0747 9.15825 9.86806 9.15825 9.625V8.50208H7.16034C6.90756 9.10486 6.5211 9.58854 6.00096 9.95312C5.48082 10.3177 4.84159 10.5 4.08325 10.5ZM4.08325 9.625C4.77353 9.625 5.33985 9.39653 5.78221 8.93958C6.22457 8.48264 6.48464 8.04514 6.56242 7.62708H10.0916V9.625H10.9958V7.62708H12.5416V6.37292H6.56242C6.48464 5.95486 6.22457 5.51736 5.78221 5.06042C5.33985 4.60347 4.77353 4.375 4.08325 4.375C3.35409 4.375 2.73429 4.63021 2.22388 5.14062C1.71346 5.65104 1.45825 6.27083 1.45825 7C1.45825 7.72917 1.71346 8.34896 2.22388 8.85938C2.73429 9.36979 3.35409 9.625 4.08325 9.625ZM4.08325 7.99167C4.3652 7.99167 4.60096 7.89687 4.79054 7.70729C4.98013 7.51771 5.07492 7.28194 5.07492 7C5.07492 6.71806 4.98013 6.48229 4.79054 6.29271C4.60096 6.10313 4.3652 6.00833 4.08325 6.00833C3.80131 6.00833 3.56554 6.10313 3.37596 6.29271C3.18638 6.48229 3.09159 6.71806 3.09159 7C3.09159 7.28194 3.18638 7.51771 3.37596 7.70729C3.56554 7.89687 3.80131 7.99167 4.08325 7.99167Z"
        fill={props.color ? props.color : '#F0932B'}
      />
    </svg>
  );
};
