import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { debugStore as debug } from '../../lib/debug';
import { saveState } from '../../lib/reduxLocalStorage';
import {
  USER_LOGGED_IN,
  USER_LOGOUT,
  REQUEST_REF_DATAS,
  RECEIVE_REF_DATAS,
  FLASH_MESSAGE,
  NOTIFICATION,
  SET_NB_LINES,
  SET_CURRENT_PAGE,
  UPDATE_FILTERS,
  UPDATE_COLUMN_STATE,
  UPDATE_USER_VIEW,
  UPDATE_CUSTOM_CONFS,
  REQUEST_USER_SETTINGS,
  USER_TUTO_COMPLETED,
  SAVE_SELECTED_SEASON,
  COPY_FROM_BUTTON,
  UPDATE_GA,
} from '../actions/action-common';
import { handleMhList, handleMh, handleMhMovements } from './mh';
import { handleMvList, handleMv } from './mv';
import { handleSiteList, handleSite } from './site';
import cloneDeep from 'lodash/cloneDeep';

// -------------------------------------------------------------------------------------------
// Manage `store.refDatas`.
// -------------------------------------------------------------------------------------------
function handleRefDatas(
  previousState = {
    isFetching: false,
    lastUpdated: 0,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_REF_DATAS:
      debug(
        'reducer REQUEST_REF_DATAS: previousState=',
        previousState,
        'action=',
        action,
      );

      return Object.assign({}, previousState, { isFetching: true });

    case RECEIVE_REF_DATAS:
      debug(
        'reducer RECEIVE_REF_DATAS: previousState=',
        previousState,
        'action=',
        action,
      );

      return Object.assign({}, previousState, {
        data: action.data,
        lastUpdated: action.receivedAt,
      });

    default:
      return previousState;
  }
}

// -------------------------------------------------------------------------------------------
// Manage `store.flashMsg`.
// -------------------------------------------------------------------------------------------
function handleFlashMessages(previousState = {}, action) {
  switch (action.type) {
    case FLASH_MESSAGE:
      debug(
        'reducer FLASH_MESSAGE: previousState=',
        previousState,
        'action=',
        action,
      );

      return Object.assign({}, previousState, action.data);

    default:
      return previousState;
  }
}

// -------------------------------------------------------------------------------------------
// NOTIFICATION.
// -------------------------------------------------------------------------------------------
function handleNotification(state, action) {
  switch (action.type) {
    case NOTIFICATION:
      return action.bool;
    default:
      return null;
  }
}

// -------------------------------------------------------------------------------------------
// Manage `store.user`.
// -------------------------------------------------------------------------------------------
function handleUser(previousState = { isAuthenticated: false }, action) {
  switch (action.type) {
    // When the user is authenticated, set the user in store.
    case USER_LOGGED_IN:
      debug(
        'reducer USER_LOGGED_IN: previousState=',
        previousState,
        'action=',
        action,
      );

      return Object.assign({}, previousState, action.user, {
        isAuthenticated: true,
      });

    case USER_LOGOUT:
      debug(
        'reducer USER_LOGOUT: previousState=',
        previousState,
        'action=',
        action,
      );

      // Reset user state.
      return { isAuthenticated: false };

    default:
      return previousState;
  }
}

// -------------------------------------------------------------------------------------------
// Manage `store.userSettings`.
// See store.js to understand the hierarchy in this part of the store.
// -------------------------------------------------------------------------------------------

const defaultValues = {
  nbLines: 100,
  page: 1,
  columnState: [],
  filters: {},
  userView: {},
};
const defaultStates = {
  list: {
    mobilhomes: { ...defaultValues },
    transfers: { ...defaultValues },
    sites: { ...defaultValues },
    pitchFees: { ...defaultValues },
    pitches: { ...defaultValues },
  },
};
function handleUserSettings(previousState = defaultStates, action) {
  let newState;
  switch (action.type) {
    case SET_NB_LINES:
      debug(
        'reducer SET_NB_LINES: previousState=',
        previousState,
        'action=',
        action,
      );

      newState = cloneDeep(previousState);
      newState.list[action.gridName].nbLines = action.nbLines;
      // reset page number if we are getting less...
      if (action.nbLines > previousState.list[action.gridName].nbLines) {
        newState.list[action.gridName].page = 0;
      }

      return newState;

    case SET_CURRENT_PAGE:
      debug(
        'reducer SET_CURRENT_PAGE: previousState=',
        previousState,
        'action=',
        action,
      );

      newState = cloneDeep(previousState);
      newState.list[action.gridName].page = action.page;

      return newState;

    case UPDATE_FILTERS:
      debug(
        'reducer UPDATE_FILTERS: previousState=',
        previousState,
        'action=',
        action,
      );

      newState = cloneDeep(previousState);
      // newState.list[action.gridName].filters = clearAndFormatFilters(action.filters)
      newState.list[action.gridName].filters = action.filters;

      // console.log('JYO: UPDATE_FILTERS: previousState: ', previousState);
      // console.log('JYO: UPDATE_FILTERS: newState: ', newState);

      return newState;

    case UPDATE_COLUMN_STATE:
      debug(
        'reducer UPDATE_COLUMN_STATE: previousState=',
        previousState,
        'action=',
        action,
      );

      newState = cloneDeep(previousState);
      newState.list[action.gridName].columnState = action.columnState;

      // console.log(`JYO: newState.list[${action.gridName}].columnState: `, newState.list[action.gridName].columnState);

      debug('reducer UPDATE_COLUMN_STATE: newState: ', newState);
      return newState;

    case REQUEST_USER_SETTINGS:
      debug(
        'reducer REQUEST_USER_SETTINGS: previousState=',
        previousState,
        'action=',
        action,
      );
      return Object.assign({}, previousState, { isFetching: true });

    case UPDATE_CUSTOM_CONFS: {
      debug(
        'reducer UPDATE_CUSTOM_CONFS: previousState=',
        previousState,
        'action=',
        action,
      );

      newState = {
        ...previousState,
        ...{ listSavedConfs: action.customConfs },
      };

      return newState;
    }

    case UPDATE_USER_VIEW:
      debug(
        'reducer UPDATE_USER_VIEW: previousState=',
        previousState,
        'action=',
        action,
      );

      newState = cloneDeep(previousState);
      initUserSettingByGridName(newState, action.gridName);

      newState.list[action.gridName].userView[action.view] = action.userView;

      debug('reducer UPDATE_USER_VIEW: newState: ', newState);
      return newState;

    case USER_TUTO_COMPLETED: {
      debug(
        'reducer UPDATE_USER_TUTO: previousState=',
        previousState,
        'action=',
        action,
      );
      return { ...previousState, isCompleteTutoTour: true };
    }

    // case SAVE_SELECTED_SEASON:
    //   debug('reducer SAVE_SELECTED_SEASON: previousState=', previousState, 'action=', action)

    //   return { ...previousState, ...{ selectedSeason: action.selectedSeason }};

    case COPY_FROM_BUTTON:
      debug(
        'reducer COPY_FROM_BUTTON: previousState=',
        previousState,
        'action=',
        action,
      );

      return { ...previousState, ...{ copyFromButton: action.copyFromButton } };

    default:
      return previousState;
  }
}

//
// Helper method to set undefined hierarchical levels in our userSettings store.
//
function initUserSettingByGridName(state, gridName, name = undefined) {
  const defaultListValues = {
    nbLines: 100,
    page: 0,
    userView: {},
  };
  if (!state.list) state.list = {};
  if (!state.list[gridName]) state.list[gridName] = defaultListValues;
  if (name && !state.list[gridName][name]) state.list[gridName][name] = {};
}

function handleGoogleAnalyticsState(state, action) {
  switch (action.type) {
    case UPDATE_GA:
      return action.bool;
    default:
      return null;
  }
}

// reorganize filters to easily converse with server
function clearAndFormatFilters(filters) {
  debug('clearAndFormatFilters: ', filters);
  let formatedFilter = {};
  if (filters && Object.keys(filters).length > 0) {
    Object.keys(filters).forEach((k) => {
      if (filters[k].value) {
        // our managed filters : we expect {value: [{value:'xxx', label:'yyy'}, {value:'www', label:'zzz'}, ...]}
        if (Object.keys(filters[k].value).length > 0) {
          formatedFilter[k] = filters[k].value.map((o) => o.value);
        } else {
          // do nothing - empty array
        }
      } else if (filters[k].filter) {
        formatedFilter[k] = filters[k].filter;
      } else if (filters[k].filterType === 'set') {
        // FIXME : on ne gère pas les filtres intégrés AG Grid autres que "set" ("AND/OR"), ...
        // valeurs simples
        if (filters[k].values /*['millesime'].includes(k)*/) {
          formatedFilter[k] = filters[k].values;
        } else {
          // FIXME : ce cas peut-il arriver ? valeurs complexes dans un filtre intégré ag-grid....
          console.error(
            `clearAndFormatFilters on filter ${filters[k]} => no values found for filterType...`,
          );
          // formatedFilter[k] = filters[k].values.map(o => o.value)
        }
      } else {
        // tous les autres cas non gérés
        // par exemple si on veux implémenter un agDateColumnFilter, il faudra ici gérer les equal, greater/less-Than, between...
        console.error(
          `clearAndFormatFilters on filter ${k}:  => case not handled !!!`,
          filters[k],
        );
      }
    });
  }
  // debug('formatedFilter: ', formatedFilter);
  return formatedFilter;
}

// =================================================================================

// Splitting reducers: http://redux.js.org/docs/basics/Reducers.html#splitting-reducers
const appReducer = combineReducers({
  refDatas: handleRefDatas,
  mhList: handleMhList,
  mh: handleMh,
  mhMovements: handleMhMovements,
  mv: handleMv,
  mvList: handleMvList,
  form: formReducer,
  flashMsg: handleFlashMessages,
  user: handleUser,
  userSettings: handleUserSettings,
  siteList: handleSiteList,
  site: handleSite,
  gaState: handleGoogleAnalyticsState,
  receiveNotification: handleNotification,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
    // + reset localStorage as well.
    // console.log('rootReducer: state: ', state);

    saveState(state);

    debug('rootReducer: USER_LOGOUT => clearing store...');
  }

  return appReducer(state, action);
};

export default rootReducer;
