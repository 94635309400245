import React from 'react';
import { useState } from 'react';

import DataTable from '@components/dashboard/DataTable';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Placeholder from '@components/common/Placeholder';
import ContentHeader from '@components/headers/ContentHeader';
import Stats from '@components/icons/Stats';
import BaseNavigation from '@components/navigations/BaseNavigation';

import style from './style.module.scss';

export default (props) => {
  const { t, dataTables } = props;
  const [dataTableCurrentIndex, setDataTableCurrentIndex] = useState(0);

  const navigation = [
    t('page.homepage.manufacturers', 'Manufacturers'),
    t('page.homepage.commercial_type_codes', 'Commercial type code'),
  ];

  return (
    <div className={style['detailed-statistics-scroll']}>
      <div className={style['detailed-statistics']}>
        <ContentHeader
          icon={Stats}
          title={t(
            'page.homepage.detailed_statistics.title',
            'Detailed statistics',
          )}
          subtitle={t(
            'page.homepage.detailed_statistics.subtitle',
            'Choose your ideal view',
          )}
        />
        <div className={style['detailed-statistics-wrapper']}>
          <BaseNavigation
            items={navigation}
            currentIndex={dataTableCurrentIndex}
            updateIndex={setDataTableCurrentIndex}
          />
          <div className={style['details-statistics-content']}>
            {!dataTables.flat().includes(undefined) &&
            dataTables.flat().length > 0 ? (
              <SwitchTransition>
                <CSSTransition
                  key={dataTableCurrentIndex}
                  timeout={300}
                  classNames="fade"
                >
                  <div className={style['data-table-wrapper']}>
                    <DataTable
                      data={dataTables[dataTableCurrentIndex]}
                      reference={
                        dataTableCurrentIndex === 0
                          ? t('page.homepage.manufacturers', 'Manufacturers')
                          : t(
                              'page.homepage.commercial_type_codes',
                              'Commercial type codes',
                            )
                      }
                      t={t}
                    />
                  </div>
                </CSSTransition>
              </SwitchTransition>
            ) : (
              <Placeholder height={480} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
