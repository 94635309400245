import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { getUserSetting } from '@security/User';

// Fetch data
import { connect } from 'react-redux';
import { fetchLogs } from '@store/actions/action-dashboard';
import { getTransfersDeadline } from '@store/actions/action-dashboard';

import {
  fetchUnitsCount,
  fetchUnitModels,
  fetchCommercialTypeModels,
} from '@store/actions/action-mh';

// Layout
import Layout from '@layout/default';

// Components
import Logs from '@components/dashboard/Logs';
import TransfersReminder from '@components/dashboard/TransfersReminder';
import KeyFigures from '@components/dashboard/KeyFigures';
import Placeholder from '@components/common/Placeholder';

import socket from '@helpers/socketio-helper';
import { AllowedBlock } from '@components/common/AllowedBlock';
import DetailedStatistics from '../../components/dashboard/DetailedStatistics';
import ScrollToTopButton from '@components/buttons/ScrollToTopButton';
import FlashMessage from '@components/common/FlashMessage';
import OnlineUsers from '@components/dashboard/OnlineUsers';

import style from './style.module.scss';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brands: [],
      rentalBrands: [],
      users: [],
      logs: [],
      unitModels: [],
      commercialTypeModelsPerBrand: [],
      dataTableCurrentIndex: 0,
    };

    this.fetchData = this.fetchData.bind(this);
    this.getFullName = this.getFullName.bind(this);
  }

  componentDidMount() {
    this.fetchData();

    socket.emit('online_users', (res) =>
      this.setState({
        users: res.filter((user) => user.socket_id !== socket.id),
      }),
    );
  }

  async fetchData() {
    const brands = await this.props.dispatch(fetchUnitsCount());
    const rentalBrands = await this.props.dispatch(fetchUnitsCount('rental'));
    const logs = await this.props.dispatch(fetchLogs());
    const transfersDeadline = await this.props.dispatch(getTransfersDeadline());
    const unitModelsPerBrand = await this.props.dispatch(fetchUnitModels());
    const commercialTypeModelsPerBrand = await this.props.dispatch(
      fetchCommercialTypeModels(),
    );

    this.setState({
      brands,
      rentalBrands,
      transfersDeadline,
      logs,
      unitModelsPerBrand,
      commercialTypeModelsPerBrand,
    });
  }

  getFullName() {
    return `${getUserSetting('firstname')} ${getUserSetting('lastname')}`;
  }

  render() {
    const { t } = this.props;
    const {
      brands,
      rentalBrands,
      users,
      logs,
      unitModelsPerBrand,
      commercialTypeModelsPerBrand,
      transfersDeadline,
    } = this.state;

    const dataTables = [unitModelsPerBrand, commercialTypeModelsPerBrand];

    // ! Document title
    document.title = `EUROPEAN CAMPING GROUP |  ${this.props.t(
      'page.homepage.document_title',
      'Homepage',
    )}`;

    return (
      <Layout {...this.props}>
        <ScrollToTopButton />
        <FlashMessage />
        <div className={style['main-content']}>
          <div>
            <AllowedBlock roles={'ROLE_LECTURE'}>
              <Fragment>
                <OnlineUsers users={users} {...this.props} />
                <KeyFigures t={t} brands={brands} rentalBrands={rentalBrands} />
              </Fragment>
            </AllowedBlock>
            <AllowedBlock roles={'ROLE_ADMIN'}>
              {logs && logs.length > 0 ? (
                <Logs logs={logs} {...this.props} />
              ) : (
                !Array.isArray(transfersDeadline) && (
                  <Placeholder height={'fullHeight'} />
                )
              )}
            </AllowedBlock>
            <AllowedBlock roles={'ROLE_ADMIN'}>
              {transfersDeadline && transfersDeadline.length > 0 ? (
                <TransfersReminder
                  transfers={transfersDeadline}
                  {...this.props}
                />
              ) : (
                !Array.isArray(transfersDeadline) && (
                  <Placeholder height={'fullHeight'} />
                )
              )}
            </AllowedBlock>
          </div>

          {/* Detailed statistics */}
          {/* <AllowedBlock roles={'ROLE_ADMIN'}> */}
          <DetailedStatistics dataTables={dataTables} {...this.props} />
          {/* </AllowedBlock> */}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user,
    userSettings: store.userSettings,
    refDatas: store.refDatas.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(Home));
