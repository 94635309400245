export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.375 15.7313H3.375C3.075 15.7313 2.8125 15.6187 2.5875 15.3937C2.3625 15.1687 2.25 14.9063 2.25 14.6063V3.375C2.25 3.075 2.3625 2.8125 2.5875 2.5875C2.8125 2.3625 3.075 2.25 3.375 2.25H15.375C15.675 2.25 15.9375 2.3625 16.1625 2.5875C16.3875 2.8125 16.5 3.075 16.5 3.375V14.6063C16.5 14.9063 16.3875 15.1687 16.1625 15.3937C15.9375 15.6187 15.675 15.7313 15.375 15.7313ZM3.375 6.01875H15.375V3.375H3.375V6.01875ZM6.1875 7.14375H3.375V14.6063H6.1875V7.14375ZM12.5625 7.14375V14.6063H15.375V7.14375H12.5625ZM11.4375 7.14375H7.3125V14.6063H11.4375V7.14375Z"
        fill={props.color ? props.color : '#333333'}
      />
    </svg>
  );
};
