import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import style from './style.module.scss';

export default (params) => {
  if (!params?.value) return;
  const valueFormatted = params?.valueFormatted;
  const currentLocation = useLocation().pathname;
  return valueFormatted && currentLocation !== valueFormatted ? (
    <Link
      className={classnames(style['link-cell-renderer'])}
      to={valueFormatted}
    >
      Go to ⚡
    </Link>
  ) : (
    <span className={style['link-cell-renderer-current']}>CURRENT</span>
  );
};
