import { jsPDF } from 'jspdf';

import logoECG from '@assets/images/pdf/logoECG.png';
import headerBackground from '@assets/images/pdf/header-background.jpg';
import dayjs from 'dayjs';

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

function convertPxToMm(value) {
  return (value * 25.4) / 72;
}

function createHeaders(keys) {
  var result = [];
  for (var i = 0; i < keys.length; i += 1) {
    result.push({
      id: keys[i],
      name: keys[i],
      prompt: keys[i],
      width: i === 0 ? convertPxToMm(50) : convertPxToMm(900) / keys.length - 1,
      align: 'center',
      padding: 0,
    });
  }
  return result;
}

export default (data) => {
  const doc = new jsPDF();

  // ! SECTION
  // Add logo
  doc.addImage(
    logoECG,
    'PNG',
    convertPxToMm(30),
    convertPxToMm(40),
    convertPxToMm(110),
    convertPxToMm(72),
  );

  // Add header background
  doc.addImage(
    headerBackground,
    'PNG',
    convertPxToMm(213),
    convertPxToMm(0),
    convertPxToMm(382),
    convertPxToMm(114),
  );
  // doc.setFont('OpenSansBold');
  doc.setFontSize(10);
  doc.setLineHeightFactor(1.5);
  doc.text(
    'European Camping Group\n570 Av. du Club Hippique\n13097 Aix-en-Provence\nFrance',
    convertPxToMm(30),
    convertPxToMm(129),
  );

  // ! SECTION
  doc.setFontSize(25);
  doc.text('Unit history', convertPxToMm(430), convertPxToMm(140));

  doc.setFontSize(10);
  doc.text(
    `Barcode : ${data.barcode}\nBrand : ${
      data.commercial_brand.lib
    }\nUnit ID : ${data._id}\nInserted at : ${dayjs(data.created_at).format(
      'llll',
    )}`,
    convertPxToMm(555),
    convertPxToMm(174),
    { align: 'right' },
  );

  // ! SECTION
  doc.setFontSize(10);
  doc.text(
    `UNIT DETAILS\nLast update date : ${dayjs(data.updated_at).format('llll')}`,
    convertPxToMm(30),
    convertPxToMm(270),
  );

  const unitFilterKeys = [
    '_id',
    'barcode',
    'id_chassis',
    'site',
    'mh_model',
    'owner',
    'commercial_brand',
    'commercial_type',
    'use_type',
    'deck_type',
    'renov_status',
    'status',
    'deck_manufacturer',
    'deck_size',
    'gas_supply_type',
    'five_year_review',
    'comment',
    'updated_by',
    'updated_at',
    'created_by',
  ];

  let unitTableData = [];
  unitFilterKeys.forEach((key, index) => {
    let name = key;
    let value = data[key];
    switch (key) {
      case '_id':
        name = 'Unit ID';
        break;
      case 'barcode':
        name = 'Barcode';
        break;
      case 'id_chassis':
        name = 'Chassis ID';
        break;
      case 'comment':
        name = 'Comment';
        break;
      case 'five_year_review':
        name = 'Five-year review';
        break;
      case 'created_by':
        name = 'Created by';
        value = data[key]?._id;
        break;
      case 'created_at':
        name = 'Created at';
        value = dayjs(data[key]).format('llll');
        break;
      case 'updated_at':
        name = 'Updated at';
        value = dayjs(data[key]).format('llll');
        break;
      case 'updated_by':
        name = 'Updated by';
        value = data[key]?._id;
        break;
      case 'site':
        name = 'Campsite';
        value = `${data[key]?.lib}\n${data[key]?.code_group}\n${
          data[key]?.gps?.lat || 'No latitude'
        }, ${data[key]?.gps?.lon || 'No longitude'}\n${
          data[key]?.loca?.city || 'No city'
        }\n${data[key]?.loca?.region || 'No region'}\n${
          data[key]?.loca?.country || 'No country'
        }`;
        break;
      case 'mh_model':
        name = 'Unit model';
        value = `${data[key]?.lib}\n${data[key]?.manufacturer?.lib}\n${data[key]?.manufacturer_model?.lib}\n${data[key]?.type?.lib}\n${data[key].vintage}`;
        break;
      case 'owner':
        name = 'Owner';
        value = data[key]?.lib;
        break;
      case 'commercial_brand':
        name = 'Commercial brand';
        value = data[key]?.lib;
        break;
      case 'commercial_type':
        name = 'Commercial type';
        value = `${data[key]?.lib}\n${data[key]?.code}`;
        break;
      case 'use_type':
        name = 'Use type';
        value = data[key]?.lib;
        break;
      case 'deck_type':
        name = 'Deck type';
        value = data[key]?.lib;
        break;
      case 'renov_status':
        name = 'Refurbishment status';
        value = data[key]?.lib;
        break;
      case 'status':
        name = 'Status';
        value = data[key]?.lib;
        break;
      case 'deck_manufacturer':
        name = 'Deck manufacturer';
        value = data[key]?.lib;
        break;
      case 'deck_size':
        name = 'Deck size';
        value = data[key]?.lib;
        break;
      case 'gas_supply_type':
        name = 'Energy supply type';
        value = data[key]?.lib;
        break;
      default:
        break;
    }

    if (value === '-' || value === null || !value || !value?.length) {
      value = '–';
    }

    unitTableData.push({
      id: index.toString(),
      Attribute: name,
      Value: value.toString(),
    });
  });

  const unitHeaders = createHeaders(['id', 'Attribute', 'Value']);
  doc.table(convertPxToMm(30), convertPxToMm(300), unitTableData, unitHeaders, {
    headerBackgroundColor: '#C2B280',
    margins: {
      top: convertPxToMm(180),
      right: 0,
      bottom: convertPxToMm(30),
      left: 0,
    },
    headerTextColor: '#FFFFFF',
    fontSize: 10,
  });

  // ! SECTION

  const transferHeaders = createHeaders([
    'id',
    'Attribute',
    'Initial state',
    'After state',
  ]);
  const transfersData = [];
  data?.transfers?.forEach((data, i) => {
    const transferData = [];
    const season = data?.season;
    const created_at = dayjs(data?.created_at).format('llll');
    const transferFilterKeys = [
      { name: 'Created by', keys: ['created_by', 'created_by'] },
      { name: 'Transfer types', keys: ['transfer_types', 'transfer_types'] },
      {
        name: 'Commercial brand',
        keys: ['commercial_brand_from', 'commercial_brand_to'],
      },
      {
        name: 'Commercial type',
        keys: ['commercial_type_from', 'commercial_type_to'],
      },
      { name: 'Campsite', keys: ['site_from', 'site_to'] },
      { name: 'Use type', keys: ['use_type_from', 'use_type_to'] },
      {
        name: 'Cladding color',
        keys: ['cladding_color_from', 'cladding_color_to'],
      },
      {
        name: 'Deck manufacturer',
        keys: ['deck_manufacturer_from', 'deck_manufacturer_to'],
      },
      { name: 'Pitch', keys: ['pitch_from', 'pitch_to'] },
      { name: 'Updated at', keys: ['updated_at', 'updated_at'] },
      { name: 'Updated by', keys: ['updated_by', 'updated_by'] },
    ];

    transferFilterKeys.forEach((obj, index) => {
      let value = data[obj.keys[0]];
      let afterValue = data[obj.keys[1]]
        ? data[obj.keys[1]]
        : data[obj.keys[0]];

      switch (obj.name) {
        case 'Transfer types':
          value = data[obj.keys[0]].map((item) => item.lib).join('\n');
          afterValue = data[obj.keys[0]].map((item) => item.lib).join('\n');
          break;
        case 'Commercial brand':
        case 'Use type':
        case 'Cladding color':
        case 'Deck manufacturer':
          value = data[obj.keys[0]]?.lib;
          afterValue = data[obj.keys[1]]?.lib;
          break;
        case 'Commercial type':
          value = data[obj.keys[0]]
            ? `${data[obj.keys[0]]?.lib}\n${data[obj.keys[0]]?.code}\n${
                data[obj.keys[0]]?.group
              }`
            : '';
          afterValue = data[obj.keys[1]]
            ? `${data[obj.keys[1]]?.lib}\n${data[obj.keys[0]]?.code}\n${
                data[obj.keys[0]]?.group
              }`
            : '';
          break;
        case 'Campsite':
          value = `${data[obj.keys[0]]?.lib}\n${
            data[obj.keys[0]]?.code_group
          }\n${data[obj.keys[0]]?.gps?.lat || 'No latitude'}, ${
            data[obj.keys[0]]?.gps?.lon || 'No longitude'
          }\n${data[obj.keys[0]]?.loca?.city || 'No city'}\n${
            data[obj.keys[0]]?.loca?.region || 'No region'
          }\n${data[obj.keys[0]]?.loca?.country || 'No country'}`;
          afterValue = `${data[obj.keys[1]]?.lib}\n${
            data[obj.keys[1]]?.code_group
          }\n${data[obj.keys[1]]?.gps?.lat || 'No latitude'}, ${
            data[obj.keys[1]]?.gps?.lon || 'No longitude'
          }\n${data[obj.keys[1]]?.loca?.city || 'No city'}\n${
            data[obj.keys[1]]?.loca?.region || 'No region'
          }\n${data[obj.keys[1]]?.loca?.country || 'No country'}`;
          break;
        case 'Created by':
          value = data[obj.keys[0]]?._id;
          afterValue = data[obj.keys[1]]?._id;
          break;
        case 'Created at':
          value = dayjs(data[obj.keys[0]]).format('llll');
          afterValue = dayjs(data[obj.keys[1]]).format('llll');
          break;
        case 'Updated by':
          value = data[obj.keys[0]]?._id;
          afterValue = data[obj.keys[1]]?._id;
          break;
        case 'Updated at':
          value = dayjs(data[obj.keys[0]]).format('llll');
          afterValue = dayjs(data[obj.keys[1]]).format('llll');
          break;
        default:
          break;
      }

      if (value === '-' || value === null || !value) {
        value = '–';
      }
      if (afterValue === '-' || afterValue === null || !afterValue) {
        afterValue = '–';
      }

      transferData.push({
        id: index.toString(),
        Attribute: obj.name,
        'Initial state': value.toString(),
        'After state': afterValue.toString(),
      });
    });

    transfersData.push({ data: transferData, details: { season, created_at } });
  });

  transfersData.forEach((transferTable) => {
    doc.addPage();
    doc.setFontSize(10);
    doc.text(
      `AMENDMENTS HISTORY\nSeason ${transferTable.details.season} - ${transferTable.details.created_at}`,
      convertPxToMm(30),
      convertPxToMm(150),
    );

    doc.table(
      convertPxToMm(30),
      convertPxToMm(180),
      transferTable.data,
      transferHeaders,
      {
        headerBackgroundColor: '#C2B280',
        margins: {
          top: convertPxToMm(180),
          right: 0,
          bottom: convertPxToMm(30),
          left: 0,
        },
        headerTextColor: '#FFFFFF',
        fontSize: 10,
      },
    );
  });

  // doc.text(`AMENDMENTS HISTORY`, convertPxToMm(30), convertPxToMm(420));

  // Page number
  const pageCount = doc.internal.getNumberOfPages(); //Total Page Number
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    // let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
    doc.setFontSize(10);
    doc.text(`${i}/${pageCount}`, convertPxToMm(550), convertPxToMm(806));

    doc.addImage(
      logoECG,
      'PNG',
      convertPxToMm(30),
      convertPxToMm(40),
      convertPxToMm(110),
      convertPxToMm(72),
    );

    // Add header background
    doc.addImage(
      headerBackground,
      'PNG',
      convertPxToMm(213),
      convertPxToMm(0),
      convertPxToMm(382),
      convertPxToMm(114),
    );
  }

  // ! PREVIEW
  // const string = doc.output('datauristring');
  // const embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  // const x = window.open();
  // x.document.open();
  // x.document.write(embed);
  // x.document.close();

  // ! DOWNLOAD
  doc.save(`${data?.barcode || data?._id}.pdf`);
};
