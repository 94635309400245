import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { getUserSetting } from '@security/User';
import dayjs from 'dayjs';
// Components
import GridButton from '../buttons/GridButton';
import Edit from '@components/icons/Edit';
import Eye from '@components/icons/Eye';
import Check from '@components/icons/Check';
import File from '@components/icons/File';
import Trash from '@components/icons/Trash';
import { useTranslation } from 'react-i18next';

export default (params) => {
  const { t } = useTranslation();

  let buttons = [];

  if (params?.methods && params?.methods?.length > 0) {
    params.methods.forEach((method, index) => {
      let labelButton;
      if (typeof method.labelButton === 'function') {
        labelButton = method.labelButton(params.node);
      } else labelButton = method.labelButton;

      const iconEdit = labelButton === t('common.action.edit', 'Edit');
      const iconFile = labelButton === t('common.action.history', 'History');
      const iconEye = labelButton === t('common.action.view', 'View');
      const iconTrash = labelButton === t('common.action.remove', 'Remove');
      buttons.push(
        <GridButton
          key={index}
          action={() => {
            ReactGA.event('grid_buttons', {
              user_interaction: `Getting access to ${labelButton}`,
              user_id: getUserSetting('_id').toString(),
              dateTime: dayjs().format(),
            });
            method.func(params?.node);
          }}
          label={labelButton}
          danger={iconTrash}
        >
          {iconEdit ? (
            <Edit color="#194989" />
          ) : iconFile ? (
            <File color="#194989" />
          ) : iconEye ? (
            <Eye color="#194989" />
          ) : iconTrash ? (
            <Trash color="var(--color-quaternary)" />
          ) : (
            <Check color="#194989" />
          )}
        </GridButton>,
      );
    });
  }

  return buttons;
};
