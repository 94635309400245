import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import dayjs from 'dayjs';
import { CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga4';
import { getUserSetting } from '@security/User';

// Layout
import Layout from '@layout/default';

// Components
import FlashMessage from '@components/common/FlashMessage';
import { Linear } from '@components/Loader';
import ColumnsDefinition from '@components/helpers/ColumnsDefinition';
import BaseButton from '@components/buttons/BaseButton';
import ExportButton from '@components/buttons/ExportButton';
import More from '@components/icons/More';
import Notification from '@components/icons/Notification';
import Excel from '@components/icons/Excel';
import Reload from '@components/icons/Reload';
import { AllowedBlock } from '@components/common/AllowedBlock';
import GridConfigs from '@components/headers/GridConfigs';
import GridViewConfig from '@components/common/dialogs/GridViewConfig';
import ScrollToTopButton from '@components/buttons/ScrollToTopButton';

// Helpers
import { logInfo } from '@helpers/logs';
import CacheManager from '@helpers/cache-helper';
import { isAllowed } from '@security/User';
import { debugMh } from '@lib/debug';
import { expandColumns, shrinkColumns } from '@components/helpers/GridHelper';

// Store
import { connect } from 'react-redux';
import getStore from '@store/store';
import { fetchAll, remove, createTerm } from '@store/actions/action-pitches';
import { clearReduxState } from '@store/actions/action-site';
import Grid from './grid/index.js';
import {
  updateFilters,
  updateColumnState,
  sendFlashMessage,
  receiveNotification,
} from '@store/actions/action-common';

import style from './style.module.scss';

const cache = new CacheManager();

class List extends Component {
  static GRID_NAME = 'pitches';
  static propTypes = {
    settings: PropTypes.shape({}),
    labels: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      transferFormOpen: false,
      mhTransferIds: [],
      expanded: true,
      list: {},
      showGridView: false,
      isFetching: true,
      hasNotification: false,
    };
    this.api = {};
    this._isMounted = true;

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onColumnStateChanged = this.onColumnStateChanged.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.getListSettings = this.getListSettings.bind(this);
    this.editRow = this.editRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.state.excelStyles = this.getExcelStyles();
    this.toggleControlsView = this.toggleControlsView.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onClearCache = this.onClearCache.bind(this);
    this.loadIndexedDB = this.loadIndexedDB.bind(this);
    this.createTermForSelectedRows = this.createTermForSelectedRows.bind(this);
  }

  getColDefs() {
    debugMh('getColDefs: this.props=', this.props);

    const listActions = [
      // {
      //   func: this.editRow,
      //   labelFunc: 'editRow',
      //   labelButton: (node) => this.props.t('common.action.edit', 'Edit'),
      // },
    ];

    isAllowed(['ROLE_ADMIN']) &&
      listActions.push({
        func: this.removeRow,
        labelFunc: 'removeRow',
        labelButton: 'Remove',
      });

    const cd = new ColumnsDefinition(
      this.props.refDatas,
      List.GRID_NAME,
      get(this.props, 'userSettings.list.pitches.filters'),
    );
    return cd.getPitchesListConfiguration(listActions, this.props.t);
  }

  getExcelStyles() {
    const cd = new ColumnsDefinition();
    return cd.getExcelStyles();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // No refresh create amendment is opened
      if (!this.props.location.hash) this.fetchData();
    }

    if (
      prevProps.receiveNotification !== this.props.receiveNotification &&
      this.props.receiveNotification !== null
    ) {
      this.setState({
        hasNotification: this.props.receiveNotification,
      });
    }
  }

  componentDidMount() {
    this.fetchData();
    this._isMounted = true;
    getStore().getState().receiveNotification !== null &&
      this.setState({
        hasNotification: getStore().getState().receiveNotification,
      });
  }

  componentWillUnmount() {
    this.setState({ list: {} });
    this.api?.destroy && this.api.destroy();
    this._isMounted = false;
  }

  async fetchData() {
    this.setState({ isFetching: true });

    cache.readData('pitchesList', (res) => {
      if (!res || !res?.data || res?.data?.length === 0 || location.search) {
        logInfo('Fetching data from API');
        sessionStorage.removeItem('pitchAwaitingRegistration');
        sessionStorage.removeItem('pitchAwaitingRemoving');

        return new Promise((resolve, reject) => {
          resolve(this.props.dispatch(fetchAll(location.search)));
        }).then((res) => {
          if (this._isMounted) {
            if (!location.search) {
              cache.writeData('pitchesList', {
                data: JSON.parse(JSON.stringify(res)),
              });
            }
            this.setState({ list: { data: res } });
            this.setState({ isFetching: false });
          }
          this.props.dispatch(
            sendFlashMessage(
              this.props.t(
                'common.flash_message.fetch_pitches',
                'Fetch pitches completed!',
              ),
              'success',
            ),
          );
        });
      } else {
        this.loadIndexedDB(res);
        sessionStorage.removeItem('pitchAwaitingRegistration');
        sessionStorage.removeItem('pitchAwaitingRemoving');
      }
    });
  }

  loadIndexedDB(res) {
    if (this._isMounted) {
      this.setState({ list: res });
      this.setState({ isFetching: false });
      logInfo('Fetching data from IndexedDB');
    }
  }

  onFilterChanged(e) {
    debugMh('onFilterChanged triggered!', e, e.api.getFilterModel());
    this.props.dispatch(updateFilters(List.GRID_NAME, e.api.getFilterModel()));
  }

  onColumnStateChanged(e) {
    debugMh('onColumnStateChanged triggered!', e, e.columnApi.getColumnState());

    this.props.dispatch(
      updateColumnState(List.GRID_NAME, e.columnApi.getColumnState()),
    );
  }

  onExport() {
    const nodeSelected = this.api.getSelectedNodes();
    const selectedColumns = this.api.columnController.columnApi
      .getAllGridColumns()
      .filter(
        (column) => column.colId !== 'selection' && column.colId !== 'actions',
      )
      .filter((column) => column.visible);

    if (selectedColumns?.length > 0) {
      const params = {
        fileName: `AssetRegister-ExportPitches-${dayjs().format(
          'YYYY.MM.DD-hh',
        )}h.xlsx`,
        sheetName: 'Pitches',
        columnKeys: selectedColumns.map((column) => column.colId),
        onlySelected: nodeSelected?.length > 0 ? true : false,
        columnGroups: true,
      };

      ReactGA.event('pitches_export', {
        user_interaction: selectedColumns
          ? 'Exporting specific pitches'
          : 'Exporting all pitches',
        count: selectedColumns > 0 ? selectedColumns : 'All',
        user_id: getUserSetting('_id').toString(),
        dateTime: dayjs().format(),
      });

      this.api.exportDataAsExcel(params);
    } else {
      this.props.dispatch(
        sendFlashMessage(
          this.props.t('common.flash_message.error', 'An error has happened'),
          'error',
        ),
      );
    }
  }

  createTermForSelectedRows() {
    const nodeSelected = this.api.getSelectedNodes();

    if (nodeSelected?.length > 0) {
      if (window.confirm('Are you sure to create a term for these rows?')) {
        const getSiteIdOfFirstElem = nodeSelected?.data?.site?._id?.toString();
        if (
          nodeSelected.every(
            (row) => row?.site?._id?.toString() === getSiteIdOfFirstElem,
          )
        ) {
          const ids = nodeSelected.map((row) => row?.data?._id);
          // this.props.dispatch(createTerm(ids));
        } else {
          this.props.dispatch(
            sendFlashMessage(
              this.props.t(
                'common.flash_message.must_select',
                "It seems every the selected rows do not have the same 'site'. Please make sure to check again.",
              ),
              'error',
            ),
          );
        }
      }
    } else {
      this.props.dispatch(
        sendFlashMessage(
          this.props.t(
            'common.flash_message.must_select',
            'An error is occurred, please make sure to select rows by clicking on the checkbox on the left side.',
          ),
          'error',
        ),
      );
    }
  }

  onAdd() {
    this.props.history.push('/pitches/create');
  }

  onReset() {
    debugMh('Reset filters and sort...');

    // ? use applyColumnState instead ?
    // this.api.setSortModel(null)
    setTimeout(() => this.api.setFilterModel(null), 150);
  }

  /**
   * Clear IndexedDB and Redux
   */
  onClearCache() {
    cache.clear();
    this.props.dispatch(clearReduxState());
    // Clear object first
    this.setState({ list: {} });
    // Then fill the object
    this.fetchData();
  }

  onGridReady(api) {
    this.api = api;
    // events : enable transfer if MH are selected
    api.addEventListener('selectionChanged', () => {
      const nbItems = api.getSelectedNodes().length;
      this.setState({
        nbItemsSelected: nbItems,
      });
    });
  }

  editRow(node) {
    // debugMh('editRow triggered!', node);
    this.props.history.push(`/pitches/${node.id}`);
  }

  removeRow(node) {
    if (confirm('Are you sure to delete this row?')) {
      this.api.applyTransaction({ remove: [node.data] });
      this.props.dispatch(remove(node.data._id));
    }
  }

  getListSettings() {
    return this.props.userSettings.list
      ? this.props.userSettings.list[List.GRID_NAME]
        ? this.props.userSettings.list[List.GRID_NAME]
        : {
            nbLines: 100,
            page: 1,
            userView: {},
            filters: {},
          }
      : {};
  }

  toggleControlsView() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    // ! Document title
    document.title = `EUROPEAN CAMPING GROUP | ${this.props.t(
      'page.pitches.document_title',
      'Pitches list',
    )}`;
    const { t, classes, ...otherProps } = this.props;
    const listSettings = this.getListSettings();
    const { expanded, showGridView, isFetching, hasNotification } = this.state;

    if (!listSettings) return <div>LOADING PITCHES...</div>;
    let content;
    if (this.state.list && this._isMounted) {
      content = (
        <div className={style['content-body']}>
          <Linear isFetching={isFetching} {...otherProps} />
          <Grid
            list={this.state.list}
            gridName={List.GRID_NAME}
            onFilterChanged={this.onFilterChanged}
            onColumnStateChanged={this.onColumnStateChanged}
            onGridReady={this.onGridReady}
            colDef={this.getColDefs()}
            excelStyles={this.state.excelStyles}
            isFetching={isFetching}
            userSettings={listSettings}
            {...otherProps}
          />
        </div>
      );
    } else {
      content = <div>:( :( :( NO RESULT OR NOT YET LOADED!</div>;
    }

    const customProps = {
      ...otherProps,
      api: this.api,
      gridName: List.GRID_NAME,
      expanded: expanded,
    };

    return (
      <Layout {...customProps}>
        <ScrollToTopButton />
        <FlashMessage />
        <header className={style['units-header']}>
          <div>
            <ExportButton
              action={() => {
                // GA
                ReactGA.event('fetch_data', {
                  user_interaction: 'Re-fetch data from pitch fees database',
                  browser: navigator.userAgent,
                  user_id: getUserSetting('_id').toString(),
                  dateTime: dayjs().format(),
                });
                this.onClearCache();
                this.props.dispatch(receiveNotification(false));
              }}
              label={t('common.action.update', 'Update now')}
              hasNotification={
                hasNotification ||
                !!sessionStorage.getItem('pitchAwaitingRegistration') ||
                !!sessionStorage.getItem('pitchAwaitingRemoving')
              }
            >
              {hasNotification ||
              !!sessionStorage.getItem('pitchAwaitingRegistration') ||
              !!sessionStorage.getItem('pitchAwaitingRemoving') ? (
                <Notification />
              ) : (
                <Reload color="#194989" />
              )}
            </ExportButton>
            <AllowedBlock roles={'ROLE_EXP_LIST'}>
              <ExportButton
                action={() => {
                  this.onExport();
                }}
                label={t('common.action.export_excel', 'Export to excel')}
                description={`${
                  this.state.nbItemsSelected && this.state.nbItemsSelected > 0
                    ? `${t('common.label.export', 'Export')} ${
                        this.state.nbItemsSelected
                      } ${t('common.label.row', 'row')}${
                        this.state.nbItemsSelected > 1 ? 's' : ''
                      }`
                    : ''
                } `}
              >
                <Excel />
              </ExportButton>
            </AllowedBlock>
          </div>
          <div className={style['units-header-add']}>
            <AllowedBlock roles={'ROLE_CREATE_MH'}>
              <BaseButton
                action={() => {
                  // GA
                  ReactGA.event('pitch_fees', {
                    user_interaction: 'Adding a pitch fee',
                    user_id: getUserSetting('_id').toString(),
                    dateTime: dayjs().format(),
                  });

                  this.onAdd();
                }}
                label={t('common.action.create_pitch', 'Add a new pitch')}
              >
                <More color="#194989" />
              </BaseButton>
            </AllowedBlock>
          </div>
        </header>
        <div className={style['content-container']}>
          <div className={style['content-container-header']}>
            <GridConfigs
              api={this.api}
              expandColumns={expandColumns}
              shrinkColumns={shrinkColumns}
              showGridView={() =>
                this.setState({ showGridView: !this.state.showGridView })
              }
              onReset={this.onReset}
            />
          </div>
          <div id="wrapper" hidden={this.state.transferFormOpen}>
            {content}
          </div>
        </div>

        <CSSTransition
          in={showGridView}
          classNames="slideBottom"
          timeout={300}
          unmountOnExit
        >
          <GridViewConfig
            handleClose={() => {
              this.setState({ showGridView: !this.state.showGridView });
            }}
            {...this.props}
            {...customProps}
          />
        </CSSTransition>
      </Layout>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user,
    userSettings: store.userSettings,
    refDatas: store.refDatas.data,
    receiveNotification: store.receiveNotification,
  };
};

export default withTranslation()(connect(mapStateToProps)(List));
