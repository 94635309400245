export default (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.51867 11.3604L2.4645 7.30622C2.41589 7.25761 2.38186 7.20899 2.36242 7.16038C2.34297 7.11177 2.33325 7.0583 2.33325 6.99997C2.33325 6.94163 2.34297 6.88816 2.36242 6.83955C2.38186 6.79094 2.41589 6.74233 2.4645 6.69372L6.53325 2.62497C6.61103 2.54719 6.70825 2.5083 6.82492 2.5083C6.94159 2.5083 7.04367 2.55205 7.13117 2.63955C7.21867 2.72705 7.26242 2.82913 7.26242 2.9458C7.26242 3.06247 7.21867 3.16455 7.13117 3.25205L3.82075 6.56247H11.0541C11.1805 6.56247 11.285 6.60379 11.3676 6.68643C11.4503 6.76906 11.4916 6.87358 11.4916 6.99997C11.4916 7.12636 11.4503 7.23087 11.3676 7.31351C11.285 7.39615 11.1805 7.43747 11.0541 7.43747H3.82075L7.14575 10.7625C7.22353 10.8402 7.26242 10.9375 7.26242 11.0541C7.26242 11.1708 7.21867 11.2729 7.13117 11.3604C7.04367 11.4479 6.94159 11.4916 6.82492 11.4916C6.70825 11.4916 6.60617 11.4479 6.51867 11.3604Z"
        fill={props.color ? props.color : '#194989'}
      />
    </svg>
  );
};
