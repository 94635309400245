// Styles for Select list components

// Groups
export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
export const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

// Styles for floating filters - multivalues - in columns header
export const filterStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontSize: '0.8em',
    lineHeight: '1em',
    padding: '6px 8px',
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    margin: 1,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    lineHeight: 1,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: 'dark',
    ':hover': {
      color: 'white',
    },
    padding: 0,
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    padding: '2px 4px',
    overflowY: 'scroll',
    height: 38,
  }),
  clearIndicator: (styles, { data }) => ({
    ...styles,
    padding: 0,
    display: 'none'
  }),
  dropdownIndicator: (styles, { data }) => ({
    ...styles,
    padding: 0,
    display: 'none'
  }),
}

// Styles for Select lists in the grid
export const gridSelectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    minHeight: 24,
    borderRadius: 0,
    borderWidth: 0,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontSize: '0.8em',
    lineHeight: '1em',
    padding: '6px 8px',
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    padding: '2px 4px',
    height: 24,
  }),
  clearIndicator: (styles, { data }) => ({
    ...styles,
    padding: 0,
  }),
  dropdownIndicator: (styles, { data }) => ({
    ...styles,
    padding: 0,
  }),
}
