export default (props) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.color ? props.color : '#FFF'}
      d="M0 20V6.66667L8.94444 0L17.7778 6.66667V20H11.2778V12.0833H6.47222V20H0ZM1.66667 18.3333H4.80555V10.4167H12.9444V18.3333H16.1111V7.5L8.94444 2.08333L1.66667 7.5V18.3333Z"
    />
  </svg>
);
