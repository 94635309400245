'use strict';

if (!window.indexedDB) {
  window.alert("Votre navigateur ne supporte pas une version stable d'IndexedDB. Quelques fonctionnalités ne seront pas disponibles.")
}


// TODO: Finish to implément idb to store all data (rows) from request
// import { openDB, deleteDB, wrap, unwrap } from 'idb';

// const STORE_NAME = 'MyTestDatabase';


// const dbPromise = openDB(STORE_NAME, 1, {
//   upgrade(db) {
//     db.createObjectStore('keyval');
//   },
//   blocked() {
//     // …
//     console.log('JYO: idb: blocked: not yet implemented');
//   },
//   blocking() {
//     // …
//     console.log('JYO: idb: blocking: not yet implemented');
//   },
//   terminated() {
//     // …
//     console.log('JYO: idb: terminated: not yet implemented');
//   }
// });

// export async function get(key) {
//   return (await dbPromise).get('keyval', key);
// }
// export async function set(key, val) {
//   return (await dbPromise).put('keyval', val, key);
// }
// export async function del(key) {
//   return (await dbPromise).delete('keyval', key);
// }
// export async function clear() {
//   return (await dbPromise).clear('keyval');
// }
// export async function keys() {
//   return (await dbPromise).getAllKeys('keyval');
// }


// set('testKey', 'testValue');
// get('testKey').then(value => console.log('get TestKey: ', value));

// let db;
// const request = indexedDB.open("MyTestDatabase");

// request.onerror = function(event) {
//   console.log("Why didn't you allow my web app to use IndexedDB?!");
// };

// request.onsuccess = function(event) {
//   db = event.target.result;
// };

//
// Tiny Redux Middleware to save the current redux store into sessionStorage
// and restore it if the browser is refreshed or closed.
// Could use https://github.com/rt2zz/redux-persist.
//
export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return {}
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return {}
  }
}

export const saveState = state => {
  try {
    if (state) {
      sessionStorage.setItem('state', JSON.stringify(state));
    }
  } catch (err) {
    console.dir(err);
  }
}