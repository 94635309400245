export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.58337 18.3333C4.23615 18.3333 3.94101 18.2117 3.69796 17.9687C3.4549 17.7256 3.33337 17.4305 3.33337 17.0833V8.04158C3.33337 7.69436 3.4549 7.39922 3.69796 7.15617C3.94101 6.91311 4.23615 6.79158 4.58337 6.79158H12.7084V4.79159C12.7084 4.04159 12.4445 3.4027 11.9167 2.87492C11.3889 2.34714 10.75 2.08325 10 2.08325C9.34726 2.08325 8.77782 2.2777 8.29171 2.66659C7.8056 3.05547 7.4931 3.55547 7.35421 4.16659C7.31254 4.34714 7.22921 4.49645 7.10421 4.6145C6.97921 4.73256 6.83337 4.79159 6.66671 4.79159C6.48615 4.79159 6.33685 4.72909 6.21879 4.60409C6.10074 4.47909 6.0556 4.33325 6.08337 4.16659C6.23615 3.20825 6.67712 2.41311 7.40629 1.78117C8.13546 1.14922 9.00004 0.833252 10 0.833252C11.0973 0.833252 12.0313 1.21867 12.8021 1.9895C13.573 2.76034 13.9584 3.69436 13.9584 4.79159V6.79158H15.4167C15.7639 6.79158 16.0591 6.91311 16.3021 7.15617C16.5452 7.39922 16.6667 7.69436 16.6667 8.04158V17.0833C16.6667 17.4305 16.5452 17.7256 16.3021 17.9687C16.0591 18.2117 15.7639 18.3333 15.4167 18.3333H4.58337ZM4.58337 17.0833H15.4167V8.04158H4.58337V17.0833ZM10 14.1666C10.4445 14.1666 10.823 14.0138 11.1355 13.7083C11.448 13.4027 11.6042 13.0346 11.6042 12.6041C11.6042 12.1874 11.448 11.8089 11.1355 11.4687C10.823 11.1284 10.4445 10.9583 10 10.9583C9.5556 10.9583 9.17712 11.1284 8.86462 11.4687C8.55212 11.8089 8.39587 12.1874 8.39587 12.6041C8.39587 13.0346 8.55212 13.4027 8.86462 13.7083C9.17712 14.0138 9.5556 14.1666 10 14.1666ZM4.58337 8.04158V17.0833V8.04158Z"
      fill={props.color ? props.color : '#6D8B74'}
    />
  </svg>
);
