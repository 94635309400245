import React from 'react';
import { useState } from 'react';
import Log from './Log';
import ContentHeader from '@components/headers/ContentHeader';
import History from '@components/icons/History';
import Reload from '@components/icons/Reload';
import ReactGA from 'react-ga4';
import BaseButton from '@components/buttons/BaseButton';
import { fetchLogs } from '@store/actions/action-dashboard';

import style from './style.module.scss';

const Logs = (props) => {
  const { t } = props;
  const [isRefreshingLogs, setIsRefreshingLogs] = useState(false);
  const [logs, setLogs] = useState(props.logs);

  async function reloadLogs() {
    const req = await props.dispatch(fetchLogs());
    setLogs(req);
    setIsRefreshingLogs(true);

    setTimeout(() => {
      setIsRefreshingLogs(false);
    }, 2000);
  }

  return (
    <div className={style['logs-wrapper']}>
      <ContentHeader
        icon={History}
        title={t('page.homepage.latest_logs.title', 'Latest logs')}
        subtitle={t(
          'page.homepage.latest_logs.subtitle',
          'Maximum display 100',
        )}
        light={true}
      />
      <div className={style['logs']}>
        <div className={style['reloading']}>
          <BaseButton
            action={() => {
              // GA
              ReactGA.event('logs_panel', {
                category: 'Fetching the latest logs',
              });

              reloadLogs();
            }}
            label={
              isRefreshingLogs
                ? t('page.homepage.buttons.fetching', 'Fetching now')
                : t('page.homepage.buttons.reload', 'Reload now')
            }
            disabled={isRefreshingLogs ? true : false}
          >
            <Reload color="#194989" />
          </BaseButton>
        </div>

        <ul>
          {Array.isArray(props?.logs) &&
            props?.logs?.map((log, index) => (
              <Log log={log} key={index} {...props} />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Logs;
