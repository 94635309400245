import MainHeader from '@components/pwa/MainHeader';
import Carousel from '@components/pwa/carousels/History';

import dayjs from 'dayjs';
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

import style from './style.module.scss';

function compare(a, b) {
  if (a.date > b.date) {
    return -1;
  }
  if (a.date < b.date) {
    return 1;
  }
  return 0;
}

export default (props) => {
  const { histories, close } = props;

  return histories ? (
    <div className={style['unit-history']}>
      <MainHeader title={'F2M'} action={() => close()} />

      <div className={style['unit-history-wrapper']}>
        <div className={style['carousel-history-wrapper']}>
          {histories.sort(compare).map((item, index) => (
            <div className={style['carousel-history-spacing']}>
              <Carousel item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};
