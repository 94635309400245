export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.32713 11.0833C1.12296 11.0833 0.974698 11.0152 0.882337 10.8791C0.789976 10.743 0.782684 10.5777 0.860462 10.3833L3.58755 3.45621C3.64588 3.31038 3.75039 3.18399 3.90109 3.07704C4.05178 2.9701 4.20977 2.91663 4.37505 2.91663C4.54032 2.91663 4.69831 2.9701 4.849 3.07704C4.9997 3.18399 5.10421 3.31038 5.16255 3.45621L7.88963 10.3833C7.96741 10.5777 7.96011 10.743 7.86775 10.8791C7.77539 11.0152 7.62227 11.0833 7.40838 11.0833C7.31116 11.0833 7.2188 11.0541 7.1313 10.9958C7.0438 10.9375 6.98546 10.8645 6.9563 10.777L6.24171 8.89579H2.50838L1.7938 10.777C1.76463 10.8645 1.70386 10.9375 1.6115 10.9958C1.51914 11.0541 1.42435 11.0833 1.32713 11.0833ZM2.85838 7.96246H5.89171L4.40421 4.01038H4.34588L2.85838 7.96246ZM9.33338 7.43746C9.20699 7.43746 9.10248 7.39614 9.01984 7.3135C8.9372 7.23086 8.89588 7.12635 8.89588 6.99996C8.89588 6.87357 8.9372 6.76906 9.01984 6.68642C9.10248 6.60378 9.20699 6.56246 9.33338 6.56246H12.9792C13.1056 6.56246 13.2101 6.60378 13.2928 6.68642C13.3754 6.76906 13.4167 6.87357 13.4167 6.99996C13.4167 7.12635 13.3754 7.23086 13.2928 7.3135C13.2101 7.39614 13.1056 7.43746 12.9792 7.43746H9.33338Z"
      fill={props.color ? props.color : '#333333'}
    />
  </svg>
);
