import style from './style.module.scss';

export default (props) => {
  const { width, height } = props;
  return (
    <div
      className={style['placeholder-element']}
      style={{
        width: width + 'px',
        height: height === 'fullHeight' ? '100%' : height + 'px',
      }}
    >
      <div className={style['animated-background']}></div>
    </div>
  );
};
