//
// MOVEMENTS List Grid
// Use ag-grid.com
//
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseGrid from '@components/BaseGrid';
import { submitSaveMv } from './MvSubmit';
import RefDatas from '@lib/RefDatas';
import { debugMv } from '@lib/debug';

import getStore from '@store/store';
import { withTranslation } from 'react-i18next';

class MvGrid extends PureComponent {
  static propTypes = {
    userSettings: PropTypes.object,
    history: PropTypes.object,
    mvList: PropTypes.shape({
      data: PropTypes.array,
      metas: PropTypes.object,
    }),
    gridName: PropTypes.string.isRequired,
    refDatas: PropTypes.object,
    colDef: PropTypes.array,
    onSortChanged: PropTypes.func,
    onFilterChanged: PropTypes.func,
    excelStyle: PropTypes.array,
    // Mutualized event listener on column state changed.
    onColumnStateChanged: PropTypes.func,
    onGridReady: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      fieldMapping: {
        'status_physical_amend.lib': 'RefStatusPhyAmend',
        'status_comm_amend.lib': 'RefStatusCommAmend',
        'status_refurb.lib': 'RefStatusRefurbAmend',
        'status_deck_replacement.lib': 'RefStatusDeckReplacement',
        'site_to.lib': 'RefSite',
        'commercial_brand_to.lib': 'RefMarqueCommerciale',
        'commercial_type_to.lib': 'RefTypeCommercial',
        'use_type_to.lib': 'RefTypeUtilisation',
        'deck_type_to.lib': 'RefTypeTerrasse',
        'deck_size_to.lib': 'RefTailleTerrasse',
        'deck_manufacturer_to.lib': 'RefDeckManufacturer',
        'cladding_color_to.lib': 'RefCladdingColor',
        'gas_supply_type_to.lib': 'RefGasSupplyType',
        'contract_manager_to.lib': 'RefContractManager',
        'connections.aircon.disconn.status.lib': 'RefConnectionStatus',
        'connections.deck.disconn.status.lib': 'RefConnectionStatus',
        'connections.deck.disposal.status.lib': 'RefConnectionStatus',
        'connections.deck.transport.status.lib': 'RefConnectionStatus',
        'connections.mh.disconn.status.lib': 'RefConnectionStatus',
        'connections.mh.transport.status.lib': 'RefConnectionStatus',
        'connections.mh.transport_bis.status.lib': 'RefConnectionStatus',
        'connections.mh.reconn.status.lib': 'RefConnectionStatus',
        'connections.deck.reconn.status.lib': 'RefConnectionStatus',
        'connections.aircon.reconn.status.lib': 'RefConnectionStatus',
      },
    };

    this.editRow = this.editRow.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.undoLastEdit = this.undoLastEdit.bind(this);
  }

  onGridReady(params) {
    this.api = params.api;
    // this.columnApi = params.columnApi
    this.props.onGridReady(params.api);
  }

  editRow(node) {
    debugMv('editRow triggered!', node._id);
    this.props.history.push(`/mv/${node._id}`);
  }

  onCellValueChanged(e) {
    debugMv('MvGrid => onCellValueChanged triggered!', e);

    // build params for submit
    const values = {
      _id: e.data._id,
      [e.colDef.field]: e.value,
    };

    // console.log('JYO: getStore().getState().refDatas: ', getStore().getState().refDatas);
    // console.log('JYO: e.colDef: ', e.colDef);
    // console.log('JYO: e?.colDef?.hasOwnProperty(cellEditorSelector): ', e?.colDef?.hasOwnProperty('cellEditorSelector'));
    // console.log('JYO: this.state.fieldMapping.hasOwnProperty(e.colDef.field): ', this.state.fieldMapping.hasOwnProperty(e.colDef.field));

    if (
      e?.colDef?.hasOwnProperty('cellEditorSelector') &&
      this.state.fieldMapping.hasOwnProperty(e.colDef.field)
    ) {
      const refData = this.state.fieldMapping[e?.colDef?.field];
      console.log('JYO: refData: ', refData);

      const flatRefData = getStore()
        ?.getState()
        ?.refDatas?.data?.[refData]?.flatMap?.((elem) => {
          if (elem.options) {
            return elem.options.map((subElem) => subElem);
          } else {
            return elem;
          }
        });

      const result = flatRefData.find((elem) => {
        if (elem.options) {
          const tempResult = elem.options.find(
            (subElem) => subElem.label === e.value,
          );
          if (tempResult) {
            return tempResult.val;
          }
        } else {
          return elem.label === e.value;
        }
      });

      console.log('result: ', result);

      if (result) {
        let fieldName = e.colDef.field;
        if (e.colDef.field.includes('.lib')) {
          fieldName = e.colDef.field.replace('.lib', '');
        } else if (e.colDef.field.includes('.nom')) {
          fieldName = e.colDef.field.replace('.nom', '');
        }
        values[fieldName] = result.val;
      }
    }

    debugMv(
      'MvGrid => onCellValueChanged triggered, values =',
      JSON.stringify(values),
    );

    // call server and on callback, flash cell to indicate that it as been updated
    return submitSaveMv(values, this.props.t).then(() => {
      if (RefDatas.GROUPED_REF_DATA.includes(e.colDef.refData)) {
        // FIXME bug connu : il faudrait fetcher toute la ligne pour récupérer l'info des colonnes calculées :
        // commercial_type.parent_lib, commercial_type.group, commercial_type.is_terrasse, commercial_type.is_clim, commercial_type.is_empl_premium
        // debugMh('this.api.refreshCells({ rowNodes: [e.node] }): ', e.node)
        return (
          !e.api.destroyCalled &&
          e.api &&
          e.api.refreshCells({ rowNodes: [e.node] })
        );
      } else {
        return (
          e.api && e.api.flashCells({ rowNodes: [e.node], columns: [e.column] })
        );
      }
    });
  }

  // Undo the last edit with provided datas = submit Save with old values...
  undoLastEdit(data) {
    // debugMh('undoLastEdit triggered, data =', data)
    const value = {
      _id: data._id,
      [data.field]: data.oldValue,
    };
    debugMv('MvGrid: undoLastEdit => value to undo: ', value);

    // on callback, refresh values in grid
    return submitSaveMv(value, this.props.t).then(() => {
      const rowNode = this.api.getRowNode(data._id);
      rowNode.setDataValue(data.field, data.oldValue);
      // FIXME bug connu : il faudrait fetcher toute la ligne pour récupérer l'info des colonnes calculées...

      return (
        !this.api.destroyCalled &&
        this.api.refreshCells({ rowNodes: [rowNode], columns: [data.field] })
      );
    });
  }

  render() {
    // debugMv('render => this.props.refDatas: ', this.props.refDatas)
    const gridName = this.props.gridName;
    const { data } = this.props.mvList;
    debugMv('this.props.mvList: ', this.props.mvList);

    /*<div className="ag-theme-material">*/
    // https://github.com/ag-grid/ag-grid-docs/blob/master/src/example.js
    // debugMv('this.props.onCellValueChanged: ', this.props.onCellValueChanged)
    return (
      <BaseGrid
        gridName={gridName}
        userListSettings={this.props.userSettings.list[gridName]}
        columnDefs={this.props.colDef}
        rowData={data}
        excelStyles={this.props.excelStyles}
        isFetching={this.props.isFetching}
        onFilterChanged={this.props.onFilterChanged}
        onColumnStateChanged={this.props.onColumnStateChanged}
        onCellValueChanged={this.onCellValueChanged}
        onGridReady={this.onGridReady}
        undo={this.undoLastEdit}
        getRowClass={(params) => {
          // debugMv(params)
          const classes = [];
          if (params.data && params.data.locked) {
            classes.push('locked');
          }
          // if (params.data && params.data.transfer_status) {
          //   classes.push(params.data.transfer_status._id === config.REF_STATUT_TRANFER.valide ? 'complete' : '')
          // }

          return classes;
        }}
      />
    );
  }
}
export default withTranslation()(MvGrid);
