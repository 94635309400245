export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.125 17.0209L11.1042 12C10.6875 12.3611 10.2014 12.6424 9.64583 12.8438C9.09028 13.0452 8.5 13.1459 7.875 13.1459C6.375 13.1459 5.10417 12.625 4.0625 11.5834C3.02083 10.5417 2.5 9.28474 2.5 7.81252C2.5 6.3403 3.02083 5.08335 4.0625 4.04169C5.10417 3.00002 6.36806 2.47919 7.85417 2.47919C9.32639 2.47919 10.5799 3.00002 11.6146 4.04169C12.6493 5.08335 13.1667 6.3403 13.1667 7.81252C13.1667 8.40974 13.0694 8.98613 12.875 9.54169C12.6806 10.0972 12.3889 10.6181 12 11.1042L17.0625 16.125C17.1875 16.2361 17.25 16.3785 17.25 16.5521C17.25 16.7257 17.1806 16.882 17.0417 17.0209C16.9167 17.1459 16.7639 17.2084 16.5833 17.2084C16.4028 17.2084 16.25 17.1459 16.125 17.0209ZM7.85417 11.8959C8.97917 11.8959 9.9375 11.4965 10.7292 10.6979C11.5208 9.89932 11.9167 8.93752 11.9167 7.81252C11.9167 6.68752 11.5208 5.72571 10.7292 4.9271C9.9375 4.12849 8.97917 3.72919 7.85417 3.72919C6.71528 3.72919 5.74653 4.12849 4.94792 4.9271C4.14931 5.72571 3.75 6.68752 3.75 7.81252C3.75 8.93752 4.14931 9.89932 4.94792 10.6979C5.74653 11.4965 6.71528 11.8959 7.85417 11.8959Z"
      fill={props?.color ? props.color : '#333333'}
    />
  </svg>
);
