import style from './style.module.scss';

export default (props) => {
  const { items, updateIndex, currentIndex } = props;
  return (
    <nav className={style['base-navigation']}>
      <ul>
        {items.map((item, index) => (
          <li
            key={index}
            className={currentIndex === index ? style['active'] : undefined}
          >
            <button type="button" onClick={() => updateIndex(index)}>
              {item}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};
