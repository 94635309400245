const countriesJSON = require('@assets/json/countries.json');

export function expandColumns(api) {
  let allColumnIds = [];
  // get all displayed columns
  api.gridCore.columnApi.getAllColumns().forEach(function (column) {
    if (column.colId !== 'selection' && column.colId !== 'actions')
      allColumnIds.push(column.colId);
  });
  api.gridCore.columnApi.autoSizeColumns(allColumnIds, true);
}

//this.props.api.gridCore.columnApi
// reduce column size to see all in screen
export function shrinkColumns(api) {
  api.sizeColumnsToFit();
}

export function prettyBrand(brand) {
  if (brand) {
    switch (brand.toLowerCase().replace(' ', '').trim()) {
      case 'homair':
      case 'palmiers':
      case 'vacanceselect':
        return `France`;
      case 'eurocamp':
      case 'alfresco':
        return `United Kingdom`;
      case 'roan':
      case 'go4camp':
        return `Holland`;
      default:
        return `${brand}`;
    }
  }
  return '';
}

export function prettyTag(type) {
  let className = null;

  if (type) {
    switch (type.toLowerCase().replaceAll(' ', '').trim()) {
      case 'sold':
      case 'destroyed':
      case 'locked':
        className = 'tag-cell-4';
        break;
      case 'mini-club':
      case 'out-resident':
      case 'reception':
      case 'recp/ staff':
      case 'resident':
      case 'staff':
      case '1.ongoing':
      case 'onsale':
      case 'confirmedonsale':
        className = 'tag-cell-1';
        break;
      case 'stock':
      case 'unused':
        className = 'tag-cell-2';
        break;
      case 'rental':
      case 'complete':
      case '2.complete':
      case 'pass':
      case 'forsale':
        className = 'tag-cell-3';
        break;
      case '3.cancelled':
        className = 'tag-cell-5';
        break;
      default:
        className = 'tag-cell-2';
        break;
    }
  }
  return className;
}

export function prettyCountry(country) {
  const simpleString = (string) => {
    return string.toLowerCase().replace(' ', '').trim();
  };

  const countryFound = countriesJSON.find(
    (countryJSON) => simpleString(countryJSON.name) === simpleString(country),
  );

  return `${countryFound ? countryFound.code.toLowerCase() : ''}`;
}

export function prettyStatus(status) {
  if (!status) return;
  const simpleString = (string) => {
    return string.toLowerCase().replace(' ', '').trim();
  };
  switch (simpleString(status)) {
    case 'active':
      return `${status}`;
    case 'ghost':
      return `${status}`;
    case 'pendingmovement':
      return `${status}`;
    case 'pendingconfirmation':
      return `${status}`;
    default:
      return `${status}`;
  }
}
