import localforage from 'localforage';
import { isArray } from 'lodash';
import { logInfo } from './logs';

let updateTimer = null;
let waitingForUpdating = [];
export default class CacheManager {
  getKeys = () => localforage.keys();
  writeData = (key, data) => localforage.setItem(key, data);
  readData = (key, callback) =>
    localforage.getItem(key).then((res) => callback && callback(res));
  removeData = (key) => localforage.removeItem(key);
  clear = () => {
    logInfo('indexedDB cleared');
    localforage.clear();

    // ! Clean Session Storage
    sessionStorage.removeItem('unitAwaitingRegistration');
    sessionStorage.removeItem('unitAwaitingRemoving');
    sessionStorage.removeItem('transferAwaitingRegistration');
    sessionStorage.removeItem('transferAwaitingRemoving');
    sessionStorage.removeItem('pitchFeesAwaitingRegistration');
    sessionStorage.removeItem('pitchFeesAwaitingRemoving');
    sessionStorage.removeItem('pitchAwaitingRegistration');
    sessionStorage.removeItem('pitchAwaitingRemoving');
  };

  /**
   * @param {string} key key value in indexedDB
   * @param {object} data edited item
   * @param {function} callback trigger when data is deleted
   */
  updateData = (key, data, callback) => {
    data._id = data._id.toString();

    // Work only if the key exists in IndexedDB
    localforage.getItem(key).then((item) => {
      if (item) {
        const index = item.data.findIndex((row) => row._id === data._id);
        waitingForUpdating.push({ index, data });
        clearTimeout(updateTimer);
        updateTimer = setTimeout(() => {
          waitingForUpdating.forEach((itemToUpdate) => {
            item.data[itemToUpdate?.index] = itemToUpdate?.data;
          });
          waitingForUpdating = [];
          localforage.setItem(key, item).then(() => callback && callback(item));
        }, 5000);
      } else {
        callback && callback();
      }
    });
  };

  /**
   * @param {string} key key value in indexedDB
   * @param {string} barcode barcode item unique
   * @param {function} callback trigger when data is deleted
   */
  deleteData = (
    { key, season, transfer_id, mobilhome_id, basic_id },
    callback,
  ) => {
    localforage.getItem(key).then((item) => {
      localforage
        .setItem(key, {
          // Delete the main based on ID
          data: item?.data?.filter((row) => {
            if (key === 'mvList') {
              return transfer_id?.toString() !== row._id.toString()
                ? row
                : false;
            } else if (key === 'mhList') {
              if (mobilhome_id?.toString() !== row._id.toString()) {
                return row;
              } else {
                false;
              }
            } else {
              return row._id !== basic_id;
            }
          }),
        })
        .then(() => {
          localforage
            .getItem(key === 'mvList' ? 'mhList' : 'mvList')
            .then((item) => {
              if (item) {
                key === 'mvList'
                  ? localforage.getItem('mhList').then((item) => {
                      item &&
                        localforage
                          .setItem('mhList', {
                            data: item?.data?.filter(
                              (unit) =>
                                !unit?.transfer_id.includes(transfer_id) &&
                                unit?.ghost,
                            ),
                          })
                          .then(() => callback && callback());
                    })
                  : localforage.getItem('mvList').then((item) => {
                      localforage
                        .setItem('mvList', {
                          data: (item.data = item?.data?.filter((transfer) => {
                            return (
                              transfer.mobilhome_id.toString() !==
                              mobilhome_id?.toString()
                            );
                          })),
                        })
                        .then(() => callback && callback());
                      // TODO : DELETE UNIT GHOST
                    });
              } else callback && callback();
            })
            .then(() => callback && callback());
        });
      // }
    });
  };

  /**
   * @param {string} key key value in indexedDB
   * @param {object} data added item
   * @param {function} callback trigger when data is deleted
   */
  addData = (key, data, callback) => {
    switch (key) {
      case 'mvList':
        // {[jsonArray]}
        localforage.getItem(key).then((item) => {
          if (item) {
            localforage
              .setItem(key, {
                data: [...item.data, ...data],
              })
              .then(() => callback && callback(data));
          } else callback && callback();
        });
        break;
      case 'mhList':
        let counter = 0;
        localforage.getItem(key).then((item) => {
          if (item) {
            const newValues = [...item.data];
            const _data = data?.units ? data.units : data.ghosts;

            if (isArray(_data)) {
              _data.forEach((ghost) => {
                const index = newValues.findIndex((row) =>
                  data?.units
                    ? row._id.toString() === ghost
                    : row.barcode === ghost.barcode,
                );

                newValues[index].ongoingAmendment = true;
                newValues[index].status.lib = 'Pending Movement';

                if (data?.ghosts) {
                  newValues.push(ghost);
                }

                localforage
                  .setItem(key, {
                    data: newValues,
                  })
                  .then(() => {
                    counter += 1;
                    if (counter === _data.length)
                      callback && callback(newValues);
                  });
              });
            } else {
              newValues.push(data);
              localforage
                .setItem(key, {
                  data: newValues,
                })
                .then(() => callback && callback(newValues));
            }
          } else {
            {
              callback && callback();
            }
          }
        });
        break;
      default:
        break;
    }
  };
}
