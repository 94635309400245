// import isEqual from 'lodash/isEqual';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSiteList, clearReduxState } from '@store/actions/action-site';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import { CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga4';
import { getUserSetting } from '@security/User';

// Layout
import Layout from '@layout/default';

// Components
import SiteGrid from '../SiteGrid';
import { Linear } from '@components/Loader';
import FlashMessage from '@components/common/FlashMessage';
import { AllowedBlock } from '@components/common/AllowedBlock';
import ExportButton from '@components/buttons/ExportButton';
import Excel from '@components/icons/Excel';
import BaseButton from '@components/buttons/BaseButton';
import Reload from '@components/icons/Reload';
import Earth from '@components/icons/Earth';
import List from '@components/icons/List';
import GridConfigs from '@components/headers/GridConfigs';
import GridViewConfig from '@components/common/dialogs/GridViewConfig';
import ScrollToTopButton from '@components/buttons/ScrollToTopButton';
import CampsitesMap from '@pages/site/SiteList/CampsitesMap';

// Store
import {
  setNbLines,
  setCurrentPage,
  updateFilters,
  updateColumnState,
} from '@store/actions/action-common';
import getStore from '@store/store';

// Cache
import CacheManager from '@helpers/cache-helper';

import { debugSite } from '@lib/debug';
import ColumnsDefinition from '@components/helpers/ColumnsDefinition';
import { expandColumns, shrinkColumns } from '@components/helpers/GridHelper';

// Helpers
const cache = new CacheManager();

import style from './style.module.scss';

class SiteList extends Component {
  static GRID_NAME = 'sites';
  static propTypes = {
    settings: PropTypes.shape({}),
    labels: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: true,
      showGridView: false,
      isFetching: true,
      showMap: false,
    };
    this.api = {};

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onColumnStateChanged = this.onColumnStateChanged.bind(this);
    this.onPaginationChanged = this.onPaginationChanged.bind(this);
    this.onNbLinesChanged = this.onNbLinesChanged.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.getListSettings = this.getListSettings.bind(this);
    this.onQuickFilterChanged = this.onQuickFilterChanged.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onClearCache = this.onClearCache.bind(this);
    this.editRow = this.editRow.bind(this);
    this.toggleControlsView = this.toggleControlsView.bind(this);
    this.validateData = this.validateData.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  debouncedFetchSiteList = debounce(() => {
    this.props.dispatch(
      fetchSiteList({
        listSettings: this.props.userSettings.list[SiteList.GRID_NAME],
      }),
    );
  }, 200);

  getColDefs() {
    debugSite('getColDefs: this.props=', this.props);

    const cd = new ColumnsDefinition(
      this.props.refDatas,
      SiteList.GRID_NAME,
      this.props.userSettings.list.sites
        ? this.props.userSettings.list.sites.filters
        : {},
    );

    const listActions = [
      {
        func: this.editRow,
        labelFunc: 'editRow',
        labelButton: this.props.t('common.action.view', 'View'),
      },
    ];

    // TODO: Enable validateData for later
    // if (isAllowed('ROLE_ADMIN')) {
    //   listActions.push({
    //     func: this.validateData,
    //     labelFunc: 'validateData',
    //     labelButton: 'Validate',
    //   });
    // }

    return cd.getSiteListConfiguration(listActions, this.props.t);
  }

  componentDidMount() {
    debugSite('SiteList => componentDidMount: ');
    this.fetchData();
  }

  async fetchData() {
    const newListSettings =
      this.props.userSettings.list[SiteList.GRID_NAME] || {};
    this.setState({ list: newListSettings });

    // siteList is not defined in the indexedDB Storage or has not data in 'data' key
    if (
      !getStore().getState().siteList ||
      getStore().getState().siteList?.data.length === 0
    ) {
      // console.info('We are now fetching data from Reducers');
      await this.props.dispatch(
        fetchSiteList({ listSettings: newListSettings }),
      );
      this.setState({ isFetching: false });
    } else {
      // console.info('We are now using Redux Store');
      this.setState({ isFetching: false });
    }
  }

  // componentDidUpdate(prevProps) {
  //   const oldListSettings = prevProps.userSettings.list[SiteList.GRID_NAME] || {}
  //   const newListSettings = this.props.userSettings.list[SiteList.GRID_NAME] || {}
  //   // Fetch the results if interesting stuff has changed...
  //   if (
  //     // !newListSettings ||
  //     // !oldListSettings ||
  //     !isEqual(oldListSettings.page, newListSettings.page) ||
  //     !isEqual(oldListSettings.sort, newListSettings.sort) ||
  //     !isEqual(oldListSettings.filters, newListSettings.filters) ||
  //     !isEqual(oldListSettings.nbLines, newListSettings.nbLines)
  //   ) {
  //     debugSite(prevProps, 'FETCHING DATA AS SOME FILTERS/SORTS/SETTINGS HAS CHANGED!')
  //     this.debouncedFetchSiteList()
  //   }
  // }

  onFilterChanged(e) {
    debugSite('onFilterChanged triggered!', e, e.api.getFilterModel());

    // Updating filters will refresh the results.

    this.props.dispatch(
      updateFilters(SiteList.GRID_NAME, e.api.getFilterModel()),
    );
  }

  onColumnStateChanged(e) {
    debugSite(
      'onColumnStateChanged triggered!',
      e,
      e.columnApi.getColumnState(),
    );

    // Updating sort will refresh the results.
    this.props.dispatch(
      updateColumnState(SiteList.GRID_NAME, e.columnApi.getColumnState()),
    );
  }

  onPaginationChanged(e) {
    let selected = e.selected;
    let offset = Math.ceil(selected * this.props.perPage);

    debugSite('onPaginationChanged triggered!', e, 'OFFSET=', offset);

    // Updating filters will refresh the results.
    this.props.dispatch(setCurrentPage(SiteList.GRID_NAME, selected));
  }

  onNbLinesChanged(nbLines) {
    debugSite('onNbLinesChanged triggered!', nbLines);

    if (nbLines > 1000) {
      // this.props.dispatch(updateSorts(SiteList.GRID_NAME, null))
    }
    this.props.dispatch(setNbLines(SiteList.GRID_NAME, nbLines));
  }

  onExport() {
    const nodeSelected = this.api.getSelectedNodes();
    const selectedColumns = this.api.columnController.columnApi
      .getAllGridColumns()
      .filter(
        (column) => column.colId !== 'selection' && column.colId !== 'actions',
      )
      .filter((column) => column.visible);

    if (selectedColumns?.length > 0) {
      const params = {
        fileName: `AssetRegister-ExportSite-${dayjs().format(
          'YYYY.MM.DD-hh',
        )}h.xlsx`,
        sheetName: 'Site',
        columnKeys: selectedColumns.map((column) => column.colId),
        onlySelected: nodeSelected?.length > 0 ? true : false,
      };

      this.api.exportDataAsExcel(params);
    } else {
      this.props.dispatch(
        sendFlashMessage(
          this.props.t(
            'common.flash_message.an_error_occurred',
            'An error has happened',
          ),
          'error',
        ),
      );
    }
  }

  onAdd() {
    this.props.history.push('/site/create');
  }

  onReset() {
    debugSite('Reset filters and sort...');

    // ? use applyColumnState instead ?
    // this.api.setSortModel(null)
    setTimeout(() => this.api.setFilterModel(null), 150);
  }

  /**
   * Clear IndexedDB and Redux
   */
  onClearCache() {
    cache.clear();
    this.props.dispatch(clearReduxState());
    // Clear object first
    this.setState({ mhList: {} });
    // Then fill the object
    this.fetchData();
  }

  onGridReady(api) {
    this.api = api;
    // events
    api.addEventListener('selectionChanged', () =>
      this.setState({ nbItemsSelected: api.getSelectedNodes().length }),
    );
  }

  editRow(node) {
    debugSite('editRow triggered!', node);
    this.props.history.push(`/site/${node.id}`);
  }

  validateData(node) {
    // console.log('ValidatData fired ... : ', SiteList.GRID_NAME, node.id);
    // this.props.dispatch(validateDataByAdmin({ gridName: SiteList.GRID_NAME, _id: node.id }));
  }

  // FIXME : does not work out of the box... for complex types (refData for example),
  // need to provide a getQuickFilterText function in colDef, returning searchable string
  // @see https://www.ag-grid.com/javascript-grid-filter-quick/
  onQuickFilterChanged(e) {
    debugSite(
      'onQuickFilterChanged: TO BE IMPLEMENTED (maybe server side...)',
      e.target.value,
    );
    this.api.setQuickFilter(e.target.value);
  }

  getListSettings() {
    debugSite(
      'SiteList : getListSettings : ',
      this.props.userSettings.list[SiteList.GRID_NAME],
    );
    return this.props.userSettings.list
      ? this.props.userSettings.list[SiteList.GRID_NAME]
      : {};
  }

  toggleControlsView() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    debugSite('SiteList : RENDER, this.props: ', this.props);
    const { siteList, t, classes, ...otherProps } = this.props;
    const listSettings = this.getListSettings();
    const { expanded, showGridView, isFetching, showMap } = this.state;

    document.title = `EUROPEAN CAMPING GROUP | ${t(
      'page.campsites.document_title',
      'Campsite list',
    )}`;
    // debugSite('SiteList => listSettings: ', listSettings)

    if (!listSettings) return <div>LOADING SITES...</div>;

    let content;
    if (siteList) {
      content = (
        <React.Fragment>
          <div className="content-body">
            <Linear isFetching={isFetching} {...otherProps} />
            <SiteGrid
              siteList={siteList}
              gridName={SiteList.GRID_NAME}
              // onSortChanged={this.onSortChanged}
              onFilterChanged={this.onFilterChanged}
              onColumnStateChanged={this.onColumnStateChanged}
              onPaginationChanged={this.onPaginationChanged}
              onGridReady={this.onGridReady}
              colDef={this.getColDefs()}
              isFetching={isFetching}
              {...otherProps}
            />
          </div>
          <div className="content-footer">
            <div className="flex-row"></div>
          </div>
        </React.Fragment>
      );
    } else {
      content = <div>:( :( :( NO RESULT OR NOT YET LOADED!</div>;
    }

    debugSite('siteList RENDER = > this.props=', this.props);
    const customProps = {
      ...otherProps,
      api: this.api,
      gridName: SiteList.GRID_NAME,
      expanded: expanded,
    };
    return (
      <Layout {...customProps}>
        <ScrollToTopButton />
        <FlashMessage />
        <header className={style['campsites-header']}>
          <div>
            <ExportButton
              action={() => {
                // GA
                ReactGA.event('fetch_data', {
                  user_interaction: 'Re-fetch data from campsites database',
                  browser: navigator.userAgent,
                  user_id: getUserSetting('_id').toString(),
                  dateTime: dayjs().format(),
                });
                this.onClearCache();
              }}
              label={t('page.sites.button.update', 'Update now')}
            >
              <Reload color="#194989" />
            </ExportButton>
            {!showMap && (
              <AllowedBlock roles={'ROLE_EXP_LIST'}>
                <ExportButton
                  action={() => {
                    this.onExport();
                  }}
                  label={`${t('common.label.export', 'Export')} ${
                    this.state.nbItemsSelected && this.state.nbItemsSelected > 0
                      ? `${this.state.nbItemsSelected} row${
                          this.state.nbItemsSelected > 1 ? 's' : ''
                        }`
                      : t('common.label.all', 'all')
                  } `}
                >
                  <Excel />
                </ExportButton>
              </AllowedBlock>
            )}
          </div>
          <div>
            <BaseButton
              disabled={isFetching}
              action={() => {
                // GA
                ReactGA.event('campsites', {
                  user_interaction: `Getting access to ${
                    showMap ? 'List view' : 'Map view'
                  }`,
                  user_id: getUserSetting('_id').toString(),
                  dateTime: dayjs().format(),
                });
                this.setState({ showMap: !showMap });
              }}
              label={
                showMap
                  ? t('common.action.list_view', 'List view')
                  : t('common.action.map_view', 'Map view')
              }
            >
              {showMap ? <Excel color="#194989" /> : <Earth color="#194989" />}
            </BaseButton>
          </div>
        </header>

        {!showMap ? (
          <Fragment>
            <div className={style['content-container']}>
              <div className={style['content-container-header']}>
                <GridConfigs
                  api={this.api}
                  expandColumns={expandColumns}
                  shrinkColumns={shrinkColumns}
                  showGridView={() =>
                    this.setState({ showGridView: !this.state.showGridView })
                  }
                  onReset={this.onReset}
                />
              </div>
              {content}
            </div>

            <CSSTransition
              in={showGridView}
              classNames="slideBottom"
              timeout={300}
              unmountOnExit
            >
              <GridViewConfig
                handleClose={() => {
                  this.setState({ showGridView: !this.state.showGridView });
                }}
                {...this.props}
                {...customProps}
              />
            </CSSTransition>
          </Fragment>
        ) : (
          <CampsitesMap campsites={siteList.data} {...this.props} />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.user,
  siteList: store.siteList,
  userSettings: store.userSettings,
  refDatas: store.refDatas.data,
});
export default withTranslation()(connect(mapStateToProps)(SiteList));
