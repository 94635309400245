export default (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_402_234)">
        <path
          d="M3.05092 0H4.74736V1.45182H3.05092C2.6282 1.45154 2.22267 1.61873 1.92344 1.91667V1.91667C1.62485 2.21523 1.45729 2.61988 1.45758 3.04167V5.11979H-5.29579e-05V3.04427C0.000451648 2.64524 0.0797736 2.25022 0.233378 1.8818C0.386982 1.51338 0.611856 1.17878 0.895143 0.897135V0.897135C1.17751 0.613242 1.51333 0.387835 1.88329 0.233873C2.25326 0.0799114 2.65007 0.000430587 3.05092 0V0ZM0.424056 7.14974H15.5758C15.6883 7.1511 15.7958 7.19648 15.8751 7.27611C15.9545 7.35574 15.9993 7.46325 15.9999 7.57552V8.42448C16.0003 8.53742 15.9559 8.64594 15.8765 8.72641C15.7971 8.80688 15.689 8.85279 15.5758 8.85417H0.424056C0.310763 8.85314 0.202502 8.80733 0.123003 8.72678C0.0435033 8.64624 -0.000748515 8.53753 -5.29579e-05 8.42448V7.57552C-5.50663e-05 7.46305 0.0445442 7.35514 0.124006 7.27537C0.203468 7.19559 0.311336 7.15043 0.424056 7.14974V7.14974ZM11.8189 0H12.949C13.3489 0.000219269 13.7449 0.0792373 14.1142 0.23252C14.4834 0.385803 14.8187 0.610334 15.1008 0.893229V0.893229C15.3848 1.17523 15.6102 1.51042 15.7641 1.87955C15.9179 2.24868 15.9972 2.64449 15.9973 3.04427V5.11979H14.5449V3.04427C14.5445 2.83535 14.5028 2.62855 14.4222 2.43573C14.3416 2.24291 14.2237 2.06785 14.0751 1.92057V1.92057C13.9271 1.77276 13.7514 1.6555 13.5579 1.57552C13.3644 1.49553 13.1571 1.45439 12.9477 1.45443H11.8189V0ZM15.9947 10.6576V12.9557C15.992 13.7623 15.6696 14.535 15.0981 15.1053C14.5265 15.6756 13.7521 15.9973 12.9438 16H11.8189V14.5482H12.949C13.3716 14.5461 13.7764 14.3777 14.0752 14.0795C14.3741 13.7813 14.5429 13.3774 14.5449 12.9557V10.6576H15.9947ZM4.74214 15.9961H3.0457C2.23868 15.9951 1.46511 15.6742 0.895143 15.1042L0.853385 15.0586C0.306826 14.4943 0.000896836 13.7405 -5.29579e-05 12.9557V10.6576H1.45497V12.9557C1.45521 13.3631 1.61162 13.7549 1.89213 14.0508L1.92475 14.0794C2.07235 14.2276 2.24779 14.3453 2.44104 14.4257C2.63429 14.5062 2.84154 14.5478 3.05092 14.5482H4.74736V16L4.74214 15.9961Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_402_234">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
