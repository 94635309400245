export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.275 13.25C3.925 13.25 3.625 13.1292 3.375 12.8875C3.125 12.6458 3 12.35 3 12C3 11.65 3.125 11.3542 3.375 11.1125C3.625 10.8708 3.925 10.75 4.275 10.75C4.60833 10.75 4.89583 10.875 5.1375 11.125C5.37917 11.375 5.5 11.6667 5.5 12C5.5 12.3333 5.37917 12.625 5.1375 12.875C4.89583 13.125 4.60833 13.25 4.275 13.25ZM4.25 7C3.9 7 3.60417 6.87917 3.3625 6.6375C3.12083 6.39583 3 6.1 3 5.75C3 5.4 3.12083 5.10417 3.3625 4.8625C3.60417 4.62083 3.9 4.5 4.25 4.5C4.6 4.5 4.89583 4.62083 5.1375 4.8625C5.37917 5.10417 5.5 5.4 5.5 5.75C5.5 6.1 5.37917 6.39583 5.1375 6.6375C4.89583 6.87917 4.6 7 4.25 7ZM4.275 19.5C3.925 19.5 3.625 19.3792 3.375 19.1375C3.125 18.8958 3 18.6083 3 18.275C3 17.925 3.125 17.625 3.375 17.375C3.625 17.125 3.925 17 4.275 17C4.60833 17 4.89583 17.125 5.1375 17.375C5.37917 17.625 5.5 17.925 5.5 18.275C5.5 18.6083 5.37917 18.8958 5.1375 19.1375C4.89583 19.3792 4.60833 19.5 4.275 19.5ZM8.75 19C8.53333 19 8.35417 18.9292 8.2125 18.7875C8.07083 18.6458 8 18.4667 8 18.25C8 18.0333 8.07083 17.8542 8.2125 17.7125C8.35417 17.5708 8.53333 17.5 8.75 17.5H20.25C20.4667 17.5 20.6458 17.5708 20.7875 17.7125C20.9292 17.8542 21 18.0333 21 18.25C21 18.4667 20.9292 18.6458 20.7875 18.7875C20.6458 18.9292 20.4667 19 20.25 19H8.75ZM8.75 12.75C8.53333 12.75 8.35417 12.6792 8.2125 12.5375C8.07083 12.3958 8 12.2167 8 12C8 11.7833 8.07083 11.6042 8.2125 11.4625C8.35417 11.3208 8.53333 11.25 8.75 11.25H20.25C20.4667 11.25 20.6458 11.3208 20.7875 11.4625C20.9292 11.6042 21 11.7833 21 12C21 12.2167 20.9292 12.3958 20.7875 12.5375C20.6458 12.6792 20.4667 12.75 20.25 12.75H8.75ZM8.75 6.5C8.53333 6.5 8.35417 6.42917 8.2125 6.2875C8.07083 6.14583 8 5.96667 8 5.75C8 5.53333 8.07083 5.35417 8.2125 5.2125C8.35417 5.07083 8.53333 5 8.75 5H20.25C20.4667 5 20.6458 5.07083 20.7875 5.2125C20.9292 5.35417 21 5.53333 21 5.75C21 5.96667 20.9292 6.14583 20.7875 6.2875C20.6458 6.42917 20.4667 6.5 20.25 6.5H8.75Z"
      fill={props.color ? props.color : '#194989'}
    />
  </svg>
);
