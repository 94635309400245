import Tippy from '@tippyjs/react';
import style from './style.module.scss';

export default ({ items }) => {
  return (
    <div className={style['campsite-contacts']}>
      <ul>
        {items?.map((item, index) => (
          <li key={index}>
            {item?.icon && (
              <figure>
                <img src={item.icon} alt="icon" title="Brand icon" />
              </figure>
            )}
            <div>
              <p>{item?.label}</p>
              <p>
                <a href={`tel:${item?.phone}`}>
                  <span>{item?.phone}</span>
                </a>
              </p>
              <p>
                <a href={`to:${item?.email}`}>
                  <span>{item?.email}</span>
                </a>
              </p>
              <p>
                <a href={`to:${item?.senior_email}`}>
                  <span>{item?.senior_email}</span>
                </a>
              </p>
              {item?.cmm_email && (
                <Tippy content="CMM" placement="left">
                  <p>
                    <a href={`to:${item?.cmm_email}`}>
                      <span>{item?.cmm_email}</span>
                    </a>
                  </p>
                </Tippy>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
