import style from './style.module.scss';

export default (props) => {
  const { items, index, updateIndex } = props;

  return (
    <nav className={style['no-wrap-navigation']}>
      <ol>
        {items?.map((item, i) => (
          <li key={i}>
            <button
              type="button"
              onClick={() => updateIndex(i)}
              className={index === i ? style['active'] : ''}
            >
              {item}
            </button>
          </li>
        ))}
      </ol>
    </nav>
  );
};
