const Expand = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.11255 11.9626C6.00005 12.0751 5.86255 12.1313 5.70005 12.1313C5.53755 12.1313 5.40005 12.0751 5.28755 11.9626L2.7188 9.39385C2.6563 9.33135 2.61255 9.26885 2.58755 9.20635C2.56255 9.14385 2.55005 9.0751 2.55005 9.0001C2.55005 8.9376 2.56255 8.87197 2.58755 8.80322C2.61255 8.73447 2.6563 8.66885 2.7188 8.60635L5.28755 6.0376C5.40005 5.9251 5.53755 5.86885 5.70005 5.86885C5.86255 5.86885 6.00005 5.9251 6.11255 6.0376C6.22505 6.1501 6.2813 6.2876 6.2813 6.4501C6.2813 6.6126 6.22505 6.7501 6.11255 6.8626L3.97505 9.0001L6.11255 11.1376C6.22505 11.2501 6.2813 11.3876 6.2813 11.5501C6.2813 11.7126 6.22505 11.8501 6.11255 11.9626V11.9626ZM15.525 9.0001C15.525 9.0626 15.5125 9.12822 15.4875 9.19697C15.4625 9.26572 15.4188 9.33135 15.3563 9.39385L12.7875 11.9626C12.675 12.0751 12.5375 12.1313 12.375 12.1313C12.2125 12.1313 12.075 12.0751 11.9625 11.9626C11.85 11.8501 11.7938 11.7126 11.7938 11.5501C11.7938 11.3876 11.85 11.2501 11.9625 11.1376L14.1 9.0001L11.9625 6.8626C11.85 6.7501 11.7938 6.6126 11.7938 6.4501C11.7938 6.2876 11.85 6.1501 11.9625 6.0376C12.075 5.9251 12.2125 5.86885 12.375 5.86885C12.5375 5.86885 12.675 5.9251 12.7875 6.0376L15.3563 8.60635C15.4188 8.66885 15.4625 8.73135 15.4875 8.79385C15.5125 8.85635 15.525 8.9251 15.525 9.0001V9.0001Z"
      fill={props.color ? props.color : '#333333'}
    />
  </svg>
);
export default Expand;
