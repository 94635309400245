export default (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.40625 9.23125C9.31875 9.14375 9.275 9.03681 9.275 8.91042C9.275 8.78403 9.31875 8.68194 9.40625 8.60417L10.5729 7.4375H5.90625C5.77986 7.4375 5.67535 7.39618 5.59271 7.31354C5.51007 7.2309 5.46875 7.12639 5.46875 7C5.46875 6.87361 5.51007 6.7691 5.59271 6.68646C5.67535 6.60382 5.77986 6.5625 5.90625 6.5625H10.5438L9.3625 5.38125C9.28472 5.30347 9.24583 5.20382 9.24583 5.08229C9.24583 4.96076 9.28958 4.85625 9.37708 4.76875C9.46458 4.68125 9.5691 4.6375 9.69063 4.6375C9.81215 4.6375 9.91667 4.68125 10.0042 4.76875L11.9438 6.70833C11.9924 6.75694 12.0264 6.80556 12.0458 6.85417C12.0653 6.90278 12.075 6.95625 12.075 7.01458C12.075 7.07292 12.0653 7.12639 12.0458 7.175C12.0264 7.22361 11.9924 7.27222 11.9438 7.32083L10.0188 9.24583C9.94097 9.32361 9.84132 9.3625 9.71979 9.3625C9.59826 9.3625 9.49375 9.31875 9.40625 9.23125ZM2.625 12.25C2.39167 12.25 2.1875 12.1625 2.0125 11.9875C1.8375 11.8125 1.75 11.6083 1.75 11.375V2.625C1.75 2.39167 1.8375 2.1875 2.0125 2.0125C2.1875 1.8375 2.39167 1.75 2.625 1.75H6.43125C6.55764 1.75 6.66215 1.79132 6.74479 1.87396C6.82743 1.9566 6.86875 2.06111 6.86875 2.1875C6.86875 2.31389 6.82743 2.4184 6.74479 2.50104C6.66215 2.58368 6.55764 2.625 6.43125 2.625H2.625V11.375H6.43125C6.55764 11.375 6.66215 11.4163 6.74479 11.499C6.82743 11.5816 6.86875 11.6861 6.86875 11.8125C6.86875 11.9389 6.82743 12.0434 6.74479 12.126C6.66215 12.2087 6.55764 12.25 6.43125 12.25H2.625Z"
      fill={props.color ? props.color : '#333333'}
    />
  </svg>
);
