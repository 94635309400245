const Edit = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.625 11.3749H3.26667L9.72708 4.91447L9.08542 4.2728L2.625 10.7332V11.3749ZM11.5792 4.28738L9.7125 2.42072L10.325 1.80822C10.4903 1.64294 10.6969 1.56273 10.9448 1.56759C11.1927 1.57245 11.3993 1.65752 11.5646 1.8228L12.1917 2.44988C12.3569 2.61516 12.4396 2.81933 12.4396 3.06238C12.4396 3.30544 12.3569 3.50961 12.1917 3.67488L11.5792 4.28738ZM2.1875 12.2499C2.06111 12.2499 1.9566 12.2086 1.87396 12.1259C1.79132 12.0433 1.75 11.9388 1.75 11.8124V10.5582C1.75 10.4999 1.75972 10.4464 1.77917 10.3978C1.79861 10.3492 1.83264 10.3006 1.88125 10.252L9.1 3.03322L10.9667 4.89988L3.74792 12.1186C3.69931 12.1672 3.65069 12.2013 3.60208 12.2207C3.55347 12.2402 3.5 12.2499 3.44167 12.2499H2.1875ZM9.40625 4.59363L9.08542 4.2728L9.72708 4.91447L9.40625 4.59363Z"
        fill={props.color ? props.color : '#FFFFFF'}
      />
    </svg>
  );
};

export default Edit;
