import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import MhList from '@pages/mh/MhList/index';
import MhCreateForm from '@pages/mh/MhCreateForm';
import MhEditForm from '@pages/mh/MhEditForm';
import MvList from '@pages/mv/MvList';
import MvCreateForm from '@pages/mv/MvCreateForm';
import MvEditForm from '@pages/mv/MvEditForm';
import SiteList from '@pages/site/SiteList';
import PitchFeesList from '@pages/pitchFees';
import PitchesList from '@pages/pitches';
import PitchFeesCreate from '@pages/pitchFees/create';
import PitchesCreate from '@pages/pitches/create';
import PitchFeesUpdate from '@pages/pitchFees/update';
import SiteDetail from '@pages/site/SiteDetail';
import UnitBarcodeScanner from '@pages/pwa/unit';
import { configureStore } from '@store/store';
import AuthRoute from '@security/AuthRoute';
import Login from '@security/Login';
import ResetPassword from '@security/ResetPassword';
import NewPassword from '@security/NewPassword';
import Logout from '@security/Logout';
import GetToken from '@security/GetToken';
import RefDatasLoader from '@components/common/RefDatasLoader';
import Home from '@pages/homepage';
import PageNotFound from '@pages/404';
import { logSuccess, logError } from '@helpers/logs';

import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
const isBrowserNotAllowed = browser.getBrowserName() === 'Internet Explorer';
const store = configureStore({}, { settings: {} });

const App = () => {
  logSuccess('*** Rendering App ***');

  return (
    <Provider store={store}>
      <RefDatasLoader>
        {isBrowserNotAllowed && (
          <h2
            style={{
              margin: '0px',
              color: 'white',
              backgroundColor: 'red',
              textAlign: 'center',
            }}
          >
            This Browser is not compatible with this App !
          </h2>
        )}
        <Router>
          <React.Fragment>
            <Switch>
              <Route exact path="/get-token" component={GetToken} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              {window.isProgressiveWebApp() ? (
                <Route exact path="/" component={UnitBarcodeScanner} />
              ) : (
                <AuthRoute exact path="/" component={Home} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute exact path="/mh" component={MhList} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute exact path="/mh/create" component={MhCreateForm} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute exact path="/mh/:id" component={MhEditForm} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute exact path="/mv" component={MvList} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute exact path="/mv/create" component={MvCreateForm} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute exact path="/mv/:id" component={MvEditForm} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute exact path="/site" component={SiteList} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute path="/site/:id" component={SiteDetail} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute exact path="/pitch-fees" component={PitchFeesList} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute exact path="/pitches" component={PitchesList} />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute
                  exact
                  path="/pitches/create"
                  component={PitchesCreate}
                />
              )}
              {/* {!window.isProgressiveWebApp() && (
                <AuthRoute
                  exact
                  path="/pitches/:id"
                  render={(props) => <PitchFeesUpdate {...props} />}
                />
              )} */}
              {!window.isProgressiveWebApp() && (
                <AuthRoute
                  exact
                  path="/pitch-fees/create"
                  component={PitchFeesCreate}
                />
              )}
              {!window.isProgressiveWebApp() && (
                <AuthRoute
                  exact
                  path="/pitch-fees/:id"
                  render={(props) => <PitchFeesUpdate {...props} />}
                />
              )}
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route
                exact
                path="/new-password/:tempToken"
                component={NewPassword}
              />
              {window.isProgressiveWebApp() ? (
                <Route path="/*" component={UnitBarcodeScanner} />
              ) : (
                <Route path="/*" component={PageNotFound} />
              )}
            </Switch>
          </React.Fragment>
        </Router>
      </RefDatasLoader>
    </Provider>
  );
};

export default App;
