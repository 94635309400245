import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FlashMessage from '@components/common/FlashMessage';
import { resetPassword } from '../../store/actions/action-common';

import bg1 from '@assets/images/login/bg-1.jpg';
import bg2 from '@assets/images/login/bg-2.jpg';
import bg3 from '@assets/images/login/bg-3.jpg';
import bg4 from '@assets/images/login/bg-4.jpg';
import bg5 from '@assets/images/login/bg-5.jpg';

import style from './style.module.scss';

class ResetPassword extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.uniqueID = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getARandomBackground = this.getARandomBackground.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const idToResetPassword = {
      uniqueID: this.uniqueID.current.value.toLowerCase().trim(),
    };

    this.props.dispatch(
      resetPassword(idToResetPassword, () => {
        this.props.history.push('/login');
      }),
    );
  }

  getARandomBackground() {
    const images = [bg1, bg2, bg3, bg4, bg5];

    const index = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const image = images[index(0, images.length - 1)];

    return {
      backgroundImage: `url(${image})`,
    };
  }

  render() {
    // console.log('render, props=', this.props);
    let redirectToReferrer = localStorage.getItem('referrer');
    if (redirectToReferrer && redirectToReferrer != 'undefined')
      redirectToReferrer = JSON.parse(redirectToReferrer);
    else {
      redirectToReferrer = { pathname: '/' };
    }

    if (this.props.user.isAuthenticated && redirectToReferrer) {
      localStorage.removeItem('referrer');
      return <Redirect to={redirectToReferrer} />;
    }

    return (
      <section
        className={style['login-section']}
        style={this.getARandomBackground()}
      >
        <FlashMessage />

        <div className={style['login-container']}>
          <div className={style['grid-item']}>
            <div className={style['wrapper']}>
              <div className={style['form-wrapper']}>
                <form
                  id="resetform"
                  className={style['form']}
                  onSubmit={this.handleSubmit}
                >
                  <p className={style['form-title']}>Reset your password</p>
                  <label>
                    <span>Email/username</span>
                    <input
                      ref={this.uniqueID}
                      id="uniqueID"
                      name="uniqueID"
                      autoComplete="email"
                      autoFocus
                    />
                  </label>
                  <button type="submit" className={style['submit']}>
                    Reset
                  </button>
                </form>
              </div>
            </div>
          </div>

          <small>
            © {new Date().getFullYear()} European Camping Group. All rights
            reserved.
          </small>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(ResetPassword);
