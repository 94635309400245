import style from './style.module.scss';
import Barcode from '@components/icons/Barcode';
import classnames from 'classnames';
import Home from '@components/icons/Home';

export default (props) => {
  const { currentPage, toggleScanner } = props;
  return (
    <div className={style['pwa-main-footer']}>
      <div
        onClick={() => toggleScanner(false)}
        className={classnames(
          style['pwa-menu-item'],
          currentPage === 0 ? style['active'] : null,
        )}
      >
        <Home color={currentPage === 0 ? '#0069b4' : '#D1D1D1'} />
        <small>Home</small>
      </div>
      <div
        onClick={() => toggleScanner(true)}
        className={classnames(
          style['pwa-menu-item'],
          currentPage === 1 ? style['active'] : null,
        )}
      >
        <Barcode color={currentPage === 1 ? '#0069b4' : '#D1D1D1'} />
        <small>Scanner</small>
      </div>
    </div>
  );
};
