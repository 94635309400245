import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { getTransferSeasons } from '@store/actions/action-mv';
import { version } from '../../../../package.json';

// Images
import logo from '@assets/images/menu/logo.png';

// Components
import MenuItem from './MenuItem/';
import MenuSubItem from './MenuSubItem/';
import ValidTransfers from '@components/common/dialogs/ValidTransfers';
import { getAPIToken } from '@security/User';

import getStore from '@store/store';

// Helpers
import { debugLayout } from '@lib/debug';

import style from './style.module.scss';

class MenuApp extends Component {
  static propTypes = {
    classes: PropTypes.object,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.func,
    // Optionnel
    userSettings: PropTypes.object,
    gridApi: PropTypes.object,
    api: PropTypes.object,
    gridName: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.goTo = this.goTo.bind(this);
    this.toggleLoadViewConf = this.toggleLoadViewConf.bind(this);
    this.toggleSaveViewConf = this.toggleSaveViewConf.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
    this.updateFontSize = this.updateFontSize.bind(this);
    this.expandingMenu = this.expandingMenu.bind(this);

    this.timer = null;

    this.state = {
      menuStates: {},
      saveViewConfOpen: false,
      loadViewConfOpen: false,
      isOpened: false,
      isTransfersValidationOpened: false,
      darkmode:
        JSON.parse(localStorage.getItem('prefers-color-scheme')) || false,
      transferSeasons: [],
      changedTextSize: localStorage.getItem('prefers-font-size'),
    };
  }

  handleToggleMenu = (menuKey) => (event) => {
    // const isOpened = Object.keys(this.state.menuStates).some(
    //   (key) => key === menuKey,
    // );
    // console.info(this.state.menuStates);
    // this.setState({ isOpened: isOpened });
    clearTimeout(this.timer);
    if (
      this.state.isOpened &&
      Object.keys(this.state.menuStates).some((key) => key === menuKey)
    ) {
      this.setState({ isOpened: false });
      this.timer = setTimeout(() => {
        this.setState({
          menuStates: {},
        });
      }, 300);
    } else {
      this.setState({
        menuStates: {
          [menuKey]: event.currentTarget,
        },
        isOpened: true,
      });
    }
  };

  handleCloseMenu = () => {
    this.setState({ isOpened: false });

    this.setState({
      menuStates: {},
    });
  };

  updateFontSize() {
    this.setState({ changedTextSize: !this.state.changedTextSize });
    localStorage.setItem(
      'prefers-font-size',
      this.state.changedTextSize ? '' : 'big',
    );
    document.documentElement.setAttribute(
      'data-font-size',
      this.state.changedTextSize ? '' : 'big',
    );
  }

  export(path) {
    return this.gotoAPIPath(path);
  }

  gotoAPIPath(path) {
    return () => {
      this.handleCloseMenu();
      window.open(
        `${process.env.REACT_APP_API_URL}${path}?access_token=${getAPIToken()}`,
      );
    };
  }

  goTo(path) {
    return () => {
      if (
        path !== this.props.history.location.pathname ||
        this.props.history.location.search
      )
        this.props.history.push(path);
    };
  }

  validateTransferSeason(id, season) {
    if (
      window.confirm(
        this.props.t(
          'common.confirm.validate_transfers_campsite_and_season',
          `Are you sure to validate every amendments of this campsite and season `,
        ) +
          season +
          '?',
      )
    ) {
      window.open(
        `${
          process.env.REACT_APP_API_URL
        }/api/jobs/validate_transfers/${id}/${season}?access_token=${getAPIToken()}`,
      );
      this.setState({
        isTransfersValidationOpened: !this.state.isTransfersValidationOpened,
        transferSeasons: this.state.transferSeasons.filter(
          (transferSeason) => transferSeason !== season,
        ),
      });
    }
  }

  openTransfersDialog() {
    return () => {
      this.handleCloseMenu();
      new Promise((res, rej) => {
        res(getStore().dispatch(getTransferSeasons()));
      }).then((res) => {
        this.setState({
          transferSeasons: res,
          isTransfersValidationOpened: !this.state.isTransfersValidationOpened,
        });
      });
    };
  }

  toggleLoadViewConf() {
    this.setState({ loadViewConfOpen: !this.state.loadViewConfOpen });
    this.handleCloseMenu();
  }

  toggleSaveViewConf() {
    this.setState({ saveViewConfOpen: !this.state.saveViewConfOpen });
    this.handleCloseMenu();
  }

  toggleMode(name) {
    if (name === 'light') {
      this.setState({ darkmode: false });
      localStorage.setItem('prefers-color-scheme', false);
      document.documentElement.setAttribute('data-theme', '');
    } else {
      this.setState({ darkmode: true });
      localStorage.setItem('prefers-color-scheme', true);
      document.body.classList.add('dark-theme');
      document.documentElement.setAttribute('data-theme', 'dark');
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.handleCloseMenu();
    }
  }

  expandingMenu() {
    if (localStorage.getItem('prefers-expanding-menu') === 'enabled') {
      localStorage.setItem('prefers-expanding-menu', 'disabled');
      document.documentElement.setAttribute(
        'prefers-expanding-menu',
        'disabled',
      );
    } else {
      localStorage.setItem('prefers-expanding-menu', 'enabled');
      document.documentElement.setAttribute(
        'prefers-expanding-menu',
        'enabled',
      );
    }
  }

  componentDidMount() {
    if (localStorage.getItem('prefers-font-size') === 'big') {
      document.documentElement.setAttribute('data-font-size', 'big');
    }
    if (JSON.parse(localStorage.getItem('prefers-color-scheme'))) {
      document.documentElement.setAttribute('data-theme', 'dark');
    }
    if (localStorage.getItem('prefers-expanding-menu') === 'enabled') {
      document.documentElement.setAttribute(
        'prefers-expanding-menu',
        'enabled',
      );
    }

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { classes, width, t, location, api } = this.props;
    const {
      menuStates,
      isOpened,
      darkmode,
      transferSeasons,
      isTransfersValidationOpened,
    } = this.state;
    debugLayout('Rendering MenuApp...');

    const desktopMenu = [
      {
        label: t('main_menu.item.homepage.title', 'Homepage'),
        to: this.goTo('/'),
        path: '/',
        roles: [],
        icon: 'dashboard',
      },
      {
        label: t('main_menu.item.unit_list.title', 'Units'),
        to: this.goTo('/mh'),
        path: '/mh',
        roles: ['ROLE_LST_MH'],
        icon: 'units',
      },
      {
        label: t('main_menu.item.pitches_list.title', 'Pitches'),
        to: this.goTo('/pitches'),
        path: '/pitches',
        roles: ['ROLE_LST_PITCHES'],
        icon: 'pitches',
      },
      {
        label: t('main_menu.item.transfer_list.title', 'Amendments'),
        to: this.goTo('/mv'),
        path: '/mv',
        roles: ['ROLE_READ_MH_MVT'],
        icon: 'amendments',
      },
      {
        label: t('main_menu.item.campsites.title', 'Campsites'),
        to: this.goTo('/site'),
        path: '/site',
        roles: ['ROLE_LST_SITES'],
        icon: 'sites',
      },
      {
        label: t('main_menu.item.pitch_fees.title', 'Pitch fees'),
        to: this.goTo('/pitch-fees'),
        path: '/pitch-fees',
        roles: ['ROLE_LST_PITCH_FEES'],
        icon: 'pitchFees',
      },
      {
        label: t('main_menu.item.exports.title', 'Exports'),
        panel: 'menu-export',
        to: this.handleToggleMenu('menu-export'),
        oneOfThem: true,
        roles: ['ROLE_EXP_MH_AFTER_MVT', 'ROLE_EXP_FORMS2MOBILE'],
        icon: 'export',
        menuItems: [
          {
            label: t(
              'main_menu.item.exports.units_after_transfers',
              'Units after transfers',
            ),
            to: this.export('/api/exports/mh-after-movements'),
            roles: ['ROLE_EXP_MH_AFTER_MVT'],
            icon: 'exports',
          },
        ],
      },
      {
        label: t('main_menu.item.settings.title', 'Settings'),
        panel: 'menu-admin',
        to: this.handleToggleMenu('menu-admin'),
        roles: ['ROLE_WRI_REF_DATA'],
        icon: 'settings',
        menuItems: [
          {
            label: t('main_menu.item.settings.back_office', 'Back office'),
            to: this.gotoAPIPath('/admin'),
            // icon: 'off',
            icon: 'admin',
            bgColor: '#9d7ef1',
          },
          {
            label: t(
              'main_menu.item.settings.update_ref_data',
              'Update ref data',
            ),
            to: this.gotoAPIPath('/api/jobs/update_denormalized_ref_datas'),
            icon: 'delete',
            bgColor: '#ff7878',
          },
          {
            label: t('main_menu.item.settings.update_users', 'Update users'),
            to: this.gotoAPIPath('/api/jobs/update_users'),
            icon: 'update',
          },
          {
            label: t('main_menu.item.settings.update_cache', 'Update cache'),
            to: this.gotoAPIPath('/api/jobs/update_redis'),
            icon: 'update',
          },
          {
            label: t(
              'main_menu.item.settings.export_f2m_s3',
              'Export F2M on S3',
            ),
            to: this.gotoAPIPath('/api/jobs/autoexportF2M'),
            icon: 'exports',
          },
          {
            label: t(
              'main_menu.item.settings.export_sales_azure',
              'Export sales on Azure',
            ),
            to: this.gotoAPIPath('/api/jobs/autoexportSales'),
            icon: 'exports',
          },
          {
            label: t(
              'main_menu.item.settings.export_bigquery',
              'Export to BigQuery',
            ),
            to: this.gotoAPIPath('/api/jobs/autoexportBigQuery'),
            icon: 'exports',
          },
          {
            label: t(
              'main_menu.item.validate_transfers',
              'Validate amendments',
            ),
            to: this.openTransfersDialog(),
            bgColor: '#6d8b74',
            icon: 'check',
          },
        ],
      },
      {
        label: t('main_menu.item.help', 'Need any help?'),
        to: () => window.open(`/pdf/asset-register-helper.pdf`),
        path: `/pdf/asset-register-helper.pdf`,
        roles: [],
        icon: 'help',
      },
    ];

    const mobileMenu = [
      {
        label: t('main_menu.item.unit_history', 'Unit history'),
        to: this.goTo('/'),
        path: '/',
        roles: ['ROLE_LST_MH'],
        icon: 'units',
      },
    ];

    const paths = !window.isProgressiveWebApp() ? desktopMenu : mobileMenu;

    const accountPaths = [
      {
        label: t('main_menu.item.expand_menu', 'Expand menu'),
        to: () => this.expandingMenu(),
        roles: [],
        icon: 'menuExpand',
        disabledOnMobile: true,
      },
      {
        label: t('main_menu.item.font_size', 'Font size'),
        to: () => this.updateFontSize(),
        roles: [],
        icon: this.state.changedTextSize
          ? 'decreaseTextSize'
          : 'increaseTextSize',
      },
      {
        label: !darkmode
          ? t('main_menu.item.dark_mode', 'Dark mode')
          : t('main_menu.item.light_mode', 'Light mode'),
        to: () => this.toggleMode(!darkmode ? 'dark ' : 'light'),
        roles: [],
        icon: !darkmode ? 'moon' : 'sun',
      },
      {
        label: t('main_menu.item.logout', 'Log out'),
        to: this.goTo('/logout'),
        path: '/logout',
        roles: [],
        icon: 'logout',
      },
    ];

    return (
      <Fragment>
        <nav
          className={classNames(style['menu'], 'menu')}
          ref={this.wrapperRef}
        >
          <div className={style['flex']}>
            <div className={style['flex-item']}>
              <button className={style['logo']} onClick={this.goTo('/')}>
                <img
                  src={logo}
                  alt={`Asset Register v${version}`}
                  title={`Asset Register v${version}`}
                />
              </button>
            </div>

            <div
              className={classNames(
                style['flex-item'],
                style['grid-nav-items'],
              )}
            >
              <div>
                {paths.map((item, index) => (
                  <MenuItem
                    key={index}
                    active={
                      this.props.history.location.pathname.indexOf(
                        item.path,
                      ) === 0 &&
                      this.props.history.location.pathname.length ===
                        item.path.length
                    }
                    label={item.label ? item.label : ''}
                    to={item.to ? item.to : ''}
                    roles={item.roles ? item.roles : null}
                    oneOfThem={item.oneOfThem ? item.oneOfThem : false}
                    icon={item.icon ? item.icon : null}
                  />
                ))}
              </div>
            </div>

            <div className={classNames(style['flex-item'], style['logout'])}>
              <div>
                {accountPaths.map((item, index) => (
                  <MenuItem
                    key={index}
                    active={
                      this.props.history.location.pathname.indexOf(
                        item.path,
                      ) === 0 &&
                      this.props.history.location.pathname.length ===
                        item.path.length
                    }
                    label={item.label ? item.label : ''}
                    to={item.to ? item.to : ''}
                    roles={item.roles ? item.roles : null}
                    oneOfThem={item.oneOfThem ? item.oneOfThem : false}
                    icon={item.icon ? item.icon : null}
                    disabledOnMobile={item?.disabledOnMobile}
                  />
                ))}
              </div>
            </div>
          </div>
          <CSSTransition
            in={isOpened}
            classNames="slideLeft"
            timeout={300}
            unmountOnExit
          >
            <div className={style['menu-panel']}>
              <h1 className={style['section-title']}>
                {
                  paths
                    .concat(accountPaths)
                    .flat()
                    .find((item) => item.panel === Object.keys(menuStates)[0])
                    .label
                }
              </h1>
              <ul>
                {paths
                  ?.concat(accountPaths)
                  ?.flat()
                  ?.find((item) => item.panel === Object.keys(menuStates)[0])
                  ?.menuItems?.map((item, index) => (
                    <MenuSubItem
                      {...item}
                      key={index}
                      separate={item.separate ? true : false}
                    />
                  ))}
              </ul>
            </div>
          </CSSTransition>
          <CSSTransition
            in={isTransfersValidationOpened}
            classNames="slideLeft"
            timeout={300}
            unmountOnExit
          >
            <ValidTransfers
              seasons={transferSeasons}
              handleClose={() =>
                this.setState({ isTransfersValidationOpened: false })
              }
              action={(id, season) => {
                return () => this.validateTransferSeason(id, season);
              }}
            />
          </CSSTransition>
        </nav>
      </Fragment>
    );
  }
}

export default withTranslation()(MenuApp);
