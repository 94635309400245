import React, { useState, Fragment } from 'react';
import BaseButton from '@components/buttons/BaseButton';
import GridButton from '@components/buttons/GridButton';
import More from '@components/icons/More';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import HomairLogo from '@assets/images/common/brands/homair_full.png';
import EurocampLogo from '@assets/images/common/brands/eurocamp_full.png';
import RoanLogo from '@assets/images/common/brands/roan_full.png';
import VacanceselectLogo from '@assets/images/common/brands/vacanceselect_full.png';

import style from './style.module.scss';
import Tippy from '@tippyjs/react';

function brandLogo(country) {
  switch (country) {
    case 'homair':
      return HomairLogo;
    case 'eurocamp':
      return EurocampLogo;
    case 'vacanceselect':
      return VacanceselectLogo;
    default:
      return RoanLogo;
  }
}

export default ({ data, reference, t }) => {
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const _data =
    data?.length > 0 && data?.filter((brand) => brand.items.length > 0);

  return _data?.length > 0 ? (
    <Fragment>
      <div className={style['data-buttons-wrapper']}>
        {_data.map((brandUnits, index) => (
          <button
            key={index}
            className={currentIndex === index ? style['active'] : undefined}
            type="button"
            onClick={() => {
              setCurrentIndex(index);
              setShow(false);
            }}
          >
            <img
              src={brandLogo(brandUnits.brand.name)}
              alt={brandUnits.brand.name}
            />
          </button>
        ))}
      </div>
      <SwitchTransition mode={'out-in'}>
        <CSSTransition key={currentIndex} timeout={300} classNames="fade">
          <div className={style['data-wrapper']}>
            <table>
              <thead>
                <tr>
                  {Object.keys(_data[currentIndex].items[0]).map((th) => (
                    <th key={th}>
                      {t(`page.homepage.data_table.${th.toLowerCase()}`, th)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {_data[currentIndex].items
                  .slice(0, !show ? 15 : _data[currentIndex].items.length)
                  .map((unit, i) => (
                    <tr className={style['data-grid']} key={i}>
                      {Object.keys(unit).map((key, j) => (
                        <td key={j}>
                          <p
                            className={
                              key.toLowerCase() === 'vintage' &&
                              !isNaN(unit[key])
                                ? unit[key] < new Date().getFullYear() - 10
                                  ? unit[key] < new Date().getFullYear() - 20
                                    ? style['old']
                                    : style['moderate']
                                  : style['recent']
                                : key.toLowerCase() === 'total'
                                ? style['total']
                                : undefined
                            }
                          >
                            {unit[key]}
                          </p>
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <div className={style['data-button-extend']}>
        <Tippy placement="top" content={show ? 'Close' : 'See more'}>
          <button
            type="button"
            onClick={() => setShow(!show)}
            className={show ? style['close'] : undefined}
          >
            <More />
          </button>
        </Tippy>
      </div>
    </Fragment>
  ) : null;
};
