import { getAPIToken } from '../../security/User';
import fetch from 'node-fetch';
import { debugStore } from '../../lib/debug';
import { fixRefValues } from '../../lib/utils';
import { sendFlashMessage } from './action-common';

const BSON = require('bson');

// ! Worker
const pitchWorker = new Worker('@workers/pitches.worker.js', {
  type: 'module',
});

export function fetchAll(query) {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    // dispatch(requestMhList());

    const url = `${process.env.REACT_APP_API_URL}/api/pitches${query && query}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
        'Content-Encoding': 'gzip',
      },
    };

    debugStore('Fetch all pitches: url=', url, options);

    try {
      const res = await fetch(url, options);

      if (res.status >= 400) {
        console.error(
          `fetchPitchesList: error status=${res.status}, statusText=${res.statusText}, for url=${url}`,
        );
      }

      let arrayResult = [];

      const reader = await res.arrayBuffer();
      BSON.deserializeStream(
        reader,
        0,
        res.headers.get('nbResults'),
        arrayResult,
        0,
      );

      // ? Get uncached
      let uncached =
        res.headers.get('uncached') && res.headers.get('uncached').split(',');

      for (let doc of arrayResult) {
        if (doc?._id) {
          doc._id = doc._id?.toString();
          if (!!uncached && uncached.includes(doc?._id)) {
            doc.uncached = true;
            uncached = uncached.filter((id) => id !== doc?._id);
          }
        }
      }

      const created =
        !!uncached &&
        uncached.map(async (id) => {
          const url = `${process.env.REACT_APP_API_URL}/api/pitches/${id}`;

          // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
          const options = {
            method: 'GET',
            headers: {
              'x-access-token': API_TOKEN,
            },
          };

          const doc = await fetch(url, options);
          if (doc.status >= 400) {
            return null;
          }
          return doc.json();
        });

      // ? Created uncached ones
      return !!uncached
        ? Promise.all(created).then((docs) => {
            docs = docs.map((doc) => {
              if (!doc) return null;
              return { ...doc, uncached: true };
            });

            docs = docs.filter((doc) => doc !== null);

            arrayResult.push(...docs);
            // dispatch(receiveMhList());
            return arrayResult;
          })
        : arrayResult;
    } catch (e) {
      console.error('fetchPitchFeesList:', e);
    }
  };
}

export function fetchOne(id) {
  if (!id) return;
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(`fetchPitches: EDIT MH id=${id}`);

    const url = `${process.env.REACT_APP_API_URL}/api/pitches/${id}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('fetchPitchFees: url=', url, options);

    return fetch(url, options)
      .then((res) => {
        if (res.status >= 400) {
          console.error(
            `fetchPitches: error status=${res.status}, statusText=${res.statusText}, for url=${url}`,
          );
          const err = new Error(res.statusText);
          err.code = res.status;
          throw err;
        }
        return res.clone().json();
      })
      .then((res) => {
        return res;
      });
  };
}
//
// After receiving data from API.
//
export function update(myValues, callback = null) {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore('updatePitchFees: myValues=', myValues);

    delete myValues.__v;

    fixRefValues(myValues);

    // ! Encoding
    const enc = new TextEncoder('utf-8');

    const isEdit =
      myValues._id &&
      myValues._id.toString() &&
      myValues._id.toString().length > 0;

    const isEditBuffer = enc.encode(isEdit).buffer;

    const apiTokenBuffer = enc.encode(API_TOKEN).buffer;

    // ! Delegate to worker
    const valuesStringify = enc.encode(JSON.stringify(myValues));
    const valuesBuffer = valuesStringify.buffer;

    pitchWorker.onmessage = (event) => {
      const { _id } = event.data;

      let pitchAwaitingRegistration = sessionStorage.getItem(
        'pitchAwaitingRegistration',
      );
      pitchAwaitingRegistration = !pitchAwaitingRegistration
        ? []
        : JSON.parse(pitchAwaitingRegistration);

      if (!pitchAwaitingRegistration.some((id) => id === _id)) {
        pitchAwaitingRegistration.push(_id);
      }

      sessionStorage.setItem(
        'pitchAwaitingRegistration',
        JSON.stringify(pitchAwaitingRegistration),
      );

      // dispatch(receiveMh(event.data));
      dispatch(sendFlashMessage(`Saved ${_id}!`, 'success'));
      callback && isEdit && callback();
      callback && !isEdit && callback(_id);

      return _id;
    };

    // ? Transferable object
    pitchWorker.postMessage({ apiTokenBuffer, isEditBuffer, valuesBuffer }, [
      apiTokenBuffer,
      isEditBuffer,
      valuesBuffer,
    ]);
  };
}

export function remove(id, callback = null) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(`Pitches: REMOVE id=${id}`);

    // ! Encoding
    const enc = new TextEncoder('utf-8');
    const apiTokenBuffer = enc.encode(API_TOKEN).buffer;
    const idBuffer = enc.encode(id).buffer;
    // ! Delegate to saving worker

    pitchWorker.onmessage = (event) => {
      if (event?.data instanceof Error) {
        dispatch(sendFlashMessage(`${event.data}`, 'warning'));
      } else {
        const _id = event.data;
        let pitchAwaitingRemoving = sessionStorage.getItem(
          'pitchAwaitingRemoving',
        );
        pitchAwaitingRemoving = !pitchAwaitingRemoving
          ? []
          : JSON.parse(pitchAwaitingRemoving);

        if (!pitchAwaitingRemoving.some((id) => id === _id)) {
          pitchAwaitingRemoving.push(_id);
        }

        sessionStorage.setItem(
          'pitchAwaitingRemoving',
          JSON.stringify(pitchAwaitingRemoving),
        );

        dispatch(sendFlashMessage(`Removed ${_id}`, 'success'));
        callback && callback();
      }
    };

    // ? Transferable object
    pitchWorker.postMessage({ apiTokenBuffer, idBuffer }, [
      apiTokenBuffer,
      idBuffer,
    ]);
  };
}

export function fetchAllPitchesPerCampsite(callback = null) {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    const url = `${process.env.REACT_APP_API_URL}/api/pitches/campsites`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('fetchPitches: url=', url, options);

    return fetch(url, options)
      .then((res) => {
        if (res.status >= 400) {
          console.error(
            `fetchPitches: error status=${res.status}, statusText=${res.statusText}, for url=${url}`,
          );
          const err = new Error(res.statusText);
          err.code = res.status;
          throw err;
        }
        return res.clone().json();
      })
      .then((res) => {
        return res;
      });
  };
}
