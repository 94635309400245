import React, { Component, Fragment, useState, useEffect } from 'react';
import PanelButton from '@components/pwa/buttons/PanelButton';
import MainHeader from '@components/pwa/MainHeader';

import getStore from '@store/store';
import { fetchMh } from '@store/actions/action-mh';

import automation from '@assets/images/pwa/automation.png';
import tinyHouse from '@assets/images/pwa/tiny-house.png';
import loudspeaker from '@assets/images/pwa/loudspeaker.png';

import style from './style.module.scss';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import UnitDetails from '../UnitDetails';
import UnitHistory from '../UnitHistory';
import UnitReport from '../UnitReport';

export default (props) => {
  const { unit, selectedIndex, isOffline } = props;

  const [data, setData] = useState(null);
  const [isUnitDetailsOpened, setIsUnitDetailsOpened] = useState(false);
  const [isUnitHistoryOpened, setIsUnitHistoryOpened] = useState(false);
  const [isUnitReportOpened, setIsUnitReportOpened] = useState(false);
  const [routeName, setRouteName] = useState(null);

  function handleRoute(name = '') {
    setRouteName(name);
    switch (name) {
      case 'details':
        setIsUnitDetailsOpened(true);
        break;
      case 'f2m':
        setIsUnitHistoryOpened(true);
        break;
      case 'report':
        setIsUnitReportOpened(true);
        break;
      default:
        setIsUnitDetailsOpened(false);
        setIsUnitHistoryOpened(false);
        setIsUnitReportOpened(false);
        break;
    }
  }

  // ! Fetch
  useEffect(() => {
    // declare the data fetching function
    if (!data) {
      const fetchData = async () => {
        const _data = await getStore().dispatch(fetchMh(unit._id));
        setData(_data);
      };
      // call the function
      fetchData()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [data]);

  return (
    <div className={style['selection-panel']}>
      <MainHeader title={unit.barcode} action={selectedIndex} />
      <div className={style['panel-buttons']}>
        <PanelButton
          action={() => handleRoute('details')}
          img={tinyHouse}
          label="See unit details"
          description="Get all information about this unit."
          disabled={isOffline}
        />
        <PanelButton
          action={() => handleRoute('f2m')}
          img={automation}
          label="See F2M history"
          description="Data from F2M application."
          disabled={isOffline}
        />
        <PanelButton
          action={() => handleRoute('report')}
          img={loudspeaker}
          label="Report an issue"
          description="Something wrong on the current unit."
        />
      </div>

      <SwitchTransition mode="out-in">
        <CSSTransition key={routeName} classNames="slideRight" timeout={300}>
          <>
            {isUnitDetailsOpened && (
              <UnitDetails unit={data} close={handleRoute} />
            )}
            {isUnitHistoryOpened && (
              <UnitHistory histories={data.f2m_data} close={handleRoute} />
            )}
            {isUnitReportOpened && (
              <UnitReport unit={unit} close={handleRoute} />
            )}
          </>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
