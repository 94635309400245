import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

export default (props) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));

  const handleLangChange = (evt) => {
    const lang = evt.target.value;
    console.log(lang);
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <select
      className={style['language-suggestion']}
      onChange={handleLangChange}
      value={language}
    >
      <option value="en">EN</option>
      <option value="es">ES</option>
      <option value="fr">FR</option>
      <option value="it">IT</option>
      <option value="nl">NL</option>
      <option value="hr">HR</option>
    </select>
  );
};
