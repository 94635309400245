import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Close from '@components/icons/Close';

import style from './style.module.scss';

function Alert(props) {
  const { className, message: text, onClose, level, ...other } = props;
  const title = () => {
    switch (level) {
      case 'success':
        return 'Congrats!';
      case 'info':
        return 'Info';
      case 'warning':
      case 'error':
        return 'Uh oh, something went wrong!';
      default:
        return 'Message';
    }
  };

  return (
    <div className={classNames(style['snack-bar-content'], style[level])}>
      <div className={style['content']}>
        <div>
          <div>
            <h1>{title()}</h1>
          </div>
          <button type="button" onClick={onClose}>
            <Close color={'var(--color-default)'} />
          </button>
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  level: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export default Alert;
