import dayjs from 'dayjs';
import React, { Fragment, useState } from 'react';
import { fetchMh } from '@store/actions/action-mh';
import { fixUnitReport } from '@store/actions/action-scanner';
import getStore from '@store/store';
import { AllowedBlock } from '@components/common/AllowedBlock';
import classnames from 'classnames';
import Archive from '@components/icons/Archive';
import FormButton from '@components/buttons/FormButton';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs().format('L LT');

import issue_reporting_character from '@assets/images/svg/issue_reporting.svg';
import issue_reporting_solved_character from '@assets/images/svg/issue_reporting_solved.svg';
import style from './style.module.scss';

export default ({ unitID, report, updateIssueReporting }) => {
  const [isFixed, setIsFixed] = useState(report.fixed);

  const rolesAllowed = [
    'ROLE_WRI_MH_EQUI',
    'ROLE_WRI_MH_HIVERN',
    'ROLE_WRI_MH_EMPL',
    'ROLE_WRI_MH_INFO',
    'ROLE_WRI_MH_MVT',
    'ROLE_WRI_MH_USE_TYPE',
    'ROLE_WRI_MH_COMMERCIAL_TYPE',
    'ROLE_ACCOUNTING_MGNT',
  ];

  function fixed(values) {
    if (
      window.confirm(
        'You are about to close this ticket. Are you sure to proceed?',
      )
    ) {
      try {
        getStore()
          .dispatch(fixUnitReport(values))
          .then((res) => {
            if (res.status >= 400) {
              console.error('Error on fix unit report');
              return;
            }
            getStore()
              .dispatch(fetchMh(unitID))
              .then(() => {
                updateIssueReporting(values.index);
                setIsFixed(true);
              })
              .catch((err) => {
                if (err && err.code === 404) {
                  err.message = `Sorry, we could not find the unit ID ${this.props.match.params.id}`;
                }
              });
          });
      } catch (err) {
        console.info(err);
      }
    }
  }
  return (
    <li className={style['issue-reporting']}>
      <div className={style['issue-reporting-refs']}>
        <p className={style['issue-reporting-category']}>
          {report.category || 'Other'}
          <small>ref: #{report.index}</small>
        </p>
        <p className={style['issue-reporting-info']}>
          Created at{' '}
          <time className={style['meta-info']} dateTime={report.created_at}>
            {dayjs(report.created_at).format('llll')}
          </time>{' '}
          by {report.created_by}
        </p>
        <div className={style['meta']}>
          <figure>
            <img
              src={
                report.fixed
                  ? issue_reporting_solved_character
                  : issue_reporting_character
              }
              alt="Issue reporting"
              title="Issue reporting"
            />
          </figure>
          <div>
            <p>
              <span className={style['issue-reporting-title']}>State:</span>
              <span
                className={classnames(
                  style['issue-reporting-state'],
                  style[report.fixed ? 'complete' : 'open'],
                )}
              >
                {report.fixed ? 'COMPLETE' : 'OPEN'}
              </span>
            </p>
            <div>
              {report.fixed || isFixed ? (
                <div className={style['meta-info']}>
                  Closed at{' '}
                  <time
                    className={style['meta-info']}
                    dateTime={report.updated_at}
                  >
                    {dayjs(report.updated_at).format('lll')}
                  </time>{' '}
                  by {report.updated_by}
                </div>
              ) : (
                <React.Fragment>
                  <span className={style['issue-reporting-title']}>
                    Actions:
                  </span>
                  <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                    <FormButton
                      action={() =>
                        fixed({ index: report.index, unit_id: unitID })
                      }
                      label="Close this issue"
                      icon={Archive}
                      color="var(--color-surface-octonary)"
                    />
                  </AllowedBlock>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={style['issue-reporting-body']}>
        <small>DESCRIPTION</small>
        {report.description}
      </div>
    </li>
  );
};
