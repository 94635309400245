import { useEffect, useRef, useState } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs().format('L LT');
import classnames from 'classnames';

import style from './style.module.scss';
import successImage from '@assets/images/pwa/surveyor.png';
import failImage from '@assets/images/pwa/cancel.png';
import dayjs from 'dayjs';

export default (props) => {
  const { item } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const [pressed, setPressed] = useState(false);
  const limitSwipeLength = 0.5;

  function handleTouchStart(event) {
    setPressed(true);
    setStartX(event.touches[0].clientX);
  }

  function handleTouchMove(event) {
    if (pressed) {
      setEndX(event.touches[0].clientX);
    }
  }

  function resetPosition() {
    setEndX(0);
    setStartX(0);
  }

  function handleTouchEnd(event) {
    setPressed(false);
    if (Math.abs(endX) > 0) {
      const sign = endX - startX > 0 ? 1 : -1;
      const swipeLength = Math.abs((endX - startX) / window.innerWidth);
      if (swipeLength >= limitSwipeLength) {
        setEndX(endX + 50 * sign);
        setCurrentIndex(currentIndex === 0 ? 1 : 0);
      } else {
        setEndX(0);
        setStartX(0);
      }
    } else {
      setEndX(0);
      setStartX(0);
    }
  }

  useEffect(() => {
    carousel.current.addEventListener('touchstart', handleTouchStart);
    carousel.current.addEventListener('touchmove', handleTouchMove);
    carousel.current.addEventListener('touchend', handleTouchEnd);

    return () => {
      carousel.current.removeEventListener('touchstart', handleTouchStart);
      carousel.current.removeEventListener('touchmove', handleTouchMove);
      carousel.current.removeEventListener('touchend', handleTouchEnd);
    };
  }, [startX, endX, pressed]);

  const dragPositionX =
    endX && endX !== startX
      ? {
          transform: `translateX(${endX - startX}px)`,
        }
      : {};

  const getTitle = (title) => {
    switch (title) {
      case 'equipment_check':
        return 'Equipment check';
      case 'technical_check':
        return 'Technical check';
      case 'safety_commissionning':
      case 'safety_commissioning':
        return 'Safety commissioning';
      case 'maintenance_request':
        return 'Maintenance request';
      default:
        return title;
    }
  };

  return (
    <div>
      <SwitchTransition mode={'out-in'}>
        <CSSTransition
          onExited={resetPosition}
          key={currentIndex}
          timeout={300}
          classNames="fade"
        >
          <div ref={carousel}>
            {currentIndex === 0 && (
              <div className={style['carousel-history']} style={dragPositionX}>
                <header className={style['carousel-history-header']}>
                  <figure>
                    <img
                      src={
                        ['Response', 'Complete', 'PASS'].includes(item.status)
                          ? successImage
                          : failImage
                      }
                      alt="status"
                    />
                  </figure>
                  <div className={style['carousel-history-header-data']}>
                    <div className={style['carousel-history-header-category']}>
                      <span>{getTitle(item.app_name)}</span>
                      <p className={style['caousel-history-status']}>
                        {item.status.toLowerCase()}
                      </p>
                    </div>
                    <span className={style['carousel-history-season']}>
                      {item.season}
                    </span>
                  </div>
                </header>
                <div className={style['carousel-history-content']}>
                  <div className={style['carousel-history-content-by']}>
                    <div className={style['carousel-history-item']}>
                      <span className={style['carousel-history-item-category']}>
                        Technician
                      </span>
                      <p>{item.user}</p>
                    </div>
                    <div className={style['carousel-history-item']}>
                      <span className={style['carousel-history-item-category']}>
                        Date
                      </span>
                      <time dateTime={item.date}>
                        {dayjs(item.date).format('lll')}
                      </time>
                    </div>
                  </div>
                  <div className={style['carousel-history-content-label']}>
                    <div className={style['carousel-history-item']}>
                      <span className={style['carousel-history-item-category']}>
                        Label
                      </span>
                      <p>{item.label || <i>Unavailable</i>}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentIndex === 1 && (
              <div className={style['carousel-history']} style={dragPositionX}>
                <header className={style['carousel-history-header']}>
                  <img
                    src={
                      ['Response', 'Complete', 'PASS'].includes(item.status)
                        ? successImage
                        : failImage
                    }
                    alt="status"
                  />
                  <div className={style['carousel-history-header-data']}>
                    <div className={style['carousel-history-header-category']}>
                      <span>{getTitle(item.app_name)}</span>
                      <p className={style['caousel-history-status']}>
                        {item.status.toLowerCase()}
                      </p>
                    </div>
                    <span className={style['carousel-history-season']}>
                      {item.season}
                    </span>
                  </div>
                </header>
                <div className={style['carousel-history-content']}>
                  {Object.keys(item.details).map((key, j) => (
                    <div className={style['carousel-history-content-label']}>
                      <div className={style['carousel-history-item']}>
                        <span
                          className={style['carousel-history-item-category']}
                        >
                          {key}
                        </span>
                        <p>{item.details[key] || <i>Unavailable</i>}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <div className={style['dots-wrapper']}>
        <div className={style['dots']}>
          {[...Array(2)].map((_, i) => (
            <div
              key={i}
              className={classnames(
                style['dot'],
                i === currentIndex && style['active'],
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
