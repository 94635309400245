export default (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0249 21.9998C11.6249 21.9998 11.2833 21.8581 10.9999 21.5748C10.7166 21.2915 10.5749 20.9498 10.5749 20.5498V18.2748H4.42493C4.12493 18.2748 3.89993 18.1415 3.74993 17.8748C3.59993 17.6081 3.6166 17.3498 3.79993 17.0998L7.72493 11.4248H6.79993C6.49993 11.4248 6.27493 11.2915 6.12493 11.0248C5.97493 10.7581 5.9916 10.4998 6.17493 10.2498L11.3749 2.8748C11.4583 2.75814 11.5541 2.6748 11.6624 2.6248C11.7708 2.5748 11.8833 2.5498 11.9999 2.5498C12.1166 2.5498 12.2291 2.5748 12.3374 2.6248C12.4458 2.6748 12.5416 2.75814 12.6249 2.8748L17.8249 10.2498C18.0083 10.4998 18.0249 10.7581 17.8749 11.0248C17.7249 11.2915 17.4999 11.4248 17.1999 11.4248H16.2999L20.1999 17.0998C20.3833 17.3498 20.3999 17.6081 20.2499 17.8748C20.0999 18.1415 19.8749 18.2748 19.5749 18.2748H13.4499V20.5748C13.4499 20.9748 13.3124 21.3123 13.0374 21.5873C12.7624 21.8623 12.4249 21.9998 12.0249 21.9998ZM5.89993 16.7748H10.6249H8.37493H15.6249H13.3999H18.1249H5.89993ZM5.89993 16.7748H18.1249L13.3999 9.9248H15.6249L11.9999 4.7748L8.37493 9.9248H10.6249L5.89993 16.7748Z"
        fill={props.color ? props.color : '#194989'}
      />
    </svg>
  );
};
