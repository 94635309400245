import React from 'react';
import { connect } from 'react-redux';
import { fetchMv } from '@store/actions/action-mv';
import { debugMv } from '@lib/debug';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs().format('L LT');
import { withTranslation } from 'react-i18next';

// Layout
import Layout from '@layout/default';

// Store
import { sendFlashMessage } from '@store/actions/action-common';

// Components
import MvForm from '../MvForm';
import { Linear } from '@components/Loader';
import FlashMessage from '@components/common/FlashMessage';
import ContentHeader from '@components/headers/ContentHeader';
import RedirectionButton from '@components/buttons/RedirectionButton';
import Amendments from '@components/icons/Amendments';
import Arrow from '@components/icons/Arrow';

import style from './style.module.scss';
class MvEditForm extends React.Component {
  static propTypes = {
    initialValues: PropTypes.object,
    dispatch: PropTypes.func,
    t: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  };

  constructor(props) {
    super(props);

    this.backToList = this.backToList.bind(this);
  }

  componentDidMount() {
    debugMv(
      'MvEditForm: componentDidMount, this.props.match: ',
      this.props.match,
    );

    // Fetching MV by its ID.
    this.props.dispatch(fetchMv(this.props.match.params.id)).catch((err) => {
      // Custom message for 404 only.
      if (err && err.code === 404) {
        err.message = `Sorry, we could not find the transfer ID ${this.props.match.params.id}`;
      }
      this.props.dispatch(sendFlashMessage(`${err}`, 'error'));
      this.props.history.push('/mv');
    });
  }

  backToList() {
    this.props.history.push('/mv');
  }

  render() {
    debugMv('MvEditForm: rendering MvEditForm...');

    const { initialValues, match, t } = this.props;

    // ! Document title
    document.title = `EUROPEAN CAMPING GROUP | ${this.props.t(
      'page.transfer.document_title',
      'Transfer',
    )} ${initialValues?.barcode}`;

    if (initialValues._id !== match.params.id) {
      // return
      return (
        <Layout {...this.props}>
          <Linear {...this.props} />
        </Layout>
      );
    } else {
      const {
        barcode,
        id_chassis: chassisID,
        created_at,
        created_by,
      } = this.props.initialValues;

      return (
        <Layout {...this.props}>
          <FlashMessage />
          <div className={style['transfer-form-wrapper']}>
            <div className={style['upper-header']}>
              <RedirectionButton
                icon={Arrow}
                action={this.backToList}
                label={t(
                  'common.action.go_back_transfers',
                  'Go back to amendments section',
                )}
              />
            </div>
            <ContentHeader
              icon={Amendments}
              title={`${barcode} - ${chassisID}`}
              subtitle={`${t('common.label.created_on', 'Created on')} ${dayjs(
                created_at,
              ).format('lll')} ${
                created_by?.username ? 'by ' + created_by.username : ''
              }`}
            />
            <MvForm {...this.props} isEdit={true} />
          </div>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) =>
  // console.log('MvEditForm => mapStateToProps,  state=', state)
  ({
    // Init form data from the Model in our store.
    initialValues: state.mv,
    refDatas: state.refDatas.data,
  });

export default withTranslation()(connect(mapStateToProps)(MvEditForm));
