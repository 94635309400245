const FullScreen = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#a)">
        <path
          d="M3.8 14.3v-3.7h1v2.5h2.6v1.2H3.7Zm0-7V3.9h3.6v1H4.9v2.6H3.7Zm6.8 7V13h2.5v-2.5h1.2v3.7h-3.7Zm2.5-7V5h-2.5V3.7h3.7v3.7H13Z"
          fill={props.color ? props.color : '#333333'}
        />
      </g>
    </svg>
  );
};

export default FullScreen;
