const Dashboard = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4375 5.25V2.1875C7.4375 2.06111 7.47882 1.9566 7.56146 1.87396C7.6441 1.79132 7.74861 1.75 7.875 1.75H11.8125C11.9389 1.75 12.0434 1.79132 12.126 1.87396C12.2087 1.9566 12.25 2.06111 12.25 2.1875V5.25C12.25 5.37639 12.2087 5.4809 12.126 5.56354C12.0434 5.64618 11.9389 5.6875 11.8125 5.6875H7.875C7.74861 5.6875 7.6441 5.64618 7.56146 5.56354C7.47882 5.4809 7.4375 5.37639 7.4375 5.25ZM1.75 7V2.1875C1.75 2.06111 1.79132 1.9566 1.87396 1.87396C1.9566 1.79132 2.06111 1.75 2.1875 1.75H6.125C6.25139 1.75 6.3559 1.79132 6.43854 1.87396C6.52118 1.9566 6.5625 2.06111 6.5625 2.1875V7C6.5625 7.12639 6.52118 7.2309 6.43854 7.31354C6.3559 7.39618 6.25139 7.4375 6.125 7.4375H2.1875C2.06111 7.4375 1.9566 7.39618 1.87396 7.31354C1.79132 7.2309 1.75 7.12639 1.75 7ZM7.4375 11.8125V7C7.4375 6.87361 7.47882 6.7691 7.56146 6.68646C7.6441 6.60382 7.74861 6.5625 7.875 6.5625H11.8125C11.9389 6.5625 12.0434 6.60382 12.126 6.68646C12.2087 6.7691 12.25 6.87361 12.25 7V11.8125C12.25 11.9389 12.2087 12.0434 12.126 12.126C12.0434 12.2087 11.9389 12.25 11.8125 12.25H7.875C7.74861 12.25 7.6441 12.2087 7.56146 12.126C7.47882 12.0434 7.4375 11.9389 7.4375 11.8125ZM1.75 11.8125V8.75C1.75 8.62361 1.79132 8.5191 1.87396 8.43646C1.9566 8.35382 2.06111 8.3125 2.1875 8.3125H6.125C6.25139 8.3125 6.3559 8.35382 6.43854 8.43646C6.52118 8.5191 6.5625 8.62361 6.5625 8.75V11.8125C6.5625 11.9389 6.52118 12.0434 6.43854 12.126C6.3559 12.2087 6.25139 12.25 6.125 12.25H2.1875C2.06111 12.25 1.9566 12.2087 1.87396 12.126C1.79132 12.0434 1.75 11.9389 1.75 11.8125ZM2.625 6.5625H5.6875V2.625H2.625V6.5625ZM8.3125 11.375H11.375V7.4375H8.3125V11.375ZM8.3125 4.8125H11.375V2.625H8.3125V4.8125ZM2.625 11.375H5.6875V9.1875H2.625V11.375Z"
      fill={props.color ? props.color : '#333333'}
    />
  </svg>
);
export default Dashboard;
