import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
// import Select from 'react-select'
import SelectList from '../helpers/SelectList'
import _ from 'lodash'
// import { debugCommon } from '../../lib/debug'
import RefDatas from '../../lib/RefDatas';
// import { groupStyles, groupBadgeStyles } from '../helpers/SelectStyles'

export default class MultiSelectFilter extends Component {
  static propTypes = {
    options: PropTypes.array, // can be array or object
    group: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    // this.formatGroupLabel = this.formatGroupLabel.bind(this)

    this.state = {
      selectedValues: null,
    }
  }

  isFilterActive() {
    const { selectedValues } = this.state
    return !_.isEmpty(selectedValues)
  }

  getModel() {
    // debugCommon('MultiSelectFilter: getModel, options, selectedValues', this.state.options, this.state.selectedValues)
    return { value: this.state.selectedValues }
  }

  setModel(model) {
    // console.log('MultiSelectFilter: setModel= ', model, 'this.props=', this.props)
    // special case : build model from values only
    if (model && model.id && Array.isArray(model.id)) {
      let lv = []
      model.id.forEach(id => {
        lv.push(RefDatas.getValueObjFromId(id, this.props.options, this.props.group, 'value'))
      })
      this.setState({ selectedValues: lv }, () => {
        this.props.filterChangedCallback()
      })
    } else {
      // default case : should contain a "value" property
      this.setState({ selectedValues: model ? model.value : [] })
    }
  }

  onFloatingFilterChanged({ model }) {
    // debugCommon('MultiSelectFilter: onFloatingFilterChanged: model =', model)
    const selectedValues = model ? model.value : []
    this.onChange(selectedValues)
  }

  onChange(selectedValues) {
    // debugCommon(`MultiSelectFilter : onChange:`, selectedValues, 'this.state: ', this.state)
    if (!_.isEqual(this.state.selectedValues, selectedValues)) {
      // debugCommon('onChange => this.state != selectedValues', this.state)
      this.setState(
        {
          selectedValues,
        },
        () => {
          // debugCommon('MultiSelectFilter => calling this.props.filterChangedCallback')
          this.props.filterChangedCallback()
        },
      )
    }
  }

  render() {
    const { selectedValues } = this.state
    const options = this.props.options

    // debugCommon('MultiSelectFilter render options: ', options)
    return (
      <SelectList
        selected={selectedValues}
        onChange={this.onChange}
        options={options}
        isClearable={true}
        isMulti={true}
        isSearchable={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={true}
        menuIsOpen={true}
        group={this.props.group}
        autoFocus={true}
      />
    )
  }
}
