import React, { useState, useEffect } from 'react';
import style from './style.module.scss';
import CookieButton from '@components/buttons/CookieButton';
import ToggleButton from '@components/buttons/ToggleButton';

export default (props) => {
  const { children } = props;
  const [darkmode, setDarkmode] = useState(
    JSON.parse(localStorage.getItem('prefers-color-scheme')) || false,
  );

  if (darkmode) {
    document.body.classList.add('dark-theme');
    document.documentElement.setAttribute('data-theme', 'dark');
  }

  function toggleMode() {
    if (darkmode) {
      setDarkmode(false);
      localStorage.setItem('prefers-color-scheme', false);
      document.documentElement.setAttribute('data-theme', '');
    } else {
      setDarkmode(true);
      localStorage.setItem('prefers-color-scheme', true);
      document.body.classList.add('dark-theme');
      document.documentElement.setAttribute('data-theme', 'dark');
    }
  }

  return (
    <main className={style.main}>
      <div className={style['layout-content']}>{children}</div>
      <CookieButton
        history={history}
        acceptedLabel={'Yes'}
        // acceptedAction={() => window.location.reload()}
        declinedLabel={'No'}
        declinedAction={() => (window.location.href = 'https://www.google.fr')}
      />

      {/* <footer>
        <ToggleButton action={toggleMode} active={darkmode} />
      </footer> */}
    </main>
  );
};
