import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { submitSave } from '@pages/pitchFees/grid/submit';
import classnames from 'classnames';
import ReactGA from 'react-ga4';
import { isAllowed } from '@security/User';

// Components
import { AllowedBlock } from '@components/common/AllowedBlock';
import Undo from '@components/icons/Undo';
import RenderField from '@components/form/RenderField';
import Terms from '@components/form/Terms';
import TermsProcessing from '@components/form/Terms/Processing';
import SuperSelect from '@components/form/SuperSelect';
import AllowedField from '@components/form/AllowedField';
import dayjs from 'dayjs';
import Save from '@components/icons/Save';
import SaveV2 from '@components/icons/SaveV2';
import Trash from '@components/icons/Trash';
import BaseNavigation from '@components/navigations/BaseNavigation';
import FormButton from '@components/buttons/FormButton';
import ArticleHeader from '@components/headers/ArticleHeader';
import { getUserSetting } from '@security/User';

// Store
import { remove } from '@store/actions/action-pitchFees';
import { debugMh } from '@lib/debug';

import style from './style.module.scss';

import locationJSON from '@assets/lottie/location';
import brandJSON from '@assets/lottie/brand';
import technicalJSON from '@assets/lottie/technical';
import commentsJSON from '@assets/lottie/comments';
import seasonJSON from '@assets/lottie/season.json';
import accountingJSON from '@assets/lottie/accounting';

export var commonFormPropTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func,
  classes: PropTypes.object,
  pristine: PropTypes.bool,
  dirty: PropTypes.bool,
  submitting: PropTypes.bool,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  error: PropTypes.any,
  initialValues: PropTypes.object,
  dispatch: PropTypes.func,
  refDatas: PropTypes.object,
  isEdit: PropTypes.bool,
  dispTextButton: PropTypes.string,
};

class Form extends React.Component {
  static propTypes = {
    ...commonFormPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      addedData: {},
      deletedData: {},
      refreshedData: {},
      currentIndex: 0,
      triggeredAction: false,
      btl: false,
      issues_reporting: this.props.initialValues?.issues_reporting,
      payment_plan:
        this.props.isEdit && this.props.initialValues?.terms?.plan
          ? this.props.initialValues?.terms?.plan.map((item) => ({
              date: new Date(item.date),
              value: item.value,
              locked: item.locked,
              validated_by: item.validated_by,
              validation_process: item.validation_process,
            }))
          : [{ date: new Date(), value: 100, locked: false }],
      payment_actual:
        this.props.isEdit && this.props.initialValues?.terms?.actual
          ? this.props.initialValues?.terms?.actual.map((item) => ({
              date: new Date(item.date),
              value: item.value,
              locked: item.locked,
              validated_by: item.validated_by,
              validation_process: item.validation_process,
            }))
          : [{ date: new Date(), value: 0, locked: false }],
    };

    this.backToList = this.backToList.bind(this);
    this.goToUnit = this.goToUnit.bind(this);
    this.saveAndBack = this.saveAndBack.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.updateIssueReporting = this.updateIssueReporting.bind(this);
    this.save = this.save.bind(this);

    // ! TABS
    const { refDatas, t, isEdit } = this.props;
    this.tabs = [
      {
        label: t('form.pitch_fees.tab.characteristics', 'Characteristics'),
        href: '#main',
        sections: [
          {
            title: t('form.transfer.label.season', 'Season'),
            file: seasonJSON,
            fields: [
              {
                name: 'pitch-fee.season',
                component: SuperSelect,
                label: t('form.transfer.label.season', 'Season'),
                nativeValues: (() => {
                  const currentYear = new Date().getFullYear();
                  let seasons = Array.from(
                    { length: 20 },
                    (_, i) => currentYear - 10 + i,
                  );
                  return seasons.map((season) => {
                    return { val: season, label: season.toString() };
                  });
                })(),
              },
            ],
          },
          {
            title: t('form.pitch_fees.panel.location', 'Location info'),
            file: locationJSON,
            fields: [
              {
                name: 'pitch-fee.site',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t('form.pitch_fees.label.campsite.title', 'Campsite'),
                refKey: 'Site',
              },
            ],
          },
          {
            title: t('form.pitch_fees.panel.commercial', 'Commercial info'),
            file: brandJSON,
            fields: [
              {
                name: 'pitch-fee.commercial_brand',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.pitch_fees.label.commercial_brand',
                  'Commercial brand',
                ),
                refKey: 'RefMarqueCommerciale',
              },
              {
                name: 'pitch-fee.product_type',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.pitch_fees.label.contracting_product_types',
                  'Product types',
                ),
                refKey: 'RefContractingProductTypes',
              },
              {
                name: 'pitch-fee.tax_rate',
                component: RenderField,
                type: 'number',
                label: t('form.pitch_fees.label.tax_rate', 'Tax rate'),
              },
              {
                name: 'pitch-fee.provisional_rate',
                component: RenderField,
                type: 'number',
                label: t(
                  'form.pitch_fees.label.provisional_rate',
                  'Provisional rate',
                ),
              },
            ],
          },
          {
            title: t('form.pitch_fees.panel.quantity', 'Quantity'),
            file: technicalJSON,
            fields: [
              {
                name: 'pitch-fee.quantity.budget',
                component: RenderField,
                type: 'number',
                label: t('form.pitch_fees.label.budget', 'Budget'),
              },
              {
                name: 'pitch-fee.quantity.actual',
                component: RenderField,
                type: 'number',
                label: t('form.pitch_fees.label.actual', 'Actual'),
              },
            ],
          },
          {
            title: t(
              'form.pitch_fees.panel.unit_price_excluding_tax',
              'Unit price excluding tax',
            ),
            file: technicalJSON,
            fields: [
              {
                name: 'pitch-fee.unit_price_excluding_tax.budget',
                component: RenderField,
                type: 'number',
                label: t('form.pitch_fees.label.budget', 'Budget'),
              },
              {
                name: 'pitch-fee.unit_price_excluding_tax.actual',
                component: RenderField,
                type: 'number',
                label: t('form.pitch_fees.label.actual', 'Actual'),
              },
            ],
          },
          // {
          //   title: t(
          //     'form.pitch_fees.panel.fees_excluding_tax',
          //     'Fees excluding tax',
          //   ),
          //   file: technicalJSON,
          //   fields: [
          //     {
          //       name: 'pitch-fee.fees_excluding_tax.budget',
          //       component: RenderField,
          //       type: 'number',
          //       label: t('form.pitch_fees.label.budget', 'Budget'),
          //     },
          //     {
          //       name: 'pitch-fee.fees_excluding_tax.actual',
          //       component: RenderField,
          //       type: 'number',
          //       label: t('form.pitch_fees.label.actual', 'Actual'),
          //     },
          //   ],
          // },
        ],
      },
      isEdit && {
        label: t('form.pitch_fees.tab.terms', 'Terms'),
        href: '#term',
        sections: [
          {
            title: 'Dates of payment',
            file: accountingJSON,
            fields: [
              {
                name: 'pitch-fee.terms.plan',
                component: Terms,
                type: 'string',
                label: t(
                  'form.pitch_fees.label.scheduled_plans',
                  'Scheduled Plans',
                ),
                defaultValue: this.state.payment_plan,
                updateTerms: (dates) => {
                  this.setState({ payment_plan: dates });
                },
              },
              {
                name: 'pitch-fee.terms.actual',
                component: Terms,
                type: 'string',
                label: t(
                  'form.pitch_fees.label.actual_plans',
                  'Currently paid',
                ),
                defaultValue: this.state.payment_actual,
                updateTerms: (dates) => {
                  this.setState({ payment_actual: dates });
                },
                subtitle:
                  (this.state.payment_plan.some((term) => !term.locked) ||
                    this.state.payment_plan.length === 0) &&
                  'Each term has to be validated first to 100%.',
                disabled:
                  this.state.payment_plan.some((term) => !term.locked) ||
                  this.state.payment_plan.length === 0,
              },
            ],
          },
          isEdit && {
            title: 'Dates of payment',
            file: accountingJSON,
            fields: [
              {
                name: 'pitch-fee.terms.chart',
                component: TermsProcessing,
                totalPrice: this.state.totalPrice,
                siblings: this.props.initialValues?.siblings,
                actualValues: this.state.payment_actual,
                plannedValues: this.state.payment_plan,
              },
            ],
          },
        ],
      },
      {
        label: t('form.pitch_fees.tab.comments', 'Comments'),
        href: '#comments',
        sections: [
          {
            title: 'Tell us more about the update',
            file: commentsJSON,
            fields: [
              {
                name: 'pitch-fee.notes',
                component: RenderField,
                multiline: true,
                rows: 4,
                label: t('form.pitch_fees.label.notes', 'Notes'),
              },
            ],
          },
        ],
      },
    ];

    // Some tabs are available only on Edit version
    this.tabs = this.tabs.filter((tab) => tab);
  }

  changeTab(index) {
    const { t } = this.props;
    const tab_names = [
      t('form.pitch_fees.tab.characteristics', 'Characteristics'),
      t('form.pitch_fees.tab.comment', 'Comment'),
    ];

    ReactGA.event('pitch_fees_details_navigation', {
      tab_name: tab_names[index],
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });

    this.setState({ currentIndex: index });
  }

  saveAndBack(data) {
    const { isEdit } = this.props;
    const sum = this.state.payment_plan
      ?.map((item) => item.value)
      ?.reduce((a, b) => Number(a) + Number(b));
    if (data?.season && data?.site && sum === 100) {
      // Exception for terms
      if (isEdit) {
        data['terms.plan'] = this.state.payment_plan;
        data['terms.actual'] = this.state.payment_actual;
      }
    } else {
      window.alert(
        'Could you kindly ensure that the season and location fields are filled? Also the sum of terms must be equal to 100 %.',
      );
      return;
    }

    // ! GA
    ReactGA.event('pitch_fees_details_form_buttons', {
      user_interaction:
        'Saving data and automatically going back to units page',
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });

    // ! Saving
    return submitSave(data, this.backToList, this.props.t);
  }

  save(data) {
    const { isEdit } = this.props;
    if (isEdit && data?.season && data?.site) {
      data['terms.actual'] = this.state.payment_actual;
      data['terms.plan'] = this.state.payment_plan;
    }
    ReactGA.event('pitch_fees_details_form_buttons', {
      user_interaction: 'Saving data',
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });

    return submitSave(data, () => this.props.refreshData(), this.props.t);
  }

  goToUnit(_id) {
    this.props.history.push(`/pitch-fees/${_id}`);
  }

  backToList() {
    ReactGA.event('pitch_fees_details_form_buttons', {
      user_interaction: 'Going back to units page without saving data',
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });
    this.props.history.push('/pitch-fees');
  }

  updateIssueReporting(index) {
    const update = this.state.issues_reporting.map((issue) => {
      if (issue.index === index) {
        issue.fixed = true;
      }
      return issue;
    });

    this.setState({
      issues_reporting: update,
    });
  }

  handleRemove(e) {
    e.preventDefault();
    this.setState({ triggeredAction: true });

    const ID = this.props.initialValues._id;
    if (
      window.confirm(
        this.props.t('form.pitch_fees.confirm.removing', { id: ID }),
      )
    ) {
      ReactGA.event('pitch_fees_details_form_buttons', {
        user_interaction: 'Delete a unit',
        user_id: getUserSetting('_id').toString(),
        dateTime: dayjs().format(),
      });

      this.props.dispatch(remove(ID, this.backToList));
    } else {
      this.setState({ triggeredAction: false });
    }
  }

  render() {
    const { submitting, reset, handleSubmit, t, isEdit } = this.props;
    const { currentIndex, triggeredAction } = this.state;

    debugMh('Rendering MhForm... props=', this.props);
    const rolesAllowed = ['ROLE_WRI_PITCH_FEES'];

    return (
      <section className={style['form-section']}>
        <div className={style['form-section-base-navigation']}>
          <BaseNavigation
            items={this.tabs.map((tab) => tab.label)}
            currentIndex={currentIndex}
            updateIndex={this.changeTab}
          />
        </div>
        <div
          className={classnames(
            style['form'],
            isAllowed([...rolesAllowed, 'ROLE_DEL_PITCH_FEES'], true)
              ? style['edition']
              : '',
          )}
        >
          <form onSubmit={handleSubmit(this.saveAndBack)}>
            {/* {isEdit && (
              <Prompt
                when={this.props.dirty}
                message={t('common.label.warning_leave_form_no_save')}
              />
            )} */}
            <div className={style['content-container']}>
              {this.tabs.map((tab, _i) => (
                <div
                  key={_i}
                  className={classnames(
                    currentIndex !== _i ? style['hide'] : style['display'],
                    tab.sections.length === 1
                      ? style['grid-one-item']
                      : undefined,
                  )}
                >
                  {tab.sections.map((section, index) => (
                    <article
                      id={section.title}
                      key={index}
                      className={style['form-input-block']}
                    >
                      <ArticleHeader
                        label={section.title}
                        lottieJSON={section.file}
                      />

                      <div className={style['article-content']}>
                        {section.fields
                          ? section.fields.map((field, i) => {
                              return <AllowedField key={i} {...field} />;
                            })
                          : section?.component && section.component}
                      </div>
                    </article>
                  ))}
                </div>
              ))}
            </div>
            <div className="content-footer actions" />
          </form>

          {isAllowed([...rolesAllowed, 'ROLE_DEL_PITCH_FEES'], true) && (
            <div className={style['form-actions']}>
              <div className={style['form-actions-wrapper']}>
                {isEdit && (
                  <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                    <FormButton
                      action={handleSubmit(this.save)}
                      label="Save"
                      icon={Save}
                      disabled={triggeredAction}
                      color="var(--color-surface-octonary)"
                    />
                  </AllowedBlock>
                )}
                <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                  <FormButton
                    action={handleSubmit(this.saveAndBack)}
                    label={t('common.action.save_and_back', 'Save and back')}
                    icon={SaveV2}
                    disabled={triggeredAction}
                    color={'var(--color-surface-octonary)'}
                  />
                </AllowedBlock>
                <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                  <FormButton
                    action={reset}
                    label={t('common.action.undo_changes', 'Undo changes')}
                    icon={Undo}
                    disabled={submitting}
                    color={'var(--color-surface-septenary)'}
                  />
                </AllowedBlock>
                {isEdit && (
                  <AllowedBlock roles={'ROLE_DEL_PITCH_FEES'} oneOfThem={true}>
                    <FormButton
                      action={this.handleRemove}
                      label={t('common.action.remove', 'Remove')}
                      icon={Trash}
                      disabled={triggeredAction}
                      color={'var(--color-surface-senary)'}
                    />
                  </AllowedBlock>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

// function onChange(values, dispatch, props, previousValues) {
//   console.log(values, props, previousValues)
// }

Form = reduxForm({
  // a unique name for the form
  form: 'pitch_fees_form',
  // onChange: onChange,
  // https://redux-form.com/7.1.2/examples/initializefromstate/
  enableReinitialize: true,
})(Form);

Form = withTranslation()(Form);

export default Form;
