import React, { Component, Fragment } from 'react';

import ConnectedUsers from './ConnectedUsers';
import Users from '@components/icons/Users';

import style from './style.module.scss';
import ContentHeader from '@components/headers/ContentHeader';
import classnames from 'classnames';

export default ({ users, t }) => {
  return (
    <div className={style['connected-users-wrapper']}>
      <ContentHeader
        icon={Users}
        title={t('page.homepage.online_users.title', 'Online users')}
        subtitle={t(
          'page.homepage.online_users.subtitle',
          'Connected right now on the Asset Register',
        )}
        light={true}
      />
      <div
        className={classnames(
          users.length > 0 ? style['connected-users'] : undefined,
          style['connected-users-content'],
        )}
      >
        {users.length > 0 ? (
          users.map((user, index) => (
            <ConnectedUsers
              key={index}
              name={user.name}
              _id={user._id}
              picture={user.picture}
              logged_in={user.logged_in}
            />
          ))
        ) : (
          <p className={style['no-users']}>
            {t(
              'page.homepage.online_users.no_users',
              'Sorry, but no one is connected',
            )}
          </p>
        )}
      </div>
    </div>
  );
};
