import React from 'react';
import style from './style.module.scss';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
export default (params) => {
  let className = 'success';
  const value = params?.valueFormatted;

  if (value < 90) className = 'moderate';
  if (value < 70) className = 'warning';
  if (value < 50) className = 'danger';
  return (
    <div className={style['percent-cell-renderer']}>
      <Tippy content={`${value}%`} placement="top">
        <div className={style['progress-bar']}>
          <div
            className={classnames(style['progress-fill'], style[className])}
            style={{ width: `${value}%` }}
          />
        </div>
      </Tippy>
    </div>
  );
};
