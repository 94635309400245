import React, { useState } from 'react';
import style from './style.module.scss';
import classnames from 'classnames';

function ToggleButton(props) {
  const { active, action, icon: Icon } = props;
  return (
    <button
      type="button"
      onClick={action}
      className={classnames(style['toggle'], active ? style['active'] : '')}
    >
      <Icon color={active && '#F0932B'} />
    </button>
  );
}

export default ToggleButton;
