const Shrink = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.95623 9.0001C6.95623 9.0626 6.94373 9.12822 6.91873 9.19697C6.89373 9.26572 6.84998 9.33135 6.78748 9.39385L4.21873 11.9626C4.10623 12.0751 3.96873 12.1313 3.80623 12.1313C3.64373 12.1313 3.50623 12.0751 3.39373 11.9626C3.28123 11.8501 3.22498 11.7188 3.22498 11.5688C3.22498 11.4188 3.28123 11.2876 3.39373 11.1751L5.56873 9.0001L3.39373 6.8251C3.28123 6.7126 3.22498 6.57822 3.22498 6.42197C3.22498 6.26572 3.28123 6.13135 3.39373 6.01885C3.50623 5.90635 3.6406 5.8501 3.79685 5.8501C3.9531 5.8501 4.08748 5.90635 4.19998 6.01885L6.78748 8.60635C6.84998 8.66885 6.89373 8.73135 6.91873 8.79385C6.94373 8.85635 6.95623 8.9251 6.95623 9.0001V9.0001ZM14.5875 11.9626C14.475 12.0751 14.3406 12.1313 14.1844 12.1313C14.0281 12.1313 13.8937 12.0751 13.7812 11.9626L11.2125 9.39385C11.15 9.33135 11.1062 9.26572 11.0812 9.19697C11.0562 9.12822 11.0437 9.0626 11.0437 9.0001C11.0437 8.9251 11.0562 8.85635 11.0812 8.79385C11.1062 8.73135 11.15 8.66885 11.2125 8.60635L13.8 6.01885C13.9125 5.90635 14.0437 5.8501 14.1937 5.8501C14.3437 5.8501 14.475 5.90635 14.5875 6.01885C14.7 6.13135 14.7562 6.26885 14.7562 6.43135C14.7562 6.59385 14.7 6.73135 14.5875 6.84385L12.4312 9.0001L14.5875 11.1563C14.7 11.2688 14.7562 11.4032 14.7562 11.5595C14.7562 11.7157 14.7 11.8501 14.5875 11.9626Z"
        fill={props.color ? props.color : '#333333'}
      />
    </svg>
  );
};

export default Shrink;
