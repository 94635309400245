export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.37261 9.0979C4.24792 9.0979 4.14426 9.05897 4.06162 8.9811C3.97898 8.90324 3.93766 8.80676 3.93766 8.69165V8.48853H2.771C2.64704 8.48853 2.54313 8.44937 2.45928 8.37105C2.37542 8.29273 2.3335 8.19568 2.3335 8.07991C2.3335 7.96412 2.37542 7.86787 2.45928 7.79113C2.54313 7.71439 2.64704 7.67603 2.771 7.67603H5.54183V6.25415H2.78558C2.66891 6.25415 2.5644 6.21392 2.47204 6.13347C2.37968 6.053 2.3335 5.9533 2.3335 5.83436V3.61353C2.3335 3.49842 2.37725 3.40194 2.46475 3.32407C2.55225 3.24621 2.65433 3.20728 2.771 3.20728H3.93766V3.00415C3.93766 2.88905 3.97983 2.79256 4.06417 2.7147C4.14852 2.63683 4.25304 2.5979 4.37771 2.5979C4.5024 2.5979 4.60607 2.63683 4.6887 2.7147C4.77134 2.79256 4.81266 2.88905 4.81266 3.00415V3.20728H5.97933C6.10329 3.20728 6.20719 3.24643 6.29105 3.32475C6.3749 3.40307 6.41683 3.50012 6.41683 3.6159C6.41683 3.73168 6.3749 3.82794 6.29105 3.90467C6.20719 3.98141 6.10329 4.01978 5.97933 4.01978H3.2085V5.44165H5.96475C6.09284 5.44165 6.2002 5.48002 6.28685 5.55675C6.3735 5.63349 6.41683 5.73505 6.41683 5.86144V8.08228C6.41683 8.19061 6.3749 8.2854 6.29105 8.36665C6.20719 8.4479 6.10329 8.48853 5.97933 8.48853H4.81266V8.69165C4.81266 8.80676 4.77049 8.90324 4.68615 8.9811C4.6018 9.05897 4.49729 9.0979 4.37261 9.0979ZM8.13572 12.1854C8.07868 12.1854 8.02586 12.1764 7.97725 12.1583C7.92863 12.1403 7.88002 12.1087 7.83141 12.0635L5.96475 10.3302C5.87725 10.2489 5.8335 10.1542 5.8335 10.0458C5.8335 9.93748 5.87725 9.84269 5.96475 9.76144C6.05225 9.68019 6.15433 9.63957 6.271 9.63957C6.38766 9.63957 6.48975 9.68019 6.57725 9.76144L8.13766 11.2104L11.3314 8.24478C11.4189 8.16353 11.521 8.1229 11.6377 8.1229C11.7543 8.1229 11.8564 8.16353 11.9439 8.24478C12.0314 8.32603 12.0752 8.42082 12.0752 8.52915C12.0752 8.63748 12.0314 8.73228 11.9439 8.81353L8.44391 12.0635C8.3953 12.1087 8.34604 12.1403 8.29614 12.1583C8.24623 12.1764 8.19275 12.1854 8.13572 12.1854Z"
        fill={props.color ? props.color : '#333333'}
      />
    </svg>
  );
};
