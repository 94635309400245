import style from './style.module.scss';
import ContentHeader from '@components/headers/ContentHeader';
import { withTranslation } from 'react-i18next';
import BaseNavigation from '@components/navigations/BaseNavigation';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { has, isBoolean } from 'lodash';
import BaseButton from '@components/buttons/BaseButton';
import Cottage from '@components/icons/Cottage';
import Unit from '@components/icons/Unit';

const UnitSearched = ({ unit, t, handleClose, handleRedirection }) => {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setData([
      {
        title: t('form.unit.panel.location', 'Location info'),
        fields: [
          {
            label: t('form.unit.label.campsite.title', 'Campsite'),
            fieldName: 'site.lib',
          },
          {
            label: t('form.unit.label.region', 'Region'),
            fieldName: 'site.loca.region',
          },
          {
            label: t('form.unit.label.country', 'Country'),
            fieldName: 'site.loca.country',
          },
          {
            fieldName: 'site.is_propre',
            label: t('form.unit.label.is_own', 'Own campsite'),
          },
          {
            fieldName: 'pitch',
            label: t('form.unit.label.pitch', 'Pitch'),
          },
          {
            fieldName: 'pitch_corres',
            label: t('form.unit.label.pitch_corres', 'Pitch corresponding'),
          },
          {
            fieldName: 'barcode',
            label: t('form.unit.label.barcode'),
          },
          {
            fieldName: 'old_id_homair',
            label: t('form.unit.label.old_id_homair', 'Old ID Homair'),
          },
          {
            fieldName: 'id_chassis',
            label: t('form.unit.label.chassis_id', 'Chassis ID'),
          },
        ],
      },
      {
        title: t('form.unit.panel.commercial', 'Commercial_info'),
        fields: [
          {
            fieldName: 'mh_model.lib',
            label: t('form.unit.label.unit_model.title', 'Unit model'),
          },
          {
            fieldName: 'use_type.lib',
            label: t('form.unit.label.use_type', 'Use type'),
          },
          {
            fieldName: 'commercial_brand.lib',
            label: t('form.unit.label.commercial_brand', 'Commercial brand'),
          },
          {
            fieldName: 'commercial_type.lib',
            label: t('form.unit.label.commercial_type', 'Commercial type'),
          },
          {
            fieldName: 'commercial_type.code',
            label: t('form.unit.label.commercial_code', 'Commercial code'),
          },
          {
            fieldName: 'commercial_type.is_clim',
            label: t('form.unit.label.ac', 'Air conditioning'),
          },
          {
            fieldName: 'commercial_type.is_terrasse',
            label: t('form.unit.label.deck', 'Deck'),
          },
          {
            fieldName: 'commercial_type.dishwasher',
            label: t('form.unit.label.dishwasher', 'Dishwasher'),
          },
        ],
      },
      {
        title: t('form.unit.panel.technical', 'Technical info'),
        fields: [
          {
            fieldName: '_id',
            label: t('form.unit.label.unit_id', 'Unit ID'),
          },
          {
            fieldName: 'mh_model.width',
            label: t('form.unit.label.width', 'Unit width (m)'),
          },
          {
            fieldName: 'mh_model.length',
            label: t('form.unit.label.length', 'Unit length (m)'),
          },
          {
            fieldName: 'deck_type.lib',
            label: t('form.unit.label.deck_type', 'Deck type'),
          },
          {
            fieldName: 'deck_size',
            label: t('form.unit.label.deck_size', 'Deck size'),
          },
          {
            fieldName: 'deck_year',
            label: t('form.unit.label.deck_year', 'Deck year'),
          },
          {
            fieldName: 'deck_manufacturer.lib',
            label: t('form.unit.label.deck_manufacturer', 'Deck manufacturer'),
          },
          {
            fieldName: 'gas_supply_type.lib',
            label: t(
              'form.unit.label.energy_supply_type',
              'Energy supply type',
            ),
          },
          {
            fieldName: 'cladding_color.lib',
            label: t('form.unit.label.cladding_color', 'Cladding color'),
          },
          {
            fieldName: 'cladding_material.lib',
            label: t('form.unit.label.cladding_material', 'Cladding material'),
          },
          {
            fieldName: 'mh_model.roof_type.lib',
            label: t('form.unit.label.roof_type', 'Roof type'),
          },
          {
            fieldName: 'perimeter',
            label: t('form.unit.label.perimeter', 'Maintenance perimeter'),
          },
          {
            fieldName: 'key_tag',
            label: t('form.unit.label.ec_key', 'Key tag'),
          },
        ],
      },
      {
        title: t('form.unit.panel.financial', 'Accounting info'),
        fields: [
          {
            fieldName: 'owner.lib',
            label: t('form.unit.label.owner', 'Owner'),
          },
          {
            fieldName: 'renov_year',
            label: t('form.unit.label.renovation_year', 'Renovation year'),
          },
          {
            fieldName: 'renov_status.lib',
            label: t('form.unit.label.renovation_status', 'Renovation status'),
          },
          {
            fieldName: 'fixed_asset_code',
            label: t('form.unit.label.fixed_asset_code', 'Fixed asset code'),
          },
          {
            fieldName: 'purchase_leasing_code',
            label: t(
              'form.unit.label.purchase_leasing_code',
              'Purchase leasing code',
            ),
          },
          {
            fieldName: 'reno_leasing_code',
            label: t(
              'form.unit.label.renovation_leasing_code',
              'Renovation leasing code',
            ),
          },
        ],
      },
    ]);
  }, []);

  function updateIndex(index) {
    setCurrentIndex(index);
  }

  return (
    <div className={style['unit-search']}>
      <div className={style['unit-search-wrapper']}>
        <ContentHeader
          icon={Cottage}
          title={`${t('common.label.unit', 'Unit')} ${
            unit?.barcode ||
            t('common.label.barcode_undefined', 'Barcode not defined')
          } - ${
            unit?.id_chassis ||
            t('common.label.chassis_id_undefined', 'Chassis ID not defined')
          }`}
          subtitle={t(
            'panel.unit_search.subtitle',
            'Characteristics information. For more, go to the unit details view.',
          )}
        />
        <div className={style['unit-search-base-navigation']}>
          <BaseNavigation
            items={data.map((data) => data?.title)}
            currentIndex={currentIndex}
            updateIndex={updateIndex}
          />
        </div>

        <div className={style['unit-search-data']}>
          <ul>
            {data[currentIndex] &&
              data[currentIndex]?.fields.map((field, index) => (
                <li key={index}>
                  <p>
                    <small>{field?.label}</small>
                    {isBoolean(get(unit, field?.fieldName))
                      ? get(unit, field?.fieldName)
                        ? t('common.label.yes', 'Yes')
                        : t('common.label.no', 'No')
                      : has(get(unit, field?.fieldName), 'lib')
                      ? get(unit, `${field?.fieldName}.lib`)
                      : get(unit, field?.fieldName)}
                  </p>
                </li>
              ))}
          </ul>

          <div className={style['buttons-wrapper']}>
            <BaseButton
              action={() => handleRedirection()}
              label={t('common.action.more_details', 'More details')}
            >
              <Unit color="#194989" />
            </BaseButton>
            <button
              className={style['close-button']}
              onClick={() => handleClose()}
            >
              {t('common.action.close', 'Close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(UnitSearched);
