import React from 'react';
import style from './style.module.scss';
import classnames from 'classnames';

const GridButton = ({
  children,
  label,
  action,
  disabled = false,
  danger = false,
}) => {
  return (
    <button
      className={classnames([style['grid-button'], danger && style['danger']])}
      type="button"
      onClick={action}
      disabled={disabled}
    >
      {children && <div className={style['icon-wrapper']}>{children}</div>}
      <span>{label}</span>
    </button>
  );
};

export default GridButton;
