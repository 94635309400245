import React, { useState, useEffect, Fragment } from 'react';
import dayjs from 'dayjs';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
import { diff } from 'deep-object-diff';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const Log = (props) => {
  const { log } = props;
  const { t } = useTranslation();

  const [showObjectDetails, setShowObjectDetails] = useState(false);

  const getFullName = () => {
    return (
      <strong>
        {log.updated_by.firstname} {log.updated_by.lastname}
      </strong>
    );
  };

  const eventName = () => {
    switch (log.event) {
      case 'create':
        return (
          <strong>{t('page.homepage.latest_logs.created', 'created')}</strong>
        );
      case 'update':
        return (
          <strong>{t('page.homepage.latest_logs.updated', 'updated')}</strong>
        );
      case 'delete':
        return (
          <strong>{t('page.homepage.latest_logs.deleted', 'deleted')}</strong>
        );
      default:
        return;
    }
  };

  function toggleObjectDetails() {
    setShowObjectDetails(!showObjectDetails);
  }

  const ObjectDetails = (original, updatedObj) => {
    if (original) {
      return (
        <div className={style['pre-wrapper']}>
          <div>
            <p>
              {t('page.homepage.latest_logs.previous_state', 'Previous state')}:
            </p>
            <pre>{JSON.stringify(diff(updatedObj, original), null, 2)}</pre>
          </div>
          <div>
            <p>
              {t('page.homepage.latest_logs.current_state', 'Current state')}:
            </p>
            <pre>{JSON.stringify(diff(original, updatedObj), null, 2)}</pre>
          </div>
        </div>
      );
    } else {
      return (
        <div className={style['pre-wrapper']}>
          <div>
            <p>
              {log.event === 'delete'
                ? `${t(
                    'page.homepage.latest_logs.deleted_item',
                    'Deleted item',
                  )}:`
                : log.event === 'create'
                ? `${t(
                    'page.homepage.latest_logs.created_item',
                    'Created item',
                  )}:`
                : log.event}
            </p>
            <pre>{JSON.stringify(updatedObj, null, 2)}</pre>
          </div>
        </div>
      );
    }
  };

  return (
    <li className={style['log']}>
      <time dateTime={dayjs(log.updated_at).format('YYYY-MM-DD')}>
        {dayjs(log.updated_at).format('LLL')}
      </time>
      <div className={style['log-content']}>
        {getFullName()} {eventName()}{' '}
        {log.collection_name !== 'transfers' ? (
          <Fragment>
            {t('page.homepage.latest_logs.object_id', 'the ObjectID')} 
            <button type="button" onClick={() => toggleObjectDetails()}>
              {log.current_state._id}
            </button>{' '}
            {t('page.homepage.latest_logs.from', 'from')} {log.collection_name} 
            {t('page.homepage.latest_logs.collection', 'collection')}.
          </Fragment>
        ) : (
          <Fragment>
            {t(
              'page.homepage.latest_logs.an_amendment_for',
              'an amendment for',
            )}{' '}
            {log?.current_state?.mobilhome_ids ? (
              log?.current_state?.mobilhome_ids.map((mh, index) => (
                <Fragment key={index}>
                  {index > 0 && ', '}
                  <button
                    type="button"
                    onClick={() => props.history.push(`/mh/${mh}`)}
                  >
                    {mh}
                  </button>
                </Fragment>
              ))
            ) : (
              <button
                type="button"
                onClick={() =>
                  props.history.push(`/mh/${log?.current_state?.mobilhome_id}`)
                }
              >
                {log?.current_state?.mobilhome_id}
              </button>
            )}
          </Fragment>
        )}
      </div>
      {showObjectDetails &&
        ObjectDetails(log.previous_state, log.current_state)}
    </li>
  );
};

export default Log;
