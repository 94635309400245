export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <path
        d="M2.3 13.5v-1.1H12v1.1H2.2Zm12.7-1L11.4 9 15 5.5l.8.8L13 9l2.8 2.7-.8.8Zm-12.8-3V8.4h7.5v1.1H2.3Zm0-3.9V4.5H12v1.1H2.2Z"
        fill={props.color ? props.color : '#333333'}
      />
    </svg>
  );
};
