import ArticleHeader from '@components/headers/ArticleHeader';
import DataTable from '@components/campsites/InAndOut/DataTable';
import { useTranslation } from 'react-i18next';
import UnitAmendments from '@assets/lottie/unit_amendments.json';
import Judgement from '@assets/lottie/judgment.json';

import style from './style.module.scss';

export default ({ incoming = [], outgoing = [] }) => {
  const { t } = useTranslation();
  return (
    <section className={style['in-and-out-section']}>
      <article>
        <ArticleHeader
          label={t('page.campsite.label.incoming_units', 'Incoming units')}
          description={`${incoming.filter((unit) => !unit.locked).length} ${t(
            'page.campsite.label.pending_units',
            'pending unit(s)',
          )}`}
          lottieJSON={UnitAmendments}
        />
        <div>
          <h3>{t('page.campsite.label.transfers', 'Amendments')}</h3>
          <div className={style['in-and-out-wrapper']}>
            {incoming.length > 0 ? (
              <DataTable data={incoming} />
            ) : (
              <p>
                {t(
                  'page.campsite.label.no_upcoming_changes',
                  'No upcoming changes.',
                )}
              </p>
            )}
          </div>
        </div>
      </article>

      <article>
        <ArticleHeader
          label={t('page.campsite.label.outgoing_units', 'Outgoing units')}
          description={`${outgoing.filter((unit) => !unit.locked).length} ${t(
            'page.campsite.label.pending_units',
            'pending unit(s)',
          )}`}
          lottieJSON={Judgement}
        />
        <div>
          <h3>{t('page.campsite.label.transfers', 'Amendments')}</h3>
          <div className={style['in-and-out-wrapper']}>
            {outgoing.length > 0 ? (
              <DataTable
                data={outgoing}
                headers={['Season', 'Barcode', 'To', 'Status']}
              />
            ) : (
              <p>
                {t(
                  'page.campsite.label.no_upcoming_changes',
                  'No upcoming changes.',
                )}
              </p>
            )}
          </div>
        </div>
      </article>
    </section>
  );
};
