import { useRef, useState } from 'react';
import { getUserSetting } from '@security/User';
import Magnify from '@components/icons/Magnify';
import { getAPIToken } from '@security/User';
import UnitSearched from '@components/common/UnitSearched';
import getStore from '@store/store';
import { fetchUnitsByBarcode } from '@store/actions/action-mh';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import LanguageButton from '@components/buttons/LanguageButton';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

function getFullName() {
  return `${getUserSetting('firstname')} ${getUserSetting('lastname')}`;
}

const MainHeader = (props) => {
  const inputRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [unitFound, setUnitFound] = useState(null);
  const [openUnitFoundPanel, setOpenUnitFoundPanel] = useState(false);
  const timer = null;
  const { t } = useTranslation();

  async function searchByBarcode() {
    // ! Value must be over 3 letters
    if (timer) clearTimeout(timer);
    if (inputRef.current.value.length > 3) {
      if (unitFound) setUnitFound(null);

      // timer = setTimeout(() => {});
      const barcode = inputRef.current.value.toString().trim();
      const units = await getStore().dispatch(fetchUnitsByBarcode(barcode));
      setSuggestions(units);
    } else {
      setSuggestions([]);
    }
  }

  function selectUnit(unit) {
    // ! Clear
    inputRef.current.value = '';
    setSuggestions([]);

    // ! Set
    setUnitFound(unit);
    setOpenUnitFoundPanel(true);
  }

  function clearUnit() {
    setUnitFound(null);
  }

  function redirection() {
    props.history.push(`/mh/${unitFound._id}`);
  }

  return (
    <header
      className={classnames(
        style['main-header'],
        unitFound ? style['panel-opened'] : undefined,
      )}
    >
      <div className={style['main-header-content']}>
        <div className={style['main-header-current-page']}>
          <h1>{document.title.split('|')[1].trim()}</h1>
          <small>
            {t('common.main_header.first_part_hi', 'Howdy')} {getFullName()},{' '}
            {t('common.main_header.second_part_hi', 'welcome back')}
          </small>
        </div>
        <div className={style['main-header-quick-search']}>
          <label>
            <Magnify />
            <div>
              <span>
                {t(
                  'common.main_header.quick_unit_search.title',
                  'Quick unit search',
                )}
              </span>
              <input
                ref={inputRef}
                onChange={searchByBarcode}
                type="text"
                placeholder={t(
                  'common.main_header.quick_unit_search.subtitle',
                  'Enter the barcode',
                )}
              />
            </div>
          </label>
          {suggestions.length > 0 && (
            <div className={style['suggestions']}>
              <h3>
                {t(
                  'common.main_header.suggestions.title',
                  'Select the unit in the list',
                )}
              </h3>
              <ul>
                {suggestions.map((unit) => (
                  <li key={unit._id}>
                    <button type="button" onClick={() => selectUnit(unit)}>
                      {unit.barcode}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className={style['main-header-info']}>
        <LanguageButton />
        <figure className={style['main-header-avatar']}>
          <img
            alt={'Your avatar'}
            title={'Profile picture'}
            src={`${
              process.env.REACT_APP_API_URL
            }/api/get_avatar?access_token=${getAPIToken()}`}
          />
        </figure>
      </div>

      <CSSTransition
        in={openUnitFoundPanel}
        timeout={300}
        classNames="fade"
        unmountOnExit
        onExited={() => clearUnit()}
      >
        <UnitSearched
          unit={unitFound}
          t={props.t}
          handleClose={() => setOpenUnitFoundPanel(false)}
          handleRedirection={redirection}
        />
      </CSSTransition>
    </header>
  );
};

export default MainHeader;
