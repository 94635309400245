const Exports = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99992 9.26046C6.94159 9.26046 6.88811 9.25073 6.8395 9.23129C6.79089 9.21185 6.74228 9.17782 6.69367 9.12921L4.49159 6.92712C4.40409 6.83962 4.36277 6.73511 4.36763 6.61358C4.37249 6.49205 4.41867 6.38754 4.50617 6.30004C4.59367 6.21254 4.69818 6.16879 4.81971 6.16879C4.94124 6.16879 5.04575 6.21254 5.13325 6.30004L6.56242 7.74379V2.77087C6.56242 2.64449 6.60374 2.53997 6.68638 2.45733C6.76902 2.37469 6.87353 2.33337 6.99992 2.33337C7.12631 2.33337 7.23082 2.37469 7.31346 2.45733C7.3961 2.53997 7.43742 2.64449 7.43742 2.77087V7.74379L8.88117 6.30004C8.96867 6.21254 9.07318 6.16879 9.19471 6.16879C9.31624 6.16879 9.42075 6.21254 9.50825 6.30004C9.59575 6.38754 9.6395 6.49205 9.6395 6.61358C9.6395 6.73511 9.59575 6.83962 9.50825 6.92712L7.30617 9.12921C7.25756 9.17782 7.20895 9.21185 7.16034 9.23129C7.11172 9.25073 7.05825 9.26046 6.99992 9.26046ZM3.20825 11.6667C2.97492 11.6667 2.77075 11.5792 2.59575 11.4042C2.42075 11.2292 2.33325 11.025 2.33325 10.7917V9.14379C2.33325 9.0174 2.37457 8.91289 2.45721 8.83025C2.53985 8.74761 2.64436 8.70629 2.77075 8.70629C2.89714 8.70629 3.00165 8.74761 3.08429 8.83025C3.16693 8.91289 3.20825 9.0174 3.20825 9.14379V10.7917H10.7916V9.14379C10.7916 9.0174 10.8329 8.91289 10.9155 8.83025C10.9982 8.74761 11.1027 8.70629 11.2291 8.70629C11.3555 8.70629 11.46 8.74761 11.5426 8.83025C11.6253 8.91289 11.6666 9.0174 11.6666 9.14379V10.7917C11.6666 11.025 11.5791 11.2292 11.4041 11.4042C11.2291 11.5792 11.0249 11.6667 10.7916 11.6667H3.20825Z"
      fill={props.color ? props.color : '#333333'}
    />
  </svg>
);
export default Exports;
