import React from 'react';
import { isAllowed } from '@security/User';
import style from './style.module.scss';
import classNames from 'classnames';

import Dashboard from '@components/icons/Dashboard';
import Units from '@components/icons/Units';
import PitchFees from '@components/icons/PitchFees';
import Pitches from '@components/icons/Pitches';
import Amendments from '@components/icons/Amendments';
import Sites from '@components/icons/Sites';
import Exports from '@components/icons/Exports';
import Settings from '@components/icons/Settings';
import Help from '@components/icons/Help';
import Logout from '@components/icons/Logout';
import IncreaseTextSize from '@components/icons/IncreaseTextSize.js';
import DecreaseTextSize from '@components/icons/DecreaseTextSize.js';
import Sun from '@components/icons/Sun.js';
import Moon from '@components/icons/Moon.js';
import MenuExpand from '@components/icons/MenuExpand.js';
import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css'; // optional

export default function MenuItem({
  label,
  roles,
  oneOfThem,
  to,
  icon,
  active,
  disabledOnMobile = false,
}) {
  const menuIcon = (icon) => {
    switch (icon) {
      case 'dashboard':
        return <Dashboard />;
      case 'units':
        return <Units />;
      case 'amendments':
        return <Amendments />;
      case 'sites':
        return <Sites />;
      case 'pitchFees':
        return <PitchFees />;
      case 'export':
        return <Exports />;
      case 'settings':
        return <Settings />;
      case 'help':
        return <Help />;
      case 'decreaseTextSize':
        return <DecreaseTextSize />;
      case 'increaseTextSize':
        return <IncreaseTextSize />;
      case 'menuExpand':
        return <MenuExpand />;
      case 'moon':
        return <Moon />;
      case 'sun':
        return <Sun />;
      case 'logout':
        return <Logout />;
      case 'pitches':
        return <Pitches />;
      default:
        return;
    }
  };

  return (
    isAllowed(roles, oneOfThem) && (
      <Tippy className="tippy-menu" content={label} placement="right">
        <button
          roles={roles}
          onClick={to}
          className={classNames(
            disabledOnMobile && style['menu-disabled-on-mobile'],
            style['menu-item'],
            active && style['active'],
            'menu-item',
          )}
        >
          {menuIcon(icon)}

          <span className="menu-item-label">{label}</span>
        </button>
      </Tippy>
    )
  );
}
