import React from 'react';
import { connect } from 'react-redux';
import { fetchRefDatas } from '@store/actions/action-common';
import dayjs from 'dayjs';
import { debugCommon } from '@lib/debug';
import lottie from 'lottie-web';

import logo from '@assets/images/menu/logo.png';

import loadingAnimationJSON from '@assets/lottie/loading.json';

import style from './style.module.scss';

//
// HOC responsible of loading ref datas.
//
class RefDatasLoader extends React.Component {
  // Ref datas will be refresh if older than this number of seconds.
  refreshAfterSeconds = 60;

  // "Will" to load ASAP.
  constructor(props) {
    super(props);

    this.state = {
      animationComplete: false,
    };

    this.loadingAnimation = React.createRef();
    // console.log('loading refDatasLoader....');

    if (props.user && props.user.isAuthenticated) {
      // console.log('loading refDatasLoader.... => authenticated');
      const diff = dayjs().diff(dayjs(props.refDatas.lastUpdated), 'second');

      // console.log('loading refDatasLoader.... diff=', diff);

      if (diff > this.refreshAfterSeconds) {
        debugCommon(
          `RefDatasLoader: ${diff}s since the last refresh => reloading the values...`,
          props.user,
        );
        props.dispatch(fetchRefDatas({ user: props.user }));
      }
    }
  }

  triggerAnimation() {
    this.animation = lottie.loadAnimation({
      container: this.loadingAnimation.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingAnimationJSON,
      name: 'loading',
    });

    this.animation.addEventListener('DOMLoaded', () => {
      if (!this.state.animationComplete) {
        setTimeout(() => this.setState({ animationComplete: true }), 1000);
      }
    });
  }

  componentDidMount() {
    if (this.loadingAnimation.current) {
      this.triggerAnimation();
    }
  }

  componentDidUpdate() {
    if (!this.animation) {
      this.triggerAnimation();
    }
  }

  componentWillUnmount() {
    lottie.destroy('loading');
  }

  render() {
    const loggedIn = this.props.user && this.props.user.isAuthenticated;

    // If logged in but ref datas not loaded, we have to wait for them before redering children,
    // to spare unuseful re-redering.
    if (loggedIn) {
      if (this.props.refDatas.lastUpdated && this.state.animationComplete) {
        return this.props.children;
      }
      return (
        <div className={style['load-data-wrapper']}>
          <figure>
            <img
              src={logo}
              alt="European Camping Group"
              title="European Camping Group"
            />
          </figure>
          <div
            ref={this.loadingAnimation}
            className={style['load-data-content']}
          />
        </div>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = ({ refDatas, user }) => ({ refDatas, user });

export default connect(mapStateToProps)(RefDatasLoader);
