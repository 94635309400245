import style from './style.module.scss';
import CacheManager from '@helpers/cache-helper';

export default (props) => {
  const { list, selectedIndex } = props;

  return (
    <div className={style['image-list']}>
      {list.map(({ picture, label, storageName }, index) => (
        <div
          key={index}
          className={style['image-list-item']}
          onClick={() => selectedIndex('pending', index)}
        >
          <figure>
            <img src={picture} alt="image" />
          </figure>
          <p>
            <span>{label}</span>
            See in pending.
          </p>
        </div>
      ))}
    </div>
  );
};
