import classnames from 'classnames';
import style from './style.module.scss';

export default (props) => {
  const { action, label, img, description, disabled } = props;
  return (
    <button
      className={classnames(
        style['panel-button'],
        disabled ? style['disabled'] : null,
      )}
      type="button"
      onClick={() => !disabled && action()}
    >
      <figure>
        <img src={img} alt="image" />
      </figure>
      <p>
        <span>{label}</span>
        {description}
      </p>
    </button>
  );
};
