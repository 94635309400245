import style from './style.module.scss';

export default ({ data, headers = ['Field name', 'Value'] }) => {
  return (
    <table className={style['campsite-data']}>
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((obj, index) => (
          <tr key={index}>
            <td>{obj?.label}</td>
            <td className={obj?.important ? style['important'] : undefined}>
              <p>{obj?.value}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
