import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import classnames from 'classnames';
import get from 'lodash/get';
import _ from 'lodash';
import ReactGA from 'react-ga4';
import { fetchAllPitchesPerCampsite } from '@store/actions/action-pitches';

// Pages
import { commonFormPropTypes } from '@pages/mh/MhForm';

// Cache
import CacheManager from '@helpers/cache-helper';

// Store
import { sendFlashMessage } from '@store/actions/action-common';
import { cancelMv, removeMv } from '@store/actions/action-mv';

// Components
import Trash from '@components/icons/Trash';
import { AllowedBlock } from '@components/common/AllowedBlock';
import FormButton from '@components/buttons/FormButton';
import Save from '@components/icons/Save';
import SaveV2 from '@components/icons/SaveV2';
import Undo from '@components/icons/Undo';
import Unit from '@components/icons/Unit';
import { submitSaveMv } from '../MvSubmit';
import RenderField from '@components/form/RenderField';
import SuperSelect from '@components/form/SuperSelect';
import PitchesSuperSelect from '@components/form/PitchesSuperSelect';
import AllowedField from '@components/form/AllowedField';
import Checkboxes from '@components/form/Checkboxes';
import Arrow from '@components/icons/Arrow';
import Tag from '@components/common/Tag';
import BaseNavigation from '@components/navigations/BaseNavigation';
import UnLocked from '@components/icons/UnLocked';
import ArticleHeader from '@components/headers/ArticleHeader';

// Lottie
import locationJSON from '@assets/lottie/location';
import brandJSON from '@assets/lottie/brand';
import technicalJSON from '@assets/lottie/technical';
import refurbishmentJSON from '@assets/lottie/refurbishment';

// Helpers
import RefDatas from '@lib/RefDatas';
import { debugMv } from '@lib/debug';
const cache = new CacheManager();

import style from './style.module.scss';
import { getUserSetting } from '../../../security/User';
import dayjs from 'dayjs';
import DatePicker from '@components/form/DatePicker';

class MvForm extends React.Component {
  static propTypes = {
    ...commonFormPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      deletedData: {},
      refreshedData: {},
      addedData: {},
      currentIndex: 0,
      triggeredAction: false,
      unlocked: false,
      expectedValidationDate: this.props.initialValues?.expected_validation_date
        ? this.props.initialValues?.expected_validation_date
        : null,
      pitchesPerCampsite: [],
      toCampsite: null,
      toPitch: null,
      pitchesList: [],
    };

    // this.onSubmit = this.onSubmit.bind(this)
    this.onButtonClick = this.onButtonClick.bind(this);
    this.saveAndBack = this.saveAndBack.bind(this);
    this.backToList = this.backToList.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.goToMh = this.goToMh.bind(this);
    this.save = this.save.bind(this);
    this.changeCampsite = this.changeCampsite.bind(this);
    this.changePitch = this.changePitch.bind(this);
  }

  onButtonClick() {
    debugMv(this.props);
  }

  save(data) {
    const { isEdit, unlocked } = this.props;
    ReactGA.event('transfer_details_form_buttons', {
      user_interaction: 'Saving data',
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });

    data.expected_validation_date = this.state?.expectedValidationDate;
    if (this.state?.toPitch) data.pitch_to = this.state.toPitch;
    if (this.state?.toCampsite) data.site_to = this.state.toCampsite;

    delete data.pitchesPerCampsite;
    delete data.pitchesList;
    delete data.expectedValidationDate;
    delete data.toPitch;
    delete data.toCampsite;

    return submitSaveMv(data, !isEdit && this.goToTransfer, this.props.t);
  }

  saveAndBack(data) {
    // ! GA
    ReactGA.event('transfer_details_form_buttons', {
      user_interaction:
        'Saving data and automatically going back to transfers page',
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });
    if (this.state?.toPitch) data.pitch_to = this.state.toPitch;
    if (this.state?.toCampsite) data.site_to = this.state.toCampsite;
    delete data.pitchesPerCampsite;
    delete data.pitchesList;
    delete data.expectedValidationDate;
    delete data.toPitch;
    delete data.toCampsite;
    // ! Saving
    return submitSaveMv(data, this.backToList, this.props.t);
  }

  goToTransfer(_id) {
    this.props.history.push(`/mv/${_id}`);
  }

  backToList() {
    ReactGA.event('unit_details_form_buttons', {
      user_interaction: 'Going back to units page without saving data',
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });
    this.props.history.push('/mv');
  }

  goToMh() {
    const ID = get(this.props.initialValues, 'mobilhome_id._id').toString();
    if (ID) this.props.history.push(`/mh/${ID}`);
  }

  handleRemove(e) {
    e.preventDefault();
    this.setState({ triggeredAction: true });

    const id = this.props.initialValues._id;
    if (
      window.confirm(
        this.props.t(
          'form.transfer.confirm.removing',
          'Do you want to delete this amendment?',
        ),
      )
    ) {
      ReactGA.event('unit_details_form_buttons', {
        user_interaction: 'Delete a transfer',
        user_id: getUserSetting('_id').toString(),
        dateTime: dayjs().format(),
      });

      this.props.dispatch(removeMv(id, this.backToList));
    } else {
      this.setState({ triggeredAction: false });
    }
  }

  // Campsite and pitch edition
  // ---------------------------
  changeCampsite(e) {
    const value = e.target.value;
    const pitchesList = this.state.pitchesPerCampsite.find(
      (doc) => doc.campsite._id === value,
    ).pitches;

    this.setState({
      toCampsite: value,
      pitchesList,
    });
  }
  changePitch(e) {
    const value = e.target.value;
    this.setState({
      toPitch: value,
    });
  }

  handleCancel(e) {
    e.preventDefault();

    this.setState({ triggeredAction: true });

    const id = this.props.initialValues._id;
    if (
      window.confirm(
        this.props.t(
          'form.transfer.confirm.cancel',
          'Are you sure to cancel this amendment?',
        ),
      )
    ) {
      ReactGA.event('unit_details_form_buttons', {
        user_interaction: 'Delete a transfer',
        user_id: getUserSetting('_id').toString(),
        dateTime: dayjs().format(),
      });

      this.props.dispatch(cancelMv(id, this.backToList));
    } else {
      this.setState({ triggeredAction: false });
    }
  }

  async componentDidMount() {
    this.setState({
      pitchesPerCampsite: await this.props.dispatch(
        fetchAllPitchesPerCampsite(),
      ),
    });
  }

  // Le cycle de vie du comporant n'est pas clair
  // - le composant est monté avec les initialValues passées par EditForm (ou CreateForm)
  // - les données réelles sont fetchées sur le "componentDidMount" du React

  render() {
    const {
      initialValues,
      submitting,
      reset,
      handleSubmit,
      t,
      isEdit,
      refDatas,
    } = this.props;

    const { currentIndex, triggeredAction, unlocked } = this.state;

    debugMv('Rendering MvForm... initialValues=', initialValues);

    const rolesAllowed = ['ROLE_ADMIN_MVT', 'ROLE_WRI_MH_MVT'];

    this.tabs = [
      {
        label: t('form.transfer.tab', 'Amendment'),
        href: '#main',
        sections: [
          {
            title: t(
              'form.transfer.section.main_information',
              'Main information',
            ),
            file: technicalJSON,
            fields: [
              {
                upperComponent: Field,
                name: 'transfer_status',
                component: SuperSelect,
                isClearable: true,
                refDatas: refDatas,
                label: t(
                  'form.transfer.label.transfer_status',
                  'Transfer status',
                ),
                refKey: 'RefStatutTransfer',
                disabled: true,
              },
              {
                name: 'mv.season',
                component: SuperSelect,
                label: t('form.transfer.label.season', 'Season'),
                nativeValues: (() => {
                  const currentYear = new Date().getFullYear();
                  let seasons = Array.from(
                    { length: 5 },
                    (_, i) => currentYear - 2 + i,
                  );
                  return seasons.map((season) => {
                    return { val: season, label: season.toString() };
                  });
                })(),
              },
              {
                name: 'mv.comment',
                component: RenderField,
                multiline: true,
                row: 4,
                label: t('form.mh.label.comment', 'Comment'),
              },
              {
                name: 'mv.expected_validation_date',
                component: DatePicker,
                updatedDate: (date) => {
                  this.setState({ expectedValidationDate: date });
                },
                initialDate: this.state.expectedValidationDate,
                label: t(
                  'form.transfer.label.expected_validation_date',
                  'Expected validation date',
                ),
              },
            ],
          },
          {
            title: t(
              'form.transfer.section.commercial_transfer',
              'Commercial amendment',
            ),
            file: brandJSON,
            fields: [
              {
                name: 'mv.status_comm_amend',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t(
                  'form.transfer.label.commercial_transfer_status',
                  'Commercial amendment status',
                ),
                refKey: 'RefStatusCommAmend',
              },
              {
                name: 'mv.commercial_brand_to',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t(
                  'form.transfer.label.commercial_brand_to',
                  'Commercial brand to',
                ),
                refKey: 'RefMarqueCommerciale',
                showPreviousValue: {
                  label: t(
                    'form.transfer.label.commercial_brand_from',
                    'Commercial brand from',
                  ),
                  value: RefDatas.getLabelFromRefField(
                    initialValues,
                    refDatas.RefMarqueCommerciale,
                    'commercial_brand_from',
                    'Unknown',
                  ),
                },
              },
              {
                name: 'mv.commercial_type_to',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t(
                  'form.transfer.label.commercial_type_to',
                  'Commercial type to',
                ),
                refKey: 'RefTypeCommercial',
                showPreviousValue: {
                  label: t(
                    'form.transfer.label.commercial_type_from',
                    'Commercial type from',
                  ),
                  value: RefDatas.getLabelFromRefField(
                    initialValues,
                    refDatas.RefTypeCommercial,
                    'commercial_type_from',
                    'Unknown',
                    true,
                  ),
                },
              },
              {
                name: 'mv.use_type_to',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t('form.transfer.label.use_type_to', 'Use type to'),
                refKey: 'RefTypeUtilisation',
                showPreviousValue: {
                  label: t(
                    'form.transfer.label.use_type_from',
                    'Use type from',
                  ),
                  value: RefDatas.getLabelFromRefField(
                    initialValues,
                    refDatas.RefTypeUtilisation,
                    'use_type_from',
                    'Unknown',
                  ),
                },
              },
            ],
          },
          {
            title: t('form.transfer.section.refurbishment', 'Refurbishment'),
            file: refurbishmentJSON,
            fields: [
              {
                name: 'mv.status_refurb',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t(
                  'form.transfer.label.refurb_status',
                  'Refurbishment status',
                ),
                refKey: 'RefStatusRefurbAmend',
              },
              {
                name: 'mv.refurb_items',
                component: Checkboxes,
                refDatas: refDatas,
                isClearable: true,
                label: t(
                  'form.transfer.label.refurb_items',
                  'Refurbishment items',
                ),
                refKey: 'RefRefurbItems',
              },
              {
                name: 'mv.cladding_color_to',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t(
                  'form.transfer.label.cladding_color_to',
                  'Cladding color to',
                ),
                refKey: 'RefCladdingColor',
                showPreviousValue: {
                  label: t(
                    'form.transfer.label.cladding_color_from',
                    'Cladding color from',
                  ),
                  value: RefDatas.getLabelFromRefField(
                    initialValues,
                    refDatas.RefCladdingColor,
                    'cladding_color_from',
                    'Unknown',
                  ),
                },
              },
            ],
          },
          {
            title: t(
              'form.transfer.section.physical_transfer',
              'Physical amendment',
            ),
            file: locationJSON,
            fields: [
              {
                name: 'mv.status_physical_amend',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t(
                  'form.transfer.label.physical_transfer_status',
                  'Physical transfer status',
                ),
                refKey: 'RefStatusPhyAmend',
              },
              {
                name: 'mv.site_to',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t('form.transfer.label.campsite_to.lib', 'Campsite to'),
                refKey: 'Site',
                showPreviousValue: {
                  label: t(
                    'form.transfer.label.campsite_from',
                    'Campsite from',
                  ),
                  value: RefDatas.getLabelFromRefField(
                    initialValues,
                    refDatas.Site,
                    'site_from',
                    'Unknown',
                    true,
                  ),
                },
              },
              {
                name: 'mv.pitch_to',
                component: RenderField,
                label: t('form.transfer.label.pitch_to', 'Pitch to'),
                type: 'text',
                showPreviousValue: {
                  label: t('form.transfer.label.pitch_from', 'Pitch from'),
                  value: get(initialValues, 'pitch_from', '-'),
                },
              },

              {
                name: 'mv.new_destination',
                component: PitchesSuperSelect,
                meta: {},
                label: 'Wrong campsite or pitch? Please select below.',
                isClearable: true,
                data: this.state.pitchesPerCampsite,
                changeCampsite: this.changeCampsite,
                changePitch: this.changePitch,
                pitchesList: this.state.pitchesList.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                }),
                labels: [
                  t('form.transfer.label.campsite_to.lib', 'Campsite to'),
                  t('form.transfer.label.pitch_to', 'Pitch to'),
                ],
              },
            ],
          },
          {
            title: t(
              'form.transfer.section.deck_replacement',
              'Deck replacement',
            ),
            file: refurbishmentJSON,
            fields: [
              {
                name: 'mv.status_deck_replacement',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t(
                  'form.transfer.label.deck_replacement_status',
                  'Deck replacement status',
                ),
                refKey: 'RefStatusDeckReplacement',
              },
              {
                name: 'mv.deck_replacement_type',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t(
                  'form.transfer.label.deck_replacement_type',
                  'Deck replacement type',
                ),
                refKey: 'RefDeckReplacementType',
              },
              {
                name: 'mv.deck_type_to',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t('form.transfer.label.deck_type_to', 'Deck type to'),
                refKey: 'RefTypeTerrasse',
                showPreviousValue: {
                  label: t(
                    'form.transfer.label.deck_type_from',
                    'Deck type from',
                  ),
                  value: RefDatas.getLabelFromRefField(
                    initialValues,
                    refDatas.RefTypeTerrasse,
                    'deck_type_from',
                    'Unknown',
                  ),
                },
              },
              {
                name: 'mv.deck_size_to',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t('form.transfer.label.deck_size_to', 'Deck size to'),
                refKey: 'RefTailleTerrasse',
                showPreviousValue: {
                  label: t(
                    'form.transfer.label.deck_size_from',
                    'Deck size from',
                  ),
                  value: RefDatas.getLabelFromRefField(
                    initialValues,
                    refDatas.RefTailleTerrasse,
                    'deck_size_from',
                    'Unknown',
                  ),
                },
              },
              {
                name: 'mv.deck_manufacturer_to',
                component: SuperSelect,
                refDatas: refDatas,
                isClearable: true,
                label: t(
                  'form.transfer.label.deck_manufacturer_to',
                  'Deck manufacturer to',
                ),
                refKey: 'RefDeckManufacturer',
                showPreviousValue: {
                  label: t(
                    'form.transfer.label.deck_manufacturer_from',
                    'Deck manufacturer from',
                  ),
                  value: RefDatas.getLabelFromRefField(
                    initialValues,
                    refDatas.RefDeckManufacturer,
                    'deck_manufacturer_from',
                    'Unknown',
                  ),
                },
              },
              {
                name: 'mv.deck_year',
                component: RenderField,
                label: t('page.units.column.deck_year', 'Deck year'),
                type: 'number',
              },
            ],
          },
        ],
      },
    ];

    return this.tabs ? (
      <section className={style['form-section']}>
        <div className={style['form-section-base-navigation']}>
          <BaseNavigation
            items={this.tabs.map((tab) => tab.label)}
            currentIndex={currentIndex}
          />
        </div>
        <div className={style['form']}>
          <form onSubmit={handleSubmit(this.save)}>
            <div className={style['content-container']}>
              {this.tabs[currentIndex].sections.map((section, index) => (
                <article
                  id={section.title}
                  key={index}
                  className={style['form-input-block']}
                >
                  <ArticleHeader
                    label={section.title}
                    lottieJSON={section.file}
                  />

                  <div className={style['article-content']}>
                    {section.fields
                      ? section.fields.map((field, i) => {
                          let value = get(
                            initialValues,
                            field.name.split('.')[1],
                          );

                          // ! Special condition for campsite
                          const isCamping = ['site_to', 'site_from'].includes(
                            field.name.split('.')[1],
                          );
                          if (isCamping) value = value?.code_group;
                          else value = value?.lib ? value.lib : value;

                          const previousValue =
                            field?.showPreviousValue?.value === '-'
                              ? undefined
                              : isCamping
                              ? field?.showPreviousValue?.value.substring(
                                  field?.showPreviousValue?.value.indexOf('[') +
                                    1,
                                  field?.showPreviousValue?.value.lastIndexOf(
                                    ']',
                                  ),
                                )
                              : field?.showPreviousValue?.value;

                          return field.upperComponent ? (
                            <Field key={i} {...field} />
                          ) : field.showPreviousValue ? (
                            <div
                              key={i}
                              className={classnames(
                                style['previous-value'],
                                !!value &&
                                  previousValue &&
                                  previousValue.split('[')[0].trim() !== value
                                  ? style['changed-value']
                                  : undefined,
                              )}
                            >
                              <div className={style['previous-value-input']}>
                                <small>{field.showPreviousValue.label}</small>
                                <p>{field.showPreviousValue.value}</p>
                              </div>
                              <div className={style['arrow']}>
                                <Arrow color={'#FFFFFF'} />
                              </div>
                              <AllowedField
                                key={i}
                                {...field}
                                disabled={initialValues.locked && !unlocked}
                              />
                            </div>
                          ) : (
                            <AllowedField
                              key={i}
                              {...field}
                              disabled={initialValues.locked && !unlocked}
                            />
                          );
                        })
                      : section?.component && section.component}
                  </div>
                </article>
              ))}
            </div>
          </form>

          <div className={style['form-actions']}>
            <div className={style['form-actions-wrapper']}>
              {initialValues.locked && !unlocked && (
                <div className={style['tags']}>
                  <Tag key={'locked'} level={'locked'} label={'LOCKED'} />
                  {getTransferTypes(initialValues)}
                </div>
              )}
              <div className={style['form-buttons-wrapper']}>
                {!initialValues.locked && (
                  <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                    <FormButton
                      action={handleSubmit(this.save)}
                      label="Save"
                      icon={Save}
                      disabled={triggeredAction}
                      color="var(--color-surface-octonary)"
                    />
                  </AllowedBlock>
                )}
                {(!initialValues.locked || unlocked) && (
                  <Fragment>
                    <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                      <FormButton
                        action={handleSubmit(this.saveAndBack)}
                        label="Save and back"
                        icon={SaveV2}
                        disabled={triggeredAction}
                        color="var(--color-surface-octonary)"
                      />
                    </AllowedBlock>
                    <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                      <FormButton
                        action={reset}
                        label="Undo changes"
                        icon={Undo}
                        disabled={submitting}
                        color="var(--color-surface-septenary)"
                      />
                    </AllowedBlock>
                  </Fragment>
                )}

                {initialValues.locked &&
                  !unlocked &&
                  !initialValues?.transfer_status?.lib
                    .toLowerCase()
                    .includes('cancelled') && (
                    <AllowedBlock roles={rolesAllowed} oneOfThem={true}>
                      <FormButton
                        action={() => {
                          if (
                            window.confirm(
                              this.props.t(
                                'buttons.messages.unlock',
                                this.props.t(
                                  'common.action.unlock_transfer',
                                  'Are you sure to cancel and unlock this amendment ? 🧐',
                                ),
                              ),
                            )
                          ) {
                            ReactGA.event('transfer_details_form_buttons', {
                              user_interaction: 'Unlocking transfer',
                              user_id: getUserSetting('_id').toString(),
                              dateTime: dayjs().format(),
                            });

                            this.setState({ unlocked: true });
                            this.props.dispatch(
                              sendFlashMessage(
                                this.props.t(
                                  'common.flash_message.unlocked_transfer',
                                  'You just have unlocked this amendment',
                                ),
                                'success',
                              ),
                            );
                          }
                        }}
                        label={this.props.t('common.action.unlock', 'Unlock')}
                        icon={UnLocked}
                        color="var(--color-surface-octonary)"
                        disabled={triggeredAction}
                      />
                    </AllowedBlock>
                  )}
                <FormButton
                  action={this.goToMh}
                  label={t('common.action.go_to_unit', 'Go to unit')}
                  icon={Unit}
                  color="var(--color-surface-septenary)"
                />
                {isEdit &&
                  initialValues.locked &&
                  !unlocked &&
                  !initialValues?.transfer_status?.lib
                    .toLowerCase()
                    .includes('cancelled') && (
                    <AllowedBlock roles={'ROLE_DEL_MH'} oneOfThem={true}>
                      <FormButton
                        action={this.handleCancel}
                        label={this.props.t('common.action.cancel', 'Cancel')}
                        icon={Trash}
                        disabled={triggeredAction}
                        color="var(--color-surface-senary)"
                      />
                    </AllowedBlock>
                  )}
                {isEdit &&
                  !initialValues?.recovery_data &&
                  (!initialValues?.locked ||
                    initialValues?.locked === undefined) && (
                    <AllowedBlock roles={'ROLE_DEL_MH'} oneOfThem={true}>
                      <FormButton
                        action={this.handleRemove}
                        label={this.props.t('common.action.remove', 'Remove')}
                        icon={Trash}
                        disabled={triggeredAction}
                        color="var(--color-surface-senary)"
                      />
                    </AllowedBlock>
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
    ) : null;
  }
}

MvForm = reduxForm({
  // a unique name for the form
  form: 'mv_form',
  // onChange: onChange,
  // https://redux-form.com/7.1.2/examples/initializefromstate/
  enableReinitialize: true,
})(MvForm);

MvForm = withTranslation()(MvForm);

export default MvForm;

export function getTransferTypes(mv) {
  const transferTypes = [];
  if (mv.transfer_types) {
    let transfer_types = null;
    if (!_.isArray(mv.transfer_types))
      transfer_types = mv.transfer_types.split(',');
    else transfer_types = [...mv.transfer_types];
    transfer_types.forEach((tt, index) => {
      if (tt && tt._id)
        transferTypes.push(
          <Tag key={tt.id || index} level={tt.code} label={tt.lib} />,
        );
    });
  }
  return transferTypes;
}
