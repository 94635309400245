import React from 'react';
import { debugLayout } from '@lib/debug';
import getStore from '@store/store';
import {
  saveUserPreferences,
  updateUserView,
  getUserPreferences,
  saveUserCustomSaveName,
  sendFlashMessage,
  updateCustomConfs,
  setNbLines,
  updateColumnState,
  saveCustomDefaultLoadView,
  updateFilters,
} from '@store/actions/action-common';
import { withTranslation } from 'react-i18next';

import BaseButton from '@components/buttons/BaseButton';
import ReactGA from 'react-ga4';
import Sync from '@components/icons/Sync';
import Edit from '@components/icons/Edit';

import style from './style.module.scss';
import { getUserSetting } from '@security/User';
import dayjs from 'dayjs';

import customize_character from '@assets/images/svg/customize_character.svg';

class GridViewConfig extends React.Component {
  constructor(props) {
    super(props);

    this.saveViewPreferences = this.saveViewPreferences.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getConfs = this.getConfs.bind(this);
    this.updateName = this.updateName.bind(this);
    this.handleChangeNewValue = this.handleChangeNewValue.bind(this);
    this.handleSelectLinesChange = this.handleSelectLinesChange.bind(this);
    this.sendNewName = this.sendNewName.bind(this);
    this.customHandleClose = this.customHandleClose.bind(this);
    this.loadViewPreferences = this.loadViewPreferences.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.nbDisplayedLinesSelectRef = React.createRef();
    this.gridViewPopinRef = React.createRef();

    this.state = {
      listSavedConfs: [],
      listDefaultConfs: [],
      confs: [],
      selectedConf: 'default',
      originalName: '',
      newName: '',
      realName: '',
      renameDisabled: true,
      overrideDisabled: true,
    };
  }

  // Fill the user preferences object and save it on server side
  saveViewPreferences({ name }) {
    const view =
      (/^[a-zA-Z]/.test(name) && name.replace(/\s/g, '_')) || 'default';
    debugLayout(
      `saveViewPreferences for grid ${this.props.gridName}, view=${view}`,
    );
    const userView = {
      columns: this.props.api.gridCore.columnApi.getColumnState(),
      filters: this.props.api.gridCore.gridApi.getFilterModel(),
      nbLines: this.nbDisplayedLinesSelectRef.current.value || 100,
    };

    this.sendNewName();
    this.handleSelectLinesChange();
    getStore().dispatch(updateUserView(this.props.gridName, view, userView));

    // Save view per default
    getStore().dispatch(
      saveCustomDefaultLoadView(this.props.gridName, { name: view }),
    );

    debugLayout(
      'saveViewPreferences => will dispatch saveUserPreferences with : this.props.userSettings',
      this.props.userSettings,
      ', this.props.gridName',
      this.props.gridName,
      ', userView: ',
      userView,
    );
    getStore().dispatch(
      saveUserPreferences(
        this.props.userSettings,
        this.props.gridName,
        view,
        userView,
      ),
    );

    this.props.handleClose();

    this.updateName('');

    setTimeout(() => this.getConfs(), 500);
  }

  loadViewPreferences(selectedView) {
    const view = selectedView || 'default';

    debugLayout(
      `reloadUserPreferences for grid ${this.props.gridName}, view=${view}`,
    );

    if (this.state.confs[view.name]) {
      const { columns, filters, nbLines } = this.state.confs[view.name];
      this.props.api.gridCore.columnApi.setColumnState(columns);

      // ? use applyColumnState instead ?
      // slightly delay the setFilter to allow the previous operations to end
      setTimeout(
        () => this.props.api.gridCore.gridApi.setFilterModel(filters),
        300,
      );

      getStore().dispatch(saveCustomDefaultLoadView(this.props.gridName, view));

      // Save the restored states into our OWN store.
      getStore().dispatch(updateColumnState(this.props.gridName, columns));
      getStore().dispatch(updateFilters(this.props.gridName, filters));
      getStore().dispatch(setNbLines(this.props.gridName, nbLines));
      this.props.api.gridCore.gridApi.paginationSetPageSize(nbLines);
    } else {
      getStore().dispatch(
        sendFlashMessage(
          `Unable to retreive your preferences from this grid. You probably does not saved a grid setup first. If you've already done this, please contact your administrator.`,
          'warning',
        ),
      );
      console.error(
        `reloadUserPreferences for grid ${this.props.gridName}, view=${view}`,
        this.props.userSettings.list[this.props.gridName],
      );
    }

    this.customHandleClose();
  }

  handleClickOutside(event) {
    if (
      this.gridViewPopinRef &&
      !this.gridViewPopinRef.current.contains(event.target)
    ) {
      this.customHandleClose();
    }
  }

  componentDidMount() {
    this.getConfs();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  async getConfs() {
    const response =
      (await getStore().dispatch(
        getUserPreferences(this.props.userSettings, this.props.gridName),
      )) || [];

    this.setState({ confs: { ...response } });
    if (response === 'Access token has expired.') {
      this.props.history.push('/logout');
    } else {
      const listDefaultConfs = [];
      const listSavedConfs = [];

      for (let property in response) {
        if (response[property] !== null) {
          if (typeof response[property] === 'number') {
            const nbLines =
              response[property] ||
              getStore().getState().userSettings?.list[this.props.gridName]
                ?.nbLines ||
              100;
            this.setState({ nbLines });
            continue;
          }
          if (/^default.*/.test(property)) {
            listDefaultConfs.push({
              name: property,
              updated_at: response[property].updated_at,
              lib: response[property].lib,
            });
          }
          if (
            !/^default.*/.test(property) &&
            typeof response[property] === 'object'
          ) {
            listSavedConfs.push({
              name: property,
              updated_at: response[property].updated_at,
              lib: response[property].lib,
            });
          }
        }
      }

      listSavedConfs.sort((a, b) => a.name.localeCompare(b));

      this.setState({ listDefaultConfs, listSavedConfs });

      await getStore().dispatch(updateCustomConfs(listSavedConfs));
    }
  }

  updateName(localNewName) {
    this.setState({
      realName: localNewName.name || '',
      originalName: localNewName.lib || localNewName.name || '',
      newName: localNewName.lib || localNewName.name || '',
    });
  }

  handleChangeNewValue(event) {
    const newName = event.target.value;
    this.setState({ newName });

    if (!checkNewName(this.state.originalName, newName)) {
      this.setState({ renameDisabled: true });
    } else {
      this.setState({ renameDisabled: false });
    }
  }

  handleSelectLinesChange() {
    const nbLines = this.nbDisplayedLinesSelectRef.current.value || 100;
    this.setState({ nbLines });

    getStore().dispatch(setNbLines(this.props.gridName, nbLines));
    this.props.api.gridCore.gridApi.paginationSetPageSize(nbLines);
    getStore().dispatch(
      sendFlashMessage(`Now there are ${nbLines} displayed line!`, 'success'),
    );
  }

  sendNewName() {
    const { handleClose } = this.props;
    if (
      checkNewName(this.state.originalName, this.state.newName) &&
      this.state.realName &&
      this.state.realName !== ''
    ) {
      getStore().dispatch(
        saveUserCustomSaveName(
          this.props.gridName,
          this.state.originalName,
          this.state.newName,
          this.state.realName,
        ),
      );
      this.updateName('');
      setTimeout(() => this.getConfs(), 500);
      handleClose();
    }
  }

  handleChange(event) {
    const newValue = JSON.parse(event.target.value);
    this.updateName(newValue);
    this.setState({
      selectedConf: newValue || 'default',
      overrideDisabled: false,
    });
  }

  customHandleClose() {
    const { handleClose } = this.props;

    this.updateName('');
    handleClose();
  }

  render() {
    const { t, open } = this.props;
    return (
      <div ref={this.gridViewPopinRef} className={style['grid-view-popin']}>
        <div className={style['grid-view-popin-wrapper']}>
          <figure>
            <img
              src={customize_character}
              alt="grid settings"
              title="grid settings"
            />
          </figure>
          <div>
            <h2>{t('grid_view.title', 'Page grid view settings')}</h2>
            <div className={style['grid']}>
              <select defaultValue="default" onChange={this.handleChange}>
                <option value="default" disabled>
                  {t('grid_view.no_grid_selected', 'No grid view selected')}
                </option>
                {this.state.listSavedConfs.constructor === Array &&
                  this.state.listSavedConfs.map((conf, index) => {
                    return (
                      <option
                        key={index}
                        value={JSON.stringify(conf)}
                        selected={this.state?.confs?.conf_to_load === conf.name}
                      >
                        {conf.lib || conf.name || 'undefined'}
                      </option>
                    );
                  })}
              </select>
              <BaseButton
                action={() => {
                  // GA
                  ReactGA.event('grid_panel', {
                    user_interaction: 'Loading another view',
                    user_id: getUserSetting('_id').toString(),
                    dateTime: dayjs().format(),
                  });

                  this.loadViewPreferences(this.state.selectedConf);
                }}
                label={t('common.action.load_view', 'Load view')}
                disabled={this.state?.selectedConf === 'default'}
              >
                <Sync />
              </BaseButton>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                !this.state.renameDisabled && this.sendNewName();
              }}
              className={style['grid']}
            >
              <label>
                <span>
                  {t(
                    'grid_view.renaming',
                    'Rename your grid view by typing below (optional)',
                  )}
                </span>
                <input
                  type="text"
                  maxLength="19"
                  value={this.state.newName}
                  disabled={
                    this.state.renameDisabled && this.state.overrideDisabled
                  }
                  onChange={this.handleChangeNewValue}
                />
              </label>
            </form>
            <form onSubmit={(e) => preventDefault} className={style['grid']}>
              <label>
                <span>
                  {t(
                    'grid_view.lines',
                    'Change the number of lines displayed (optional)',
                  )}
                </span>
                <select
                  ref={this.nbDisplayedLinesSelectRef}
                  defaultValue={
                    getStore().getState().userSettings?.list[
                      this.props.gridName
                    ]?.nbLines || 100
                  }
                >
                  {[...Array(10)]
                    .map((_, i) => (i === 0 ? 100 : 500 * i))
                    .map((nb) => (
                      <option key={nb} value={nb}>
                        {nb}
                      </option>
                    ))}
                </select>
              </label>
            </form>
            <div className={style['grid-buttons']}>
              <BaseButton
                action={() => {
                  // GA
                  ReactGA.event('grid_panel', {
                    user_interaction: 'Saving the current grid view',
                    user_id: getUserSetting('_id').toString(),
                    dateTime: dayjs().format(),
                  });

                  this.saveViewPreferences(this.state.selectedConf);
                }}
                label={t('common.action.save', 'Save')}
                disabled={this.state.overrideDisabled}
              >
                <Edit color="#194989" />
              </BaseButton>
              <button
                className={style['cancel-button']}
                onClick={() => this.customHandleClose()}
              >
                {t('common.action.cancel', 'Cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function checkNewName(originalName, newName) {
  let result = false;
  if (
    !originalName.includes('default') &&
    originalName !== newName &&
    !newName.includes('default') &&
    newName !== '' &&
    !checkSpecialChars(newName) &&
    newName.length < 20
  ) {
    result = true;
  }

  return result;
}

function checkSpecialChars(name) {
  return /[!@#$%^&~²*()+\-=\[\]{};':"\\|,.<>\/?]/.test(name);
}

export default withTranslation()(GridViewConfig);
