export default (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3L6 7.58V6H4V9.11L1 11.4L2.21 12.99L4 11.62V21H20V11.62L21.79 12.98L23 11.4L12 3ZM18 19H13V15H11V19H6V10.1L12 5.52L18 10.1V19ZM10 1C10 2.66 8.66 4 7 4C6.45 4 6 4.45 6 5H4C4 3.34 5.34 2 7 2C7.55 2 8 1.55 8 1H10Z"
        fill={props.color ? props.color : '#333333'}
      />
    </svg>
  );
};
