import Arrow from '@components/icons/Arrow';
import style from './style.module.scss';

export default (props) => {
  const { action } = props;
  return (
    <button className={style['back-button']} type="button" onClick={action}>
      <Arrow />
    </button>
  );
};
