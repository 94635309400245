import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import SettingButton from '@components/buttons/SettingButton';
import ResetFilter from '@components/icons/ResetFilter';
import Expand from '@components/icons/Expand';
import Shrink from '@components/icons/Shrink';
import Columns from '@components/icons/Columns';
import ReactGA from 'react-ga4';
import { getUserSetting } from '@security/User';
import dayjs from 'dayjs';

import style from './style.module.scss';

export default ({
  api,
  onReset,
  expandColumns,
  shrinkColumns,
  showGridView,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classnames(
        style['content-container-header-item'],
        style['content-container-header-setup'],
      )}
    >
      <div className={style['setting-button-wrapper']}>
        <SettingButton
          label={t('common.action.grid_view', 'Grid view')}
          action={() => {
            ReactGA.event('grid_buttons', {
              user_interaction: 'Opening grid settings panel',
              user_id: getUserSetting('_id').toString(),
              dateTime: dayjs().format(),
            });
            showGridView();
          }}
        >
          <Columns color="#333" />
        </SettingButton>
      </div>

      <div className={style['setting-button-wrapper']}>
        <SettingButton
          label={t('common.action.reset_filter', 'Reset applied filters')}
          action={() => {
            ReactGA.event('grid_buttons', {
              user_interaction: 'Resetting filters applied to the grid',
              user_id: getUserSetting('_id').toString(),
              dateTime: dayjs().format(),
            });
            onReset();
          }}
        >
          <ResetFilter />
        </SettingButton>
      </div>

      <div className={style['setting-button-wrapper']}>
        <SettingButton
          label={t('common.action.expand_columns', 'Expand columns')}
          action={() => {
            ReactGA.event('grid_buttons', {
              user_interaction: 'Expanding columns',
              user_id: getUserSetting('_id').toString(),
              dateTime: dayjs().format(),
            });
            expandColumns(api);
          }}
        >
          <Expand />
        </SettingButton>
      </div>
      <div className={style['setting-button-wrapper']}>
        <SettingButton
          label={t('common.action.shrink_columns', 'Shrink columns')}
          action={() => {
            ReactGA.event('grid_buttons', {
              user_interaction: 'Shrinking columns',
              user_id: getUserSetting('_id').toString(),
              dateTime: dayjs().format(),
            });
            shrinkColumns(api);
          }}
        >
          <Shrink />
        </SettingButton>
      </div>
    </div>
  );
};
