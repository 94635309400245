import Tippy from '@tippyjs/react';
import TopArrow from '@components/icons/TopArrow';
import { CSSTransition } from 'react-transition-group';
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { getUserSetting } from '@security/User';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

export default (props) => {
  const { t } = useTranslation();
  const limit = 100;

  const scrollToTop = () => {
    ReactGA.event('default', {
      user_interaction: 'Auto-scroll to top page',
      user_id: getUserSetting('_id').toString(),
      dateTime: dayjs().format(),
    });
    window.scrollTo(0, 0);
  };

  const [scrollTop, setScrollTop] = useState(window.scrollY);
  const [show, setShow] = useState(false);
  const handleOnScroll = () => {
    setScrollTop(window.scrollY);
    scrollTop > limit ? setShow(true) : setShow(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll);
    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  }, [handleOnScroll]);

  return (
    <CSSTransition in={show} timeout={400} classNames="fade" unmountOnExit>
      <Tippy
        content={t('common.action.scroll_to_top', 'Scroll to top')}
        placement="left"
      >
        <button
          type="button"
          onClick={scrollToTop}
          className={style['scrollToTopButton']}
        >
          <TopArrow />
        </button>
      </Tippy>
    </CSSTransition>
  );
};
