//
// Définition des actions.
// ===========================================
// Destinées à être `dispatchées` par des evénements généralement provenant de la vue ou au `onload`.
//
//
import fetch from 'node-fetch';
import { debugStore } from '../../lib/debug';
import { getAPIToken } from '../../security/User';

// ------------------------------------------------------------------------
// User
// ------------------------------------------------------------------------
// OAuth callback authentication:
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export function userLoggedIn(user) {
  return {
    type: USER_LOGGED_IN,
    user,
  };
}

export const USER_LOGOUT = 'USER_LOGOUT';
export function userLogout() {
  return {
    type: USER_LOGOUT,
  };
}

//
// Store the year selected.
//
// export const SAVE_SELECTED_SEASON = 'SAVE_SELECTED_SEASON'
// export function saveSelectedSeason({ selectedSeason }) {
//   return {
//     type: SAVE_SELECTED_SEASON,
//     selectedSeason,
//   }
// }

//
// Store the bool of copy button (for excel).
//
export const COPY_FROM_BUTTON = 'COPY_FROM_BUTTON';
export function toggleCopyFromButton({ copyFromButton }) {
  return {
    type: COPY_FROM_BUTTON,
    copyFromButton,
  };
}

// Legacy user/pwd authentication:
export function userLogin(credentials) {
  return function (dispatch) {
    // const API_TOKEN = getAPIToken()

    debugStore('userLogin: logging in user...');

    const url = `${process.env.REACT_APP_API_URL}/login`;

    // var data = new FormData()
    // data.append('username', credentials.username)
    // data.append('password', credentials.password)

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'POST',
      headers: {
        // Check what headers the API needs. A couple of usuals right below
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
      // headers: {
      //   'x-access-token': API_TOKEN,
      // },
    };

    debugStore('userLogin: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `userLogin: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );

          const err = new Error(response.statusText);
          err.status = response.status;
          throw err;
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `userLogin: JSON parse error from /api/ref_datas with response=${text}, err=${err}`,
          );
          throw Error(`Error while getting the REF DATAS, err=${err}`);
        }
      })
      .catch((e) => {
        console.error('userLogin:', e);
        dispatch(userLogout());
        dispatch(
          sendFlashMessage(
            'No account was found with these credentials or it has been disabled.',
            'error',
          ),
        );
      })
      .then((json) => {
        if (json) {
          dispatch(userLoggedIn(json.user));
          dispatch(fetchRefDatas({ user: json.user }));
          dispatch(fetchUserSettings({ user: json.user }));
        }
        return json;
      });
  };
}

// Add reset password logic
export function resetPassword(idToResetPassword, callback) {
  return (dispatch) => {
    debugStore('userResetPassword: POST reset password query...');

    const url = `${process.env.REACT_APP_API_URL}/forgot`;

    const options = {
      method: 'POST',
      headers: {
        // Check what headers the API needs. A couple of usuals right below
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(idToResetPassword),
    };

    debugStore('userResetPassword: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          return response.json().then((json) => {
            const { message, error } = json;
            const { status, statusText } = response;

            throw { message, error, status, statusText };
          });
        } else {
          return response.json();
        }
      })
      .then((json) => {
        if (json) {
          // dispatch(userLogout());
          dispatch(sendFlashMessage(json.message, 'success'));
          setTimeout(() => callback(), 2000);
        }
        return json;
      })
      .catch((e) => {
        dispatch(
          sendFlashMessage(`${e.message || e.error || e.statusText}`, 'error'),
        );
        throw e;
      });
  };
}

// Add new password logic
export function newPassword(credentials, callback) {
  return (dispatch) => {
    debugStore('userNewPassword: POST new passowrd query...');

    const url = `${process.env.REACT_APP_API_URL}/reset/${credentials.tempToken}`;

    const options = {
      method: 'POST',
      headers: {
        // Check what headers the API needs. A couple of usuals right below
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    };

    debugStore('userNewPassword: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          return response.json().then((json) => {
            const { message, error } = json;
            const { status, statusText } = response;

            throw { message, error, status, statusText };
          });
        } else {
          return response.json();
        }
      })
      .then((json) => {
        if (json) {
          // dispatch(userLogout());
          dispatch(sendFlashMessage(json.message, 'success'));
          setTimeout(() => callback(), 2000);
        }
        return json;
      })
      .catch((e) => {
        dispatch(
          sendFlashMessage(`${e.message || e.error || e.statusText}`, 'error'),
        );
        throw e;
      });
  };
}

export const USER_TUTO_COMPLETED = 'USER_TUTO_COMPLETED';
export function userTutoCompleted() {
  return {
    type: USER_TUTO_COMPLETED,
  };
}

export function setUserTutoComplete() {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();
    debugStore('setUserTutoComplete: Fired...');

    const url = `${process.env.REACT_APP_API_URL}/api/user/completedTour`;

    const options = {
      method: 'PUT',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('setUserTutoComplete: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `setUserTutoComplete: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );

          const err = new Error(response.statusText);
          err.status = response.status;
          throw err;
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `setUserTutoComplete: JSON parse error from /api/ref_datas with response=${text}, err=${err}`,
          );
          throw Error(`Error while getting the REF DATAS, err=${err}`);
        }
      })
      .then((json) => {
        if (json) {
          dispatch(userTutoCompleted());
        }
        return json;
      });
  };
}

export const REQUEST_USER_SETTINGS = 'REQUEST_USER_SETTINGS';
export function requestUserSettings() {
  return {
    type: REQUEST_USER_SETTINGS,
  };
}

// ------------------------------------------------------------------------
// REF DATAS HANDLING
// ------------------------------------------------------------------------
export const REQUEST_REF_DATAS = 'REQUEST_REF_DATAS';
export const RECEIVE_REF_DATAS = 'RECEIVE_REF_DATAS';

//
// Before receiving data from API.
//
export function requestRefDatas() {
  return {
    type: REQUEST_REF_DATAS,
  };
}

//
// After receiving data from API.
//
export function receiveRefDatas({ data }) {
  return {
    type: RECEIVE_REF_DATAS,
    data,
    receivedAt: Date.now(),
  };
}

//
// Fetch the REF datas from API.
//
export function fetchRefDatas({ user }) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore('fetchRefDatas: FETCH REF_DATAS');
    dispatch(requestRefDatas());

    const url = `${process.env.REACT_APP_API_URL}/api/ref_datas`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('fetchRefDatas: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          const err = `fetchRefDatas: error status=${response.status}, statusText=${response.statusText}, for url=${url}`;
          console.error(err);
          // dispatch(userLogout())
          throw new Error(err);
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `fetchRefDatas: JSON parse error from /api/ref_datas with response=${text}, err=${err}`,
          );
          throw Error(`Error while getting the REF DATAS, err=${err}`);
        }
      })
      .catch((e) => {
        console.error('fetchRefDatas:', e);
        dispatch(userLogout());
      })
      .then((json) => {
        if (json) {
          dispatch(receiveRefDatas({ data: json }));
        }
        return json;
      });
  };
}

//
// Fetch the user settings from API.
//
export function fetchUserSettings({ user }) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore('fetchUserSettings: user=', user);
    dispatch(requestUserSettings());

    const url = `${process.env.REACT_APP_API_URL}/api/user/settings`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('fetchUserSettings: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          const err = `fetchUserSettings: error status=${response.status}, statusText=${response.statusText}, for url=${url}`;
          console.error(err);
          // dispatch(userLogout())
          throw new Error(err);
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `fetchUserSettings: JSON parse error from /api/user/settings with response=${text}, err=${err}`,
          );
          throw Error(`Error while getting the user settings, err=${err}`);
        }
      })
      .then((settings) => {
        console.log('settings: ', settings);

        if (settings.isCompleteTutoTour) {
          dispatch(userTutoCompleted());
        }

        if (settings && settings.views) {
          for (const gridName in settings.views) {
            const userViews = settings.views[gridName];

            if (userViews && userViews.default) {
              // NOTE Si nécessaire, gérer ici les multiviews. Par défaut on prend la vue... par défaut :)
              debugStore(
                `INIT USER VIEW FOR ${gridName}, FROM SERVER, with :`,
                userViews.default,
              );

              for (const conf in userViews) {
                if (typeof userViews[conf] === 'object') {
                  dispatch(updateUserView(gridName, conf, userViews[conf]));
                }
              }

              // Set the default user columns
              // * There may be a better solution (currently, the grid is setup with the default conf columns, then update with this data)
              const { columns, filters } =
                userViews[userViews.conf_to_load] || userViews.default;
              dispatch(updateColumnState(gridName, columns));
              dispatch(updateFilters(gridName, filters));
            }
          }
        }
        return settings;
      })
      .catch((e) => {
        console.error('fetchUserSettings:', e);
        dispatch(userLogout());
      });
  };
}

//
// Get user preferences
//
export function getUserPreferences(userSettings, gridName) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(
      'saveUserPreferences: userSettings=',
      userSettings,
      'gridName=',
      gridName,
    );
    const url = `${process.env.REACT_APP_API_URL}/api/user/settings/list/${gridName}/views`;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('saveUserPreferences: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `saveUserPreferences: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `saveUserPreferences: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(`Error while saving user preferences, err=${err}`);
        }
      })
      .then((json) => {
        return json;
      });
  };
}

//
// Update user preferences
//
export function saveUserPreferences(userSettings, gridName, view, userView) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(
      'saveUserPreferences: userSettings=',
      userSettings,
      'gridName=',
      gridName,
      'view=',
      view,
      'userView=',
      userView,
    );
    const url = `${process.env.REACT_APP_API_URL}/api/user/settings/list/${gridName}/${view}`;

    // console.log('settings to save =', userSettingsView)

    const options = {
      method: 'put',
      body: JSON.stringify({ userView }),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('saveUserPreferences: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `saveUserPreferences: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `saveUserPreferences: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(`Error while saving user preferences, err=${err}`);
        }
      })
      .then((json) => {
        dispatch(sendFlashMessage('User preferences saved!', 'success'));
        return json;
      });
  };
}

//
// Update user preferences
//
export function saveCustomDefaultLoadView(
  gridName,
  defaultViewToLoadByDefault,
) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();
    const url = `${process.env.REACT_APP_API_URL}/api/user/settings/conf/load/${gridName}`;

    // process data :
    const userSettingsView = { defaultViewToLoadByDefault };

    const options = {
      method: 'put',
      body: JSON.stringify(userSettingsView),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('saveUserPreferences: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `saveUserPreferences: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `saveUserPreferences: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(`Error while saving user preferences, err=${err}`);
        }
      })
      .then((json) => {
        dispatch(sendFlashMessage('User preferences saved!', 'success'));
        return json;
      });
  };
}

//
// Update user preferences
//
export function saveUserCustomSaveName(
  gridName,
  originalName,
  newName,
  realName,
) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();
    const url = `${process.env.REACT_APP_API_URL}/api/user/settings/rename/${gridName}`;

    // process data :
    const userSettingsView = { originalName, newName, realName };
    // console.log('settings to save =', userSettingsView);

    const options = {
      method: 'put',
      body: JSON.stringify(userSettingsView),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('saveUserCustomSaveName: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `saveUserCustomSaveName: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
          dispatch(
            sendFlashMessage('Error while saving user preferences', 'error'),
          );
        } else {
          // Better JSON parsing error handling.
          return response.text();
        }
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `saveUserPreferences: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(`Error while saving user preferences, err=${err}`);
        }
      })
      .then((json) => {
        dispatch(sendFlashMessage('User preferences saved!', 'success'));
        return json;
      });
  };
}

// ------------------------------------------------------------------------
// FLASH MESSAGES.
// ------------------------------------------------------------------------
export const FLASH_MESSAGE = 'FLASH_MESSAGE';

export const sendFlashMessage = (message, level) => ({
  type: FLASH_MESSAGE,
  data: {
    message,
    level,
  },
});

// ------------------------------------------------------------------------
// Grid/List states.
// ------------------------------------------------------------------------
export const SET_NB_LINES = 'SET_NB_LINES';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_COLUMN_STATE = 'UPDATE_COLUMN_STATE';
export const UPDATE_USER_VIEW = 'UPDATE_USER_VIEW';
export const UPDATE_CUSTOM_CONFS = 'UPDATE_CUSTOM_CONFS';

export const setNbLines = (gridName, nbLines) => ({
  type: SET_NB_LINES,
  gridName,
  nbLines,
});

export const setCurrentPage = (gridName, page) => ({
  type: SET_CURRENT_PAGE,
  gridName,
  page,
});

//
// Before calling API.
//
export function updateFilters(gridName, filters) {
  return {
    type: UPDATE_FILTERS,
    gridName,
    filters,
  };
}

export function updateColumnState(gridName, columnState) {
  return {
    type: UPDATE_COLUMN_STATE,
    gridName,
    columnState,
  };
}
export function updateUserView(gridName, view, userView) {
  return {
    type: UPDATE_USER_VIEW,
    gridName,
    view,
    userView,
  };
}

export function updateCustomConfs(customConfs) {
  return {
    type: UPDATE_CUSTOM_CONFS,
    customConfs,
  };
}

export const UPDATE_GA = 'UPDATE_GA';

export function updateGoogleAnalyticsState(bool) {
  return {
    type: UPDATE_GA,
    bool,
  };
}

// ------------------------------------------------------------------------
// RECEIVE NOTIFICATION.
// ------------------------------------------------------------------------
export const NOTIFICATION = 'NOTIFICATION';

export function receiveNotification(bool) {
  return {
    type: NOTIFICATION,
    bool,
  };
}

// ------------------------------------------------------------------------
// Validate Data By Admin
// ------------------------------------------------------------------------

export function validateDataByAdmin({ _id, gridName }) {
  return (dispatch) => {
    const API_TOKEN = getAPIToken();

    debugStore('validateDataByAdmin: _id=', _id);
    debugStore('validateDataByAdmin: gridName=', gridName);

    const url = `${process.env.REACT_APP_API_URL}/api/adminValidateData/${gridName}/`;

    const options = {
      method: 'put',
      body: JSON.stringify({ _id }),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('validateDataByAdmin: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        // console.log(('response: ', response));

        if (response.status >= 400) {
          console.error(
            `validateDataByAdmin: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `validateDataByAdmin: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(`Error while saving MH transfer, err=${err}`);
        }
      })
      .then((json) => {
        if (json && json.status >= 400) {
          dispatch(sendFlashMessage(json.msg, 'error'));
        } else {
          dispatch(
            sendFlashMessage('Data is now validated by Admin!', 'success'),
          );
        }
        // return json
      });
  };
}

// ------------------------------------------------------------------------
// Fetch weather for campsite
// ------------------------------------------------------------------------

export function getCampsiteWeather(lat, lon) {
  return function (dispatch) {
    const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&exclude=jourly,minutely,hourly&units=metric&appid=${process.env.REACT_APP_OPEN_WEATHER_MAP_ID}`;

    const options = {
      method: 'GET',
    };

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(`Unable to find the location : ${lat}, ${lon}`);
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(`response=${text}, err=${err}`);
          throw Error(`err=${err}`);
        }
      })
      .then((json) => {
        return json;
      });
  };
}
