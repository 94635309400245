import MainHeader from '@components/pwa/MainHeader';
import style from './style.module.scss';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import locationImage from '@assets/images/pwa/location.jpg';
import NoWrapNavigation from '@components/pwa/navigation/NoWrapNavigation';
import { useState } from 'react';

export default (props) => {
  const { unit, close } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const navigation = ['Location', 'Commercial', 'Technical', 'Accounting'];

  function updateIndex(index) {
    setCurrentIndex(index);
  }
  return (
    <div className={style['unit-details']}>
      <div className={style['unit-header']}>
        <MainHeader title={'Unit details'} action={() => close()} />
      </div>
      <NoWrapNavigation
        index={currentIndex}
        updateIndex={updateIndex}
        items={navigation}
      />
      <SwitchTransition mode="out-in">
        <CSSTransition key={currentIndex} classNames="fade" timeout={300}>
          <>
            {currentIndex === 0 && (
              <div className={style['section']}>
                <header className={style['section-header']}>
                  <figure>
                    <img src={locationImage} alt="Location section" />
                  </figure>
                  <span className={style['section-header-title']}>
                    {navigation[currentIndex]}
                  </span>
                </header>

                <div className={style['section-content']}>
                  <div className={style['section-item']}>
                    <span>Campsite</span>
                    <i>{unit?.site?.lib}</i>, {unit?.site?.loca?.city},{' '}
                    {unit?.site?.loca?.region}, {unit?.site?.loca?.country}
                  </div>
                  <div className={style['section-item']}>
                    <span>Own campsite</span>
                    {unit?.site?.is_propre ? 'Yes.' : 'No.'}
                  </div>
                  <div className={style['section-item']}>
                    <span>Pitch</span>
                    {unit?.pitch ? unit?.pitch : <i>Not available</i>}
                  </div>
                  <div className={style['section-item']}>
                    <span>Pitch corresponding</span>
                    {unit?.pitch_corres ? (
                      unit?.pitch_corres
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Barcode</span>
                    {unit?.barcode ? unit?.barcode : <i>Not available</i>}
                  </div>
                  <div className={style['section-item']}>
                    <span>Legacy ID/Barcode</span>
                    {unit?.old_id_homair ? (
                      unit?.old_id_homair
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Chassis ID</span>
                    {unit?.id_chassis ? unit?.id_chassis : <i>Not available</i>}
                  </div>
                </div>
              </div>
            )}

            {currentIndex === 1 && (
              <div className={style['section']}>
                <header className={style['section-header']}>
                  <figure>
                    <img src={locationImage} alt="Location section" />
                  </figure>
                  <span className={style['section-header-title']}>
                    {navigation[currentIndex]}
                  </span>
                </header>
                <div className={style['section-content']}>
                  <div className={style['section-item']}>
                    <span>MH model</span>
                    {unit?.mh_model?.lib ? (
                      unit?.mh_model?.lib
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Use type</span>
                    {unit?.use_type?.lib ? (
                      unit?.use_type?.lib
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Commercial Brand</span>
                    {unit?.commercial_brand?.lib ? (
                      unit?.commercial_brand?.lib
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Commercial Type</span>
                    {unit?.commercial_type?.lib ? (
                      unit?.commercial_type?.lib
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                </div>
              </div>
            )}

            {currentIndex === 2 && (
              <div className={style['section']}>
                <header className={style['section-header']}>
                  <figure>
                    <img src={locationImage} alt="Location section" />
                  </figure>
                  <span className={style['section-header-title']}>
                    {navigation[currentIndex]}
                  </span>
                </header>
                <div className={style['section-content']}>
                  <div className={style['section-item']}>
                    <span>Unit ID</span>
                    {unit?._id ? unit?._id : <i>Not available</i>}
                  </div>
                  <div className={style['section-item']}>
                    <span>Unit width</span>
                    {unit?.mh_model?.width ? (
                      unit?.mh_model?.width
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Unit length</span>
                    {unit?.mh_model?.length ? (
                      unit?.mh_model?.length
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Deck Type</span>
                    {unit?.deck_type?.lib ? (
                      unit?.deck_type?.lib
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Deck Year</span>
                    {unit?.deck_year ? unit?.deck_year : <i>Not available</i>}
                  </div>
                  <div className={style['section-item']}>
                    <span>Deck Manufacturer</span>
                    {unit?.deck_manufacturer?.lib ? (
                      unit?.deck_manufacturer?.lib
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Energy Supply Type</span>
                    {unit?.gas_supply_type?.lib ? (
                      unit?.gas_supply_type?.lib
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Cladding Color</span>
                    {unit?.cladding_color?.lib ? (
                      unit?.cladding_color?.lib
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Cladding Material</span>
                    {unit?.cladding_material?.lib ? (
                      unit?.cladding_material?.lib
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Roof Type</span>
                    {unit?.mh_model?.roof_type?.lib ? (
                      unit?.mh_model?.roof_type?.lib
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Maintenance Perimeter</span>
                    {unit?.perimeter ? unit?.perimeter : <i>Not available</i>}
                  </div>
                </div>
              </div>
            )}

            {currentIndex === 3 && (
              <div className={style['section']}>
                <header className={style['section-header']}>
                  <figure>
                    <img src={locationImage} alt="Location section" />
                  </figure>
                  <span className={style['section-header-title']}>
                    {navigation[currentIndex]}
                  </span>
                </header>
                <div className={style['section-content']}>
                  <div className={style['section-item']}>
                    <span>Owner</span>
                    {unit?.owner?.lib ? unit?.owner?.lib : <i>Not available</i>}
                  </div>
                  <div className={style['section-item']}>
                    <span>Refurbishment Year</span>
                    {unit?.renov_year ? unit?.renov_year : <i>Not available</i>}
                  </div>
                  <div className={style['section-item']}>
                    <span>Fixed Asset Code</span>
                    {unit?.fixed_asset_code ? (
                      unit?.fixed_asset_code
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Purchasing Leasing Code</span>
                    {unit?.purchasing_leasing_code ? (
                      unit?.purchasing_leasing_code
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                  <div className={style['section-item']}>
                    <span>Refurbishment Leasing Code</span>
                    {unit?.reno_leasing_code ? (
                      unit?.reno_leasing_code
                    ) : (
                      <i>Not available</i>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
