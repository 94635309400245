import React from 'react';
import classNames from 'classnames';
import Exports from '@components/icons/Exports';
import Restore from '@components/icons/Restore';
import Save from '@components/icons/Save';
import Off from '@components/icons/Off';
import AdminPanel from '@components/icons/AdminPanel';
import Trash from '@components/icons/Trash';
import Reload from '@components/icons/Reload';
import Check from '@components/icons/Check';
import Book from '@components/icons/Book';
import { isAllowed } from '@security/User';

import style from './style.module.scss';

const getIcon = (icon) => {
  switch (icon) {
    case 'exports':
      return <Exports color={'#FFFFFF'} />;
    case 'restore':
      return <Restore />;
    case 'save':
      return <Save />;
    case 'off':
      return <Off />;
    case 'admin':
      return <AdminPanel />;
    case 'delete':
      return <Trash />;
    case 'update':
      return <Reload />;
    case 'check':
      return <Check />;
    case 'book':
      return <Book />;
    default:
      return;
  }
};

const MenuSubItem = ({ label, to, icon, separate, bgColor, roles }) => {
  if (!roles || roles.length === 0 || isAllowed(roles, true)) {
    return (
      <li
        className={classNames(
          style['menu-sub-item'],
          separate && style['separate'],
        )}
      >
        <button type="button" onClick={to}>
          {label}
        </button>
        {icon && (
          <div
            style={{ backgroundColor: bgColor }}
            className={style['menu-sub-item-icon']}
          >
            {getIcon(icon)}
          </div>
        )}
      </li>
    );
  }

  return null;
};

export default MenuSubItem;
