import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  userLoggedIn,
  fetchRefDatas,
  fetchUserSettings,
} from '../store/actions/action-common';
import qs from 'qs';

//
// Back from OAuth.
// Client > server > google > server > Client.
//
class GetToken extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    let paramsUrl = qs.parse(window.location.search.substring(1));
    try {
      const user = JSON.parse(paramsUrl.u);
      // console.log('GetToken: user=', user)

      // Update the store.
      // Login Component should be re-rendered and trigger a Redirect to the private page.
      this.props.dispatch(userLoggedIn(user));
      this.props.dispatch(fetchUserSettings({ user }));
      this.props.dispatch(fetchRefDatas({ user }));
      // const { from } = location.state || { from: { pathname: '/home' } }

      let redirectToReferrer = localStorage.getItem('referrer');
      if (redirectToReferrer)
        redirectToReferrer = JSON.parse(redirectToReferrer);
      else {
        redirectToReferrer = { pathname: '/mh' };
      }

      // localStorage.setItem('auth', true)

      // console.log('GetToken: redirecting to ', redirectToReferrer);

      //window.location.href = redirectToReferrer.from.pathname

      return <Redirect to={redirectToReferrer} />;
      //return <div>GetToken...</div>
    } catch (err) {
      console.error(`GetToken: err=${err}`, paramsUrl);
      return <Redirect to="/" />;
    }
  }
}
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(GetToken);
