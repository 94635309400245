const Check = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 48 48"
    >
      <path
        fill={props.color ? props.color : '#FFFFFF'}
        d="M28 20.1V17.7Q29.65 17 31.375 16.65Q33.1 16.3 35 16.3Q36.3 16.3 37.55 16.5Q38.8 16.7 40 17V19.2Q38.8 18.75 37.575 18.525Q36.35 18.3 35 18.3Q33.1 18.3 31.35 18.775Q29.6 19.25 28 20.1ZM28 31.1V28.65Q29.65 27.95 31.375 27.625Q33.1 27.3 35 27.3Q36.3 27.3 37.55 27.5Q38.8 27.7 40 28V30.2Q38.8 29.75 37.575 29.525Q36.35 29.3 35 29.3Q33.1 29.3 31.35 29.75Q29.6 30.2 28 31.1ZM28 25.6V23.2Q29.65 22.5 31.375 22.15Q33.1 21.8 35 21.8Q36.3 21.8 37.55 22Q38.8 22.2 40 22.5V24.7Q38.8 24.25 37.575 24.025Q36.35 23.8 35 23.8Q33.1 23.8 31.35 24.275Q29.6 24.75 28 25.6ZM12.4 33.8Q15.1 33.8 17.625 34.425Q20.15 35.05 22.6 36.3V14.95Q20.35 13.45 17.725 12.625Q15.1 11.8 12.4 11.8Q10.5 11.8 8.675 12.275Q6.85 12.75 5 13.45Q5 13.45 5 13.45Q5 13.45 5 13.45V35.15Q5 35.15 5 35.15Q5 35.15 5 35.15Q6.55 34.45 8.525 34.125Q10.5 33.8 12.4 33.8ZM25.6 36.3Q28.1 35.05 30.5 34.425Q32.9 33.8 35.6 33.8Q37.5 33.8 39.525 34.1Q41.55 34.4 43 34.9Q43 34.9 43 34.9Q43 34.9 43 34.9V13.45Q43 13.45 43 13.45Q43 13.45 43 13.45Q41.3 12.55 39.4 12.175Q37.5 11.8 35.6 11.8Q32.9 11.8 30.375 12.625Q27.85 13.45 25.6 14.95ZM24.15 41.55Q21.6 39.7 18.6 38.575Q15.6 37.45 12.4 37.45Q10.65 37.45 8.95 37.925Q7.25 38.4 5.55 39Q4.1 39.7 2.725 38.85Q1.35 38 1.35 36.35V13.25Q1.35 12.2 1.825 11.325Q2.3 10.45 3.2 10Q5.35 9 7.65 8.55Q9.95 8.1 12.35 8.1Q15.5 8.1 18.5 8.975Q21.5 9.85 24.1 11.65Q26.65 9.85 29.6 8.975Q32.55 8.1 35.65 8.1Q38.05 8.1 40.325 8.575Q42.6 9.05 44.75 10Q45.65 10.45 46.15 11.325Q46.65 12.2 46.65 13.25V36.65Q46.65 38.3 45.225 39.075Q43.8 39.85 42.4 39Q40.75 38.35 39.05 37.9Q37.35 37.45 35.6 37.45Q32.45 37.45 29.575 38.6Q26.7 39.75 24.15 41.55ZM13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Q13.8 24.35 13.8 24.35Z"
      />
    </svg>
  );
};

export default Check;
