import getStore from '../store/store';
import { get } from 'lodash';

//
// Return the current user from the store.
// Avoid to subscribe all you components to the store.
//
export default function getUser() {
  const store = getStore();
  if (!store) return {};
  const state = store.getState();
  if (!state) return {};
  const user = state.user;

  return user;
}

//
// Get a user setting/information.
// Look for a `settings.name` at first, and for `name` if the later is undefined.
// Return the setting value.
//
export function getUserSetting(name) {
  const user = getUser();
  if (!user) return;

  let value = get(user, `settings.${name}`);
  if (!value) {
    value = get(user, name);
  }
  return value;
}

//
// Return the current user token.
//
export function getAPIToken() {
  return getUserSetting('jwt.token');
}

//
// Teste si l'utilisateur a les roles passés.
// Soit en mode "tous les roles" => oneOfThem = false||undefined.
// Soit en mode "au moins un des roles" => oneOfThem = true.
//
export function isAllowed(roles, oneOfThem = undefined) {
  if (!Array.isArray(roles)) {
    roles = [roles];
  }

  var userRoles = getUserSetting('userRoles');

  if (!userRoles) return;

  var result = true;
  // Mode "OU" entre les rôles.
  if (oneOfThem) {
    var ok = false;
    roles.forEach((role) => {
      if (userRoles.indexOf(role) !== -1) {
        ok = true;
        // trouvé ? on sort !
      }
    });

    result = ok;
  }
  // Mode "ET" entre les rôles.
  else {
    roles.forEach((role) => {
      result = result && userRoles.indexOf(role) !== -1;
    });
  }

  return result;
}
