const Off = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.58333 1.75H6.41667V7.58333H7.58333V1.75ZM10.4008 3.01583L9.5725 3.84417C10.4942 4.585 11.0833 5.7225 11.0833 7C11.0833 9.2575 9.2575 11.0833 7 11.0833C4.7425 11.0833 2.91667 9.2575 2.91667 7C2.91667 5.7225 3.50583 4.585 4.42167 3.83833L3.59917 3.01583C2.4675 3.97833 1.75 5.40167 1.75 7C1.75 9.89917 4.10083 12.25 7 12.25C9.89917 12.25 12.25 9.89917 12.25 7C12.25 5.40167 11.5325 3.97833 10.4008 3.01583Z"
      fill={props.color ? props.color : '#FFFFFF'}
    />
  </svg>
);
export default Off;
