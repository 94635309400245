export function stickyAgHeader() {
  const grid = document.querySelector('#myGrid');
  const wrapper = document.querySelector('#wrapper');
  if (
    grid.getBoundingClientRect().top <= 0 &&
    !wrapper?.hasAttribute('hidden')
  ) {
    !grid.classList.contains('fixed') && grid.classList.add('fixed');
  } else grid.classList.contains('fixed') && grid.classList.remove('fixed');
}
