import {
  RECEIVE_MV,
  REQUEST_MV_EXPORT,
  RECEIVE_MV_EXPORT,
  REQUEST_MV_LIST,
  RECEIVE_MV_LIST,
  UPDATE_MV_LINE,
} from '../actions/action-mv';
import { debugStore as debug } from '../../lib/debug';

const types = ['aircon', 'deck', 'mh'];
const actions = [
  'disconn',
  'transport',
  'storage',
  'transport_bis',
  'reconn',
  'disposal',
];

// -------------------------------------------------------------------------------------------
// Manage `store.mv`.
// -------------------------------------------------------------------------------------------
export function handleMv(previousState = {}, action) {
  let mergeMvState;
  switch (action.type) {
    case RECEIVE_MV:
      debug(
        'reducer RECEIVE_MV: previousState=',
        previousState,
        'action=',
        action,
      );
      // Copy _id into id to be compatible with the Grid.
      // if (action.data && action.data.length) {
      //   action.data.map(d => {
      //     d.id = d._id
      //   })
      // }
      // prevent empty properties to persist from previous object :
      if (
        typeof action.data === 'object' &&
        Object.keys(action.data).length > 0
      ) {
        mergeMvState = Object.assign({}, action.data);
        // debug('mergeMvState: ', mergeMvState)
      } else {
        mergeMvState = previousState;
      }

      // if (mergeMvState?.connections?.aircon?.disconn?.status) {
      //   mergeMvState.connections.aircon.disconn.status = { _id: mergeMvState?.connections?.aircon?.disconn?.status?._id, lib: mergeMvState?.connections?.aircon?.disconn?.status?.lib };
      // }

      // for (const type of types) {
      //   for (const action of actions) {
      //     if (mergeMvState?.connections?.[type]?.[action]?.status?._id) {
      //       mergeMvState.connections[type][action].status = { _id: mergeMvState?.connections?.[type]?.[action]?.status?._id, lib: mergeMvState?.connections?.[type]?.[action]?.status?.lib };
      //     }
      //   }
      // }

      // console.log('mv: mergeMvState: ', mergeMvState);

      return mergeMvState;

    default:
      return previousState;
  }
}

// -------------------------------------------------------------------------------------------
// Manage `store.mvList`.
// -------------------------------------------------------------------------------------------
//
// Update data (mvList) in state, provided by the Mobilhomes API call (action `fetchMvList`).
//
export function handleMvList(
  previousState = {
    isFetching: false,
    lastUpdated: 0,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_MV_LIST:
    case REQUEST_MV_EXPORT:
      debug(
        'reducer REQUEST_MV_LIST/REQUEST_MV_EXPORT: previousState=',
        previousState,
        'action=',
        action,
      );
      return Object.assign({}, previousState, { isFetching: true });

    case RECEIVE_MV_LIST:
      debug(
        'reducer RECEIVE_MV_LIST: previousState=',
        previousState,
        'action=',
        action,
      );

      return Object.assign({}, previousState, {
        isFetching: false,
        lastUpdated: action.receivedAt,
      });

    case RECEIVE_MV_EXPORT:
      debug(
        'reducer RECEIVE_MV_EXPORT: previousState=',
        previousState,
        'action=',
        action,
      );
      return Object.assign({}, previousState, { isFetching: false });

    case UPDATE_MV_LINE:
      debug(
        'reducer UPDATE_MV_LINE: previousState=',
        previousState,
        'action=',
        action,
      );
      // console.log('JYO: action: ', action);
      const newState = { ...previousState };
      newState.data = newState?.data?.map((elem) => {
        // console.log('JYO: elem: ', elem)
        if (elem._id === action?.data?._id) {
          // console.log('JYO: MH FOUNDED !!!');
          elem.connections = action?.data?.connections;
        }

        return elem;
      });

      // console.log('JYO: mv: UPDATE_MV_LINE: newState: ', newState);
      return newState;

    default:
      return previousState;
  }
}
