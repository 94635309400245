/**
 * @param {string} string the text you would like to display
 * @returns console output
 */

export const logInfo = (string) => {
  return console.info(
    `%c🧐 ${string}`,
    'background: #0a3d62; color: white; padding: 5px 10px',
  );
};

export const logError = (string) => {
  return console.error(
    `%c👎 ${string}`,
    'background: #b71540; color: white; padding: 5px 10px',
  );
};

export const logSuccess = (string) => {
  return console.info(
    `%c👍🏾 ${string}`,
    'background: #78e08f; color: black; padding: 5px 10px',
  );
};
