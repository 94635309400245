import IssueReport from './IssueReporting';
import style from './style.module.scss';

export default ({ unitID, reports, history, updateIssueReporting }) => {
  return (
    <ul className={style['issues-reporting']}>
      {reports.map((report, index) => (
        <IssueReport
          updateIssueReporting={updateIssueReporting}
          key={index}
          unitID={unitID || 'test'}
          report={report}
        />
      ))}
    </ul>
  );
};
