import { find, get } from 'lodash';

export default class RefDatas {
  constructor(refDatas) {
    this.refDatas = refDatas;
  }

  static GROUPED_REF_DATA = ['RefTypeCommercial', 'Site'];

  // Format data for React-select
  getRefSelectFormatedData(refLabel) {
    let aRef = [];
    if (refLabel && this.refDatas && this.refDatas[refLabel]) {
      this.refDatas[refLabel].forEach((el) => {
        let stage = { value: el.val, label: el.label };
        if (el.options && el.options.length > 0) {
          stage.options = [];
          stage.options.push(
            ...el.options.map((a) => ({
              value: a.val,
              label: a.label,
              _id: a.val,
            })),
          );
        }
        aRef.push(stage);
      });
    }
    return aRef;
  }

  getRefData(refColl) {
    if (refColl && this.refDatas && this.refDatas[refColl]) {
      const obj = this.refDatas[refColl].map((o) => {
        o._id = o.val;
        return o;
      });
      // console.log('obj: ', obj)
      return obj;
      //return this.refDatas[refColl]
    }
    return {};
  }

  // Get complete value from values with provided id
  static getValueObjFromId(id, values, isGroup, key = 'val', defaultValue) {
    let realValue;
    if (id) {
      if (isGroup) {
        values.forEach((groupedOption) => {
          if (realValue) return;
          realValue = find(groupedOption.options, { [key]: id });
        });
      } else {
        realValue = find(values, { [key]: id });
      }
    }
    // console.log('getValueObjFromId => realValue: ', realValue)
    return realValue;
  }

  //
  // Return the corresponding label from an ObjectId of a ref data field.
  //
  static getLabelFromRefField(
    initialValues,
    refDataValues,
    key,
    defaultLabel = 'Unknown',
    isGroup = false,
  ) {
    let id = get(initialValues, key);
    // Temporary fix id because object not same after saving and go back to page
    if (id?._id) {
      id = id._id;
    }
    let label;
    if (id) {
      const obj = RefDatas.getValueObjFromId(id, refDataValues, isGroup, 'val');
      if (obj) label = obj.label;
      else label = defaultLabel;
    } else {
      label = '-';
    }

    return label;
  }
}
