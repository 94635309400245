import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import style from './style.module.scss';
import SettingButton from '@components/buttons/SettingButton';
import Excel from '@components/icons/Excel';
import ResetFilter from '@components/icons/ResetFilter';
import ExportButton from '@components/buttons/ExportButton';
import FlagCellRenderer from '@components/cells/FlagCellRenderer';
import classnames from 'classnames';
import LinkCellRenderer from '@components/cells/LinkCellRenderer';
import TagCellRenderer from '@components/cells/TagCellRenderer';
import TransferTypesCellRenderer from '@components/cells/TransferTypesCellRenderer';
import FullScreen from '@components/icons/FullScreen';
import ExitFullScreen from '@components/icons/ExitFullScreen';

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

export default ({
  data,
  headers = ['Season', 'Barcode', 'From', 'Status'],
}) => {
  const { t } = useTranslation();
  const gridRef = useRef(null);

  const defaultFilter = {
    filter: 'agSetColumnFilter',
    floatingFilterComponentParams: { suppressFilterButton: false },
  };

  const preformattedColumns = [
    {
      colId: 'selection',
      headerName: '☑︎',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: 'left',
      width: 50,
      suppressMenu: true,
      suppressSizeToFit: true,
    },
    {
      field: '_id',
      headerName: 'See Transfer Details',
      valueFormatter: (params) => `/mv/${params.value}`,
      width: 100,
      cellRenderer: 'linkCellRenderer',
    },
    {
      field: 'season',
      headerName: 'Season',
      ...defaultFilter,
    },
    {
      field: 'barcode',
      headerName: 'Barcode',
      ...defaultFilter,
      cellStyle: { 'font-weight': 600 },
    },
    {
      field: 'mobilhome_id',
      headerName: 'See Unit Details',
      valueFormatter: (params) => `/mh/${params.value}`,
      cellRenderer: 'linkCellRenderer',
      width: 100,
    },
    {
      headerName: 'Commercial',
      headerClass: 'groupColumnHeader',
      children: [
        {
          field: 'commercial_brand_from.lib',
          headerName: 'Brand from',
          cellRenderer: 'flagCellRenderer',
          ...defaultFilter,
        },
        {
          field: 'commercial_brand_to.lib',
          headerName: 'Brand to',
          cellRenderer: 'flagCellRenderer',
          ...defaultFilter,
        },
        {
          field: 'commercial_type_from.lib',
          headerName: 'Type from',
          ...defaultFilter,
        },
        {
          field: 'commercial_type_to.lib',
          headerName: 'Type to',
          ...defaultFilter,
        },
      ],
    },
    {
      headerName: 'Deck',
      headerClass: 'groupColumnHeader',
      children: [
        {
          field: 'deck_type_from.lib',
          headerName: 'Deck from',
          ...defaultFilter,
        },
        {
          field: 'deck_type_to.lib',
          headerName: 'Deck to',
          ...defaultFilter,
        },
        {
          field: 'deck_size_from.lib',
          headerName: 'Size from',
          ...defaultFilter,
        },
        {
          field: 'deck_size_to.lib',
          headerName: 'Size to',
          ...defaultFilter,
        },
        {
          field: 'deck_manufacturer_from.lib',
          headerName: 'Manufacturer from',
          ...defaultFilter,
        },
        {
          field: 'deck_manufacturer_to.lib',
          headerName: 'Manufacturer to',
          ...defaultFilter,
        },
      ],
    },
    {
      headerName: 'Use type',
      headerClass: 'groupColumnHeader',
      children: [
        {
          field: 'use_type_from.lib',
          headerName: 'From',
          cellRenderer: 'tagCellRenderer',
          ...defaultFilter,
        },
        {
          field: 'use_type_to.lib',
          headerName: 'To',
          cellRenderer: 'tagCellRenderer',
          ...defaultFilter,
        },
      ],
    },
    {
      headerName: 'Pitch',
      headerClass: 'groupColumnHeader',
      children: [
        {
          field: 'pitch_from',
          headerName: 'From',
          ...defaultFilter,
        },
        {
          field: 'pitch_to',
          headerName: 'To',
          ...defaultFilter,
        },
      ],
    },
    {
      headerName: 'Campsite from',
      headerClass: 'groupColumnHeader',
      children: [
        {
          field: 'site_from._id',
          headerName: 'See Campsite Details',
          valueFormatter: (params) => `/site/${params.value}`,
          cellRenderer: 'linkCellRenderer',
          width: 100,
        },
        {
          field: 'site_from.lib',
          headerName: 'Campsite name',
          ...defaultFilter,
        },
        {
          field: 'site_from.loca.city',
          columnGroupShow: 'closed',
          headerName: 'City',
          ...defaultFilter,
        },
        {
          field: 'site_from.loca.region',
          columnGroupShow: 'closed',
          headerName: 'Region',
          ...defaultFilter,
        },
        {
          field: 'site_from.loca.country',
          columnGroupShow: 'closed',
          headerName: 'Country',
          ...defaultFilter,
        },
        {
          field: 'site_from.code_group',
          headerName: 'Group Code',
          ...defaultFilter,
        },
        {
          field: 'site_from.address',
          headerName: 'Address',
          columnGroupShow: 'closed',
          ...defaultFilter,
        },
        {
          field: 'site_from.zip_code',
          headerName: 'Zip code',
          columnGroupShow: 'closed',
          ...defaultFilter,
        },
      ],
    },
    {
      headerName: 'Campsite to',
      headerClass: 'groupColumnHeader',
      children: [
        {
          field: 'site_to._id',
          headerName: 'See Campsite Details',
          valueFormatter: (params) => `/site/${params.value}`,
          cellRenderer: 'linkCellRenderer',
          width: 100,
        },
        {
          field: 'site_to.lib',
          headerName: 'Campsite name',
          ...defaultFilter,
        },
        {
          field: 'site_to.loca.city',
          columnGroupShow: 'closed',
          headerName: 'City',
          ...defaultFilter,
        },
        {
          field: 'site_to.loca.region',
          columnGroupShow: 'closed',
          headerName: 'Region',
          ...defaultFilter,
        },
        {
          field: 'site_to.loca.country',
          headerName: 'Country',
          columnGroupShow: 'closed',
          ...defaultFilter,
        },
        {
          field: 'site_to.code_group',
          headerName: 'Group code',
          ...defaultFilter,
        },
        {
          field: 'site_to.address',
          headerName: 'Address',
          columnGroupShow: 'closed',
          ...defaultFilter,
        },
        {
          field: 'site_to.zip_code',
          headerName: 'Zip code',
          columnGroupShow: 'closed',
          ...defaultFilter,
        },
      ],
    },
    {
      field: 'transfer_types',
      headerName: 'Transfer types',
      ...defaultFilter,
      cellRenderer: 'transferTypesCellRenderer',
      valueGetter: (params) =>
        params.data.transfer_types.map((type) => type.lib),
    },
    {
      field: 'locked',
      headerName: 'Locked',
      ...defaultFilter,
      valueFormatter: (params) => (params.value ? '🔒' : '🔓'),
    },
    {
      field: 'updated_at',
      headerName: 'Updated at',
      ...defaultFilter,
    },
  ];

  const [rowData, setRowData] = useState(data);
  const [columnDefs, setColumnDefs] = useState(preformattedColumns);
  const [nbItemsSelected, setNbItemsSelected] = useState(null);
  const [enabledFullScreen, setEnabledFullScreen] = useState(false);

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
  };

  const resetFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  const onCopyToExcel = useCallback(() => {
    gridRef.current.api.copySelectedRowsToClipboard(true);
  });

  const toggleFullScreen = useCallback(() => {
    setEnabledFullScreen(!enabledFullScreen);
    document.body.classList.toggle('disabled-scrolling');
  });

  useEffect(() => {
    const nbItems = gridRef.current.api.getSelectedNodes().length;
    setNbItemsSelected(nbItems);
    document.body.classList.remove('disabled-scrolling');

    gridRef.current.api.addEventListener('selectionChanged', () => {
      const nbItems = gridRef.current.api.getSelectedNodes().length;
      setNbItemsSelected(nbItems);
    });
  }, []);

  return (
    <div
      className={classnames(
        style['in-and-out-ag-grid'],
        enabledFullScreen && style['fullscreen'],
      )}
    >
      <div className={style['in-and-out-cta']}>
        <div className={style['setting-buttons']}>
          <SettingButton
            label={t('common.action.reset_filter', 'Reset applied filters')}
            action={resetFilters}
          >
            <ResetFilter color="#333" />
          </SettingButton>
          <SettingButton
            label={
              !enabledFullScreen
                ? t('common.action.full_screen', 'Switch to full screen')
                : t('common.action.exit_full_screen', 'Exit full screen')
            }
            action={toggleFullScreen}
          >
            {!enabledFullScreen ? (
              <FullScreen color="var(--color-default)" />
            ) : (
              <ExitFullScreen />
            )}
          </SettingButton>
        </div>
        <div>
          <ExportButton
            action={onCopyToExcel}
            disabled={nbItemsSelected < 1}
            label={t('common.action.copy_to_clipboard', 'Copy to clipboard')}
            description={
              nbItemsSelected === 0
                ? t('common.label.no_selected', '0 selected')
                : `${nbItemsSelected} ${t('common.label.selected', 'selected')}`
            }
            invert={true}
          >
            <Excel />
          </ExportButton>
        </div>
      </div>
      <div className={classnames(style['grid-wrapper'], 'ag-theme-alpine')}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          rowSelection="multiple"
          animateRows={true}
          filter={true}
          defaultColDef={{
            resizable: true,
            sortable: true,
            floatingFilter: true,
            suppressMenu: true,
          }}
          pagination={true}
          sideBar={sideBar}
          headerHeight={35}
          floatingFiltersHeight={35}
          frameworkComponents={{
            flagCellRenderer: FlagCellRenderer,
            transferTypesCellRenderer: TransferTypesCellRenderer,
            linkCellRenderer: LinkCellRenderer,
            tagCellRenderer: TagCellRenderer,
          }}
        />
      </div>
    </div>
  );
};
