import style from './style.module.scss';
import chat from '@assets/images/pwa/discussions.png';
import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

export default (props) => {
  const { items, selectedIndex } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const [pressed, setPressed] = useState(false);
  const limitSwipeLength = 0.5;

  function handleTouchStart(event) {
    setPressed(true);
    setStartX(event.touches[0].clientX);
  }

  function handleTouchMove(event) {
    if (pressed) {
      setEndX(event.touches[0].clientX);
    }
  }

  function resetPosition() {
    setEndX(0);
    setStartX(0);
  }

  function handleTouchEnd(event) {
    setPressed(false);
    if (Math.abs(endX) > 0) {
      const sign = endX - startX > 0 ? 1 : -1;
      const swipeLength = Math.abs((endX - startX) / window.innerWidth);
      if (swipeLength >= limitSwipeLength) {
        setEndX(endX + 50 * sign);
        setCurrentIndex(
          currentIndex + sign < 0
            ? items.length - 1
            : currentIndex + sign > items.length - 1
            ? 0
            : currentIndex + sign,
        );
      } else {
        setEndX(0);
        setStartX(0);
      }
    } else {
      setEndX(0);
      setStartX(0);

      selectedIndex('news', currentIndex);
    }
  }

  function handleClick() {
    selectedIndex('news', currentIndex);
  }

  useEffect(() => {
    if (items.length > 1) {
      carousel.current.addEventListener('touchstart', handleTouchStart);
      carousel.current.addEventListener('touchmove', handleTouchMove);
      carousel.current.addEventListener('touchend', handleTouchEnd);
    } else {
      carousel.current.addEventListener('click', handleClick);
    }
    return () => {
      if (items.length > 1) {
        carousel.current.removeEventListener('touchstart', handleTouchStart);
        carousel.current.removeEventListener('touchmove', handleTouchMove);
        carousel.current.removeEventListener('touchend', handleTouchEnd);
      } else {
        carousel.current.removeEventListener('click', handleClick);
      }
    };
  }, [startX, endX, pressed]);

  const dragPositionX =
    endX && endX !== startX
      ? {
          transform: `translateX(${endX - startX}px)`,
        }
      : {};

  return (
    <div className={style['carousel-component']}>
      <div className={style['carousel']} ref={carousel}>
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
            onExited={resetPosition}
            key={currentIndex}
            timeout={300}
            classNames="fade"
          >
            <div className={style['carousel-item']} style={dragPositionX}>
              <div className={style['carousel-content']}>
                <div className={style['carousel-tag']}>
                  🔔  {items[currentIndex].tag}
                </div>
                <span className={style['carousel-title']}>
                  {items[currentIndex].title}
                </span>
              </div>
              <figure className={style['carousel-image']}>
                <img src={chat} alt="discussions" />
              </figure>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <div className={style['carousel-pagination']}>
        <div className={style['dots']}>
          {items.length > 1 &&
            items.map((_, index) => (
              <div
                key={index}
                className={classnames(
                  style['dot'],
                  style[currentIndex === index && 'active'],
                )}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
