import MainHeader from '@components/pwa/MainHeader';
import style from './style.module.scss';

import { submitUnitReport } from '@pages/pwa/ScannerSubmit';
import loudspeaker from '@assets/images/pwa/loudspeaker.png';
import Edit from '@components/icons/Edit';
import BaseButton from '@components/pwa/buttons/BaseButton';
import Letter from '@components/icons/Letter';
import { useRef } from 'react';
// Cache
import CacheManager from '@helpers/cache-helper';

// Helpers
const cache = new CacheManager();

export default (props) => {
  const { unit, close } = props;
  const form = useRef(null);

  function saveData(e) {
    e.preventDefault();
    const formData = new FormData(form.current);
    const data = {};
    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }

    if (!data?.description || !data.category) {
      window.alert(
        '⚠️ Your description or category is empty. Please make sure to describe as specifically as possible.',
      );
      return;
    }

    // _id
    data['unit_id'] = unit._id;
    delete data._id;

    if (window.navigator?.onLine) {
      return submitUnitReport(data).then((response) => {
        if (response.status >= 400) {
        }
        if (navigator.vibrate) navigator.vibrate(200);
        window.alert(
          '🎆 Thank you for your reporting. We will fix it as soon as possible.',
        );
        form.current.reset();
        close();
      });
    } else {
      window.alert(
        '⚠️ Please note, your report will be uploaded to the server when you will be online.',
      );
      cache.readData('requests', (array) => {
        if (!array) {
          // null
          cache.writeData('reports', [data]).then(() => {
            form.current.reset();
            close();
          });
        } else {
          // Exists
          cache.writeData('reports', [...array, data]).then(() => {
            form.current.reset();
            close();
          });
        }
        return null;
      });
    }
  }

  return (
    <div className={style['report-details']}>
      <div className={style['report-header']}>
        <MainHeader title={'Report an issue'} action={() => close()} />
      </div>
      <div className={style['report-content']}>
        <header className={style['report-content-header']}>
          <figure>
            <img src={loudspeaker} alt="image" />
          </figure>
          <div className={style['report-content-header-data']}>
            <span>Report an issue</span>
            <p>Something wrong on the current unit.</p>
          </div>
        </header>
        <form
          ref={form}
          className={style['report-form']}
          onSubmit={saveData}
          encType="multipart/form-data"
        >
          <label>
            <span>
              Category*
              <Edit color="#D1D1D1" />
            </span>
            <select required="true" defaultValue={''} name="category">
              <option value="" selected disabled>
                Issue category
              </option>
              <option>Dimension</option>
              <option>Pitch</option>
            </select>
          </label>

          <label>
            <span>
              Message*
              <Edit color="#D1D1D1" />
            </span>
            <textarea
              name="description"
              rows="10"
              required="true"
              placeholder="Describe the current situation."
            ></textarea>
          </label>
          <div className={style['scanner']}>
            <BaseButton
              category="Note"
              content="Make sure to fill every fields before submitting."
              label="Send the report"
              type="submit"
              svg={<Letter />}
              action={saveData}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
