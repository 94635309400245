export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8 11.65L15.675 14.475C15.825 14.625 15.9 14.8042 15.9 15.0125C15.9 15.2208 15.825 15.4 15.675 15.55C15.525 15.7 15.35 15.775 15.15 15.775C14.95 15.775 14.775 15.7 14.625 15.55L11.525 12.5C11.4416 12.4167 11.3833 12.3292 11.35 12.2375C11.3166 12.1458 11.3 12.05 11.3 11.95V7.675C11.3 7.45833 11.3708 7.27917 11.5125 7.1375C11.6541 6.99583 11.8333 6.925 12.05 6.925C12.2666 6.925 12.4458 6.99583 12.5875 7.1375C12.7291 7.27917 12.8 7.45833 12.8 7.675V11.65ZM11.925 21C9.59165 21 7.61248 20.25 5.98748 18.75C4.36248 17.25 3.39165 15.375 3.07498 13.125C3.04165 12.8917 3.08748 12.6917 3.21248 12.525C3.33748 12.3583 3.51665 12.2667 3.74998 12.25C3.94998 12.2333 4.12498 12.2958 4.27498 12.4375C4.42498 12.5792 4.51665 12.75 4.54998 12.95C4.83331 14.8167 5.64998 16.375 6.99998 17.625C8.34998 18.875 9.99165 19.5 11.925 19.5C14.0416 19.5 15.8333 18.7583 17.3 17.275C18.7666 15.7917 19.5 13.9917 19.5 11.875C19.5 9.80833 18.7583 8.0625 17.275 6.6375C15.7916 5.2125 14.0083 4.5 11.925 4.5C10.7916 4.5 9.72915 4.75833 8.73748 5.275C7.74581 5.79167 6.88331 6.475 6.14998 7.325H8.02498C8.24165 7.325 8.42081 7.39583 8.56248 7.5375C8.70415 7.67917 8.77498 7.85833 8.77498 8.075C8.77498 8.29167 8.70415 8.47083 8.56248 8.6125C8.42081 8.75417 8.24165 8.825 8.02498 8.825H4.29998C4.08331 8.825 3.90415 8.75417 3.76248 8.6125C3.62081 8.47083 3.54998 8.29167 3.54998 8.075V4.375C3.54998 4.15833 3.62081 3.97917 3.76248 3.8375C3.90415 3.69583 4.08331 3.625 4.29998 3.625C4.51665 3.625 4.69581 3.69583 4.83748 3.8375C4.97915 3.97917 5.04998 4.15833 5.04998 4.375V6.275C5.91665 5.25833 6.94581 4.45833 8.13748 3.875C9.32915 3.29167 10.5916 3 11.925 3C13.175 3 14.35 3.23333 15.45 3.7C16.55 4.16667 17.5125 4.80417 18.3375 5.6125C19.1625 6.42083 19.8125 7.36667 20.2875 8.45C20.7625 9.53333 21 10.7 21 11.95C21 13.2 20.7625 14.375 20.2875 15.475C19.8125 16.575 19.1625 17.5333 18.3375 18.35C17.5125 19.1667 16.55 19.8125 15.45 20.2875C14.35 20.7625 13.175 21 11.925 21Z"
      fill={props.color ? props.color : '#FFF'}
    />
  </svg>
);
