import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
const REACT_APP_GA_ID = `${process.env.REACT_APP_GA_ID}`;
import { CSSTransition } from 'react-transition-group';
import CookieBanner from '@components/common/CookieBanner';
import { useCookies } from 'react-cookie';

// Store
import getStore from '@store/store';
import { updateGoogleAnalyticsState } from '@store/actions/action-common';

// Logs
import { logSuccess } from '@helpers/logs';

// Components
import Cookie from '@components/icons/Cookie';

// Style
import style from './style.module.scss';
import dayjs from 'dayjs';
import { getUserSetting } from '@security/User';

export default (props) => {
  const {
    acceptedLabel,
    declinedLabel,
    declinedAction,
    acceptedAction,
    history,
  } = props;

  const [cookies, setCookie, removeCookie] = useCookies(['enable_ga']);
  // TODO : CLEAN IT UP
  const [show, setShow] = useState(
    cookies?.enable_ga === undefined || cookies?.enable_ga == 'false',
  );

  useEffect(() => {
    if (!show) {
      // Accepted
      logSuccess('Cookie initialized!');
      ReactGA.initialize(REACT_APP_GA_ID);
      getStore().dispatch(updateGoogleAnalyticsState(true));

      if (history && !window.isProgressiveWebApp()) {
        history.listen((location) => {
          getUserSetting('_id') &&
            ReactGA.event('menu_navigation', {
              location: `${location.pathname} + ${location.search}`,
              user_id: getUserSetting('_id').toString(),
              dateTime: dayjs().format(),
            });
        });
      }
    }
  }, []);

  return (
    <div className={style['cookie-wrapper']}>
      <CSSTransition in={show} classNames="alert" timeout={300} unmountOnExit>
        <div className={style['cookie-content']}>
          <CookieBanner
            acceptedLabel={acceptedLabel}
            acceptedAction={acceptedAction}
            declinedLabel={declinedLabel}
            declinedAction={declinedAction}
            history={props.history}
            close={() => setShow(false)}
          />
        </div>
      </CSSTransition>
      <CSSTransition in={show} classNames="alert" timeout={300} unmountOnExit>
        <button
          className={style['cookie-button']}
          type="button"
          onClick={() =>
            cookies.enable_ga !== undefined ||
            (cookies?.enable_ga == 'false' && setShow(!show))
          }
        >
          <Cookie />
        </button>
      </CSSTransition>
    </div>
  );
};
