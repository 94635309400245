import classnames from 'classnames';
import style from './style.module.scss';

export default ({ icon: Icon, title, subtitle, light = false }) => {
  return (
    <header
      className={classnames(
        style['content-header'],
        light ? style['light'] : null,
      )}
    >
      <div className={style['icon-wrapper']}>
        <Icon color={'#194989'} />
      </div>
      <div>
        <h1>{title}</h1>
        {subtitle && <small>{subtitle}</small>}
      </div>
    </header>
  );
};
