import React, { useState } from 'react';

import GoogleMapReact from 'google-map-react';
const REACT_APP_GOOGLE_MAP_KEY = `${process.env.REACT_APP_GOOGLE_MAP_KEY}`;
import Tippy from '@tippyjs/react';
import styles from '@assets/json/map.json';
import darkStyles from '@assets/json/darkmap.json';
// import marker from '@assets/images/map/marker.png';
import BaseButton from '@components/buttons/BaseButton';
import classnames from 'classnames';
import Marker from './Marker';
import BulletList from '@components/icons/BulletList';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

function grade(cluster) {
  switch (cluster) {
    case 'A':
      return '★★★';
    case 'A-':
      return '★★★–';
    case 'A+':
      return '★★★＋';
    case 'B':
      return '★★';
    case 'B-':
      return '★★–';
    case 'B+':
      return '★★＋';
    case 'C':
      return '★';
    case 'C-':
      return '★–';
    case 'C+':
      return '★＋';
  }
}

export default (props) => {
  const { t } = useTranslation();

  let campsites = [...props.campsites];

  // ? Remove unused campsites without location
  campsites = campsites.filter(
    (campsite) => !['OUT', 'STORAGE', 'FACTORY'].includes(campsite.code_group),
  );

  let timer;

  const darkmode =
    JSON.parse(localStorage.getItem('prefers-color-scheme')) || false;

  const filter = (e) => {
    const keyword = e.target.value;

    clearTimeout(timer);

    timer = setTimeout(() => {
      if (keyword !== '') {
        const results = campsites.filter((campsite) => {
          return (
            campsite?.lib.toLowerCase().includes(keyword.toLowerCase()) ||
            campsite?.code_group.toLowerCase().startsWith(keyword.toLowerCase())
          );
        });
        setFilteredCampsites(results);
      } else {
        setFilteredCampsites(campsites);
      }
    }, 500);
  };

  campsites = campsites.map((campsite) => {
    return {
      _id: campsite._id,
      lib: campsite.lib,
      abc_cluster: campsite.abc_cluster,
      code_group: campsite.code_group,
      depot: campsite.depot,
      in_season_depot: campsite?.in_season_depot,
      area_managers: [
        campsite?.french_brand_area
          ? { ...campsite?.french_brand_area, flag: 'fr' }
          : null,
        campsite?.brit_brand_area
          ? { ...campsite?.brit_brand_area, flag: 'gb' }
          : null,
        campsite?.roan_contact
          ? { ...campsite?.roan_contact, flag: 'nl' }
          : null,
      ],
      rmt_regions: campsite?.rmt_regions,
      gps: campsite?.gps,
      safety_commissioning_report: campsite?.safety_commissioning_report,
      units_number: campsite?.units_number,
    };
  });

  const [filteredCampsites, setFilteredCampsites] = useState(campsites);
  const [center, setCenter] = useState({
    lat: 49.056485344048454,
    lng: 2.262508712992433,
  });
  const [zoom, setZoom] = useState(5);
  const [showList, setShowList] = useState(false);
  const [currentCampsiteActive, setCurrentCampsiteActive] = useState(null);

  return (
    <div className={style['campsites-map']}>
      <div className={style['campsites-list-button']}>
        <BaseButton
          action={() => setShowList(!showList)}
          label={
            showList
              ? t('common.action.close_list', 'Close list')
              : t('common.action.open_list', 'Open list')
          }
        >
          <BulletList />
        </BaseButton>
      </div>

      <div className={classnames(style['list'], showList ? style['open'] : '')}>
        <label>
          <span>
            {t('page.campsites.label.map_view.name_code', 'NAME/CODE')}
          </span>
          <input
            onChange={filter}
            type="text"
            placeholder={t(
              'page.campsites.label.map_view.placeholder',
              'Enter text here',
            )}
            autoFocus
          />
        </label>
        <ul>
          {filteredCampsites.map((campsite, index) => (
            <li
              key={index}
              onClick={() => {
                setZoom(10);
                setCenter({
                  lat: Number(campsite?.gps?.lat),
                  lng: Number(campsite?.gps?.lon),
                });
                setShowList(false);
                setCurrentCampsiteActive(
                  currentCampsiteActive === campsite.code_group
                    ? null
                    : campsite.code_group,
                );
              }}
            >
              <header>
                <h4>
                  {campsite.lib} | {campsite.code_group}
                  <p>{grade(campsite.abc_cluster)}</p>
                </h4>
              </header>
              <div className={style['details']}>
                <ul>
                  {campsite.area_managers.some((item) => item !== null) ? (
                    campsite.area_managers.map((am, index) => {
                      if (am === null) return;

                      return (
                        <li key={index}>
                          <div>
                            <img
                              src={
                                require(`@assets/images/flags/${am.flag}.svg`)
                                  .default
                              }
                              alt={am.flag}
                            />
                            <span>Area Manager</span>
                          </div>
                          <div>
                            {Object.keys(am).map((key, i) => {
                              if (
                                key === 'lib' ||
                                key.indexOf('email') > 0 ||
                                key.indexOf('phone') > 0
                              )
                                return <p key={i}>{am[key]}</p>;
                            })}
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <p className={style['contact-unavailable']}>
                      AM not available.
                    </p>
                  )}
                </ul>
                {campsite?.rmt_regions.length > 0 ? (
                  <ul className={style['rmt']}>
                    <li>
                      <div>🛠️ RMT</div>
                      {/* {campsite?.rmt_region && ( */}
                      <div>
                        {campsite.rmt_regions.map((region, i) => (
                          <div key={i}>
                            <Tippy content="Region code" placement="top">
                              <span className={style['list-header']}>
                                {region._id}
                              </span>
                            </Tippy>
                            {region.members.map((member, k) => (
                              <ul key={k}>
                                <li className={style['list-title']}>RMT</li>
                                <li>
                                  {member?.firstname}{' '}
                                  {member?.lastname?.toUpperCase()} (
                                  {member.code})
                                </li>
                                <li>{member?.email}</li>
                                <li>{member?.phone}</li>
                                <li>{member?.language}</li>
                                <li className={style['list-title']}>RMM</li>
                                <li>
                                  {member.rmm?.firstname} {member.rmm?.lastname}{' '}
                                  ({member.rmm?.code})
                                </li>
                                <li>{member.rmm?.email}</li>
                                <li>{member.rmm?.phone}</li>
                                <li>{member.rmm?.language}</li>
                              </ul>
                            ))}
                          </div>
                        ))}
                      </div>
                      {/* )} */}
                    </li>
                  </ul>
                ) : (
                  <p className={style['contact-unavailable']}>
                    RMT not available.
                  </p>
                )}
                <div className={style['depot']}>
                  {campsite?.depot && (
                    <Tippy content="Official depot" placement="top">
                      <span className={style['official']}>
                        {campsite?.depot?.toString()}
                      </span>
                    </Tippy>
                  )}
                  {campsite?.in_season_depot && (
                    <Tippy content="In season depot" placement="top">
                      <span className={style['in-season']}>
                        {campsite?.in_season_depot?.toString()}
                      </span>
                    </Tippy>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className={style['map']}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: REACT_APP_GOOGLE_MAP_KEY,
          }}
          defaultZoom={12}
          zoom={zoom}
          center={center}
          // onGoogleApiLoaded={({ map, maps }) =>
          //   setTimeout(() => this.renderMarkers(map, maps), 200)
          // }
          yesIWantToUseGoogleMapApiInternals
          options={{ styles: darkmode ? darkStyles : styles }}
        >
          {filteredCampsites.map((campsite, index) => (
            <Marker
              key={index}
              active={currentCampsiteActive === campsite.code_group}
              lat={campsite.gps.lat}
              lng={campsite.gps.lon}
              campsite={campsite}
              grade={grade(campsite.abc_cluster)}
            />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  );
};
