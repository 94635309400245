import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
const localizedFormat = require('dayjs/plugin/localizedFormat');
import dayjs from 'dayjs';
dayjs.extend(localizedFormat);
import { useTranslation } from 'react-i18next';

// Layout
import Layout from '@layout/default';

// Components
import ContentHeader from '@components/headers/ContentHeader';
import { Linear } from '@components/Loader';
import Form from '@pages/pitchFees/Form';
import FlashMessage from '@components/common/FlashMessage';
import Unit from '@components/icons/Unit';
import RedirectionButton from '@components/buttons/RedirectionButton';
import Arrow from '@components/icons/Arrow';

// Helpers
import { debugMh } from '@lib/debug';

// Store
import { fetchOne } from '@store/actions/action-pitchFees';
import { sendFlashMessage } from '@store/actions/action-common';
import getStore from '@store/store';
import style from './style.module.scss';

export default (props) => {
  const [initialData, setInitialData] = useState(null);
  const { t } = useTranslation();
  const { match } = props;
  const user = useSelector((state) => state.user);
  const refDatas = useSelector((state) => state?.refDatas?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOne(props.match.params.id))
      .then((res) => {
        setInitialData(res);

        document.title = `EUROPEAN CAMPING GROUP | ${t(
          'page.pitch_fee.document_title',
          'Pitch Fee',
        )} ${res?._id || ''}`;
      })
      .catch((err) => {
        if (err && err.code === 404) {
          err.message = `Sorry, we could not find the unit ID ${props.match.params.id}`;
        }
        dispatch(sendFlashMessage(`${err}`, 'error'));
        props.history.push('/pitch-fees');
      });
  }, [dispatch]);

  const refreshData = () => {
    props.history.push('/pitch-fees/' + initialData._id);
  };

  const backToList = () => {
    props.history.push('/pitch-fees');
  };

  // return initialData ? <div>ok</div> : 'nope';
  return (
    <div>
      {initialData?._id !== match.params.id ? (
        <Layout {...props}>
          <Linear {...props} />
        </Layout>
      ) : (
        <Layout {...props}>
          <FlashMessage />

          <div className={style['unit-form-wrapper']}>
            <div className={style['upper-header']}>
              <RedirectionButton
                icon={Arrow}
                action={backToList}
                label={t(
                  'common.action.go_back_pitch_fees',
                  'Go back to pitch fees section',
                )}
              />
            </div>
            <ContentHeader
              icon={Unit}
              // title={`${barcode} - ${chassisID}`}
              title={`${initialData?.site?.lib} - ${initialData?.site?.code_group}`}
              subtitle={`${initialData?.siblings?.length} pitch(es)`}
              // subtitle={`${t('common.label.updated_on', 'Updated on')} ${dayjs(
              //   updated_at || created_at,
              // ).format('lll')} ${t('common.label.by', 'by')} ${
              //   updated_by?.username || created_by?.username
              // }`}
            />
            <Form
              refDatas={refDatas}
              initialValues={initialData}
              {...props}
              isEdit={true}
              refreshData={refreshData}
            />
          </div>
        </Layout>
      )}
    </div>
  );
};
