import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { userLogout } from '@store/actions/action-common';
import PropTypes from 'prop-types';
import CacheManager from '@helpers/cache-helper';

const cache = new CacheManager();
class Logout extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    user: PropTypes.object,
  };

  componentDidMount() {
    cache.clear();
    this.props.dispatch(userLogout());
  }

  render() {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: '/' },
        }}
      />
    );
  }
}
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(Logout);
